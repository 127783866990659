import { logout } from 'store/auth/actions';
import {
  getCustomViews,
  saveCustomView,
  updateCustomView,
  selectCustomView,
} from './actions';

const INITIAL_STATE = {
  selected: null,
  list: [],
  listLoading: false,
};

const customViewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case selectCustomView.TRIGGER: {
      return {
        ...state,
        selected: action.payload,
      };
    }

    case getCustomViews.TRIGGER: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case getCustomViews.SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }

    case saveCustomView.SUCCESS: {
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }

    case updateCustomView.SUCCESS: {
      const editedIndex = state.list.findIndex(
        (item) => item.id === action.payload.id
      );
      if (editedIndex !== -1) state.list[editedIndex] = action.payload;
      return {
        ...state,
        list: [...state.list],
      };
    }

    case getCustomViews.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    default: {
      return state;
    }
  }
};

export default customViewsReducer;
