import React, { memo, useCallback, useMemo } from 'react';
import { array, func, string } from 'prop-types';
import clsx from 'clsx';
import noop from 'lodash/noop';

import { ReactComponent as ArrowDown } from 'assets/images/icons/arrow-down.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

const TableHeadCell = ({ id, sorts, children, onClick, onRemoveClick }) => {
  const handleColumnClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const handleAscendClickSort = useCallback(() => {
    onClick(id, 'asc');
  }, [id, onClick]);

  const handleDescendClickSort = useCallback(() => {
    onClick(id, 'desc');
  }, [id, onClick]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(id);
  }, [id, onRemoveClick]);

  const { isAcs, isDest, hasSort } = useMemo(() => {
    const sort = sorts.find((sort) => sort.startsWith(id));

    return {
      hasSort: !!sort,
      isAcs: sort === `${id}:asc`,
      isDest: sort === `${id}:desc`,
    };
  }, [sorts, id]);

  return (
    <div>
      <span className='col-label'>
        <span onClick={handleColumnClick}>{children}</span>

        <ArrowDown
          className={clsx('th-ascend', { 'is-active': isAcs })}
          onClick={handleAscendClickSort}
        />
        <ArrowDown
          className={clsx('th-descend', { 'is-active': isDest })}
          onClick={handleDescendClickSort}
        />
        {hasSort && <CloseIcon className='th-close' onClick={handleRemoveClick} />}
      </span>
    </div>
  );
};

TableHeadCell.propTypes = {
  id: string,
  sorts: array,
  onClick: func,
  onRemoveClick: func,
};

TableHeadCell.defaultProps = {
  onClick: noop,
  onRemoveClick: noop,
};

export default memo(TableHeadCell);
