import { Helmet } from 'react-helmet';
import { MAIN_TITLE } from 'settings';

const PageTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{`${title} | ${MAIN_TITLE}`}</title>
    </Helmet>
  );
};

export default PageTitle;
