import clsx from 'clsx';
import ReactDOM from 'react-dom';

import './ua-bottom-menu.styles.scss';

const UaBottomMenu = ({ children, className }) => {
  const domNode = document.querySelector('#bottom-menu-portal');
  return (
    domNode &&
    ReactDOM.createPortal(
      <div className='ua-bottom-menu'>
        <div className={clsx('ua-bottom-menu-content', className)}>
          {children}
        </div>
      </div>,
      domNode
    )
  );
};

export default UaBottomMenu;
