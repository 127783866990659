import { createSelector } from 'reselect';
import { ROLES } from 'settings';

export const getAccounts = (state) => state.accounts.list;
export const getLogoLoading = (state) => state.accounts.logoLoading;
export const getNameLoading = (state) => state.accounts.updateLoading;

export const getAccountRoles = (state) => ({
  roles: state.accounts.roles,
  isLoading: state.accounts.rolesLoading,
});

export const getAccountMembers = (state) => ({
  members: state.accounts.members,
  isLoading: state.accounts.membersLoading,
});

export const getOwnershipStatus = (state) => state.accounts.ownershipStatus;
export const getOwnershipLoading = (state) => state.accounts.ownershipLoading;

export const getOwnerAcceptStatus = (state) => state.accounts.ownerAcceptStatus;

export const getInviteStatus = (state) => ({
  invite: state.accounts.inviteStatus,
  isLoading: state.accounts.inviteLoading,
});

export const getCloseAccountStatus = (state) => ({
  accountStatus: state.accounts.closeAccountStatus,
  isLoading: state.accounts.closeAccountLoading,
});

export const getAccountSeats = (state) => state.accounts.currentAccount.seats;

export const getCurrentAccount = (state) => state.accounts.currentAccount;

export const getAccountError = (state) => state.accounts.accountError;

export const getCurrentRole = createSelector(
  getCurrentAccount,
  (account) => (!!account.account_users && account.account_users[0].role) || {}
);

export const isCurrentAccountOwner = createSelector(
  getCurrentAccount,
  ({ account_users }) =>
    account_users && account_users[0].role.slug === ROLES.OWNER
);

export const getCurrentSubscription = createSelector(
  getCurrentAccount,
  (account) => (!!account.subscriptions && account.subscriptions[0]) || {}
);

export const getIsSubscribe = createSelector(getCurrentAccount, (account) =>
  account && account.subscriptions ? !!account.subscriptions.length : true
);
