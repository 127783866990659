export const getClientsState = (state) => state.clients;

export const getClients = (state) => getClientsState(state).list;

export const getClientsLoading = (state) => ({
  getLoading: getClientsState(state).getLoading,
  addLoading: getClientsState(state).addLoading,
  editLoading: getClientsState(state).editLoading,
  deleteLoading: getClientsState(state).deleteLoading,
});
