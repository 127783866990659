import routine from '../services/routine';

export const getBoard = routine('GET_BOARD');
export const getGuestBoard = routine('GET_GUEST_BOARD');
export const editBoard = routine('EDIT_BOARD');
export const updateBoard = routine('UPDATE_BOARD');
export const addBoard = routine('ADD_BOARD');
export const copyBoard = routine('COPY_BOARD');
export const shareBoard = routine('SHARE_BOARD');
export const deleteBoard = routine('DELETE_BOARD');
export const preDeleteBoard = routine('PRE_DELETE_BOARD');
export const cancelDeleteBoard = routine('CANCEL_DELETE_BOARD');
export const preArchiveBoard = routine('PRE_ARCHIVE_BOARD');
export const cancelArchiveBoard = routine('CANCEL_ARCHIVE_BOARD');
export const setIsFavoriteBoard = routine('SET_IS_FAVORITE_BOARD');
export const addBoardMember = routine('ADD_BOARD_MEMBER');
export const removeBoardMember = routine('REMOVE_BOARD_MEMBER');

export const togglePersonal = routine('TOGGLE_PERSONAL');

export const getStatuses = routine('GET_STATUSES');
export const getTags = routine('GET_TAGS');
export const addTag = routine('ADD_TAG');
export const editTag = routine('EDIT_TAG');
export const deleteTag = routine('DELETE_TAG');
export const addStatus = routine('ADD_STATUS');
export const editStatus = routine('EDIT_STATUS');
export const deleteStatus = routine('DELETE_STATUS');

export const dndBoardStatus = routine('DND_BOARD_STATUS');
export const dndBoardProject = routine('DND_BOARD_PROJECT');
export const setBoardsSearch = routine('SET_BOARDS_SEARCH');

export const addBoardStatusFromSocket = routine('ADD_BOARD_STATUS_FROM_SOCKET');
export const editBoardStatusFromSocket = routine('EDIT_BOARD_STATUS_FROM_SOCKET');
export const removeBoardStatusFromSocket = routine('REMOVE_BOARD_STATUS_FROM_SOCKET');
