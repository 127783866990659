import { logout } from 'store/auth/actions';
import {
  getProjects,
  getGuestProjects,
  getProjectUpdates,
  editProject,
  addProject,
  addProjectUpdate,
  editProjectUpdate,
  addMember,
  archiveProjects,
  deleteMember,
  getPriorities,
  addTask,
  editTask,
  deleteTask,
  uploadFile,
  deleteFile,
  deleteProjects,
  moveProjects,
  unarchiveProjects,
  preDeleteProjects,
  cancelDeleteProjects,
  preArchiveProjects,
  cancelArchiveProjects,
  preMoveProjects,
  cancelMoveProjects,
  addProjectStatusFromSocket,
  editProjectStatusFromSocket,
  removeProjectStatusFromSocket,
  addProjectTagFromSocket,
  editProjectTagFromSocket,
  removeProjectTagFromSocket,
  updateProject,
} from './actions';
import { getTags, removeBoardMember } from 'store/boards/actions';

import { getStatuses } from 'store/boards/actions';
import { deleteClient, editClient } from 'store/clients/actions';
import { STATUSES } from 'settings';

const INITIAL_STATE = {
  list: [],
  priorities: [],
  tags: [],
  statuses: [],
  pagination: {},
  listLoading: true,
  beforeChangeList: [],
};

const projectsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case editProject.SUCCESS: {
      let index = state.list.findIndex((item) => item.id === action.payload.id);
      let projects = [...state.list];
      const members = projects[index].members
        ? [...projects[index].members]
        : [];
      projects[index] = {
        ...projects[index],
        ...action.payload,
        members: [...members],
      };

      return {
        ...state,
        list: projects,
      };
    }

    case getProjects.TRIGGER: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case getGuestProjects.TRIGGER: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case getProjects.SUCCESS: {
      return {
        ...state,
        list: action.payload.projects.data,
        pagination: action.payload.projects.pagination,
      };
    }

    case getGuestProjects.SUCCESS: {
      return {
        ...state,
        list: action.payload.projects.data,
        pagination: action.payload.projects.pagination,
      };
    }

    case addProject.SUCCESS: {
      let projects = [...state.list];
      const index = projects.findIndex(({ id }) => id === action.payload.id);

      if (index === -1) {
        projects = [{ ...action.payload, updates: [] }, ...projects];
      }

      return {
        ...state,
        list: projects,
      };
    }

    case addProjectUpdate.SUCCESS: {
      let index = state.list.findIndex(
        (item) => item.id === action.payload.project_id
      );
      let projects = [...state.list];

      if (index !== -1) {
        const updateIndex = projects[index].updates.findIndex(
          ({ id }) => id === action.payload.id
        );

        if (updateIndex === -1) {
          projects[index] = {
            ...projects[index],
            updates: [action.payload, ...projects[index].updates],
          };
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case getProjectUpdates.SUCCESS: {
      let index = state.list.findIndex((item) => item.id === action.payload.id);
      let projects = [...state.list];
      projects[index] = {
        ...projects[index],
        updates: action.payload.updateList,
      };

      return {
        ...state,
        list: projects,
      };
    }

    case editProjectUpdate.SUCCESS: {
      let projIndex = state.list.findIndex(
        (item) => item.id === action.payload.project_id
      );
      let projects = [...state.list];

      if (projIndex !== -1) {
        let updateIndex = state.list[projIndex].updates.findIndex(
          (item) => item.id === action.payload.id
        );

        if (updateIndex !== -1) {
          let updates = [...projects[projIndex].updates];
          updates[updateIndex] = action.payload;
          projects[projIndex] = {
            ...projects[projIndex],
            updates: [...updates],
          };
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case editClient.SUCCESS: {
      const projects = state.list.map((project) => {
        if (project?.client && project?.client.id === action.payload.id) {
          return {
            ...project,
            client: action.payload,
          };
        }
        return project;
      });

      return {
        ...state,
        list: projects,
      };
    }

    case updateProject.SUCCESS: {
      const projects = [...state.list];
      const index = projects.findIndex((item) => item.id === action.payload.id);
      const oldProject = projects[index];

      if (index < 0) return state;

      projects[index] = {
        ...projects[index],
        ...action.payload,
      };

      if (oldProject.board_id !== action.payload.board_id) {
        projects.splice(index, 1);
      }

      return {
        ...state,
        list: projects,
      };
    }

    case addMember.SUCCESS: {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.projectId
      );
      let projects = [...state.list];

      if (index !== -1) {
        const member = {
          user: action.payload.user,
          user_id: action.payload.user.id,
          created_at: action.payload.user.created_at,
          project_id: action.payload.projectId,
          updated_at: action.payload.user.updated_at,
        };

        const memberIndex = projects[index].members.findIndex(
          (item) => item.user_id === member.user_id
        );

        if (memberIndex === -1) {
          projects[index] = {
            ...projects[index],
            members: [...projects[index].members, member],
          };
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case preArchiveProjects.TRIGGER: {
      const projects = [...state.list];
      for (const id of action.payload) {
        const index = projects.findIndex((item) => item.id === id);
        projects[index] = { ...projects[index], is_archived: true };
      }

      return {
        ...state,
        beforeChangeList: [...state.list],
        list: projects,
      };
    }

    case cancelArchiveProjects.TRIGGER:
    case archiveProjects.FAILURE: {
      const prev = [...state.beforeChangeList];
      return {
        ...state,
        beforeChangeList: [],
        list: prev,
      };
    }

    case archiveProjects.SUCCESS: {
      const projects = [...state.list];
      for (const id of action.payload.ids) {
        const index = projects.findIndex((item) => item.id === id);
        projects[index] = { ...projects[index], is_archived: true };
      }

      return {
        ...state,
        beforeChangeList: [],
        list: projects,
      };
    }

    case unarchiveProjects.SUCCESS: {
      const projects = [...state.list];
      for (const id of action.payload.ids) {
        const index = projects.findIndex((item) => item.id === id);
        projects[index] = { ...projects[index], is_archived: false };
      }

      return {
        ...state,
        list: projects,
      };
    }

    case deleteMember.SUCCESS: {
      const projects = [...state.list];
      const index = projects.findIndex(
        (item) => item.id === action.payload.projectId
      );

      if (index !== -1) {
        const memberIndex = projects[index].members.findIndex(
          (item) => item.user_id === action.payload.memberId
        );

        if (memberIndex !== -1) {
          projects[index].members.splice(memberIndex, 1);
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case removeBoardMember.SUCCESS: {
      const boardId = state.list[0].board_id;
      if (parseInt(action.payload.board_id) !== boardId) return state;

      const projects = state.list.map((project) => {
        const members = project.members.filter(
          ({ user_id }) => user_id !== action.payload.user_id
        );

        return {
          ...project,
          members,
        };
      });

      return {
        ...state,
        list: projects,
      };
    }

    case getPriorities.SUCCESS: {
      return {
        ...state,
        priorities: action.payload,
      };
    }

    case getTags.SUCCESS: {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case getStatuses.SUCCESS: {
      return {
        ...state,
        statuses: action.payload,
      };
    }

    case addTask.SUCCESS: {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.projectId
      );
      const projects = [...state.list];

      if (index !== -1) {
        const taskIndex = projects[index].tasks.findIndex(
          (file) => file.id === action.payload.task.id
        );

        if (taskIndex === -1) {
          projects[index] = {
            ...projects[index],
            tasks: [...projects[index].tasks, action.payload.task],
          };
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case deleteTask.SUCCESS: {
      const { projectId, taskId } = action.payload;

      const projects = [...state.list];
      const index = projects.findIndex((item) => item.id === projectId);

      if (index !== -1) {
        const tasks = [...projects[index].tasks];
        const taskIndex = tasks.findIndex((item) => item.id === taskId);
        if (taskIndex !== -1) {
          tasks.splice(taskIndex, 1);
        }
        projects[index].tasks = tasks;
      }

      return {
        ...state,
        list: projects,
      };
    }

    case editTask.SUCCESS: {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.projectId
      );
      const projects = [...state.list];

      if (index !== -1) {
        const taskIndex = state.list[index].tasks.findIndex(
          (item) => item.id === action.payload.taskId
        );
        const tasks = [...projects[index].tasks];

        if (taskIndex !== -1) {
          tasks[taskIndex] = action.payload.task;
          projects[index] = { ...projects[index], tasks: [...tasks] };
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case deleteFile.SUCCESS: {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.projectId
      );
      const projects = [...state.list];

      if (index !== -1) {
        const fileIndex = projects[index].files.findIndex(
          (item) => item.id === action.payload.fileId
        );

        if (fileIndex !== -1) {
          projects[index].files.splice(fileIndex, 1);
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case uploadFile.SUCCESS: {
      const index = state.list.findIndex(
        (item) => item.id === action.payload.projectId
      );
      const projects = [...state.list];

      if (index !== -1) {
        const fileIndex = projects[index].files.findIndex(
          (file) => file.id === action.payload.file.id
        );

        if (fileIndex === -1) {
          projects[index] = {
            ...projects[index],
            files: [...projects[index].files, action.payload.file],
          };
        }
      }

      return {
        ...state,
        list: projects,
      };
    }

    case getProjects.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case preDeleteProjects.TRIGGER: {
      return {
        ...state,
        beforeChangeList: [...state.list],
        list: state.list.filter((item) => !action.payload.includes(item.id)),
      };
    }

    case cancelDeleteProjects.TRIGGER:
    case deleteProjects.FAILURE: {
      const prev = [...state.beforeChangeList];
      return {
        ...state,
        list: prev,
        beforeChangeList: [],
      };
    }

    case deleteProjects.SUCCESS: {
      return {
        ...state,
        beforeChangeList: [],
      };
    }

    case preMoveProjects.TRIGGER: {
      return {
        ...state,
        beforeChangeList: [...state.list],
        list: state.list.filter((item) => !action.payload.includes(item.id)),
      };
    }

    case cancelMoveProjects.TRIGGER:
    case moveProjects.FAILURE: {
      const prev = [...state.beforeChangeList];
      return {
        ...state,
        list: prev,
        beforeChangeList: [],
      };
    }

    case moveProjects.SUCCESS: {
      return {
        ...state,
        beforeChangeList: [],
      };
    }

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    case getGuestProjects.FULFILL: {
      return {
        ...state,
        listLoading: false,
      };
    }

    case addProjectStatusFromSocket.SUCCESS: {
      let statuses = [...state.statuses];
      const statusData = action.payload;

      const statusIndex = statuses.findIndex(
        (status) => status.id === statusData.id
      );

      if (statusIndex === -1) {
        statuses.push({ ...statusData });
      }

      return {
        ...state,
        statuses,
      };
    }

    case editProjectStatusFromSocket.SUCCESS: {
      const projects = state.list.map((project) => {
        if (project.status_id === action.payload.id) {
          return {
            ...project,
            status: action.payload,
          };
        }
        return project;
      });

      let statuses = [...state.statuses];
      const statusData = action.payload;

      const statusIndex = statuses.findIndex(
        (status) => status.id === statusData.id
      );

      if (statusIndex !== -1) {
        statuses[statusIndex] = { ...statusData };
      }

      return {
        ...state,
        statuses,
        list: projects,
      };
    }

    case removeProjectStatusFromSocket.SUCCESS: {
      const newStatus = state.statuses.find(({ slug }) => slug === STATUSES.NEW);

      const projects = state.list.map((project) => {
        if (project.status_id === action.payload.id) {
          return {
            ...project,
            status: newStatus,
            status_id: newStatus.id,
          };
        }
        return project;
      });

      let statuses = [...state.statuses];
      const statusData = action.payload;

      const statusIndex = statuses.findIndex(
        (status) => status.id === statusData.id
      );

      if (statusIndex !== -1) {
        statuses.splice(statusIndex, 1);
      }

      return {
        ...state,
        statuses,
        list: projects,
      };
    }

    case addProjectTagFromSocket.SUCCESS: {
      let tags = [...state.tags];
      const tagData = action.payload;

      const tagIndex = tags.findIndex((tag) => tag.id === tagData.id);

      if (tagIndex === -1) {
        tags.push({ ...tagData });
      }

      return {
        ...state,
        tags,
      };
    }

    case editProjectTagFromSocket.SUCCESS: {
      const projects = state.list.map((project) => {
        const tags = project.tags.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });

        return {
          ...project,
          tags,
        };
      });

      const tags = [...state.tags];
      const tagData = action.payload;

      const tagIndex = tags.findIndex((tag) => tag.id === tagData.id);

      if (tagIndex !== -1) {
        tags[tagIndex] = { ...tagData };
      }

      return {
        ...state,
        list: projects,
        tags,
      };
    }

    case removeProjectTagFromSocket.SUCCESS: {
      const projects = state.list.map((project) => {
        const tags = project.tags.filter(({ id }) => action.payload.id !== id);

        return {
          ...project,
          tags,
        };
      });

      let tags = [...state.tags];
      const tagData = action.payload;

      const tagIndex = tags.findIndex((tag) => tag.id === tagData.id);

      if (tagIndex !== -1) {
        tags.splice(tagIndex, 1);
      }

      return {
        ...state,
        list: projects,
        tags,
      };
    }

    case deleteClient.SUCCESS: {
      const projects = state.list.map((item) => {
        if (item.client?.id === action.payload) {
          return {
            ...item,
            client: null,
          };
        }
        return item;
      });

      return {
        ...state,
        list: projects,
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
