import { put, takeLatest, call } from 'redux-saga/effects';

import { updateCustomView as updateCustomViewAction } from '../actions';

import { updateCustomView as updateCustomViewApi } from '../api';

function* updateSaga({ payload }) {
  try {
    yield put(updateCustomViewAction.request());

    const customView = yield call(updateCustomViewApi, payload);

    yield put(updateCustomViewAction.success(customView));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(updateCustomViewAction.fulfill());
  }
}

export default function* update() {
  yield takeLatest(updateCustomViewAction.TRIGGER, updateSaga);
}
