import { useMemo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Alert } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { arrayOf, func, objectOf, shape, string } from 'prop-types';

// components
import UaInput from 'components/common/ua-input/ua-input.component';
import UaButton from 'components/common/ua-button/ua-button.component';
// styles
import './registration-form.styles.scss';
// images
import eyeIcon from 'assets/images/icons/eye.svg';
import { ReactComponent as BoxChecked } from 'assets/images/icons/box-checked.svg';
import { ReactComponent as Box } from 'assets/images/icons/box.svg';
// schemas
import SignUpSchema from './SignUpSchema';
// hooks
import useAlert from 'hooks/useAlert';
import useQuery from 'hooks/useQuery';
// utils
import routes from 'utils/routes';
import { SUPPORT_EMAIL } from '../../../storage/consts';
import UaCheckbox from '../../common/ua-checkbox/ua-checkbox.component';

const RegistrationForm = ({ onSubmit, error, isLoading }) => {
  const history = useHistory();
  const { state } = useLocation();
  const query = useQuery();
  const alertProps = useAlert({
    isShow: error.message,
  });
  const [isCheckedTermsOfUse, setIsCheckedTermsOfUse] = useState(false);

  useEffect(() => {
    if (query.has('email'))
      history.replace({ search: '', state: query.get('email') });
  }, []);

  const formik = useFormik({
    validationSchema: SignUpSchema,
    onSubmit,
    initialValues: {
      email: state,
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const passwordChanged = useMemo(
    () => formik.values.password.length > 0 || formik.touched.password,
    [formik.values.password, formik.touched.password]
  );

  const handleIconClick = (event) => {
    const nearestInput = event.target.parentNode.querySelector('input');
    if (nearestInput.type === 'password') {
      nearestInput.type = 'text';
    } else {
      nearestInput.type = 'password';
    }
  };

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  const handleChangeTermsOfUse = () => {
    setIsCheckedTermsOfUse((prevValue) => !prevValue);
  };

  return (
    <form
      className='form form-auth registration-form'
      onSubmit={formik.handleSubmit}>
      <h1 className='form__caption'>Let’s Sign Up!</h1>

      <Alert {...alertProps} variant='danger'>
        {error.message}
      </Alert>

      <div className='form__controls'>
        <div className='form__controls-row'>
          <UaInput
            id='firstName'
            name='firstName'
            value={formik.values.firstName}
            placeholder='Enter first name'
            label='First name'
            autocomplete='given-name'
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            showError={!!error.fields.firstName}
            error={
              (formik.touched.firstName && formik.errors.firstName) ||
              error.fields.firstName
            }
            valid={formik.touched.firstName && !formik.errors.firstName}
          />
          <UaInput
            id='lastName'
            name='lastName'
            value={formik.values.lastName}
            placeholder='Enter last name'
            label='Last name'
            autocomplete='family-name'
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            showError={!!error.fields.lastName}
            error={
              (formik.touched.lastName && formik.errors.lastName) ||
              error.fields.lastName
            }
            valid={formik.touched.lastName && !formik.errors.lastName}
          />
        </div>
        <UaInput
          name='email'
          type='text'
          value={formik.values.email}
          placeholder='Enter email'
          label='Email address'
          autocomplete='email'
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          showError={!!error.fields.email}
          error={
            (formik.touched.email && formik.errors.email) || error.fields.email
          }
          valid={formik.touched.email && !formik.errors.email}
        />
        <UaInput
          id='password'
          name='password'
          type='password'
          value={formik.values.password}
          placeholder='Enter password'
          label='Password'
          autocomplete='new-password'
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          handleIconClick={handleIconClick}
          icon={eyeIcon}
          showError={!!error.fields.password}
          error={
            (formik.touched.password && formik.errors.password) ||
            error.fields.password
          }
          valid={formik.touched.password && !formik.errors.password}
        />
        <UaInput
          id='passwordConfirmation'
          last
          name='passwordConfirmation'
          type='password'
          value={formik.values.passwordConfirmation}
          placeholder='Confirm password'
          label='Confirm password'
          autocomplete='confirm-password'
          handleChange={formik.handleChange}
          onFocus={() => formik.setFieldTouched('passwordConfirmation', true)}
          onBlur={formik.handleBlur}
          handleIconClick={handleIconClick}
          icon={eyeIcon}
          showError={!!error.fields.passwordConfirmation}
          error={
            (formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation) ||
            error.fields.passwordConfirmation
          }
          valid={
            formik.touched.passwordConfirmation &&
            !formik.errors.passwordConfirmation
          }
        />
        <div
          className={clsx('form__requirements requirements', {
            'requirements--hidden': !passwordChanged,
          })}>
          <div
            className={clsx('requirement-item', {
              'requirement-item--passed':
                formik.values.password.length >= 8 &&
                formik.values.password.length <= 20,
            })}>
            <div className='requirement-item__icon'>
              {formik.values.password.length >= 8 &&
              formik.values.password.length <= 20 ? (
                <BoxChecked />
              ) : (
                <Box />
              )}
            </div>
            <p className='requirement-item__text'>8-20 characters</p>
          </div>
          <div
            className={clsx('requirement-item', {
              'requirement-item--passed': /\d/g.test(formik.values.password),
            })}>
            <div className='requirement-item__icon'>
              {/\d/g.test(formik.values.password) ? <BoxChecked /> : <Box />}
            </div>
            <p className='requirement-item__text'>At least one number</p>
          </div>
          <div
            className={clsx('requirement-item', {
              'requirement-item--passed': /[A-Z]/g.test(formik.values.password),
            })}>
            <div className='requirement-item__icon'>
              {/[A-Z]/g.test(formik.values.password) ? <BoxChecked /> : <Box />}
            </div>
            <p className='requirement-item__text'>
              At least one capital letter
            </p>
          </div>
          <div
            className={clsx('requirement-item', {
              'requirement-item--passed':
                !/\s/g.test(formik.values.password) && formik.values.password,
            })}>
            <div className='requirement-item__icon'>
              {!/\s/g.test(formik.values.password) && formik.values.password ? (
                <BoxChecked />
              ) : (
                <Box />
              )}
            </div>
            <p className='requirement-item__text'>No spaces</p>
          </div>
        </div>
        <div className='registration-form__terms'>
          <UaCheckbox
            id='termsOfUse'
            className='registration-form__terms-checkbox'
            label={
              <p className='form__help-text'>
                I agree to{' '}
                <a
                  className='registration-form__terms-link'
                  href='https://unmanage.io/tos'
                  target='_blank'
                  rel='noopener noreferrer'>
                  terms of service
                </a>{' '}
                and acceptable use policy
              </p>
            }
            checked={isCheckedTermsOfUse}
            name='termsOfUse'
            handleChange={handleChangeTermsOfUse}
          />
        </div>
        <UaButton
          isLoading={isLoading}
          disabled={!isCheckedTermsOfUse}
          variant='primary'
          type='submit'
          className='form__submit'>
          Register
        </UaButton>
        <p className='form__help-text'>
          Already have an account?{' '}
          <Link
            variant='link'
            to={routes.login}
            className='form__link btn btn-link'>
            Log in!
          </Link>
        </p>
        <p className='form__help-text'>
          Have questions or need help?{' '}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className='form__link btn btn-link'>
            Contact us
          </a>
        </p>
      </div>
    </form>
  );
};

RegistrationForm.propTypes = {
  onSubmit: func,
  error: shape({
    message: string,
    fields: objectOf(arrayOf(string)),
  }),
};

RegistrationForm.defaultProps = {
  onSubmit: () => {},
};

export default RegistrationForm;
