import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import './filter-members.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import UserAvatar from 'components/user-avatar/user-avatar.component';

import { ReactComponent as TeamIcon } from 'assets/images/icons/users.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-circle-solid.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus-circle-solid.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

import { getAccountMembers } from 'store/accounts/selectors';
import useStrictFilter from 'hooks/useStrictFilter';

const FilterMembers = ({ value, onChange }) => {
  const { members } = useSelector(getAccountMembers);
  const [isOpen, setIsOpen] = useState(false);

  const { onSelect, onDelete, onToggleStrict, filtered } = useStrictFilter({
    value,
    list: members,
    onChange,
  });

  return (
    <div className='project-filter'>
      <div className='project-filter__header'>
        <span className='project-filter__icon'>
          <TeamIcon />
        </span>
        <span className='project-filter__name'>Team Members</span>
        <label className='project-filter__toggle'>
          <input
            type='checkbox'
            value={value.isStrict}
            onChange={onToggleStrict}
          />
          <span className='project-tasks-completed--span'>Strict filter</span>
        </label>
      </div>
      <div className='project-filter__body'>
        <div className='project-filter-selected-items project-filter-selected-items--flex'>
          {value.include.map((item) => (
            <div
              className='filter-members-selected-item'
              key={`include-member-${item.id}`}>
              <UserAvatar user={item.user} width={30} height={30} />
              <div className='filter-members-selected__type'>
                <PlusIcon />
              </div>
              <p>
                {item.user.firstname} {item.user.lastname}
              </p>

              <button
                onClick={onDelete('include', item)}
                className='filter-members-selected-item-delete'>
                <CloseIcon />
              </button>
            </div>
          ))}
          {!value.isStrict &&
            value.exclude.map((item) => (
              <div
                className='filter-members-selected-item'
                key={`exclude-member-${item.id}`}>
                <UserAvatar user={item.user} width={30} height={30} />
                <div className='filter-members-selected__type'>
                  <MinusIcon />
                </div>
                <p>
                  {item.user.firstname} {item.user.lastname}
                </p>
                <button
                  onClick={onDelete('exclude', item)}
                  className='filter-members-selected-item-delete'>
                  <CloseIcon />
                </button>
              </div>
            ))}
        </div>

        <button
          className='project-filter-select-btn'
          onClick={() => setIsOpen(true)}>
          Select members <AngleDown />
        </button>
      </div>

      <UaModal show={isOpen} onHide={() => setIsOpen(false)} noFooter>
        <Tabs className='project-filter-tabs'>
          <Tab eventKey='include' title='Include'>
            {!!filtered.length &&
              filtered.map((member) => (
                <div
                  onClick={onSelect('include', member)}
                  className='project-filter-member__option'
                  key={member.id}>
                  <UserAvatar user={member.user} width={30} height={30} />
                  <p>
                    {member.user.firstname} {member.user.lastname}
                  </p>
                </div>
              ))}

            {!filtered.length && 'Members list is empty'}
          </Tab>
          {!value.isStrict && (
            <Tab eventKey='exclude' title='Exclude'>
              {!!filtered.length &&
                filtered.map((member) => (
                  <div
                    onClick={onSelect('exclude', member)}
                    className='project-filter-member__option'
                    key={member.id}>
                    <UserAvatar user={member.user} width={30} height={30} />
                    <p>
                      {member.user.firstname} {member.user.lastname}
                    </p>
                  </div>
                ))}

              {!filtered.length && 'Members list is empty'}
            </Tab>
          )}
        </Tabs>
      </UaModal>
    </div>
  );
};

export default FilterMembers;
