import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { editProfile as editProfileAction } from '../actions';
// api
import { editProfile as editProfileApi } from '../api';
// services
import getErrorMessage from '../../services/getErrorMessages';
import { toast } from 'react-toastify';

function* editProfileSaga({ payload: userData }) {
  try {
    yield put(editProfileAction.request());

    const profile = yield call(editProfileApi, userData);

    if ('email' in userData) {
      toast('A confirmation email has been sent', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'success',
      });
    }

    yield put(editProfileAction.success({ profile }));
  } catch (error) {
    console.error(error);
    yield put(editProfileAction.failure(getErrorMessage(error.response)));
  } finally {
    yield put(editProfileAction.fulfill());
  }
}

export default function* editProfile() {
  yield takeLatest(editProfileAction.TRIGGER, editProfileSaga);
}
