import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { requestResetPass as requestResetPassAction } from '../actions';
// api
import { requestResetPass as requestResetPassApi } from '../api';
// services
import getErrorMessage from '../../services/getErrorMessages';

function* requestResetPasswordSaga({ payload: userData }) {
  try {
    yield put(requestResetPassAction.request());

    yield call(requestResetPassApi, userData);

    yield put(requestResetPassAction.success());
  } catch (error) {
    console.error(error);
    yield put(requestResetPassAction.failure(getErrorMessage(error.response)));
  } finally {
    yield put(requestResetPassAction.fulfill());
  }
}

export default function* requestResetPassword() {
  yield takeLatest(requestResetPassAction.TRIGGER, requestResetPasswordSaga);
}
