import routine from '../services/routine';

export const auth = routine('AUTH');
export const logout = routine('LOGOUT');
export const signUp = routine('SIGN_UP');
export const signIn = routine('SIGN_IN');
export const requestResetPass = routine('REQUEST_RESET_PASSWORD');
export const resetPassword = routine('RESET_PASSWORD');
export const getProfile = routine('GET_PROFILE');
export const editProfile = routine('EDIT_PROFILE');
export const uploadProfilePhoto = routine('UPLOAD_PROFILE_PHOTO');
export const deleteProfilePhoto = routine('DELETE_PROFILE_PHOTO');

export const removeErrors = routine('AUTH_REMOVE_ERRORS');
export const resetAuthState = routine('AUTH_RESET_STATE');

export const verifyEmail = routine('VERIFY_EMAIL');
export const confirmEmail = routine('CONFIRM_EMAIL');
