import {put, takeEvery, call, select} from 'redux-saga/effects';

import { deleteTask as deleteTaskAction } from '../actions';

import { deleteTask as deleteTaskApi } from '../api';
import {getProjectSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(deleteTaskAction.request());

    yield call(deleteTaskApi, payload);

    yield put(deleteTaskAction.success(payload));
  } catch (error) {
    console.error(error);
    yield put(deleteTaskAction.failure(error.response));
  } finally {
    yield put(deleteTaskAction.fulfill());
  }
}

export default function* deleteTask() {
  yield takeEvery(deleteTaskAction.TRIGGER, addSaga);
}
