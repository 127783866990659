import { combineReducers } from 'redux';
// reducers
import support from './support/reducer';
import boards from './boards/reducer';
import projects from './projects/reducer';
import auth from './auth/reducer';
import accounts from './accounts/reducer';
import dashboards from './dashboards/reducer';
import customViews from './custom-views/reducer';
import socket from './socket/reducer';
import downloadFiles from './downloadFiles/reducer';
import payment from './payment/reducer';
import clients from './clients/reducer';
import app from './app/reducer';

const rootReducer = combineReducers({
  support,
  boards,
  projects,
  auth,
  accounts,
  dashboards,
  customViews,
  socket,
  downloadFiles,
  payment,
  clients,
  app,
});

export default rootReducer;
