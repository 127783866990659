import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-circle-outline.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit-think.svg';
import hexToRgb from 'utils/hexToRgb';

const TagEditItem = ({ color, name, projectId, index, onTagChange }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(name);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const rgb = useMemo(() => Object.values(hexToRgb(color) || {}), [color]);
  const avg = useMemo(
    () => Math.round(rgb.reduce((a, b) => a + b, 0) / rgb.length),
    [rgb]
  );
  const tagFontClass = useMemo(
    () => (avg < 150 ? 'tag--light' : 'tag--dark'),
    [avg]
  );

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onSave = () => {
    onTagChange(value, projectId, index);
    setIsEdit(false);
  };

  return (
    <div className='tag-edit-container'>
      <div
        className={`tag-edit-content ${tagFontClass}`}
        style={{ backgroundColor: color }}>
        {isEdit ? (
          <input
            ref={inputRef}
            className='tag-edit-input'
            type='text'
            value={value}
            onChange={onChange}
          />
        ) : (
          name
        )}
      </div>
      {!isEdit ? (
        <button
          className='tag-edit-button'
          type='button'
          onClick={() => setIsEdit(true)}>
          <EditIcon />
        </button>
      ) : (
        <button className='tag-edit-button' type='button' onClick={onSave}>
          <CheckIcon />
        </button>
      )}
    </div>
  );
};

export default TagEditItem;
