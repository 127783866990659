import React, { memo, useMemo } from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg';

import './tag.styles.scss';
import hexToRgb from 'utils/hexToRgb';
import clsx from 'clsx';

const Tag = ({
  variant = 'primary',
  text,
  editing,
  outline = false,
  onDelete,
  onClick,
  color,
  beforeIcon,
}) => {
  const rgb = useMemo(() => Object.values(hexToRgb(color) || {}), [color]);
  const avg = useMemo(
    () => Math.round(rgb.reduce((a, b) => a + b, 0) / rgb.length),
    [rgb]
  );
  const tagFontClass = useMemo(
    () => (avg < 150 ? 'tag--light' : 'tag--dark'),
    [avg]
  );
  const tagOutlineClass = useMemo(
    () => (outline ? 'tag--outline' : ''),
    [outline]
  );

  return (
    <span
      className={clsx(`tag ${variant}`, {
        [tagOutlineClass]: tagOutlineClass,
        [tagFontClass]: tagFontClass,
        'tag-delete-btn': !!onDelete && editing,
      })}
      style={{ backgroundColor: color }}
      onClick={onClick}>
      {!!beforeIcon && beforeIcon}
      {text.charAt(0).toUpperCase()}
      {text.substring(1)}
      {!!onDelete && editing && (
        <span className='delete-btn' onClick={onDelete}>
          <CloseIcon fill='white' height='10px' />
        </span>
      )}
    </span>
  );
};

export default memo(Tag);
