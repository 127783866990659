import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function UATooltip({
  children,
  label,
  placement = 'top',
  show = true,
  hideArrow = false,
}) {
  if (show)
    return (
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip
            bsPrefix={`${hideArrow ? 'hide-arrow ' : ''}tooltip`}
            id={`tooltip-${placement}`}>
            {label}
          </Tooltip>
        }>
        {children}
      </OverlayTrigger>
    );

  return children;
}

export default UATooltip;
