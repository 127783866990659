import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  getCustomViews,
  getSelectedCustomView,
} from 'store/custom-views/selectors';
import { selectCustomView } from 'store/custom-views/actions';
import { getProjectsPagination } from 'store/projects/selectors';

import './filters-card.styles.scss';

import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import Tag from 'components/dashboard/tag/tag.component';
import FilterTitle from 'components/filters/filter/filter-title/filter-title.component';
import FilterMembers from 'components/filters/filter/filter-members/filter-members.component';
import FilterPriority from 'components/filters/filter/filter-priority/filter-priority.component';
import FilterTags from 'components/filters/filter/filter-tags/filter-tags.component';
import FilterStatus from 'components/filters/filter/filter-status/filter-status.component';
import FilterDate from 'components/filters/filter/filter-date/filter-date.component';
import CreateCustomViewButton from 'components/filters/custom-views/create-custom-view/create-custom-view.component';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';
import { useContext } from 'react';
import { BoardContext } from 'context/board-context/board-context';
import FilterClients from 'components/filters/filter/filter-clients/filter-clients.component';

const FiltersCard = ({ onClose, editing = true }) => {
  const dispatch = useDispatch();
  const customView = useSelector(getSelectedCustomView);
  const { number_of_filtered, total } = useSelector(getProjectsPagination);
  const customViews = useSelector(getCustomViews);

  const {
    projectFilter,
    setProjectFilter,
    onClearProjectFilter,
    searchValue,
    setSearch,
  } = useContext(BoardContext);

  const handleClear = () => {
    onClearProjectFilter();
    dispatch(selectCustomView(null));
  };

  const handleFilterChange = (key) => (value) => {
    setProjectFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleSelectCustomView = (item) => () => {
    setProjectFilter({ ...item.filters });
    dispatch(selectCustomView(item));
  };

  return (
    <div className='filters-card'>
      <div className='filters-card__header'>
        <div className='filters-card__title-line'>
          <h5 className='filters-card__title'>Filters</h5>
          <Tag
            className='filters-card__header-tag'
            text={`${number_of_filtered} of ${total}`}
            variant='info'
          />
          {!!onClose && (
            <button className='filters-card__close-button' onClick={onClose}>
              <CloseIcon />
            </button>
          )}
        </div>
        <div className='filters-card__actions'>
          <div className='filters-card__actions-wrapper'>
            {editing && (
              <UaDropdown
                className='filters-card__custom-view-dropdown'
                buttonContent={
                  <>
                    <span>Custom view</span>
                    <AngleDown />
                  </>
                }
                withIcon={false}>
                {customViews.map((item) => (
                  <Dropdown.Item
                    key={`custom-view-item-${item.id}`}
                    onClick={handleSelectCustomView(item)}>
                    {item.name}
                  </Dropdown.Item>
                ))}
                {!customViews.length && (
                  <Dropdown.Item>Don’t have any custom views yet</Dropdown.Item>
                )}
              </UaDropdown>
            )}
          </div>
          <div className='filters-card__actions-wrapper'>
            <button
              className='filters-card__clear-button'
              onClick={handleClear}>
              Clear Filters
            </button>
            {editing && (
              <CreateCustomViewButton
                className='filters-card__save-button'
                edit={customView}
                filters={projectFilter}>
                Save Custom View
              </CreateCustomViewButton>
            )}
          </div>
        </div>
      </div>
      <div className='filters-card__body'>
        <FilterTitle value={searchValue} onChange={setSearch} />
        <FilterMembers
          value={projectFilter.members}
          onChange={handleFilterChange('members')}
        />
        <FilterClients
          value={projectFilter.clients}
          onChange={handleFilterChange('clients')}
        />
        <FilterPriority
          value={projectFilter.priorities}
          onChange={handleFilterChange('priorities')}
        />
        <FilterTags
          value={projectFilter.tags}
          onChange={handleFilterChange('tags')}
        />
        <FilterStatus
          value={projectFilter.statuses}
          onChange={handleFilterChange('statuses')}
        />
        <FilterDate
          value={projectFilter.due_date}
          onChange={handleFilterChange('due_date')}
        />
      </div>
    </div>
  );
};

export default FiltersCard;
