// actions
import {
  addBoard,
  addBoardMember,
  addBoardStatusFromSocket,
  addTag,
  cancelArchiveBoard,
  cancelDeleteBoard,
  copyBoard,
  deleteBoard,
  deleteTag,
  dndBoardProject,
  dndBoardStatus,
  editBoard,
  editBoardStatusFromSocket,
  editTag,
  getBoard,
  getGuestBoard,
  getStatuses,
  getTags,
  preArchiveBoard,
  preDeleteBoard,
  removeBoardMember,
  removeBoardStatusFromSocket,
  setBoardsSearch,
  setIsFavoriteBoard,
  togglePersonal,
  updateBoard,
} from './actions';
import { getDashboard } from '../dashboards/actions';
import { addMember, addProject, getProjects } from 'store/projects/actions';
import { logout } from 'store/auth/actions';

const INITIAL_STATE = {
  board: null,
  boardError: {},
  boardLoading: false,
  copyLoading: false,
  list: [],
  beforeChangeList: [],
  beforeChangeBoard: null,
  statuses: [],
  statusesPositions: [],
  projectsPositions: [],
  tags: [],
  searchBoard: '',
};

const boardsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case preArchiveBoard.TRIGGER: {
      const boards = [...state.list];
      const index = boards.findIndex((item) => item.id === payload.id);
      boards[index] = { ...boards[index], ...payload };

      if (state.board && state.board.id === payload.id) {
        return {
          ...state,
          list: boards,
          board: { ...state.board, ...payload },
          beforeChangeBoard: { ...state.board },
          beforeChangeList: [...state.list],
          statusesPositions: payload.statuses_positions,
          projectsPositions: payload.projects_positions,
        };
      } else {
        return {
          ...state,
          list: boards,
          beforeChangeList: [...state.list],
          statusesPositions: payload.statuses_positions,
          projectsPositions: payload.projects_positions,
        };
      }
    }

    case cancelArchiveBoard.TRIGGER:
    case editBoard.FAILURE: {
      const prev = [...state.beforeChangeList];
      const prevBoard = { ...state.beforeChangeBoard };

      if (state.board && state.beforeChangeBoard) {
        return {
          ...state,
          board: prevBoard,
          list: prev,
          beforeChangeList: [],
          beforeChangeBoard: null,
        };
      }
      return {
        ...state,
        list: prev,
        beforeChangeList: [],
        beforeChangeBoard: null,
      };
    }

    case setIsFavoriteBoard.SUCCESS:
    case editBoard.SUCCESS: {
      const boards = [...state.list];
      const index = boards.findIndex((item) => item.id === payload.id);
      boards[index] = { ...boards[index], ...payload };

      const board = state.list.find((item) => item.id === payload.id) || {};

      if (state.board && state.board.id === payload.id) {
        return {
          ...state,
          list: boards,
          board: { ...board, ...payload },
          statusesPositions: payload.statuses_positions,
          projectsPositions: payload.projects_positions,
        };
      } else {
        return {
          ...state,
          list: boards,
          statusesPositions: payload.statuses_positions,
          projectsPositions: payload.projects_positions,
        };
      }
    }

    case preDeleteBoard.TRIGGER: {
      const boards = state.list.filter(({ id }) => id !== payload);
      return {
        ...state,
        beforeChangeList: [...state.list],
        list: boards,
      };
    }

    case cancelDeleteBoard.TRIGGER:
    case deleteBoard.FAILURE: {
      const prev = [...state.beforeChangeList];
      return {
        ...state,
        list: prev,
        beforeChangeList: [],
      };
    }

    case deleteBoard.SUCCESS: {
      return {
        ...state,
        beforeChangeList: [],
      };
    }

    case addBoardMember.SUCCESS: {
      const members = [...state.board.members];
      const index = members.findIndex(({ id }) => id === payload.user.id);

      let board = {
        ...state.board,
      };

      if (index === -1) {
        const member = {
          firstname: payload.user.firstname,
          lastname: payload.user.lastname,
          id: payload.user.id,
          profile_photo_path: payload.user.profile_photo_path,
        };

        board = {
          ...board,
          members: [...state.board.members, member],
        };
      }

      return {
        ...state,
        board,
      };
    }

    case addMember.SUCCESS: {
      const members = [...state.board.members];
      const memberIds = members.map(({ id }) => id);

      if (memberIds.includes(payload.user.id)) {
        return state;
      }

      members.push({
        id: payload.user.id,
        lastname: payload.user.lastname,
        firstname: payload.user.firstname,
        profile_photo_path: payload.user.profile_photo_path,
      });
      const board = { ...state.board, members };

      return {
        ...state,
        board,
      };
    }

    case removeBoardMember.SUCCESS: {
      if (parseInt(payload.board_id) !== state.board.id) return state;

      const members = [...state.board.members];
      const index = members.findIndex(({ id }) => id === payload.user_id);

      if (index !== -1) {
        members.splice(index, 1);
      }

      return {
        ...state,
        board: {
          ...state.board,
          members,
        },
      };
    }

    case togglePersonal.SUCCESS: {
      const boards = [...state.list];
      const index = boards.findIndex((item) => item.id === payload.boardId);
      if (!payload.isPersonalDashboard) {
        boards[index] = {
          ...boards[index],
          is_in_personal_dashboard: payload.isPersonal,
        };
      } else {
        boards.splice(index, 1);
      }

      return {
        ...state,
        list: boards,
      };
    }

    case setBoardsSearch.TRIGGER: {
      return {
        ...state,
        searchBoard: payload,
      };
    }

    case getDashboard.SUCCESS:
      return {
        ...state,
        list: payload.dashboard.boards,
      };

    case getGuestBoard.TRIGGER:
      return {
        ...state,
        boardError: INITIAL_STATE.boardError,
      };

    case getGuestBoard.SUCCESS:
      return {
        ...state,
        board: payload,
      };

    case updateBoard.SUCCESS:
      return {
        ...state,
        board: {
          ...state.board,
          ...payload,
        },
      };

    case getGuestBoard.FAILURE:
      return {
        ...state,
        boardError: payload.data,
      };

    case copyBoard.TRIGGER:
      return {
        ...state,
        copyLoading: true,
      };
    case copyBoard.FULFILL:
      return {
        ...state,
        copyLoading: false,
      };

    case copyBoard.SUCCESS:
    case addBoard.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case getBoard.TRIGGER: {
      if (payload?.initial) {
        return {
          ...state,
          boardLoading: true,
        };
      }
      return { ...state };
    }

    case getBoard.SUCCESS: {
      const boards = [...state.list];
      const index = boards.findIndex((item) => item.id === payload.id);
      boards[index] = { ...boards[index], ...payload };

      return {
        ...state,
        list: boards,
        board: payload,
        statusesPositions: payload.statuses_positions
          ? payload.statuses_positions
          : payload.statuses.map(({ id }, index) => ({
              status_id: id,
              order: index,
            })),
      };
    }

    case getBoard.FULFILL: {
      return {
        ...state,
        boardLoading: false,
      };
    }

    case getProjects.SUCCESS: {
      if (state.board.projects_positions) {
        return {
          ...state,
          projectsPositions: [...state.board.projects_positions],
        };
      } else {
        const statusesProjects = {};
        for (let status of state.board.statuses) {
          statusesProjects[status.id] = [];
        }

        for (let project of payload.projects.data) {
          statusesProjects[project.status.id]?.push({
            status_id: project.status.id,
            project_id: project.id,
          });
        }

        const projectsPositions = [];
        for (let index in statusesProjects) {
          for (let i = 0; i < statusesProjects[index].length; i++) {
            projectsPositions?.push({
              ...statusesProjects[index][i],
              order: i,
            });
          }
        }

        return {
          ...state,
          projectsPositions,
        };
      }
    }

    case dndBoardStatus.SUCCESS: {
      return {
        ...state,
        board: { ...state.board, statuses_positions: [...payload] },
        statusesPositions: payload,
      };
    }

    case dndBoardProject.SUCCESS: {
      return {
        ...state,
        projectsPositions: payload,
      };
    }

    case getStatuses.SUCCESS: {
      return {
        ...state,
        statuses: payload,
      };
    }

    case addProject.SUCCESS: {
      const tags = [...state.tags];
      const tagIds = tags.map(({ id }) => id);

      const newTags = payload.tags
        .map((item) => {
          if (!tagIds.includes(item.id)) return item;
          return null;
        })
        .filter((item) => item);

      return {
        ...state,
        tags: [...tags, ...newTags],
      };
    }

    case getTags.SUCCESS: {
      return {
        ...state,
        tags: payload,
      };
    }

    case addTag.SUCCESS: {
      if (state.board.id === parseInt(payload.board_id)) {
        return {
          ...state,
          tags: [...state.tags, payload],
        };
      }

      return {
        ...state,
      };
    }

    case editTag.SUCCESS: {
      const index = state.tags.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.tags[index] = payload;
      }

      return {
        ...state,
        tags: [...state.tags],
      };
    }

    case deleteTag.SUCCESS: {
      const newTags = [...state.tags.filter((item) => item.id !== payload)];
      return {
        ...state,
        tags: newTags,
      };
    }

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    case addBoardStatusFromSocket.SUCCESS: {
      const boards = [...state.list];
      const boardIndex = boards.findIndex(
        (item) => item.id === payload.boardId
      );

      let board = state.board;

      if (boardIndex !== -1) {
        const statusIndex = boards[boardIndex].statuses.findIndex(
          (status) => status.id === payload.status.id
        );

        if (statusIndex === -1) {
          boards[boardIndex].statuses.push({ ...payload.status });
        }

        if (state.board.id === boards[boardIndex].id) {
          board = { ...boards[boardIndex] };
        }
      }

      return {
        ...state,
        boards,
        board,
      };
    }

    case editBoardStatusFromSocket.SUCCESS: {
      const boards = [...state.list];
      const boardIndex = boards.findIndex(
        (item) => item.id === payload.boardId
      );

      let board = state.board;

      if (boardIndex !== -1) {
        const statusIndex = boards[boardIndex].statuses.findIndex(
          (status) => status.id === payload.status.id
        );

        if (statusIndex > -1) {
          boards[boardIndex].statuses[statusIndex] = { ...payload.status };
        }

        if (state.board.id === boards[boardIndex].id) {
          board = { ...boards[boardIndex] };
        }
      }

      return {
        ...state,
        boards,
        board,
      };
    }

    case removeBoardStatusFromSocket.SUCCESS: {
      const boards = [...state.list];
      const boardIndex = boards.findIndex(
        (item) => item.id === payload.boardId
      );

      let board = state.board;

      if (boardIndex !== -1) {
        const statusIndex = boards[boardIndex].statuses.findIndex(
          (status) => status.id === payload.status.id
        );

        boards[boardIndex].statuses.splice(statusIndex, 1);

        if (state.board.id === boards[boardIndex].id) {
          board = { ...boards[boardIndex] };
        }
      }

      return {
        ...state,
        boards,
        board,
      };
    }

    default: {
      return state;
    }
  }
};

export default boardsReducer;
