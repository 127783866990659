import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getPriorities as getPrioritiesAction } from '../actions';
// api
import { getPriorities as getPrioritiesApi } from '../api';

function* getSaga() {
  try {
    yield put(getPrioritiesAction.request());

    const priorities = yield call(getPrioritiesApi);

    yield put(getPrioritiesAction.success(priorities));
  } catch (error) {
    console.error(error);
    yield put(getPrioritiesAction.failure(error.response));
  } finally {
    yield put(getPrioritiesAction.fulfill());
  }
}

export default function* getPriorities() {
  yield takeLatest(getPrioritiesAction.TRIGGER, getSaga);
}
