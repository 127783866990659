import { forwardRef } from 'react';
import { Spinner } from 'react-bootstrap';

import './kanban-container.styles.scss';

const KanbanContainer = forwardRef(
  ({ children, loading, ...otherProps }, ref) => {
    return (
      <div className='kanban-container' ref={ref} {...otherProps}>
        {loading ? (
          <div className='kanban-container-loader'>
            <Spinner animation='border' role='status'></Spinner>
          </div>
        ) : (
          children
        )}
      </div>
    );
  }
);

export default KanbanContainer;
