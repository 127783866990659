import { all, fork } from 'redux-saga/effects';
// sagas
import boards from './boards/sagas';
import projects from './projects/sagas';
import accounts from './accounts/sagas';
import auth from './auth/sagas';
import dashboards from './dashboards/sagas';
import customViews from './custom-views/sagas';
import support from './support/sagas';
import socket from './socket/sagas';
import payment from './payment/sagas';
import clients from './clients/sagas';

export default function* rootSaga() {
  yield all([
    fork(boards),
    fork(projects),
    fork(accounts),
    fork(auth),
    fork(dashboards),
    fork(customViews),
    fork(support),
    fork(socket),
    fork(payment),
    fork(clients),
  ]);
}
