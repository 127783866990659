import React, { forwardRef, useRef, useEffect, memo, useMemo } from 'react';
import {
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useTable,
} from 'react-table';

const columns = [
  {
    Header: 'Project Title',
    accessor: 'name',
    minWidth: 190,
  },
  {
    Header: 'Project Team',
    accessor: 'members',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    minWidth: 100,
  },
  {
    Header: 'Tags',
    accessor: 'tags',
    minWidth: 100,
  },
  {
    Header: 'Status',
    accessor: 'status',
    minWidth: 100,
  },
  {
    Header: 'Due Date',
    accessor: 'due_date',
  },
];

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type='checkbox' ref={resolvedRef} {...rest} />;
});

const useProjectTable = ({ data }) => {
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 140, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  return useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => {
        return [
          // Let's make a column for selection
          {
            id: 'selection',
            disableResizing: true,
            minWidth: 35,
            width: 35,
            maxWidth: 35,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: memo(({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            )),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: memo(({ row: { getToggleRowSelectedProps } }) => (
              <div>
                <IndeterminateCheckbox {...getToggleRowSelectedProps()} />
              </div>
            )),
          },
          ...columns,
        ];
      });
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );
};

export default useProjectTable;
