import React, { useState } from 'react';

import './user-editable-name.styles.scss';

import UaInput from 'components/common/ua-input/ua-input.component';

import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

function EditableText({
  user,
  isEditing = true,
  editingId,
  onEditStart,
  onEditEnd,
  onEditCancel,
}) {
  const { id, firstname, lastname, is_active } = user;
  const [newValue, setNewValue] = useState({
    firstname,
    lastname,
  });

  const onChange = (key) => (e) => {
    setNewValue((prev) => ({ ...prev, [key]: e.target.value }));
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') onEditEnd(newValue);
  };

  return (
    <>
      {editingId === id && is_active && isEditing ? (
        <div className='user-name-edit'>
          <UaInput
            value={newValue.firstname}
            onChange={onChange('firstname')}
            onKeyPress={onKeyPress}
          />
          <UaInput
            value={newValue.lastname}
            onChange={onChange('lastname')}
            onKeyPress={onKeyPress}
          />
          <button className='close-btn' onClick={onEditCancel}>
            <CloseIcon height={19} width={19} />
          </button>
        </div>
      ) : (
        <p className='user-name'>
          {!!firstname && !!lastname ? `${firstname} ${lastname}` : 'User name'}
          {is_active && isEditing && (
            <span className='edit-button' onClick={onEditStart}>
              <EditIcon color='#333333' />
            </span>
          )}
        </p>
      )}
    </>
  );
}

export default EditableText;
