import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { auth as authAction, getProfile } from '../actions';
// services
import { getAccessToken } from '../services/tokenService';

function* authSaga() {
  try {
    const token = yield call(getAccessToken);

    if (token) yield put(getProfile());

    yield put(authAction.success());
  } catch (error) {
    console.error(error);
    yield put(authAction.failure(error));
  }
}

export default function* auth() {
  yield takeLatest(authAction.TRIGGER, authSaga);
}
