import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDueSoon, getOverdue } from 'utils/getFilterDate';
import {
  BoardContext,
  DEFAULT_PROJECT_FILTERS,
} from 'context/board-context/board-context';

import UaEditableField from 'components/common/ua-editable-field/ua-editable-field.component';
import BoardTeam from 'components/dashboard/board-team/board-team.component';
import KpiCard from 'components/dashboard/kpi-card/kpi-card.component';

import calendar from 'assets/images/icons/calendar-outline.svg';
import calendarCompleted from 'assets/images/icons/calendar-completed.svg';
import calendarDue from 'assets/images/icons/calendar-due.svg';
import calendarOverdue from 'assets/images/icons/calendar-overdue.svg';

import { getBoard } from 'store/boards/selectors';
import { editBoard } from 'store/boards/actions';
import { getCurrentRole } from 'store/accounts/selectors';
import { ROLES, STATUSES } from 'settings';

const BoardHeader = () => {
  const dispatch = useDispatch();
  const { setListShowOption, setProjectFilter, isGuest } =
    useContext(BoardContext);
  const board = useSelector(getBoard);
  const currentRole = useSelector(getCurrentRole);

  const isUser = currentRole.slug === ROLES.USER;

  const onChangeBoardName = (e) => {
    dispatch(editBoard({ id: board.id, name: String(e.target.value).trim() }));
  };

  const kpi = useMemo(
    () =>
      board?.kpi.reduce((memo, x) => {
        memo[x['type']] = x;
        return memo;
      }, {}),
    [board?.kpi]
  );

  const onActiveClick = () => {
    const completed = board.statuses.filter(
      ({ slug }) => slug !== STATUSES.COMPLETED
    );
    setListShowOption((prev) => ({
      ...prev,
      archivedProjects: false,
    }));
    setProjectFilter({
      ...DEFAULT_PROJECT_FILTERS,
      statuses: completed,
    });
  };

  const onCompletedClick = () => {
    const completed = board.statuses.find(
      ({ slug }) => slug === STATUSES.COMPLETED
    );
    setProjectFilter({
      ...DEFAULT_PROJECT_FILTERS,
      statuses: completed ? [completed] : [],
    });
  };

  const onDueSoonClick = () => {
    setListShowOption((prev) => ({
      ...prev,
      archivedProjects: false,
    }));
    setProjectFilter({
      ...DEFAULT_PROJECT_FILTERS,
      due_date: getDueSoon(),
    });
  };

  const onOverdueClick = () => {
    setListShowOption((prev) => ({
      ...prev,
      archivedProjects: false,
    }));
    setProjectFilter({
      ...DEFAULT_PROJECT_FILTERS,
      due_date: getOverdue(),
    });
  };

  const kpiData = [
    {
      icon: calendar,
      overview: {
        label: 'Active Projects',
        count: kpi?.active.value || 0,
        type: kpi?.active.type || '',
      },
      onClick: onActiveClick,
    },
    {
      icon: calendarCompleted,
      overview: {
        label: 'Completed',
        count: kpi?.completed.value || 0,
        type: kpi?.completed.type || '',
      },
      onClick: onCompletedClick,
    },
    {
      icon: calendarDue,
      overview: {
        label: 'Due soon',
        count: kpi?.due_soon.value || 0,
        type: kpi?.due_soon.type || '',
      },
      onClick: onDueSoonClick,
    },
    {
      icon: calendarOverdue,
      overview: {
        label: 'Overdue',
        count: kpi?.overdue.value || 0,
        type: kpi?.overdue.type || '',
      },
      onClick: onOverdueClick,
    },
  ];

  return (
    <div className='page-header'>
      <div className='page-header__col'>
        <UaEditableField
          tag='h1'
          contentClass='page-title'
          text={board?.name}
          onBlur={onChangeBoardName}
        />
        <BoardTeam board={board || {}} editing={!isGuest && !isUser} />
      </div>
      <div className='page-header__col cards'>
        {kpiData.map((item) => (
          <div className='card-container' key={item.overview.label}>
            <KpiCard disable {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoardHeader;
