import { put, takeLatest, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
// actions
import { inviteAccountUser as action } from '../actions';
// api
import { inviteAccountUser as api } from '../api';
import { getBoardSocket } from 'store/socket/selectors';

function* getSaga({ payload }) {
  try {
    yield put(action.request());
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    const response = yield call(api, payload);

    toast('Invitation has been successfully sent.', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'success',
    });

    yield put(action.success(response));
  } catch (error) {
    console.error(error);

    if (!error.response.data.message) {
      toast('Invitation error, please try again.', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error'
      });
    }

    if (error.response.data.message === 'LIMIT_EXPIRED') {
      toast('Invitation limit expired', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error'
      });
    }

    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* inviteAccountUser() {
  yield takeLatest(action.TRIGGER, getSaga);
}
