import { memo, useMemo } from 'react';
import { bool, func, string } from 'prop-types';
import clsx from 'clsx';

import './ua-checkbox.styles.scss';

import { ReactComponent as Box } from 'assets/images/icons/box.svg';
import { ReactComponent as BoxChecked } from 'assets/images/icons/box-checked.svg';
import { ReactComponent as Circle } from 'assets/images/icons/circle.svg';
import { ReactComponent as CircleCheck } from 'assets/images/icons/check.svg';

const UaCheckboxIcon = memo(({ checked, circle }) => {
  const renderWithCircle = useMemo(
    () => (checked ? <CircleCheck /> : <Circle />),
    [checked]
  );
  const renderWithBox = useMemo(
    () => (checked ? <BoxChecked /> : <Box />),
    [checked]
  );

  return (
    <div className='ua-checkbox__icon'>
      {circle ? renderWithCircle : renderWithBox}
    </div>
  );
});

const UaCheckbox = ({
  id,
  label,
  value,
  name,
  classNameContainer,
  handleChange,
  className,
  checked,
  circle,
  ...rest
}) => {
  return (
    <div className={clsx('ua-checkbox', classNameContainer)}>
      <input
        id={id}
        className={clsx('ua-checkbox__control', className)}
        name={name}
        type='checkbox'
        value={value}
        checked={checked}
        onChange={handleChange}
        {...rest}
      />
      <label className='ua-checkbox__label' htmlFor={id}>
        <UaCheckboxIcon checked={checked} circle={circle} />
        {label}
      </label>
    </div>
  );
};

UaCheckbox.propTypes = {
  id: string,
  label: string,
  value: string,
  checked: bool,
  name: string,
  circle: bool,
  handleChange: func,
  classNameContainer: string,
  className: string,
};

UaCheckbox.defaultProps = {
  id: 'customCheckbox',
};

export default UaCheckbox;
