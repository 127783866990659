import { put, takeLatest, call, select } from 'redux-saga/effects';

import { addStatus as addStatusAction } from '../actions';
import { addStatus as addStatusApi } from '../api';

import { editSaga as editBoard } from './edit';
import { getSaga as getStatuses } from './getStatuses';

import { getStatusesPositions} from 'store/boards/selectors';
import {getBoardSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const statuses_positions = yield select(getStatusesPositions);
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(addStatusAction.request());

    const status = yield call(addStatusApi, payload);

    yield put(addStatusAction.success(status));

    yield call(editBoard, {
      payload: {
        id: payload.boardId,
        statuses_positions: [
          ...statuses_positions,
          { order: statuses_positions.length, status_id: status.id },
        ],
      },
    });
    yield call(getStatuses, { payload: { boardId: payload.boardId } });
    payload.callback();
  } catch (error) {
    console.error(error);
    yield put(addStatusAction.failure(error.response));
  } finally {
    yield put(addStatusAction.fulfill());
  }
}

export default function* addStatus() {
  yield takeLatest(addStatusAction.TRIGGER, addSaga);
}
