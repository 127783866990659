import { put, takeEvery, call } from 'redux-saga/effects';

import { sendSupport as action } from '../actions';

import { sendSupport as api } from '../api';

function* addSaga({ payload }) {
  try {
    yield put(action.request());

    yield call(api, payload);

    yield put(action.success());
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* uploadFile() {
  yield takeEvery(action.TRIGGER, addSaga);
}
