import React from 'react';

import './search.styles.scss';

import searchIcon from '../../assets/images/icons/search.svg';
import closeIcon from '../../assets/images/icons/close.svg';
import UaInput from '../common/ua-input/ua-input.component';

const Search = ({ handleSearch, handleClearField=()=>{}, showClearField=false, className, ...defaultProps }) => {
  return (
    <div className={`search ${className || ''}`}>
      <UaInput
        prependIcon={searchIcon}
        handleChange={handleSearch}
        showClearField={showClearField}
        clearFieldIcon={closeIcon}
        handleIconClick={handleClearField}
        {...defaultProps}
      />
    </div>
  );
};

export default Search;
