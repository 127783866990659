import React, { memo, useCallback, useMemo } from 'react';
import { object } from 'prop-types';
import clsx from 'clsx';

import ProjectInfo from 'components/project/widgets/project-info/project-info.component';
import ProjectUpdate from 'components/project/widgets/project-update/project-update.component';
import ProjectFiles from 'components/project/widgets/project-files/project-files.component';
import ProjectTasks from 'components/project/widgets/project-tasks/project-tasks.component';
import { useSelector } from 'react-redux';
import { getProjects as projectsSelector } from 'store/projects/selectors';
import useDevice from 'hooks/useDevice';
import { TABLET_QUERY } from 'settings';

const ProjectWidget = ({
  widgetFilters,
  onProjectClick,
  projectId,
  editing,
  className,
  minWidth,
}) => {
  const isTablet = useDevice(TABLET_QUERY);
  const projects = useSelector(projectsSelector);

  const project = useMemo(
    () => projects.find(({ id }) => id === projectId),
    [projectId, projects]
  );

  const isShowWidget = useCallback(
    (key) => widgetFilters[key].includes(project.id),
    [widgetFilters, project.id]
  );

  const isOpen = useMemo(
    () =>
      Object.keys(widgetFilters)
        .map((key) => widgetFilters[key].includes(project.id))
        .some((v) => v),
    [widgetFilters, project.id]
  );

  return (
    <div
      style={{ minWidth: !isTablet && minWidth }}
      className={clsx('project-widgets-row', className, { open: isOpen })}>
      <div className='project-widgets-cell'>
        <div className='project-widgets-container'>
          {isShowWidget('showInfo') && (
            <ProjectInfo
              info={project.info}
              projectId={project.id}
              editable={editing}
            />
          )}

          {isShowWidget('showUpdates') && (
            <ProjectUpdate
              projectId={project.id}
              updates={project.updates}
              editing={editing}
              onProjectClick={onProjectClick}
              isSeeAll
            />
          )}

          {isShowWidget('showFiles') && (
            <ProjectFiles
              projectId={project.id}
              files={project.files}
              editing={editing}
              onProjectClick={onProjectClick}
              isSeeAll
            />
          )}

          {isShowWidget('showTasks') && (
            <ProjectTasks
              projectId={project.id}
              tasks={project.tasks}
              editing={editing}
              onProjectClick={onProjectClick}
              isSeeAll
            />
          )}
        </div>
      </div>
    </div>
  );
};

ProjectWidget.propTypes = {
  widgetFilters: object,
  project: object,
};

ProjectWidget.defaultProps = {
  project: {},
  filter: {},
};

export default memo(ProjectWidget);
