import {put, takeLatest, call, select} from 'redux-saga/effects';

import { deleteTag as deleteTagAction } from '../actions';
import { deleteTag as deleteTagApi } from '../api';
import {getBoardSocket} from 'store/socket/selectors';

function* deleteSaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    const socketId = socket.id;

    yield put(deleteTagAction.request());

    yield call(deleteTagApi, {id:payload, socketId});

    yield put(deleteTagAction.success(payload));
  } catch (error) {
    console.error(error);
    yield put(deleteTagAction.failure(error.response));
  } finally {
    yield put(deleteTagAction.fulfill());
  }
}

export default function* deleteTag() {
  yield takeLatest(deleteTagAction.TRIGGER, deleteSaga);
}
