import { put, takeEvery, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { addMember as addMemberAction } from '../actions';

import { addMember as addMemberApi } from '../api';
import { getProjectSocket } from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(addMemberAction.request());

    const response = yield call(addMemberApi, payload);

    toast('User has been successfully invited', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'success',
    });

    yield put(
      addMemberAction.success({
        projectId: payload.projectId,
        boardId: payload.boardId,
        user: response,
      })
    );
  } catch (error) {
    console.error(error);

    toast('Something was wrong', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'error',
    });

    yield put(addMemberAction.failure(error.response));
  } finally {
    yield put(addMemberAction.fulfill());
  }
}

export default function* addMember() {
  yield takeEvery(addMemberAction.TRIGGER, addSaga);
}
