import React from 'react';

import './boards-list.styles.scss';

import { Droppable, Draggable } from 'react-beautiful-dnd';

import BoardItem from '../board-item/board-item.component';

const BoardsList = ({ items = [], droppableId, disabled }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <ul
          className='boards-list'
          ref={provided.innerRef}
          {...provided.droppableProps}>
          {items && items.length > 0 ? (
            items.map((item, index) => (
              <Draggable
                draggableId={String(item.id)}
                index={index}
                isDragDisabled={disabled}
                key={`board-item-${item.id}`}>
                {(dragProvided) => (
                  <li
                    className='boards-list__item'
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    ref={dragProvided.innerRef}>
                    <BoardItem board={item} disabled={disabled} />
                  </li>
                )}
              </Draggable>
            ))
          ) : (
            <li className='boards-list__item boards-list__item--empty'>
              List is empty
            </li>
          )}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

export default BoardsList;
