import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useQuery from 'hooks/useQuery';

import EditProfile from 'components/settings/edit-profile/edit-profile.component';
import SupportForm from 'components/settings/support-form/support-form.component';
import AccountSettings from 'components/settings/account-settings/account-settings.component';
import ManageUsers from 'components/settings/manage-users/manage-users.component';
import Billing from 'components/settings/billing/billing.component';

import './setting.styles.scss';

import { ReactComponent as ProfileIcon } from 'assets/images/icons/user-solid.svg';
import { ReactComponent as CogIcon } from 'assets/images/icons/cog-solid.svg';
import { ReactComponent as UsersIcon } from 'assets/images/icons/users-solid.svg';
import { ReactComponent as BillingIcon } from 'assets/images/icons/file-invoice-dollar-solid.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info-circle-solid.svg';

import {
  getAccountError,
  getCurrentAccount,
  getIsSubscribe,
  isCurrentAccountOwner,
} from 'store/accounts/selectors';
import clsx from 'clsx';
import PageLoader from 'components/page-loader/page-loader.component';
import { getMembersList, getRolesList } from 'store/accounts/actions';
import { getProfile } from 'store/auth/selectors';
import PageTitle from 'components/page-title/page-title.component';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const { account_id } = useSelector(getProfile);

  const isAccountOwner = useSelector(isCurrentAccountOwner);
  const accountError = useSelector(getAccountError);
  const currentAccount = useSelector(getCurrentAccount);
  const isSubscribe = useSelector(getIsSubscribe);

  const isEmptyAccount = !Object.keys(currentAccount).length;

  const query = useQuery();
  const history = useHistory();
  const ref = useRef(null);
  const [blurTabs, setBlurTabs] = useState({ isLeft: false, isRight: true });

  const activeTab = query.has('tab') ? query.get('tab') : 'profile';

  const handleSelect = (key) => {
    if (activeTab !== key) history.push(`/settings?tab=${key}`);
  };

  const tabs = useMemo(
    () => [
      {
        eventKey: 'profile',
        titleIcon: <ProfileIcon />,
        titleText: 'Profile',
        content: <EditProfile />,
        hide: !isSubscribe,
      },
      {
        eventKey: 'account-settings',
        titleIcon: <CogIcon />,
        titleText: 'Account Settings',
        content: <AccountSettings />,
        hide: isEmptyAccount || !isAccountOwner || !isSubscribe,
      },
      {
        eventKey: 'manage-users',
        titleIcon: <UsersIcon />,
        titleText: 'Manage Users',
        content: <ManageUsers />,
        hide: isEmptyAccount || !isSubscribe,
      },
      {
        eventKey: 'billing',
        titleIcon: <BillingIcon />,
        titleText: 'Billing',
        content: <Billing />,
        hide: !isAccountOwner,
      },
      {
        eventKey: 'support',
        titleIcon: <InfoIcon />,
        titleText: 'Support',
        content: <SupportForm />,
        hide: false,
      },
    ],
    [isAccountOwner, isEmptyAccount, isSubscribe]
  );

  const checkScroll = () => {
    const siteWidth = document.body.offsetWidth;
    const contentWidth = ref.current.offsetWidth;
    const horPadding = (siteWidth - contentWidth) / 2;

    const tabs = ref.current.childNodes[0].childNodes;

    const firstX = tabs[0].getBoundingClientRect().x;
    const isLeft = firstX - horPadding < 0;

    const lastX = tabs[tabs.length - 1].getBoundingClientRect().x;
    const lastWidth = tabs[tabs.length - 1].getBoundingClientRect().width;
    const correctedLastX = Math.floor(lastX + lastWidth);

    const isRight = correctedLastX + horPadding > siteWidth;

    setBlurTabs({ isLeft, isRight });
  };

  useEffect(() => {
    if (account_id) {
      dispatch(getMembersList(account_id));
      dispatch(getRolesList());
    }
  }, [account_id]);

  useEffect(() => {
    if (ref.current) ref.current.childNodes[0].onscroll = checkScroll;
  }, [ref]);

  if (isAccountOwner === undefined && !accountError) {
    return <PageLoader />;
  }

  return (
    <div className='page settings-page'>
      <PageTitle
        title={tabs.find((o) => o.eventKey === activeTab)?.titleText}
      />

      <div
        className={clsx('page-content', {
          'page-content__left-blur': blurTabs.isLeft,
          'page-content__right-blur': blurTabs.isRight,
        })}
        ref={ref}>
        <Tabs
          className='settings-tabs'
          activeKey={activeTab}
          mountOnEnter={true}
          onSelect={handleSelect}>
          {tabs.map(
            ({ eventKey, titleIcon, titleText, content, hide }) =>
              !hide && (
                <Tab
                  key={eventKey}
                  tabClassName='settings-tab'
                  eventKey={eventKey}
                  title={
                    <>
                      {titleIcon}
                      {titleText}
                    </>
                  }>
                  {content}
                </Tab>
              )
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default React.memo(SettingsPage);
