import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getDashboard as getDashboardAction } from '../actions';
// api
import { getDashboard as getDashboardApi } from '../api';

function* getSaga({ payload: { id } }) {
  try {
    yield put(getDashboardAction.request());

    const dashboard = yield call(getDashboardApi, id);

    yield put(getDashboardAction.success({ dashboard }));
  } catch (error) {
    console.error(error);
    yield put(getDashboardAction.failure(error.response));
  } finally {
    yield put(getDashboardAction.fulfill());
  }
}

export default function* get() {
  yield takeLatest(getDashboardAction.TRIGGER, getSaga);
}
