import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { resetUserPassword as action } from '../actions';
// api
import { resetUserPassword as api } from '../api';
import { toast } from 'react-toastify';

function* getSaga({ payload }) {
  try {
    yield put(action.request());
    yield call(api, payload);

    toast('Password reset link was sent successfully', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'success',
    });

    payload.success();

    yield put(action.success());
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* resetUserPassword() {
  yield takeLatest(action.TRIGGER, getSaga);
}
