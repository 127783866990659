import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import './project-team.styles.scss';

import UserAvatar from '../../user-avatar/user-avatar.component';
import UserSearchModal from '../../user-search-modal/user-search-modal.component';

import { getAccountMembers } from 'store/accounts/selectors';
import { addMember, deleteMember } from 'store/projects/actions';

import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { useParams } from 'react-router-dom';

const ProjectTeam = ({
  project = {},
  emptyState = '\u2014',
  editing = true,
  addButton = true,
  className = '',
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { members: membersList, isLoading } = useSelector(getAccountMembers);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const { members = [] } = project;

  const onMemberClick = (member) => {
    const isBoardMember = members.find(({ user }) => user.id === member.id);

    if (!isBoardMember) {
      dispatch(
        addMember({
          projectId: project.id,
          memberId: member.id,
          boardId: parseInt(id),
        })
      );
    } else {
      dispatch(
        deleteMember({
          projectId: project.id,
          memberId: member.id,
        })
      );
    }
  };

  const users = useMemo(
    () => members.map(({ user }) => user),
    [members, members.length]
  );

  return (
    <div className={`project-team ${className}`}>
      <div
        className={`project-team__members ${
          !addButton && 'project-team__editable'
        }`}
        {...(!addButton &&
          editing && { onClick: () => setShowSearchModal(true) })}>
        {!members.length && !addButton
          ? emptyState
          : members.map((member) => (
              <UserAvatar
                key={`project-team-${member.id}`}
                user={member.user}
                width={30}
                height={30}
                className='project-team__members-item'
              />
            ))}
      </div>
      {addButton && (
        <button
          className='project-team__add-button'
          onClick={() => setShowSearchModal(true)}>
          <PlusIcon />
        </button>
      )}
      <UserSearchModal
        title='Members'
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        onSelect={onMemberClick}
        list={membersList}
        selectedList={users}
        isLoading={isLoading}
        projectId={project.id}
      />
    </div>
  );
};

ProjectTeam.propTypes = {
  project: PropTypes.object,
  addButton: PropTypes.bool,
  className: PropTypes.string,
};

export default ProjectTeam;
