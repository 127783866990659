import './aside-container.styles.scss';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

const AsideContainer = ({ open, onClose, children }) => {
  return (
    <aside
      className={`aside-container ${open ? '' : 'aside-container--hidden'}`}>
      <div className='aside-container__content'>
        <div className='aside-container__header'>
          <button className='aside-container__close-button' onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        {children}
      </div>
    </aside>
  );
};

export default AsideContainer;
