import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const ALERT_DELAY = 5;

export default function useAlert({ isShow: isShowProp }) {
  const location = useLocation();
  const [isShow, setShow] = useState(Boolean(isShowProp));

  useEffect(() => {
    if (isShow) {
      const alertTimer = setTimeout(() => setShow(false), ALERT_DELAY * 1000);

      return () => {
        clearTimeout(alertTimer);
      };
    }
  }, [isShow]);

  useEffect(() => {
    if (isShowProp) {
      setShow(true);
    }
  }, [isShowProp]);

  useEffect(() => {
    setShow(false);
  }, [location]);

  return { show: isShow };
}
