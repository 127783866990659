import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { addProject } from 'store/projects/actions';

import UaInput from 'components/common/ua-input/ua-input.component';
import UaModal from 'components/common/ua-modal/ua-modal.component';
import CreateProjectSchema from 'components/dashboard/create-project/create-project.validate';
import { toast } from 'react-toastify';

const CreateProjectButton = ({ boardId, statusId, className, children }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const toastId = useRef(null);

  const formik = useFormik({
    initialValues: {
      project: '',
    },
    validationSchema: CreateProjectSchema,
    onSubmit: (values) => {
      dispatch(
        addProject({
          id: boardId,
          name: values.project,
          status_id: statusId ?? null,
        })
      );
      setShow(false);
    },
  });

  const handleChange = (event) => {
    if (event.target.value.length > 50) {
      if (!toastId.current) {
        toastId.current = toast('Maximum project name length - 50 characters', {
          autoClose: 2000,
          pauseOnHover: false,
          hideProgressBar: true,
          closeButton: true,
          type: 'error',
          onClose: () => (toastId.current = null),
        });
      } else {
        toast.update(toastId.current);
      }
    } else {
      formik.handleChange(event);
    }
  };

  useEffect(() => {
    if (!show) formik.resetForm();
    if (show) ref.current.focus();
  }, [show]);

  return (
    <>
      <button
        className={`create-project-button ${className || ''}`}
        onClick={() => setShow(true)}>
        {children}
      </button>

      <UaModal
        show={show}
        onHide={() => setShow(false)}
        title='New Project'
        okLabel='Save'
        onSubmit={formik.handleSubmit}>
        <UaInput
          ref={ref}
          type='text'
          id='project'
          name='project'
          label='Project Name'
          value={formik.values.project}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          showError
        />
      </UaModal>
    </>
  );
};

export default CreateProjectButton;
