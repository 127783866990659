import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { fork, take, call, put, cancel, select } from 'redux-saga/effects';

import { API_BASE_URL } from 'settings';
import { logout } from 'store/auth/actions';

import {
  addBoard,
  deleteBoard,
  editBoard,
  addBoardMember,
  removeBoardMember,
  preDeleteBoard,
  addBoardStatusFromSocket,
  editBoardStatusFromSocket,
  removeBoardStatusFromSocket,
  addTag,
  editTag,
  deleteTag,  updateBoard,
} from 'store/boards/actions';

import { setBoardSocket } from '../actions';

import { getCurrentAccount } from '../../accounts/actions';
import { getCurrentAccount as currentAccountSelector } from '../../accounts/selectors';

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('joinedRoom', () => {
      socket.on('board', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;
        if (res.action === 'updated-kpi' && res.data?.sender_socket_id === socket.io.nsps['/projects']?.id) return;

        switch (res.action) {
          case 'created':
            emit(addBoard.success(res.data));
            break;

          case 'updated':
            emit(editBoard.success(res.data));
            break;

          case 'deleted':
            emit(preDeleteBoard.trigger(res.data.id));
            emit(deleteBoard.success());
            break;

          case 'updated-kpi':
            emit(updateBoard.success({ kpi: res.data.kpi }));
            break;

          default:
            break;
        }
      });

      socket.on('board-invite', (res) => {
        switch (res.action) {
          case 'invite':
            emit(addBoardMember.success(res.data));
            break;

          case 'remove':
            emit(
              removeBoardMember.success({
                board_id: res.data.board_id,
                user_id: res.data.user.id,
              })
            );
            break;

          default:
            break;
        }
      });
      socket.on('project-tags', (res) => {
        switch (res.action) {
          case 'created':
            emit(addTag.success(res.data));
            break;

          case 'updated':
            emit(editTag.success(res.data));
            break;

          case 'deleted':
            emit(deleteTag.success(res.data.id));
            break;

          default:
            break;
        }
      });
      socket.on('project-status', (res) => {
        switch (res.action) {
          case 'created':
            emit(
              addBoardStatusFromSocket.success({
                boardId: parseInt(res.data.board_id),
                status: {
                  color: res.data.color,
                  name: res.data.name,
                  slug: res.data.slug,
                },
              })
            );
            break;

          case 'updated':
            emit(
              editBoardStatusFromSocket.success({
                boardId: parseInt(res.data.board_id),
                status: {
                  color: res.data.color,
                  name: res.data.name,
                  slug: res.data.slug,
                },
              })
            );
            break;

          case 'deleted':
            emit(
              removeBoardStatusFromSocket.success({
                boardId: parseInt(res.data.board_id),
                status: {
                  color: res.data.color,
                  name: res.data.name,
                  slug: res.data.slug,
                },
              })
            );
            break;

          default:
            break;
        }
      });
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* handleIO(socket) {
  yield fork(read, socket);
}

function* flow() {
  while (true) {
    yield take(getCurrentAccount.SUCCESS);
    const socket = yield call(io, `${API_BASE_URL}/boards`);
    yield put(setBoardSocket(socket));
    const { id } = yield select(currentAccountSelector);
    socket.emit('joinRoom', id);

    const task = yield fork(handleIO, socket);
    yield take(logout.SUCCESS);
    socket.emit('leaveRoom', id);
    yield cancel(task);
    socket.disconnect();
  }
}

export default function* rootSaga() {
  yield fork(flow);
}
