import {put, takeLatest, select} from 'redux-saga/effects';
// actions
import { preDeleteBoard as action } from '../actions';
// api
import { getBoard } from 'store/boards/selectors';
import { toast } from 'react-toastify';
import history from '../../../history';
import routes from 'utils/routes';

export function* preDeleteSaga({ payload }) {
  try {
    const board = yield select(getBoard);

    if (board?.id === payload) {
      toast('Board has been deleted', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error',
      });
      history.push(routes.dashboard);
    }
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* preDeleteBoard() {
  yield takeLatest(action.TRIGGER, preDeleteSaga);
}
