import clsx from 'clsx';
import { CustomPicker } from 'react-color';
import {
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';

import './ua-colorpicker.styles.scss';

import hexToRgb from 'utils/hexToRgb';
import React from 'react';

const colors = [
  {
    hex: '#DF9A23',
    h: '38',
    s: '0.75',
    l: '0.51',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#8309E6',
    h: '273',
    s: '0.92',
    l: '0.47',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#DF302B',
    h: '2',
    s: '0.74',
    l: '0.52',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#1517C0',
    h: '207',
    s: '0.80',
    l: '0.42',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#FAFAFA',
    h: '0',
    s: '0',
    l: '0.53',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#7ED934',
    h: '93',
    s: '0.68',
    l: '0.53',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#000000',
    h: '0',
    s: '0',
    l: '0',
    a: '1',
    source: 'hsl',
  },
  {
    hex: '#EFF000',
    h: '60',
    s: '1',
    l: '0.47',
    a: '1',
    source: 'hsl',
  },
];

const HuePointer = () => {
  return <div className='ua-colorpicker__hue-pointer' />;
};

const Colorpicker = CustomPicker((props) => {
  return (
    <>
      <div className='ua-colorpicker__editable-input'>
        <EditableInput
          ref={props.innerRef}
          value={props.hex}
          onChange={props.onChange}
        />
        <button
          type='button'
          className='ua-colorpicker__clear'
          onClick={(e) => {
            e.stopPropagation();
            props.onChange({ ...hexToRgb(props.color), a: 1, source: 'rgb' });
          }}>
          Clear
        </button>
      </div>
      {props.error && (
        <div className='ua-input__error'>{props.error}</div>
      )}
      <div className='ua-colorpicker__container'>
        <div className='ua-colorpicker__saturation'>
          <Saturation {...props} onChange={props.onChange} />
        </div>
        <div className='ua-colorpicker__hue'>
          <Hue
            {...props}
            onChange={props.onChange}
            direction='vertical'
            pointer={HuePointer}
          />
        </div>
        <div className='ua-colorpicker__predefined'>
          {colors.map((color) => {
            return (
              <div
                className='color'
                key={`color-${color.hex}`}
                onClick={() => props.onChange(color)}>
                <div
                  style={{
                    backgroundColor: color.hex,
                    borderColor: color.hex,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
});
const UaColorpicker = ({ color, handleChange, error, valid }) => {
  return (
    <div
      className={clsx('ua-colorpicker', {
        'ua-colorpicker--error': error,
        'ua-colorpicker--valid': valid,
      })}>
      <Colorpicker
        color={color}
        error={error}
        onChange={(data) => {
          handleChange({ name: 'color', value: data.hex });
        }}
      />
    </div>
  );
};

export default UaColorpicker;
