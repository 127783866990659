// actions
import { closeAppSidebar, openAppSidebar, toggleAppSidebar } from './actions';

const INITIAL_STATE = {
  isSidebarOpen: true,
};

const appReducer = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case openAppSidebar.TRIGGER: {
      return {
        ...state,
        isSidebarOpen: true,
      };
    }

    case closeAppSidebar.TRIGGER: {
      return {
        ...state,
        isSidebarOpen: false,
      };
    }

    case toggleAppSidebar.TRIGGER: {
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    }

    default:
      return state;
  }
};

export default appReducer;
