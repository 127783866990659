import React, { useEffect } from 'react';

import BoardComponent from 'components/board/board/board.component';
import { getGuestBoard } from 'store/boards/actions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BoardContextProvider } from 'context/board-context/board-context';
import { closeAppSidebar } from 'store/app/actions';

const BoardGuestPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => dispatch(closeAppSidebar()), [dispatch]);

  useEffect(() => {
    if (id) dispatch(getGuestBoard({ id, initial: true }));
  }, [id]);

  return (
    <BoardContextProvider isGuest={true}>
      <BoardComponent />
    </BoardContextProvider>
  );
};

export default BoardGuestPage;
