import { put, takeEvery, select } from 'redux-saga/effects';
import sortBy from 'lodash/sortBy';
// actions
import {
  dndBoardDashboard as dndBoardDashboardAction,
  changePosition,
} from '../actions';
// selectors
import { getCurrentDashboard, getBoardPositions } from '../selectors';
import {
  getActiveBoards,
  getArchivedBoards,
  getFavoriteBoards,
} from 'store/boards/selectors';
import { changePositions } from 'utils/draggable-sort';
import { ORDER_TYPES } from 'settings';
import dndDataFilter from 'utils/dndDataFilter';

function* dropPosition({ boards, from, to, key }) {
  const { id } = yield select(getCurrentDashboard);
  const orders = yield select(getBoardPositions);
  const filter = dndDataFilter(orders[key], boards, 'id', 'id');

  const list = filter?.length
    ? filter
    : boards.map(({ id }, index) => ({ id, order: index }));

  const dropArea = changePositions(sortBy(list, ['order']), {
    from,
    to,
  });

  const newPositions = dropArea.map((props, index) => ({
    ...props,
    order: index,
  }));

  yield put(
    changePosition({
      dashboard_id: id,
      boards_positions: { [key]: newPositions },
    })
  );
}

function* dndBoardSaga({ payload }) {
  const { from, to, type } = payload;
  try {
    if (type === ORDER_TYPES.FAVORITES) {
      const favoriteBoards = yield select(getFavoriteBoards);

      yield dropPosition({
        boards: favoriteBoards,
        from,
        to,
        key: ORDER_TYPES.FAVORITES,
      });
    } else if (type === ORDER_TYPES.ALL) {
      const activeBoards = yield select(getActiveBoards);

      yield dropPosition({
        boards: activeBoards,
        from,
        to,
        key: ORDER_TYPES.ALL,
      });
    } else {
      const archiveBoards = yield select(getArchivedBoards);

      yield dropPosition({
        boards: archiveBoards,
        from,
        to,
        key: ORDER_TYPES.ARCHIVED,
      });
    }
  } catch (error) {
    console.error(error);
    yield put(dndBoardDashboardAction.failure(error.response));
  } finally {
    yield put(dndBoardDashboardAction.fulfill());
  }
}

export default function* get() {
  yield takeEvery(dndBoardDashboardAction.TRIGGER, dndBoardSaga);
}
