import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import clsx from 'clsx';
import useSocket from 'hooks/useSocket';

import './account-settings.styles.scss';

import TransferOwnershipModal from 'components/transfer-ownership-modal/transfer-ownership-modal.component';
import usePhoto from 'hooks/usePhoto';
import UAButton from 'components/common/ua-button/ua-button.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import uploadIcon from 'assets/images/icons/cloud-upload.png';
import CloseAccountModal from 'components/close-account-modal/close-account-modal.component';

import { ReactComponent as Trash } from 'assets/images/icons/trash-alt.svg';

import { getDownloadData } from 'store/downloadFiles/selectors';
import {
  closeAccount,
  updateAccount,
  deleteLogo,
  transferOwnership,
  downloadDataRequest,
} from 'store/accounts/actions';
import {
  getCloseAccountStatus,
  getCurrentAccount,
  getLogoLoading,
  getNameLoading,
  getAccountMembers,
  getOwnershipStatus,
  getOwnershipLoading,
} from 'store/accounts/selectors';
import { getProfile } from 'store/auth/selectors';
import AccountInfoSchema from './AccountInfoSchema';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const dataRequest = useSelector(getDownloadData(profile.account_id));
  const accountStatus = useSelector(getCloseAccountStatus);
  const { isConnected } = useSocket();
  const { members } = useSelector(getAccountMembers);
  const ownershipStatus = useSelector(getOwnershipStatus);
  const ownershipLoading = useSelector(getOwnershipLoading);
  const { account_id } = useSelector(getProfile);
  const { name, logo_path } = useSelector(getCurrentAccount);
  const isLogoLoading = useSelector(getLogoLoading);
  const isNameLoading = useSelector(getNameLoading);

  const [transferModal, setTransferModal] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const [userPhoto, isLoading] = usePhoto(
    `/accounts/${account_id}/logo`,
    logo_path
  );

  const onDrop = (file) => {
    dispatch(
      updateAccount({
        accountId: account_id,
        data: {
          logo: file[0],
        },
      })
    );
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', multiple: false, onDrop });

  const onSubmit = (values) => {
    dispatch(
      updateAccount({
        accountId: account_id,
        data: {
          name: values.companyName.trim(),
        },
      })
    );
  };

  const onImageChange = (e) => {
    dispatch(
      updateAccount({
        accountId: account_id,
        data: {
          logo: e.target.files[0],
        },
      })
    );
  };

  const onDeleteLogo = () => {
    dispatch(deleteLogo(account_id));
  };

  const formik = useFormik({
    validationSchema: AccountInfoSchema,
    onSubmit,
    initialValues: {
      companyName: '',
    },
  });

  useEffect(() => {
    if (name) formik.setFieldValue('companyName', name);
  }, [name]);

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  const onCloseAccount = (values) => {
    dispatch(
      closeAccount({
        accountId: account_id,
        ...values,
      })
    );
  };

  const onDownloadRequest = () =>
    dispatch(downloadDataRequest(profile.account_id));

  const downloadBtnContent = () => {
    if (dataRequest.isLoading)
      return <Spinner animation='border' size='sm' color='#00f' />;
    return <span>Download Data</span>;
  };

  const onTransferOwnership = (values) => {
    dispatch(
      transferOwnership({
        accountId: account_id,
        ...values,
      })
    );
  };

  const onShowModal = () => setConfirmShow(true);
  const onHideModal = () => setConfirmShow(false);

  return (
    <div className='account-settings'>
      <div className='account-settings__col'>
        <div className='upload-logo'>
          <input {...getInputProps()} onChange={onImageChange} />
          {!logo_path ? (
            <>
              <h3 className='upload-logo__title'>Upload Logo</h3>
              <div
                {...getRootProps({
                  className: clsx('upload-logo__dropzone', {
                    'upload-logo__dropzone--active': isDragActive,
                    'upload-logo__dropzone--accept': isDragAccept,
                    'upload-logo__dropzone--reject': isDragReject,
                  }),
                })}>
                <div className='upload-logo__dropzone-icon'>
                  <img src={uploadIcon} alt='' />
                </div>
                <p className='upload-logo__dropzone-label'>
                  Drag Files to Upload
                </p>
              </div>
            </>
          ) : (
            <>
              <h3 className='upload-logo__title'>Upload Logo</h3>
              <div className='upload-logo__preview-container'>
                <img
                  className='upload-logo__preview'
                  draggable={false}
                  src={userPhoto}
                  alt=''
                />
                <button
                  className='upload-logo__preview-delete'
                  onClick={onDeleteLogo}>
                  <Trash />
                </button>
              </div>
            </>
          )}
          <Button variant='primary' onClick={open}>
            Choose a File
          </Button>

          {(isLogoLoading || isLoading) && (
            <div className='upload-logo__loading'>
              <Spinner animation='border' />
            </div>
          )}
        </div>
      </div>

      <div className='account-settings__col'>
        <div className='account-info'>
          <h3 className='account-info__title'>Account Info</h3>
          <form className='account-info__form' onSubmit={formik.handleSubmit}>
            <UaInput
              id='companyName'
              containerClassName='account-info__form-input'
              name='companyName'
              placeholder='Enter company name'
              label='Company name'
              value={formik.values.companyName}
              handleChange={handleChange}
              onBlur={formik.handleBlur}
              showError={true}
              error={formik.touched.companyName && formik.errors.companyName}
              valid={formik.touched.companyName && !formik.errors.companyName}
            />
            <UAButton
              variant='primary'
              isLoading={isNameLoading}
              type='submit'
              className='account-info__form-submit'>
              Update Company Name
            </UAButton>
          </form>
          <div className='account-info__actions'>
            <div className='account-info__actions-row'>
              <div className='account-info__actions-col'>
                <button
                  onClick={onDownloadRequest}
                  className='account-info__action account-info__action--warning'
                  disabled={!isConnected || dataRequest.isLoading}>
                  {downloadBtnContent()}
                </button>
              </div>
              <div className='account-info__actions-col'>
                <button
                  onClick={onShowModal}
                  className='account-info__action account-info__action--danger'>
                  <span>Close Account</span>
                </button>
              </div>
              <div className='account-info__actions-col'>
                <button
                  onClick={() => setTransferModal(true)}
                  className='account-info__action account-info__action--primary'>
                  <span>Transfer Ownership</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TransferOwnershipModal
        show={transferModal}
        onHide={() => setTransferModal(false)}
        list={members}
        title='Transfer Ownership'
        status={ownershipStatus}
        ownershipLoading={ownershipLoading}
        onTransferOwnership={onTransferOwnership}
      />

      <CloseAccountModal
        show={confirmShow}
        onHide={onHideModal}
        onCloseAccount={onCloseAccount}
        title='Are you sure?'
        isLoading={accountStatus.isLoading}
        status={accountStatus.accountStatus}
      />
    </div>
  );
};

export default AccountSettings;
