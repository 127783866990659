import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { subscribePlan as action } from '../actions';
// api
import { subscribePlan as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    payload.success(response.invoice);

    yield put(action.success(response));
  } catch (error) {
    console.error(error);
    payload.error();
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* subscribePlan() {
  yield takeLatest(action.TRIGGER, getSaga);
}
