import { useSelector } from 'react-redux';

import { isSocketConnected } from 'store/socket/selectors';

export default function useSocket() {
  const isConnected = useSelector(isSocketConnected);

  return {
    isConnected,
  };
}
