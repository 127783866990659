import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { fork, take, call, put, cancel } from 'redux-saga/effects';

import { API_BASE_URL } from 'settings';
import { getProfile, logout } from 'store/auth/actions';
import {downloadData, downloadDataRequest} from 'store/accounts/actions';

import { setUserSocket, updateSocketState} from '../actions';

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('joinedRoom', () => {
      emit(updateSocketState(true));

      socket.on('account-download', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId !== res?.sender_socket_id) return;

        emit(downloadData(res));
      });

      socket.on('error', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId !== res?.sender_socket_id) return;

        if (res.message === 'Cannot generate cvs') {
          emit(downloadDataRequest.fulfill());
        }
      });
    });
    socket.on('leaveRoom', (room) => {});
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* handleIO(socket) {
  yield fork(read, socket);
}

function* flow() {
  while (true) {
    const { payload } = yield take(getProfile.SUCCESS);
    const socket = yield call(io, `${API_BASE_URL}/users`);
    yield put(setUserSocket(socket));
    socket.emit('joinRoom', payload.profile.id);

    const task = yield fork(handleIO, socket);
    yield take(logout.SUCCESS);
    socket.emit('leaveRoom', payload.profile.id);
    yield put(updateSocketState(false));
    yield cancel(task);
    socket.disconnect();
  }
}

export default function* rootSaga() {
  yield fork(flow);
}
