import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { deleteAccountUser as action } from '../actions';
// api
import { deleteAccountUser as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());
    yield call(api, payload);
    yield put(action.success(payload));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteAccountUser() {
  yield takeLatest(action.TRIGGER, getSaga);
}
