import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const nameFieldTypes = {
  firstname: 'firstName',
  lastname: 'lastName',
  password_confirm: 'passwordConfirmation',
};

const getErrorMessage = ({ data } = {}) => {
  const messages = {
    fields: {},
    main: '',
  };

  if (!isEmpty(data)) {
    const { error, message } = data;

    if (isArray(message) && !isEmpty(message)) {
      messages.fields = message.reduce((accum, { property, constraints }) => {
        const nameProperty =
          property in nameFieldTypes ? nameFieldTypes[property] : property;

        accum[nameProperty] = Object.values(constraints);

        return accum;
      }, messages.fields);
    } else {
      messages.main = message || error || '';
    }
  }

  return messages;
};

export default getErrorMessage;
