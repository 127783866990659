import './setting-item.styles.scss';

const SettingItem = ({ icon, name, body }) => {
  return (
    <div className='setting-card-item'>
      <div className='setting-card-item__header'>
        <span className='setting-card-item__icon'>{icon}</span>
        <span className='setting-card-item__name'>{name}</span>
      </div>
      <div className='setting-card-item__body'>{body}</div>
    </div>
  );
};

export default SettingItem;
