import React, { useMemo } from 'react';
import { groupBy } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import BoardsList from 'components/dashboard/boards-list/boards-list.component';

const PersonalDashboard = ({ boards, accounts }) => {
  const groupBoardsByAccounts = useMemo(
    () => groupBy(boards, 'account_id'),
    [boards]
  );

  const accountNames = useMemo(
    () =>
      accounts.reduce((r, a) => {
        r[a.id] = a.name || 'Without name';
        return r;
      }, Object.create(null)),
    [accounts]
  );

  const arr = Object.keys(groupBoardsByAccounts);

  return (
    <div className='section-content'>
      {arr.map((id) => (
        <React.Fragment key={id}>
          <h3 className='content-title'>{accountNames[id]}</h3>
          <DragDropContext onDragEnd={() => {}}>
            <BoardsList
              items={groupBoardsByAccounts[id]}
              droppableId={`personal-dashboard-${id}`}
              disabled
            />
          </DragDropContext>
        </React.Fragment>
      ))}
    </div>
  );
};

export default PersonalDashboard;
