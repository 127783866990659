import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import './close-account-modal.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import UaButton from 'components/common/ua-button/ua-button.component';
import CloseAccountSchema from './CloseAccountSchema';

const CloseAccountModal = ({
  show,
  onHide,
  title,
  onCloseAccount,
  isLoading,
  status,
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (status?.statusCode === 201) {
      setError(null);
      onHide();
    } else if (status?.error) {
      setError(status.message);
    }
  }, [status]);

  const onSubmit = (values) => {
    onCloseAccount(values);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: CloseAccountSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!show)
      return () => {
        formik.setFieldTouched('password', false);
      };
  }, [show]);

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  return (
    <UaModal
      show={show}
      onHide={onHide}
      title={title}
      noFooter={true}
      dialogClassName='close-account-modal'>
      <form onSubmit={formik.handleSubmit}>
        <UaInput
          id='password'
          name='password'
          type='password'
          placeholder='Account password'
          value={formik.values.password}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          showError={true}
          error={error || (formik.touched.password && formik.errors.password)}
        />
        <div className='close-account__submit-container'>
          <UaButton
            type='submit'
            isLoading={isLoading}
            onClick={formik.handleSubmit}
            className='close-account__submit'>
            Confirm
          </UaButton>
        </div>
      </form>
    </UaModal>
  );
};

export default CloseAccountModal;
