// actions
import {
  createCard,
  deleteCard,
  getCards,
  updateCard,
  getTransactions,
  updateTransaction,
  addTransaction,
  getPlans,
  deleteTransaction,
} from './actions';
import immutable from 'immutability-helper';

const INITIAL_STATE = {
  cards: [],
  cardsLoading: false,
  transactions: [],
  transactionsLoading: false,
  plans: [],
  plansLoading: false,
};

const projectsReducer = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case getCards.TRIGGER: {
      return {
        ...state,
        cardsLoading: true,
      };
    }

    case getCards.FULFILL: {
      return {
        ...state,
        cardsLoading: false,
      };
    }

    case createCard.SUCCESS: {
      return {
        ...state,
        cards: [...state.cards, payload],
      };
    }

    case getCards.SUCCESS: {
      return {
        ...state,
        cards: payload,
      };
    }

    case updateCard.SUCCESS: {
      const index = state.cards.findIndex(
        ({ id }) => id === payload.response.id
      );
      const cards = [...state.cards];

      if ('is_default' in payload.data) {
        for (const i in cards) {
          cards[i] = { ...cards[i], is_default: false };
        }
      }

      cards[index] = payload.response;

      return {
        ...state,
        cards: cards,
      };
    }

    case deleteCard.SUCCESS: {
      const index = state.cards.findIndex((item) => item.id === payload);
      const cards = [...state.cards];
      cards.splice(index, 1);

      return {
        ...state,
        cards: cards,
      };
    }

    case getTransactions.TRIGGER: {
      return {
        ...state,
        transactionsLoading: true,
      };
    }

    case getTransactions.FULFILL: {
      return {
        ...state,
        transactionsLoading: false,
      };
    }

    case getTransactions.SUCCESS: {
      return {
        ...state,
        transactions: payload.rows,
      };
    }

    case updateTransaction.SUCCESS: {
      const index = state.transactions.findIndex(({ id }) => id === payload.id);
      const transactions = [...state.transactions];
      transactions[index] = payload;

      return {
        ...state,
        transactions,
      };
    }

    case deleteTransaction.SUCCESS: {
      const index = state.transactions.findIndex(({ id }) => id === payload.id);
      const transactions = immutable(state.transactions, {
        $splice: [[index, 1]],
      });

      return {
        ...state,
        transactions,
      };
    }

    case addTransaction.SUCCESS: {
      const transactions = [...state.transactions];
      transactions.unshift(payload);

      return {
        ...state,
        transactions,
      };
    }

    case getPlans.TRIGGER: {
      return {
        ...state,
        plansLoading: true,
      };
    }

    case getPlans.FULFILL: {
      return {
        ...state,
        plansLoading: false,
      };
    }

    case getPlans.SUCCESS: {
      return {
        ...state,
        plans: payload,
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
