import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import routes from 'utils/routes';

// styles
import './registration.styles.scss';
import LaptopImg from 'assets/images/auth_laptop.png';
// components
import RegistrationForm from 'components/auth/registration-form/registration-form.component';
// actions
import { signUp, resetAuthState } from 'store/auth/actions';
// selectors
import {
  getAuthErrors,
  getAuthIsLoading,
  getAuthState,
} from 'store/auth/selectors';
import { SUPPORT_EMAIL } from '../../storage/consts';

const RegistrationPage = () => {
  const { isSuccessSignUp } = useSelector(getAuthState);
  const error = useSelector(getAuthErrors);
  const isLoading = useSelector(getAuthIsLoading);

  const dispatch = useDispatch();
  const handleSubmit = (user) => {
    dispatch(signUp(user));
  };

  useEffect(() => {
    dispatch(resetAuthState());
  }, [dispatch]);

  const handleMoveToForm = () => {
    const section = document.querySelector('.registration-form');

    section.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <div className='registration-content'>
      <div className='registration-content__left welcome-section'>
        <h2 className='welcome-section__title'>
          Project tracking is better with Unmanage
        </h2>
        <div className='welcome-section__desc-wrapper'>
          <p className='welcome-section__desc text-black'>
            Tired of complex systems, or looking for a better way to get
            organized?
          </p>
          <p className='welcome-section__desc welcome-section__desc--with-img text-black'>
            Get more done today with Unmanage
            <div className='welcome-section__advantages'>
              <ul className='welcome-section__advantages-list'>
                <li className='welcome-section__advantages-list-item'>
                  Simple, powerful tools
                </li>
                <li className='welcome-section__advantages-list-item'>
                  Multiple project views
                </li>
                <li className='welcome-section__advantages-list-item'>
                  Flexible workflows
                </li>
                <li className='welcome-section__advantages-list-item'>
                  Great pricing
                </li>
                <li className='welcome-section__advantages-list-item'>
                  And more...
                </li>
              </ul>
            </div>
          </p>
        </div>
        <div className='welcome-section__decoration-img'>
          <Image src={LaptopImg} loading='lazy' />
        </div>

        <div className='registration-content__mobile-navigation mobile-navigation'>
          <div>Start your registration</div>
          <button
            className='mobile-navigation__button'
            onClick={handleMoveToForm}>
            <svg
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M13.7498 6.25V20.2125L7.64983 14.1125C7.16233 13.625 6.36233 13.625 5.87483 14.1125C5.75895 14.2281 5.66702 14.3655 5.60429 14.5167C5.54156 14.6679 5.50928 14.83 5.50928 14.9938C5.50928 15.1575 5.54156 15.3196 5.60429 15.4708C5.66702 15.622 5.75895 15.7594 5.87483 15.875L14.1123 24.1125C14.5998 24.6 15.3873 24.6 15.8748 24.1125L24.1123 15.875C24.2281 15.7593 24.3199 15.6219 24.3825 15.4707C24.4451 15.3195 24.4774 15.1574 24.4774 14.9938C24.4774 14.8301 24.4451 14.668 24.3825 14.5168C24.3199 14.3656 24.2281 14.2282 24.1123 14.1125C23.9966 13.9968 23.8592 13.905 23.708 13.8423C23.5568 13.7797 23.3947 13.7475 23.2311 13.7475C23.0674 13.7475 22.9054 13.7797 22.7542 13.8423C22.6029 13.905 22.4656 13.9968 22.3498 14.1125L16.2498 20.2125V6.25C16.2498 5.5625 15.6873 5 14.9998 5C14.3123 5 13.7498 5.5625 13.7498 6.25Z'
                fill='white'
              />
            </svg>
          </button>
        </div>
      </div>
      <div className='registration-content__right'>
        {isSuccessSignUp ? (
          <div className='form form-card registration-content__success'>
            <h1 className='form__caption'>Registration Successful!</h1>
            <p>
              Check your email for your personal link to get started
              <div>
                <NavLink className='form__link' to={routes.login}>
                  Go back
                </NavLink>
              </div>
            </p>
            <p>
              Have questions or need help?
              <div>
                <a href={`mailto:${SUPPORT_EMAIL}`} className='form__link'>
                  Contact us
                </a>
              </div>
            </p>
          </div>
        ) : (
          <RegistrationForm
            onSubmit={handleSubmit}
            error={error}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default RegistrationPage;
