import { getProfile, logout, resetPassword } from 'store/auth/actions';
import {
  getRolesList,
  getMembersList,
  transferOwnership,
  ownerAccept,
  closeAccount,
  updateAccount,
  deleteLogo,
  deleteAccountUser,
  inviteAccountUser,
  updateAccountUser,
  getCurrentAccount,
  refreshCurrentAccount,
} from './actions';

import { unsubscribePlan, subscribePlan } from 'store/payment/actions';

const INITIAL_STATE = {
  list: null,
  currentAccount: {},
  accountError: null,
  logoLoading: false,
  updateLoading: false,
  roles: [],
  rolesLoading: false,
  members: [],
  membersLoading: false,
  ownershipStatus: null,
  ownershipLoading: false,
  ownerAcceptStatus: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  inviteStatus: {},
  inviteLoading: false,
  closeAccountStatus: null,
  closeAccountLoading: false,
  subscriptions: [],
};

const accountsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case getProfile.SUCCESS:
      return {
        ...state,
        list: payload.profile.accounts,
      };

    case getCurrentAccount.SUCCESS:
    case refreshCurrentAccount.SUCCESS: {
      return {
        ...state,
        currentAccount: payload,
        accountError: null,
      };
    }

    case getCurrentAccount.FAILURE: {
      return {
        ...state,
        accountError: payload.data,
      };
    }

    case subscribePlan.SUCCESS: {
      const currentAccount = {
        ...state.currentAccount,
        subscriptions: [payload.subscription],
      };

      return {
        ...state,
        currentAccount,
      };
    }

    case unsubscribePlan.SUCCESS: {
      const currentAccount = {
        ...state.currentAccount,
        subscriptions: [],
      };

      return {
        ...state,
        currentAccount,
      };
    }

    case resetPassword.SUCCESS:
      return {
        ...state,
        list: INITIAL_STATE.list,
      };

    case getRolesList.REQUEST:
      return {
        ...state,
        rolesLoading: true,
      };

    case getRolesList.SUCCESS:
      return {
        ...state,
        roles: payload,
      };

    case getRolesList.FULFILL:
      return {
        ...state,
        rolesLoading: false,
      };

    case getMembersList.TRIGGER:
      return {
        ...state,
        membersLoading: true,
      };

    case getMembersList.SUCCESS:
      return {
        ...state,
        members: payload,
      };

    case getMembersList.FULFILL:
      return {
        ...state,
        membersLoading: false,
      };

    case transferOwnership.TRIGGER:
      return {
        ...state,
        ownershipLoading: true,
      };

    case transferOwnership.SUCCESS: {
      return {
        ...state,
        ownershipStatus: { success: true },
      };
    }

    case transferOwnership.FAILURE: {
      return {
        ...state,
        ownershipStatus: payload.data,
      };
    }

    case transferOwnership.FULFILL: {
      return {
        ...state,
        ownershipStatus: null,
        ownershipLoading: false,
      };
    }

    case ownerAccept.TRIGGER: {
      return {
        ...state,
        ownerAcceptStatus: {
          ...INITIAL_STATE.ownerAcceptStatus,
          isLoading: true,
        },
      };
    }

    case ownerAccept.SUCCESS: {
      return {
        ...state,
        ownerAcceptStatus: {
          ...state.ownerAcceptStatus,
          isSuccess: true,
        },
      };
    }

    case ownerAccept.FAILURE: {
      return {
        ...state,
        ownerAcceptStatus: {
          ...state.ownerAcceptStatus,
          error: payload.data.statusCode,
        },
      };
    }

    case ownerAccept.FULFILL: {
      return {
        ...state,
        ownerAcceptStatus: {
          ...state.ownerAcceptStatus,
          isLoading: false,
        },
      };
    }

    case deleteAccountUser.SUCCESS: {
      const index = state.members.findIndex(
        (item) => item.user_id === payload.userId
      );
      const members = [...state.members];
      members.splice(index, 1);

      return {
        ...state,
        members,
      };
    }

    case inviteAccountUser.REQUEST:
      return {
        ...state,
        inviteLoading: true,
      };

    case inviteAccountUser.SUCCESS:
      return {
        ...state,
        inviteStatus: { statusCode: 200 },
        members: [...state.members, payload],
      };

    case inviteAccountUser.FAILURE:
      return {
        ...state,
        inviteStatus: payload.data,
      };

    case inviteAccountUser.FULFILL:
      return {
        ...state,
        inviteStatus: INITIAL_STATE.inviteStatus,
        inviteLoading: false,
      };

    case updateAccountUser.SUCCESS: {
      const index = state.members.findIndex(
        (item) => item.user_id === payload.user.id
      );
      const members = [...state.members];
      members[index] = {
        ...state.members[index],
        ...payload,
        user: {
          ...state.members[index].user,
          ...payload.user,
        },
      };

      return {
        ...state,
        members,
      };
    }
    case closeAccount.REQUEST:
      return {
        ...state,
        closeAccountLoading: true,
      };

    case closeAccount.SUCCESS:
      return {
        ...state,
        closeAccountStatus: { statusCode: 201 },
      };

    case closeAccount.FAILURE:
      return {
        ...state,
        closeAccountStatus: payload.data,
      };

    case closeAccount.FULFILL:
      return {
        ...state,
        closeAccountStatus: null,
        closeAccountLoading: false,
      };

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };
    case updateAccount.TRIGGER: {
      if (payload.data.logo) {
        return {
          ...state,
          logoLoading: true,
        };
      } else if (payload.data.name) {
        return {
          ...state,
          updateLoading: true,
        };
      }

      return {
        ...state,
      };
    }

    case updateAccount.SUCCESS: {
      return {
        ...state,
        currentAccount: { ...state.currentAccount, ...payload.response },
      };
    }

    case updateAccount.FULFILL: {
      return {
        ...state,
        logoLoading: false,
        updateLoading: false,
      };
    }

    case deleteLogo.TRIGGER: {
      return {
        ...state,
        logoLoading: true,
      };
    }

    case deleteLogo.SUCCESS: {
      const account = { ...state.currentAccount };
      account.logo_path = null;

      return {
        ...state,
        currentAccount: account,
      };
    }

    case deleteLogo.FULFILL: {
      return {
        ...state,
        logoLoading: false,
      };
    }

    default:
      return state;
  }
};

export default accountsReducer;
