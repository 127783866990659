import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';

export default function useFilter({ options, selected = [], onChange }) {
  const suggestions = useMemo(() => {
    const selectedOptionsIndicies = selected.map((item) => item.id);
    return options.filter((item) => !selectedOptionsIndicies.includes(item.id));
  }, [options, selected]);

  const handleChange = (selectedOption) => {
    const newOptions = [...selected, selectedOption];
    onChange(newOptions);
  };

  const handleDelete = (selectedOption) => () => {
    const newOptions = [...selected];
    const index = selected.findIndex((item) =>
      isEqual(item, selectedOption)
    );
    newOptions.splice(index, 1);
    onChange(newOptions);
  };

  return {
    suggestions,
    onChange: handleChange,
    onDelete: handleDelete,
  };
}
