import axios from 'axios';

import routes from 'utils/routes';
import history from '../../history';
import store from '../index';
import { logout } from '../auth/actions';

// settings
import { API_BASE_URL } from 'settings';

const axiosInstance = axios.create({ baseURL: API_BASE_URL });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message === 'Unauthorized'
    ) {
      store.dispatch(logout());
      history.push(routes.login);
    }
    return Promise.reject(error);
  }
);

const api = (props) => axiosInstance(props).then((response) => response.data);

const withMethod = (method) => (props) =>
  api({
    method,
    ...props,
  });

export const apiGET = withMethod('GET');
export const apiPOST = withMethod('POST');
export const apiPUT = withMethod('PUT');
export const apiPATCH = withMethod('PATCH');
export const apiDELETE = withMethod('DELETE');

export const setHeader = (name, value) => {
  axiosInstance.defaults.headers.common[name] = value;
};

export const removeHeader = (name) => {
  delete axiosInstance.defaults.headers.common[name];
};

export default api;
