import React from 'react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { bool, func, string } from 'prop-types';
import noop from 'lodash/noop';

import './ua-input.styles.scss';

const UaInput = forwardRef(
  (
    {
      id,
      name,
      placeholder,
      label,
      type,
      icon,
      handleChange,
      handleIconClick,
      showError,
      error,
      valid,
      last,
      additionalLabel,
      prependIcon,
      clearFieldIcon,
      showClearField,
      containerClassName,
      className,
      ...otherProps
    },
    ref
  ) => {
    return (
      <div
        className={clsx('ua-input', containerClassName, {
          'ua-input--last': last,
          'ua-input--invalid': error,
          'ua-input--valid': valid,
          'ua-input--with-error': showError && error,
        })}>
        {label ? (
          <label
            className={clsx('ua-input__label', {
              'ua-input__label--flex': additionalLabel,
            })}
            htmlFor={id}>
            {label}
            {additionalLabel}
          </label>
        ) : null}
        <div className='ua-input__container'>
          <input
            id={id}
            className={clsx('ua-input__control', className, {
              'ua-input__control--with-icon': icon,
              'ua-input__control--with-prepend-icon': prependIcon,
              'ua-input__control--with-close-icon': showClearField && otherProps.value,
            })}
            name={name}
            placeholder={placeholder}
            type={type || 'text'}
            onChange={handleChange}
            ref={ref}
            {...otherProps}
          />
          {prependIcon ? (
            <img
              className='ua-input__icon ua-input__icon--prepend'
              src={prependIcon}
              alt='input icon'
            />
          ) : null}
          {!showClearField && icon ? (
            <img
              className={clsx('ua-input__icon', {
                'ua-input__icon--clickable': handleIconClick,
              })}
              src={icon}
              alt='input icon'
              onClick={handleIconClick}
            />
          ) : null}
          {showClearField && otherProps.value ? (
            <img
              className={clsx('ua-input__icon', {
                'ua-input__icon--clickable': handleIconClick,
              })}
              src={clearFieldIcon}
              alt='input icon'
              onClick={handleIconClick}
            />
          ) : null}
        </div>
        {showError && error ? (
          <div className='ua-input__error'>{error}</div>
        ) : null}
      </div>
    );
  }
);

UaInput.propTypes = {
  showError: bool,
  error: string,
  last: bool,
  handleIconClick: func,
  prependIcon: string,
  containerClassName: string,
  showClearField: bool,
};

UaInput.defaultProps = {
  handleIconClick: noop(),
  showClearField: false,
};

export default UaInput;
