import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

import ability from 'settings/ability';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = ({ children, role }) => {
  const currentAbility = ability(role);
  const isManageAccess = currentAbility.can('manage', 'all');

  const providerValues = { ...currentAbility, isManageAccess };

  return (
    <AbilityContext.Provider value={providerValues}>
      {children}
    </AbilityContext.Provider>
  );
};
