import React, { memo, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import Tag from '../tag/tag.component';

import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-circle-solid.svg';
import { ReactComponent as AddIcon } from 'assets/images/icons/plus-bold.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/note.svg';

import './tags-list.styles.scss';
import UaInput from 'components/common/ua-input/ua-input.component';
import UaConfirm from 'components/common/ua-modal/ua-confirm.component';
import TagSchema from './TagSchema';
import UaColorpicker from 'components/common/ua-colorpicker/ua-colorpicker.component';

import { getBoard, getTags } from 'store/boards/selectors';
import { addTag, editTag, deleteTag } from 'store/boards/actions';
import { ReactComponent as Trash } from '../../../assets/images/icons/trash.svg';
import useDevice from 'hooks/useDevice';
import { TABLET_QUERY } from 'settings';

const TagsList = ({ editing, projectTags = [], onChangeTags }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(getBoard);
  const tags = useSelector(getTags);
  const isTablet = useDevice(TABLET_QUERY);

  const [isShowAddBtn, setIsShowAddBtn] = useState(false);
  const [isTagListOpen, setIsTagListOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [tagToEdit, setTagToEdit] = useState(null);
  const [deleteItem, setDeleteItem] = useState({
    showConfirm: false,
    item: null,
  });

  const tagIds = useMemo(() => projectTags.map(({ id }) => id), [projectTags]);
  const filterTagList = useMemo(
    () => tags.filter((item) => !tagIds.includes(item.id)),
    [tags, tagIds]
  );

  const handleShowOptionClick = (option) => (e) => {
    e.stopPropagation();
    onChangeTags([...tagIds, option]);
    setIsTagListOpen(false);
  };

  const handleDelete = (tag) => () => {
    dispatch(deleteTag(tag.tagId));
  };

  const handleRemove = (tag) => () => {
    const prevIds = [...tagIds];
    const index = prevIds.indexOf(tag);
    prevIds.splice(index, 1);

    onChangeTags(prevIds);
  };

  const handleAdd = () => () => {
    setIsAddFormOpen(true);
  };

  const mouseEnter = () => setIsShowAddBtn(true);
  const mouseLeave = () => setIsShowAddBtn(false);
  const onAddClick = () => setIsTagListOpen(true);

  const resetDropdown = () => {
    setIsShowAddBtn(false);
    setIsAddFormOpen(false);
    setTagToEdit(null);
    setEditMode(false);
    formik.values.name = '';
    formik.values.color = '';
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '',
    },
    validationSchema: TagSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        slug: values.name.replace(/\s/g, '-').toLocaleLowerCase(),
      };
      if (editMode) {
        dispatch(
          editTag({
            boardId: id,
            tagId: tagToEdit.id,
            tag: data,
            callback: resetDropdown,
          })
        );
      } else {
        dispatch(addTag({ boardId: id, tag: data, callback: resetDropdown }));
      }
    },
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  const handleColorChange = (event) => {
    formik.setFieldValue(event.name, event.value);
  };

  return (
    <div
      className='tag-list'
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}>
      {!projectTags.length ? (
        <span>&mdash;</span>
      ) : (
        projectTags.map(({ id, name, color }) => (
          <Tag
            key={`tag-${id}`}
            text={name}
            color={color}
            onDelete={handleRemove(id)}
            editing={editing}
          />
        ))
      )}
      {(isShowAddBtn || isTagListOpen || isTablet) && editing && (
        <>
          <UaDropdown
            show={isTagListOpen}
            className='tag-list__dropdown'
            dropdownVariant='light'
            buttonContent={
              <PlusIcon fill='#888888' width='12px' height='12px' />
            }
            onClick={onAddClick}
            withIcon={false}
            handleRootClose={() => {
              setIsTagListOpen(false);
              resetDropdown();
            }}>
            {!isAddFormOpen ? (
              <>
                {filterTagList.map((tag) => (
                  <React.Fragment key={`priority-item-${tag.id}`}>
                    <Dropdown.Item
                      as='div'
                      className='tag-list-item'
                      onClick={handleShowOptionClick(tag.id)}>
                      <Tag
                        key={`tag-list-item-${tag.id}`}
                        text={tag.name}
                        color={tag.color}
                      />{' '}
                      <button
                        className='tag-list-item__button'
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.values.name = tag.name;
                          formik.values.color = tag.color;
                          setTagToEdit(tag);
                          setEditMode(true);
                          setIsAddFormOpen(true);
                        }}>
                        <EditIcon />
                      </button>
                      <button
                        className='tag-list-item__button'
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteItem({
                            showConfirm: true,
                            item: {
                              tagId: tag.id,
                            },
                          });
                        }}>
                        <Trash color='#FB404B' width='23px' height='23px' />
                      </button>
                    </Dropdown.Item>
                  </React.Fragment>
                ))}
                <Dropdown.Item
                  onClick={handleAdd()}
                  className='tag-list-add-button'>
                  <AddIcon /> Create a new tag
                </Dropdown.Item>
              </>
            ) : (
              <div className='tag-list-form'>
                <form onSubmit={formik.handleSubmit}>
                  <UaInput
                    id='name'
                    name='name'
                    type='text'
                    placeholder='Tag Name'
                    value={formik.values.name}
                    handleChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name}
                    valid={formik.touched.name && !formik.errors.name}
                  />
                  <UaColorpicker
                    color={formik.values.color}
                    handleChange={handleColorChange}
                    error={formik.touched.color && formik.errors.color}
                    valid={formik.touched.color && !formik.errors.color}
                  />
                  <button type='submit' className='tag-list-add-button'>
                    {editMode ? (
                      'Edit tag'
                    ) : (
                      <>
                        <AddIcon /> Create a new tag
                      </>
                    )}
                  </button>
                </form>
              </div>
            )}
          </UaDropdown>
          <UaConfirm
            show={deleteItem.showConfirm}
            onHide={() => setDeleteItem({ showConfirm: false, item: null })}
            onOk={handleDelete(deleteItem.item)}
            okLabel='Yes'
            cancelLabel='No'
            message='Are you sure you want to delete this tag?'
            notification={{
              text: 'We successfully deleted 1 item',
            }}
          />
        </>
      )}
    </div>
  );
};

export default memo(TagsList);
