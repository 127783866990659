import React, { useContext } from 'react';
import Search from 'components/search/search.component';
import KanbanControls from 'components/board/kanban-controls/kanban-controls.component';
import Kanban from 'components/kanban/kanban.component';
import { BoardContext } from 'context/board-context/board-context';

const BoardKanbanView = () => {
  const { searchValue, setSearch } = useContext(BoardContext);

  return (
    <div className='projects-kanban-container'>
      <div className='toolbar-container'>
        <div className='toolbar-col toolbar-col--search'>
          <Search
            id='boardSearch'
            className='toolbar-search'
            value={searchValue}
            handleSearch={(e) => setSearch(e.target.value)}
            type='text'
            placeholder='Search'
            handleClearField={() => setSearch('')}
            showClearField
          />
        </div>
        <div className='toolbar-col toolbar-col--buttons toolbar-col__kanban'>
          <KanbanControls />
        </div>
      </div>
      <Kanban />
    </div>
  );
};

export default BoardKanbanView;
