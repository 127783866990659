import { put, takeLatest, call, select } from 'redux-saga/effects';
// actions
import { editClient as action } from '../actions';
// api
import { editClient as api } from '../api';
import {getAccountSocket, getProjectSocket} from 'store/socket/selectors';

function* saga({ payload }) {
  try {
    const socketAccount = yield select(getAccountSocket);
    const socketProject = yield select(getProjectSocket);
    payload.socketId = [socketAccount.id, socketProject.id].join(',');

    yield put(action.request());

    const client = yield call(api, payload);

    yield put(action.success(client));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* editClient() {
  yield takeLatest(action.TRIGGER, saga);
}
