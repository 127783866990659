import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { saveCustomView } from 'store/custom-views/actions';
import { getProfile } from 'store/auth/selectors';

import UaInput from 'components/common/ua-input/ua-input.component';
import UaModal from 'components/common/ua-modal/ua-modal.component';
import CreateCustomViewSchema from 'components/filters/custom-views/create-custom-view/create-custom-view.validate';

const CreateCustomViewButton = ({
  customView,
  filters,
  className,
  children,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();

  const profile = useSelector(getProfile);

  const formik = useFormik({
    initialValues: { name: customView ? customView.name : '' },
    validationSchema: CreateCustomViewSchema,
    onSubmit: (values) => {
      dispatch(
        saveCustomView({
          accountId: profile.account_id,
          userId: profile.id,
          name: values.name,
          filters: filters,
        })
      );
      setShow(false);
    },
  });

  useEffect(() => {
    if (!show) formik.resetForm();
    if (show) ref.current.focus();
  }, [show]);

  return (
    <>
      <button
        className={`create-custom-view-button ${className || ''}`}
        onClick={() => setShow(true)}>
        {children}
      </button>

      <UaModal
        show={show}
        onHide={() => setShow(false)}
        title='New Custom View'
        okLabel='Save'
        onSubmit={formik.handleSubmit}>
        <UaInput
          type='text'
          ref={ref}
          name='name'
          label='Custom View Name'
          placeholder='Custom View'
          value={formik.values.name}
          handleChange={formik.handleChange}
          onBlur={formik.handleBlur}
          showError
          error={formik.touched.name && formik.errors.name}
          valid={formik.touched.name && !formik.errors.name}
        />
      </UaModal>
    </>
  );
};

export default CreateCustomViewButton;
