import { apiGET } from 'store/services/api';
import { toast } from 'react-toastify';

const getFile = ({ url, name }) => {
  apiGET({
    url: url,
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      toast('Something wrong', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error',
      });
      console.error(err);
    });
};

export default getFile;
