import { apiGET, apiPOST, apiPATCH, apiDELETE } from '../services/api';

export const signUp = ({
  email,
  firstName,
  lastName,
  password,
  passwordConfirmation,
}) =>
  apiPOST({
    url: '/auth/register',
    data: {
      email,
      firstname: firstName,
      lastname: lastName,
      password,
      password_confirm: passwordConfirmation,
    },
  });

export const signIn = ({ email, password }) =>
  apiPOST({
    url: '/auth/login',
    data: {
      email,
      password,
    },
  });

export const requestResetPass = ({ email }) =>
  apiPOST({
    url: '/auth/reset-password',
    data: {
      email,
    },
  });

export const resetPassword = ({
  email,
  token,
  password,
  passwordConfirmation,
}) =>
  apiPOST({
    url: '/auth/new-password',
    data: {
      token,
      email,
      password,
      password_confirm: passwordConfirmation,
    },
  });

export const getProfile = () =>
  apiGET({
    url: '/profile',
  });

export const editProfile = (data) =>
  apiPATCH({
    url: '/profile',
    data,
  });

export const uploadProfilePhoto = (file) => {
  const fileFormData = new FormData();
  fileFormData.append('photo', file);

  return apiPOST({
    url: '/profile/photo',
    data: fileFormData,
  });
};

export const verifyEmail = (token) =>
  apiGET({
    url: `/auth/email-verification/${token}`,
  });

export const confirmEmail = (token) =>
  apiGET({
    url: `/auth/email-confirmation/${token}`,
  });

export const deleteProfilePhoto = () => {
  return apiDELETE({
    url: '/profile/photo',
  });
};
