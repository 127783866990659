import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// styles
import './login.styles.scss';
// components
import LoginForm from 'components/auth/login-form/login-form.component';
// actions
import { removeErrors, signIn } from 'store/auth/actions';
// selectors
import { getAuthErrors, getAuthIsLoading } from 'store/auth/selectors';

const LoginPage = () => {
  const dispatch = useDispatch();
  const error = useSelector(getAuthErrors);
  const isLoading = useSelector(getAuthIsLoading);

  const handleSubmit = (values) => {
    dispatch(signIn(values));
  };

  useEffect(() => {
    dispatch(removeErrors());
  }, [dispatch]);

  return (
    <LoginForm onSubmit={handleSubmit} error={error} isLoading={isLoading} />
  );
};

export default LoginPage;
