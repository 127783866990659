import React from 'react';

import clsx from 'clsx';

import './ua-textarea.styles.scss';

const UaTextarea = (
  {
    id,
    name,
    placeholder,
    label,
    type,
    value,
    handleChange,
    showError,
    error,
    valid,
    ...otherProps
  },
  ref
) => {
  return (
    <div
      className={clsx('ua-textarea', {
        'ua-textarea--invalid': error,
        'ua-textarea--valid': valid,
        'ua-textarea--with-error': error && showError,
      })}>
      <label htmlFor={id} className='ua-textarea__label'>
        {label}
      </label>
      <textarea
        id={id}
        className='ua-textarea__control'
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        ref={ref}
        {...otherProps}
      />
      {showError && error ? (
        <div className='ua-input__error'>{error}</div>
      ) : null}
    </div>
  );
};

export default React.forwardRef(UaTextarea);
