import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import './invite-user-button.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';

import plus from 'assets/images/icons/plus-circle-solid.png';
import { useFormik } from 'formik';
import InviteUserButtonSchema from 'components/settings/manage-users/invite-user-button/invite-user-button.schema';
import { LimitAddUsersModal } from '../../../../modals/limit-add-users-modal.component';

const InviteUserButton = ({
  totalUsers,
  accountSeats,
  onUserInvite,
  inviteStatus,
  roles,
}) => {
  const { invite } = inviteStatus;

  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const limitModalRef = useRef();

  const onSubmit = (values) => {
    onUserInvite({
      email: values.email,
      role_id: values.role,
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      role: 2,
    },
    validationSchema: InviteUserButtonSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  useEffect(() => {
    if (invite?.statusCode === 200) {
      setShow(false);
      setError(null);
    } else if (invite?.error) {
      setError(invite.message);
    }
  }, [invite]);

  const handleChange = (event) => {
    formik.handleChange(event);
  };

  const handleBlur = (event) => {
    formik.handleBlur(event);
  };

  const handleChangeRole = (option) => {
    formik.setFieldValue('role', parseInt(option));
  };

  const onHide = () => {
    setShow(false);
  };

  const onOpenInviteUserModal = () => {
    if (accountSeats && totalUsers >= accountSeats) {
      limitModalRef.current?.onOpenModal();
      return;
    }

    setShow(true);
  };

  return (
    <>
      <button className='invite-user-button' onClick={onOpenInviteUserModal}>
        <img src={plus} alt='' /> <span>New User</span>
      </button>

      <LimitAddUsersModal ref={limitModalRef} />

      <UaModal
        show={show}
        onHide={onHide}
        title='Invite User'
        onSubmit={formik.handleSubmit}
        okLabel='Invite'>
        <UaInput
          type='email'
          name='email'
          label='Email'
          value={formik.values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder='user@example.com'
          showError
          error={(formik.touched.email && formik.errors.email) || error}
          valid={formik.touched.email && !formik.errors.email}
        />
        <label className='ua-input__label'>Role</label>
        <UaDropdown
          className='invite-user-button__role'
          dropdownVariant='light'
          buttonContent={
            roles.find((el) => el.id === formik.values.role)?.name || ''
          }
          withIcon={false}>
          {roles.map((item) => (
            <Dropdown.Item
              key={item.slug}
              eventKey={item.id}
              onSelect={handleChangeRole}>
              {item.name}
            </Dropdown.Item>
          ))}
        </UaDropdown>
      </UaModal>
    </>
  );
};

export default InviteUserButton;
