import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import App from './App';
import store from './store';
import history from './history';

import LogRocket from 'logrocket';
import { IS_PRODUCTION,LOGROCKET_KEY } from 'settings';

if ( IS_PRODUCTION ) LogRocket.init(LOGROCKET_KEY);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
