import React, { useContext } from 'react';
import Search from 'components/search/search.component';
import ListControls from 'components/board/list-controls/list-controls.component';
import ProjectsTable from 'components/dashboard/projects-table/projects-table.component';
import { BoardContext } from 'context/board-context/board-context';

const BoardListView = () => {
  const { searchValue, setSearch } = useContext(BoardContext);

  return (
    <div className='projects-table-container'>
      <div className='toolbar-container'>
        <div className='toolbar-col toolbar-col--search'>
          <Search
            id='boardSearch'
            className='toolbar-search'
            value={searchValue}
            handleSearch={(e) => setSearch(e.target.value)}
            handleClearField={() => setSearch('')}
            showClearField
            type='text'
            placeholder='Search'
          />
        </div>
        <div className='toolbar-col toolbar-col--buttons'>
          <ListControls />
        </div>
      </div>

      <div className='table-container'>
        <ProjectsTable />
      </div>
    </div>
  );
};

export default BoardListView;
