import { all, fork } from 'redux-saga/effects';
// sagas
import get from './get';
import dndBoard from './dndBoard';
import update from './update';
import changePosition from './changePosition';

export default function* dashboards() {
  yield all([fork(get), fork(dndBoard), fork(update), fork(changePosition)]);
}
