export default function dateFormat(value = new Date(), format = 'numeric') {
  let locale = 'en-US';
  const options = {};
  const date = new Date(value);

  let dateString = '';

  if (format === 'numeric') {
    const day = date.getDate() < 10 ? `${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear() % 100;
    dateString = `${month}/${day}/${year}`;
  }

  if (format === 'full-year') {
    const day = date.getDate() < 10 ? `${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year = date.getFullYear();
    dateString = `${month}/${day}/${year}`;
  }

  if (format === 'month-day') {
    options.day = '2-digit';
    options.month = 'short';

    const dateValue = date.valueOf();
    const timeZone = date.getTimezoneOffset() * 60 * 1000;

    return new Intl.DateTimeFormat(locale, options).format(dateValue + timeZone);
  }

  if (format === 'full') {
    options.weekday = 'long';
    options.year = 'numeric';
    options.month = 'long';
    options.day = '2-digit';
    options.hour = '2-digit';
    options.minute = '2-digit';

    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  if (format === 'time') {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hour12 = false;

    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  return dateString;
}
