import { put, takeEvery, call, select } from 'redux-saga/effects';
// actions
import { addBoard as addBoardAction } from '../actions';
// services
import { addBoard as addBoardApi } from '../api';
// selectors
import {getActiveBoards} from '../selectors';

import { changePosition } from 'store/dashboards/actions';
import {
  getBoardPositions,
  getCurrentDashboard,
} from 'store/dashboards/selectors';
import {getBoardSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    yield put(addBoardAction.request());
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    const board = yield call(addBoardApi, payload);

    const activeBoards = yield select(getActiveBoards);
    const { id } = yield select(getCurrentDashboard);
    const { all } = yield select(getBoardPositions);

    const newPositions = [...all];
    newPositions.push({ id: board.id, order: activeBoards.length });

    yield put(
      changePosition({
        dashboard_id: id,
        boards_positions: { all: newPositions },
      })
    );

    yield put(addBoardAction.success(board));
  } catch (error) {
    console.error(error);
    yield put(addBoardAction.failure(error.response));
  } finally {
    yield put(addBoardAction.fulfill());
  }
}

export default function* add() {
  yield takeEvery(addBoardAction.TRIGGER, addSaga);
}
