import './filter.styles.scss';

const ProjectFilter = ({ icon, name, body }) => {
  return (
    <div className='project-filter'>
      <div className='project-filter__header'>
        <span className='project-filter__icon'>{icon}</span>
        <span className='project-filter__name'>{name}</span>
      </div>
      <div className='project-filter__body'>{body}</div>
    </div>
  );
};

export default ProjectFilter;
