import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import clsx from 'clsx';
import { Spinner } from 'react-bootstrap';

import './edit-profile.styles.scss';

import UserAvatar from 'components/user-avatar/user-avatar.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import UaButton from 'components/common/ua-button/ua-button.component';

import eyeIcon from 'assets/images/icons/eye.svg';
import { ReactComponent as BoxChecked } from 'assets/images/icons/box-checked.svg';
import { ReactComponent as Box } from 'assets/images/icons/box.svg';
import { ReactComponent as UploadIcon } from 'assets/images/icons/upload-solid.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash-alt.svg';

import {
  getProfile,
  isProfileLoading,
  isProfilePhotoLoading,
  isProfileUpdateLoading,
  getProfileUpdateError,
  getProfileUpdateSuccess,
} from 'store/auth/selectors';
import {
  editProfile,
  uploadProfilePhoto,
  deleteProfilePhoto,
} from 'store/auth/actions';

import EditProfileSchema from './EditProfileSchema';

const acceptFiles = ['image/png', 'image/gif', 'image/jpeg'];

const EditProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const isPhotoLoading = useSelector(isProfilePhotoLoading);
  const isLoading = useSelector(isProfileLoading);
  const isProfileUpdating = useSelector(isProfileUpdateLoading);
  const profileError = useSelector(getProfileUpdateError);
  const profileUpdateSuccess = useSelector(getProfileUpdateSuccess);

  const onChange = (e) => {
    if (acceptFiles.includes(e.target.files[0].type)) {
      dispatch(uploadProfilePhoto(e.target.files[0]));
    } else {
      e.target.value = null;
    }
  };

  const onDeletePhoto = () => {
    dispatch(deleteProfilePhoto());
  };

  const onSubmit = (values) => {
    const data = { ...values };
    for (const key in data) {
      if (!data[key]) {
        delete data[key];
      }
      if (!!profile[key] && data[key] === profile[key]) {
        delete data[key];
      }
    }
    if (Object.keys(data).length) {
      dispatch(editProfile(data));
    }
  };

  const formik = useFormik({
    validationSchema: EditProfileSchema,
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      email: profile.email || '',
      firstname: profile.firstname || '',
      lastname: profile.lastname || '',
      old_password: '',
      password: '',
      password_confirm: '',
    },
  });

  useEffect(() => {
    if (profileUpdateSuccess) {
      toast('Success updated', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'success',
      });

      formik.setValues({
        email: profile.email || '',
        firstname: profile.firstname || '',
        lastname: profile.lastname || '',
        old_password: '',
        password: '',
        password_confirm: '',
      });
    }
  }, [profileUpdateSuccess]);

  useEffect(() => {
    if (
      !formik.values.old_password &&
      !formik.values.password &&
      formik.touched.old_password
    ) {
      formik.setFieldTouched('old_password', false);
    }
    if (
      !formik.values.password_confirm &&
      !formik.values.password &&
      formik.touched.password_confirm
    ) {
      formik.setFieldTouched('password_confirm', false);
    }
  }, [formik.touched]);

  const passwordChanged = useMemo(
    () => formik.values.password.length > 0 && formik.touched.password,
    [formik.values.password, formik.touched.password]
  );

  const handleIconClick = (event) => {
    const nearestInput = event.target.parentNode.querySelector('input');
    if (nearestInput.type === 'password') {
      nearestInput.type = 'text';
    } else {
      nearestInput.type = 'password';
    }
  };

  const handleChange = (event) => {
    formik.handleChange(event);
    // formik.handleBlur(event);
  };

  if (isLoading) return null;

  return (
    <div className='edit-profile'>
      <h3 className='edit-profile__title'>Edit Profile</h3>
      <div className='edit-profile__avatar'>
        <div className='edit-profile__avatar-container'>
          <UserAvatar user={profile} width={104} height={104} />
          {profile.profile_photo_path && (
            <button
              className='edit-profile__avatar-delete'
              onClick={onDeletePhoto}>
              <Trash />
            </button>
          )}
        </div>
        <label className='edit-profile__avatar-button'>
          <input
            type='file'
            accept={acceptFiles.join(',')}
            name='profile-avatar'
            onChange={onChange}
          />
          <span className='edit-profile__avatar-button-icon'>
            <UploadIcon />
          </span>
          <span className='edit-profile__avatar-button-text'>
            Upload Picture
          </span>
        </label>

        {isPhotoLoading && (
          <div className='upload-logo__loading'>
            <Spinner animation='border' />
          </div>
        )}
      </div>
      <div className='edit-profile__form-container'>
        <h3 className='edit-profile__form-caption'>Edit Profile</h3>
        <form className='edit-profile__form' onSubmit={formik.handleSubmit}>
          <div className='edit-profile__form-row'>
            <div className='edit-profile__form-col'>
              <UaInput
                id='firstname'
                name='firstname'
                value={formik.values.firstname}
                placeholder='Enter first name'
                label='First name'
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                showError={true}
                error={formik.touched.firstname && formik.errors.firstname}
                valid={formik.touched.firstname && !formik.errors.firstname}
              />
            </div>
            <div className='edit-profile__form-col'>
              <UaInput
                id='lastname'
                name='lastname'
                value={formik.values.lastname}
                placeholder='Enter last name'
                label='Last name'
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                showError={true}
                error={formik.touched.lastname && formik.errors.lastname}
                valid={formik.touched.lastname && !formik.errors.lastname}
              />
            </div>
            <div className='edit-profile__form-col'>
              <UaInput
                name='email'
                type='text'
                value={formik.values.email}
                placeholder='Enter email'
                label='Email address'
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                showError={true}
                error={formik.touched.email && formik.errors.email}
                valid={formik.touched.email && !formik.errors.email}
              />
            </div>
          </div>
          <div className='edit-profile__form-row'>
            <div className='edit-profile__form-col'>
              <UaInput
                id='old_password'
                name='old_password'
                type='password'
                value={formik.values.old_password}
                placeholder='Enter password'
                label='Old password'
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                handleIconClick={handleIconClick}
                icon={eyeIcon}
                showError={
                  formik.touched.old_password && !!formik.errors.old_password
                }
                error={
                  formik.touched.old_password && formik.errors.old_password
                }
                valid={
                  formik.values.old_password &&
                  formik.touched.old_password &&
                  !formik.errors.old_password
                }
              />
            </div>
            <div className='edit-profile__form-col'>
              <UaInput
                id='password'
                name='password'
                type='password'
                value={formik.values.password}
                placeholder='Enter password'
                label='New password'
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                handleIconClick={handleIconClick}
                icon={eyeIcon}
                showError={false}
                error={
                  formik.values.password &&
                  formik.touched.password &&
                  formik.errors.password
                }
                valid={
                  formik.values.password &&
                  formik.touched.password &&
                  !formik.errors.password
                }
              />
            </div>
            <div className='edit-profile__form-col'>
              <UaInput
                id='password_confirm'
                last
                name='password_confirm'
                type='password'
                value={formik.values.password_confirm}
                placeholder='Confirm password'
                label='Confirm password'
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                handleIconClick={handleIconClick}
                icon={eyeIcon}
                showError={
                  formik.touched.password_confirm &&
                  !!formik.errors.password_confirm
                }
                error={
                  formik.touched.password_confirm &&
                  formik.errors.password_confirm
                }
                valid={
                  formik.values.password_confirm &&
                  formik.touched.password_confirm &&
                  !formik.errors.password_confirm
                }
              />
            </div>
          </div>
          <div className='edit-profile__form-row'>
            <div className='edit-profile__form-col edit-profile__form-col--requirements'>
              <div
                className={clsx('form__requirements requirements', {
                  'requirements--hidden': !passwordChanged,
                })}>
                <div
                  className={clsx('requirement-item', {
                    'requirement-item--passed':
                      formik.values.password.length >= 8 &&
                      formik.values.password.length <= 20,
                  })}>
                  <div className='requirement-item__icon'>
                    {formik.values.password.length >= 8 &&
                    formik.values.password.length <= 20 ? (
                      <BoxChecked />
                    ) : (
                      <Box />
                    )}
                  </div>
                  <p className='requirement-item__text'>8-20 characters</p>
                </div>
                <div
                  className={clsx('requirement-item', {
                    'requirement-item--passed': /\d/g.test(
                      formik.values.password
                    ),
                  })}>
                  <div className='requirement-item__icon'>
                    {/\d/g.test(formik.values.password) ? (
                      <BoxChecked />
                    ) : (
                      <Box />
                    )}
                  </div>
                  <p className='requirement-item__text'>At least one number</p>
                </div>
                <div
                  className={clsx('requirement-item', {
                    'requirement-item--passed': /[A-Z]/g.test(
                      formik.values.password
                    ),
                  })}>
                  <div className='requirement-item__icon'>
                    {/[A-Z]/g.test(formik.values.password) ? (
                      <BoxChecked />
                    ) : (
                      <Box />
                    )}
                  </div>
                  <p className='requirement-item__text'>
                    At least one capital letter
                  </p>
                </div>
                <div
                  className={clsx('requirement-item', {
                    'requirement-item--passed':
                      !/\s/g.test(formik.values.password) &&
                      formik.values.password,
                  })}>
                  <div className='requirement-item__icon'>
                    {!/\s/g.test(formik.values.password) &&
                    formik.values.password ? (
                      <BoxChecked />
                    ) : (
                      <Box />
                    )}
                  </div>
                  <p className='requirement-item__text'>No spaces</p>
                </div>
              </div>
            </div>
          </div>

          <div className='edit-profile__form-row ua-input__error'>
            {profileError?.main}
          </div>
          <div className='edit-profile__form-action'>
            <UaButton
              type='submit'
              variant='primary'
              isLoading={isProfileUpdating}>
              Update Profile
            </UaButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
