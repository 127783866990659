import { apiPOST } from '../services/api';

export const sendSupport = ({firstName, lastName, email, text, files}) => {
  const fileFormData = new FormData();
  
  fileFormData.append('firstname', firstName);
  fileFormData.append('lastname', lastName);
  fileFormData.append('email', email);
  fileFormData.append('text', text);
  if (files && files.length) files.map((file) => fileFormData.append('files', file));

  return apiPOST({
    url: '/support',
    data: fileFormData,
  });
};
