import {put, takeLatest, call, select} from 'redux-saga/effects';

import { editStatus as editStatusAction } from '../actions';
import { editStatus as editStatusApi } from '../api';

import { getSaga as getStatuses } from './getStatuses';
import {getBoardSocket} from 'store/socket/selectors';

function* editSaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(editStatusAction.request());

    const status = yield call(editStatusApi, payload);

    yield put(editStatusAction.success(status));

    yield call(getStatuses, { payload: { boardId: payload.boardId } });
    payload.callback();
  } catch (error) {
    console.error(error);
    yield put(editStatusAction.failure(error.response));
  } finally {
    yield put(editStatusAction.fulfill());
  }
}

export default function* editStatus() {
  yield takeLatest(editStatusAction.TRIGGER, editSaga);
}
