import { put, takeLatest, call } from 'redux-saga/effects';

import { createCard as action } from '../actions';

import { createCard as api } from '../api';
import { toast } from 'react-toastify';

function* addSaga({ payload }) {
  try {
    yield put(action.request());
    const response = yield call(api, payload);
    payload.success(response);
    yield put(action.success(response));
  } catch (error) {
    console.error(error);
    toast(error.response.data.message, {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'error',
    });
    payload.error();
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* createCard() {
  yield takeLatest(action.TRIGGER, addSaga);
}
