import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import './project-files.styles.scss';

import { uploadFile, deleteFile } from 'store/projects/actions';
import getFile from 'utils/getFile';

import { ReactComponent as Paperclip } from 'assets/images/icons/paperclip.svg';
import { ReactComponent as File } from 'assets/images/icons/file.svg';
import { ReactComponent as Photo } from 'assets/images/icons/photo.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash-alt.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { BoardContext } from 'context/board-context/board-context';
import { usePrevious } from 'hooks/usePrevious';

const FILE_EXTENSIONS = ['doc', 'docx'];
const IMAGE_EXTENSIONS = ['png', 'gif', 'jpg', 'jpeg'];
const UNFULL_COUNT = 2;

const ProjectFiles = ({
  projectId,
  files = [],
  isSeeAll = false,
  className = '',
  editing = true,
  isCard = false,
}) => {
  const dispatch = useDispatch();
  const { onSelectProjectCard, onResetOptions, projectCardOptions } =
    useContext(BoardContext);

  const [isFullSee, setIsFullSee] = useState(false);
  const previousProjectId = usePrevious(projectId, null);

  const maxLength = !isFullSee ? UNFULL_COUNT : files.length;

  useEffect(() => {
    if (projectCardOptions.files && !isFullSee) {
      setIsFullSee(true);
      onResetOptions();
    }
  }, [projectCardOptions.files]);

  useEffect(() => {
    if (previousProjectId !== projectId && !projectCardOptions.files)
      setIsFullSee(false);
  }, [projectId, projectCardOptions.files]);

  const onSeeAllClick = () => {
    onSelectProjectCard({ id: projectId }, { files: true });
  };

  const onToggleSeeAll = () => setIsFullSee((prev) => !prev);

  const getIcon = (file) => {
    const extension = file.name.split('.').pop();

    if (IMAGE_EXTENSIONS.includes(extension.toLowerCase())) {
      return <Photo />;
    } else if (FILE_EXTENSIONS.includes(extension.toLowerCase())) {
      return <File />;
    } else {
      return <File />;
    }
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    dispatch(
      uploadFile({
        projectId,
        file,
      })
    );

    event.target.value = null;
  };

  const handleDelete = (fileId) => () => {
    if (editing) {
      dispatch(
        deleteFile({
          projectId,
          fileId,
        })
      );
    }
  };

  const handleDownload =
    ({ id, name }) =>
    () => {
      getFile({
        url: `/projects/${projectId}/files/${id}`,
        name,
      });
    };

  return (
    <div className={`project-widget project-files ${className}`}>
      <div className='project-widget__header'>
        <div className='project-widget__header-icon'>
          <Paperclip />
        </div>
        <div className='project-widget__header-title'>Files</div>
        <div className='project-widget__header-action'>
          {files?.length > UNFULL_COUNT && !isSeeAll && (
            <div className='project-update__list-control'>
              <button
                onClick={onToggleSeeAll}
                className='project-update__action'>
                {isFullSee ? 'Show Less' : 'Show More'}
              </button>
            </div>
          )}

          {isSeeAll && (
            <button onClick={onSeeAllClick} className='project-update__action'>
              See all
            </button>
          )}
        </div>
      </div>
      <div className='project-widget__content'>
        {files && files.length > 0 ? (
          <div className='project-widget__list'>
            {files.slice(0, maxLength).map((file) => (
              <div
                className='project-files__file'
                key={`project-${projectId}-file-${file.id}`}>
                <div
                  className='project-files__file-icon'
                  onClick={handleDownload(file)}>
                  {getIcon(file)}
                </div>
                <div className='project-files__file-name'>{file.name}</div>
                {editing && (
                  <div className='project-files__file-action'>
                    <button
                      className='project-files__file-remove'
                      onClick={handleDelete(file.id)}>
                      <Trash fill='#FB404B' opacity={0.5} />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <span className='project-widget__content-empty'>
            Files list is empty
          </span>
        )}
        {editing && isCard && (
          <div className='project-files__list-control'>
            <label className='project-files__add-file'>
              <input type='file' multiple onChange={handleChange} />
              <PlusIcon /> Add a File
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectFiles;
