import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { logout as logoutAction } from '../actions';
// services
import { removeAccessToken } from '../services/tokenService';
import history from '../../../history';
import routes from 'utils/routes';

function* logoutSaga() {
  try {
    yield call(removeAccessToken);
    yield put(logoutAction.success());
    history.push(routes.login);
  } catch (error) {
    console.error(error);
    yield put(logoutAction.failure(error));
  }
}

export default function* logout() {
  yield takeLatest(logoutAction.TRIGGER, logoutSaga);
}
