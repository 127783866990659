import { useMemo } from 'react';
import { bool, func, object } from 'prop-types';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';

// styles
import './reset-password-form.styles.scss';
// images
import { ReactComponent as Box } from 'assets/images/icons/box.svg';
import { ReactComponent as BoxChecked } from 'assets/images/icons/box-checked.svg';
import eyeIcon from 'assets/images/icons/eye.svg';
// components
import UaInput from 'components/common/ua-input/ua-input.component';
import UaButton from 'components/common/ua-button/ua-button.component';
// schemas
import ResetPasswordSchema from './ResetPasswordSchema';
// utils
import routes from 'utils/routes';
import { SUPPORT_EMAIL } from '../../../storage/consts';

const ResetPasswordForm = ({ onSubmit, error, isLoading }) => {
  const formik = useFormik({
    validationSchema: ResetPasswordSchema,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    onSubmit,
  });

  const passwordChanged = useMemo(
    () => formik.values.password.length > 0 || formik.touched.password,
    [formik.values.password, formik.touched.password]
  );

  const handleIconClick = (event) => {
    const nearestInput = event.target.parentNode.querySelector('input');
    if (nearestInput.type === 'password') {
      nearestInput.type = 'text';
    } else {
      nearestInput.type = 'password';
    }
  };

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  return (
    <form
      className='form form-auth'
      onSubmit={formik.handleSubmit}
      autoComplete='off'>
      <h1 className='form__caption'>Create a new password</h1>
      <div className='form__controls'>
        <UaInput
          id='password'
          name='password'
          type='password'
          value={formik.values.password}
          placeholder='Enter password'
          label='Password'
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          handleIconClick={handleIconClick}
          icon={eyeIcon}
          showError={!!error.fields.password}
          error={
            (formik.touched.password && formik.errors.password) ||
            error.fields.password
          }
          valid={formik.touched.password && !formik.errors.password}
          autoComplete='off'
        />
        <UaInput
          id='passwordConfirmation'
          last
          name='passwordConfirmation'
          type='password'
          value={formik.values.passwordConfirmation}
          placeholder='Confirm password'
          label='Confirm password'
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          handleIconClick={handleIconClick}
          icon={eyeIcon}
          showError={!!error.fields.passwordConfirmation}
          error={
            (formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation) ||
            error.fields.passwordConfirmation
          }
          valid={
            formik.touched.passwordConfirmation &&
            !formik.errors.passwordConfirmation
          }
          autoComplete='off'
        />
        <div
          className={`form__requirements requirements ${
            !passwordChanged ? 'requirements--hidden' : ''
          }`}>
          <div
            className={`requirement-item ${
              formik.values.password.length >= 8 &&
              formik.values.password.length <= 20
                ? 'requirement-item--passed'
                : ''
            }`}>
            <div className='requirement-item__icon'>
              {formik.values.password.length >= 8 &&
              formik.values.password.length <= 20 ? (
                <BoxChecked />
              ) : (
                <Box />
              )}
            </div>
            <p className='requirement-item__text'>8-20 characters</p>
          </div>
          <div
            className={`requirement-item ${
              /\d/g.test(formik.values.password)
                ? 'requirement-item--passed'
                : ''
            }`}>
            <div className='requirement-item__icon'>
              {/\d/g.test(formik.values.password) ? <BoxChecked /> : <Box />}
            </div>
            <p className='requirement-item__text'>At least one number</p>
          </div>
          <div
            className={`requirement-item ${
              /[A-Z]/g.test(formik.values.password)
                ? 'requirement-item--passed'
                : ''
            }`}>
            <div className='requirement-item__icon'>
              {/[A-Z]/g.test(formik.values.password) ? <BoxChecked /> : <Box />}
            </div>
            <p className='requirement-item__text'>
              At least one capital letter
            </p>
          </div>
          <div
            className={`requirement-item ${
              !/\s/g.test(formik.values.password) && formik.values.password
                ? 'requirement-item--passed'
                : ''
            }`}>
            <div className='requirement-item__icon'>
              {!/\s/g.test(formik.values.password) && formik.values.password ? (
                <BoxChecked />
              ) : (
                <Box />
              )}
            </div>
            <p className='requirement-item__text'>No spaces</p>
          </div>
        </div>
        <div className='form__controls-row form__action-row'>
          <div className='form__controls-group'>
            <Link to={routes.login} className='btn btn-light'>
              Go Back
            </Link>
          </div>
          <div className='form__controls-group'>
            <UaButton
              isLoading={isLoading}
              type='submit'
              variant='primary'
              className='btn-submit'>
              Log In
            </UaButton>
          </div>
        </div>
        <a href={`mailto:${SUPPORT_EMAIL}`} className='form__link btn btn-link'>
          Questions or issues? <br /> {SUPPORT_EMAIL}
        </a>
      </div>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: func,
  error: object,
  isLoading: bool,
};

ResetPasswordForm.defaultProps = {
  onSubmit: noop,
  isLoading: false,
};

export default ResetPasswordForm;
