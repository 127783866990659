import routine from '../services/routine';

export const getProjects = routine('GET_PROJECTS');
export const getGuestProjects = routine('GET_GUEST_PROJECTS');
export const editProject = routine('EDIT_PROJECT');
export const updateProject = routine('UPDATE_PROJECT');
export const addProject = routine('ADD_PROJECT');

export const getProjectUpdates = routine('GET_PROJECT_UPDATES');
export const addProjectUpdate = routine('ADD_PROJECT_UPDATE');
export const editProjectUpdate = routine('EDIT_PROJECT_UPDATE');

export const addMember = routine('ADD_USER_TO_PROJECT');
export const archiveProjects = routine('ARCHIVE_PROJECTS');
export const preArchiveProjects = routine('PRE_ARCHIVE_PROJECTS');
export const cancelArchiveProjects = routine('CANCEL_ARCHIVE_PROJECTS');
export const unarchiveProjects = routine('UNARCHIVE_PROJECTS');
export const deleteMember = routine('DELETE_USER_FROM_PROJECT');

export const getPriorities = routine('GET_PRIORITIES');

export const addTask = routine('ADD_TASK');
export const editTask = routine('EDIT_TASK');
export const deleteTask = routine('DELETE_TASK');

export const uploadFile = routine('UPLOAD_FILE');
export const deleteFile = routine('GET_FILE');
export const deleteProjects = routine('DELETE_PROJECTS');
export const preDeleteProjects = routine('PRE_DELETE_PROJECTS');
export const cancelDeleteProjects = routine('CANCEL_DELETE_PROJECTS');
export const moveProjects = routine('MOVE_PROJECTS');
export const preMoveProjects = routine('PRE_MOVE_PROJECTS');
export const cancelMoveProjects = routine('CANCEL_MOVE_PROJECTS');
export const copyProjects = routine('COPY_PROJECTS');

export const addProjectStatusFromSocket = routine('ADD_PROJECT_STATUS_FROM_SOCKET');
export const editProjectStatusFromSocket = routine('EDIT_PROJECT_STATUS_FROM_SOCKET');
export const removeProjectStatusFromSocket = routine('REMOVE_PROJECT_STATUS_FROM_SOCKET');

export const addProjectTagFromSocket = routine('ADD_PROJECT_TAG_FROM_SOCKET');
export const editProjectTagFromSocket = routine('EDIT_PROJECT_TAG_FROM_SOCKET');
export const removeProjectTagFromSocket = routine('REMOVE_PROJECT_TAG_FROM_SOCKET');
