import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './kanban-column.styles.scss';

import CreateProject from '../../dashboard/create-project/create-project.component';

import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { ReactComponent as HideIcon } from 'assets/images/icons/look.svg';

import { editProfile } from 'store/auth/actions';
import { getProfileSettings } from 'store/auth/selectors';
import { useParams } from 'react-router-dom';

const KanbanColumn = forwardRef(
  (
    {
      title,
      statusId,
      children,
      isCreateColumn = false,
      isHidden,
      className,
      ...otherProps
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const settings = useSelector(getProfileSettings);

    const isColumnHidden = useMemo(() => {
      return (
        settings &&
        settings.boards[id] &&
        settings.boards[id].hiddenColumns &&
        settings.boards[id].hiddenColumns.includes(statusId)
      );
    }, [settings, id, statusId]);

    const handleHide = () => {
      const boards = { ...settings.boards };

      const hiddenColumns =
        boards[id] && boards[id].hiddenColumns ? boards[id].hiddenColumns : [];
      hiddenColumns.push(statusId);
      dispatch(
        editProfile({
          settings: {
            ...settings,
            boards: {
              ...boards,
              [id]: {
                ...boards[id],
                hiddenColumns,
              },
            },
          },
        })
      );
    };

    const handleUnhide = () => {
      const newSettings = { ...settings };

      newSettings.boards[id].hiddenColumns = newSettings.boards[
        id
      ].hiddenColumns.filter((item) => item !== statusId);

      dispatch(
        editProfile({
          settings: newSettings,
        })
      );
    };

    const toggleHide = () => {
      if (isColumnHidden) {
        handleUnhide();
      } else {
        handleHide();
      }
    };

    return (
      <div
        className={clsx(
          'kanban-column',
          {
            'kanban-column--create': isCreateColumn,
            'kanban-column--hidden': isHidden,
          },
          className
        )}
        ref={ref}
        {...otherProps}>
        <div className='kanban-column-inner'>
          <div className='kanban-column__header'>
            <h6 className='kanban-column__header-title'>{title}</h6>
            <button
              className='kanban-column__header-button'
              onClick={toggleHide}>
              <HideIcon width={20} height={20} />
            </button>
          </div>
          {(Array.isArray(children) && children.length) ||
          Object.keys(children).length ? (
            <div className='kanban-column__body'>{children}</div>
          ) : null}
          <div className='kanban-column__footer'>
            <CreateProject
              boardId={id}
              statusId={statusId}
              className='kanban-column__add-project-button'
              title='Create a New Project'>
              <PlusIcon /> Add Another Project
            </CreateProject>
          </div>
        </div>
      </div>
    );
  }
);

export default KanbanColumn;
