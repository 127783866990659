import React from 'react';

import './client-item.styles.scss';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import clsx from 'clsx';

const ClientItem = ({
  client,
  selectedClient,
  onClientSelect,
  onDeleteClient,
  setEditingField,
}) => {
  const onEditStart = (e) => {
    e.stopPropagation();
    setEditingField(client);
  };

  return (
    <li
      key={`client-list-${client.id}`}
      className={clsx('client-list__item', {
        selected: selectedClient === client.id,
      })}
      onClick={onClientSelect(client.id)}>
      <p className='client-list-item__item-name'>{client.name}</p>
      <div>
        <button
          onClick={onEditStart}
          className='client-list__item-control edit-client'>
          <EditIcon />
        </button>
        <button
          onClick={onDeleteClient(client.id)}
          className='client-list__item-control delete-client'>
          <TrashIcon />
        </button>
      </div>
    </li>
  );
};

export default ClientItem;
