import { apiGET, apiPOST, apiPATCH, apiDELETE } from '../services/api';

export const createCard = ({ id, is_default = false }) =>
  apiPOST({
    url: '/payment-cards',
    data: {
      payment_method_id: id,
      is_default,
    },
  });

export const getCards = () =>
  apiGET({
    url: '/payment-cards',
  });

export const updateCard = ({ id, data }) =>
  apiPATCH({
    url: `/payment-cards/${id}`,
    data,
  });

export const deleteCard = (id) =>
  apiDELETE({
    url: `/payment-cards/${id}`,
  });

export const getTransactions = (id) =>
  apiGET({
    url: `/transactions?account_id=${id}`,
  });

export const getPlans = () =>
  apiGET({
    url: '/plans',
  });

export const subscribePlan = (data) =>
  apiPOST({
    url: '/subscriptions',
    data,
  });

export const changePlan = ({ oldId, newId, seats }) =>
  apiPATCH({
    url: `/subscriptions/${oldId}/change-plane`,
    data: {
      new_plan_id: newId,
      seats,
    },
  });

export const unsubscribePlan = ({ id }) =>
  apiDELETE({
    url: `/subscriptions/${id}`,
  });
