import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import 'components/clients-modal/clients-modal.styles.scss';

import { getProfile } from 'store/auth/selectors';
import { getClients } from 'store/clients/selectors';
import { editProject } from 'store/projects/actions';
import AddClient from 'components/clients-modal/add-client/add-client.component';
import ClientsList from 'components/clients-modal/client-list/client-list.component';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { addClient, deleteClient, editClient } from 'store/clients/actions';

const ClientsModal = ({ show, onHide, project }) => {
  const dispatch = useDispatch();
  const clients = useSelector(getClients);
  const { account_id, id: user_id } = useSelector(getProfile);

  const [isAdding, setIsAdding] = useState(false);

  const toggleIsAdding = () => setIsAdding((prev) => !prev);

  const onAddClient = ({ name }) => {
    dispatch(addClient({
      name,
      account_id,
      user_id,
      onSuccess: () => setIsAdding(false),
    }));
  };

  const onEditClient = ({ id, name }) => {
    dispatch(editClient({
      name,
      id,
      user_id,
    }));
  };

  const onDeleteClient = (id) => (e) => {
    e.stopPropagation();
    dispatch(deleteClient(id));
  };

  const onClientSelect = (client_id) => () => {
    dispatch(
      editProject({
        id: project.id,
        data: {
          client_id,
        },
      })
    );
    onHide();
  };

  const onClientClear = () => {
    dispatch(
      editProject({
        id: project.id,
        data: {
          client_id: null,
        },
      })
    );
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName='ua-modal ua-modal--no-footer user-search-modal'>
      <Modal.Header>
        <button type='button' className='ua-modal__close-btn' onClick={onHide}>
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>
        <h3 className='ua-modal__title'>
          {isAdding ? 'Enter client name' : 'Choose a Client'}
        </h3>
        {isAdding ? (
          <AddClient onSubmit={onAddClient} onCancel={toggleIsAdding} />
        ) : (
          <ClientsList
            clients={clients}
            selectedClient={project.client_id}
            onAddClient={toggleIsAdding}
            onClientSelect={onClientSelect}
            onClientClear={onClientClear}
            onEditClient={onEditClient}
            onDeleteClient={onDeleteClient}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ClientsModal;
