import { put, takeLatest, call } from 'redux-saga/effects';

import { getCustomViews as getCustomViewsAction } from '../actions';

import { getCustomViews as getCustomViewsApi } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(getCustomViewsAction.request());

    const customViews = yield call(getCustomViewsApi, payload);

    yield put(getCustomViewsAction.success(customViews));
  } catch (error) {
    console.error(error);
    yield put(getCustomViewsAction.failure(error.response));
  } finally {
    yield put(getCustomViewsAction.fulfill());
  }
}

export default function* get() {
  yield takeLatest(getCustomViewsAction.TRIGGER, getSaga);
}
