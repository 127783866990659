import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { signUp as signUpAction } from '../actions';
// api
import { signUp as signUpApi } from '../api';
// services
import getErrorMessage from '../../services/getErrorMessages';

function* signUpSaga({ payload: userData }) {
  try {
    yield put(signUpAction.request());

    const user = yield call(signUpApi, userData);

    yield put(signUpAction.success(user));

    window.open('https://unmanage.io/welcome', '_self');
  } catch (error) {
    yield put(signUpAction.failure(getErrorMessage(error.response)));
  } finally {
    yield put(signUpAction.fulfill());
  }
}

export default function* signUp() {
  yield takeLatest(signUpAction.TRIGGER, signUpSaga);
}
