import React, { useEffect } from 'react';

import BoardComponent from 'components/board/board/board.component';
import { getBoard } from 'store/boards/actions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, isAuth } from 'store/auth/selectors';
import { getCustomViews as getCustomViewsAction } from 'store/custom-views/actions';
import { BoardContextProvider } from 'context/board-context/board-context';
import { closeAppSidebar } from 'store/app/actions';

const BoardPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const authState = useSelector(isAuth);
  const { account_id } = useSelector(getProfile);

  useEffect(() => dispatch(closeAppSidebar()), [dispatch]);

  useEffect(() => {
    if (authState && id) dispatch(getBoard({ id, initial: true }));
  }, [id, authState]);

  useEffect(() => {
    if (account_id) dispatch(getCustomViewsAction(account_id));
  }, [account_id]);

  return (
    <BoardContextProvider isGuest={false}>
      <BoardComponent />
    </BoardContextProvider>
  );
};

export default BoardPage;
