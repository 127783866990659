import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getMembersList as getUsersAction } from '../actions';
// api
import { getMembersList as getUsersApi } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(getUsersAction.request());

    const users = yield call(getUsersApi, payload);

    yield put(getUsersAction.success(users));
  } catch (error) {
    console.error(error);
    yield put(getUsersAction.failure(error.response));
  } finally {
    yield put(getUsersAction.fulfill());
  }
}

export default function* getMembers() {
  yield takeLatest(getUsersAction.TRIGGER, getSaga);
}
