import { all, fork } from 'redux-saga/effects';
// sagas
import get from './get';
import getGuestProjects from './getGuestProjects';
import add from './add';
import edit from './edit';

import getUpdates from './getUpdates';
import addUpdate from './addUpdate';
import editUpdate from './editUpdate';
import archive from './archive';
import unarchive from './unarchive';
import deleteProjects from './delete';
import moveProjects from './move';
import copyProjects from './copy';

import addMember from './addMember';
import deleteMember from './deleteMember';

import getPriorities from './getPriorities';

import deleteTask from './deleteTask';
import addTask from './addTask';
import editTask from './editTask';

import deleteFile from './deleteFile';
import uploadFile from './uploadFile';

export default function* projects() {
  yield all([
    fork(get),
    fork(getGuestProjects),
    fork(add),
    fork(addMember),
    fork(edit),
    fork(getUpdates),
    fork(addUpdate),
    fork(editUpdate),
    fork(archive),
    fork(unarchive),
    fork(deleteMember),
    fork(getPriorities),
    fork(deleteTask),
    fork(addTask),
    fork(editTask),
    fork(deleteFile),
    fork(uploadFile),
    fork(deleteProjects),
    fork(moveProjects),
    fork(copyProjects),
  ]);
}
