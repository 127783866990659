import cloneDeep from 'lodash/cloneDeep';

// actions
import { getProfile, logout } from '../auth/actions';
import {
  dndBoardDashboard,
  toggleTypeDashboard,
  updateDashboard,
  changePosition, getDashboard
} from './actions';

const INITIAL_STATE = {
  list: [],
  isLoading: false,
  boards_positions: {},
  isAccount: false,
};

export default function dashboardsReducer(
  state = INITIAL_STATE,
  { payload, type }
) {
  switch (type) {
    case getDashboard.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getDashboard.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case getProfile.SUCCESS: {
      const {
        dashboards,
        boards_positions: { dashboard },
        preferred_dashboard: { type },
      } = payload.profile;

      return {
        ...state,
        list: dashboards,
        boards_positions: dashboard,
        isAccount: type === 'account',
      };
    }

    case changePosition.TRIGGER: {
      return {
        ...state,
        boards_positions: {
          ...state.boards_positions,
          [payload.dashboard_id]: {
            ...state.boards_positions[payload.dashboard_id],
            ...payload.boards_positions,
          },
        },
      };
    }

    case changePosition.SUCCESS: {
      return {
        ...state,
        boards_positions: {
          ...state.boards_positions,
          [payload.id]: payload.response,
        },
      };
    }

    case toggleTypeDashboard.TRIGGER: {
      return {
        ...state,
        isAccount: !state.isAccount,
      };
    }

    case dndBoardDashboard.SUCCESS:
    case updateDashboard.SUCCESS: {
      const { id, ...dashboard } = payload.dashboard || payload;
      const boardIndex = state.list.findIndex(
        ({ id: boardId }) => boardId === id
      );
      const boards = cloneDeep(state.list);

      boards[boardIndex] = { ...boards[boardIndex], ...dashboard };

      return {
        ...state,
        list: boards,
      };
    }

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
