import { useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { confirmEmail, resetAuthState } from 'store/auth/actions';
import {
  getVerificationError,
  isVerificationLoading,
} from 'store/auth/selectors';
import routes from 'utils/routes';
import { NAME_APP } from '../../settings';
import { SUPPORT_EMAIL } from '../../storage/consts';

const EmailConfirmationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isLoading = useSelector(isVerificationLoading);
  const hasError = useSelector(getVerificationError);

  const token = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token');
  }, [location]);

  const sendConfirmation = () => {
    dispatch(resetAuthState());
    localStorage.removeItem(`${NAME_APP}/token`);
    dispatch(confirmEmail(token));
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (token && !location.state) {
      sendConfirmation();
      return;
    }
    if (!token && !location.state?.isEmailConfirmed) {
      history.replace(routes.login);
    }
  }, []);

  return (
    <div
      className={`form form-card form-card--verification ${
        isLoading ? 'form-card--verification-loading' : ''
      }`}>
      {isLoading && (
        <div className='loader-container'>
          <Spinner animation='border' role='status' />
        </div>
      )}
      {!isLoading && hasError && (
        <>
          Confirmation unsuccessful, please try again.
          <div className='links'>
            <button onClick={sendConfirmation}>Retry</button>
            <NavLink to={routes.login}>Login</NavLink>
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              className='form__link btn btn-link'>
              Questions or issues? <br /> {SUPPORT_EMAIL}
            </a>
          </div>
        </>
      )}
      {!isLoading && !hasError && (
        <>
          Your Email has been changed successfully.
          <NavLink to={routes.login}>Login</NavLink>
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className='form__link btn btn-link'>
            Questions or issues? <br /> {SUPPORT_EMAIL}
          </a>
        </>
      )}
    </div>
  );
};

export default EmailConfirmationPage;
