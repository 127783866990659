import React, { useMemo, useState } from 'react';

import './due-date.styles.scss';

import UaDatepicker from 'components/common/ua-datepicker/ua-datepicker.component';
import dateFormat from 'utils/date';

import { ReactComponent as Cross } from 'assets/images/icons/times-circle-bold.svg';
import { ReactComponent as Clock } from 'assets/images/icons/clock-outline.svg';

const DAY =  1000 * 60 * 60 * 24 * 7; // 7 Day

const DueDate = ({ date, editing, onDateChange }) => {
  const [show, setShow] = useState(false);

  const isDueDate = useMemo(() => {
    const currentDate = Date.now();
    const dueDate = new Date(date).getTime();

    return dueDate - currentDate < DAY;
  }, [date]);

  const isOverdueDate = useMemo(() => {
    const currentDate = Date.now();
    const dueDate = new Date(date).getTime();

    return currentDate > dueDate;
  }, [date]);

  const onShow = () => {
    if (editing) setShow(true);
  };

  return (
    <span className='due-date'>
      <button className='project-due-date__button' onClick={onShow}>
        <span className='project-due-date__button-label'>
          {date ? (
            <>
              {isDueDate && !isOverdueDate ? <Clock /> : null}
              {isOverdueDate ? <Cross /> : null}
              {dateFormat(date, 'month-day')}
            </>
          ) : (
            '\u2014'
          )}
        </span>
      </button>

      {editing && (
        <UaDatepicker
          title='Due Date'
          noFooter={true}
          show={show}
          onHide={() => setShow(false)}
          date={date}
          onDateChange={onDateChange}
        />
      )}
    </span>
  );
};

export default DueDate;
