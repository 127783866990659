import React from 'react';
import { useDispatch } from 'react-redux';

import UaConfirm from 'components/common/ua-modal/ua-confirm.component';

import { togglePersonal } from 'store/boards/actions';

const BoardPersonalModal = ({
  board: { is_in_personal_dashboard, id },
  isShow,
  onClose,
}) => {
  const dispatch = useDispatch();

  const handleTogglePersonal = () => {
    dispatch(
      togglePersonal({ boardId: id, isPersonal: !is_in_personal_dashboard })
    );
    onClose();
  };

  return (
    <UaConfirm
      show={isShow}
      onHide={onClose}
      onOk={handleTogglePersonal}
      okLabel='Yes'
      cancelLabel='No'
      message={`Are you sure you want ${
        !is_in_personal_dashboard ? 'add to' : 'remove from'
      } personal dashboard this board?`}
    />
  );
};

export default BoardPersonalModal;
