import { useState, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './project-info.styles.scss';

import UaEditableField from 'components/common/ua-editable-field/ua-editable-field.component';
import { editProject } from 'store/projects/actions';

import { ReactComponent as AlignLeft } from 'assets/images/icons/align-left.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/note.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-circle-outline.svg';
import { useParams } from 'react-router-dom';

const ProjectInfo = ({ info, projectId, editable = true, className = '' }) => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [projInfo, setProjInfo] = useState(info);
  const { id: boardId } = useParams();

  useEffect(() => {
    setProjInfo(info);
  }, [info]);

  const handleChange = (value) => {
    setProjInfo(value);
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    dispatch(
      editProject({
        id: projectId,
        boardId,
        data: {
          info: projInfo,
        },
      })
    );
    setEditing(false);
  };

  return (
    <div className={`project-widget project-info ${className}`}>
      <div className='project-widget__header'>
        <div className='project-widget__header-icon'>
          <AlignLeft />
        </div>
        <div className='project-widget__header-title'>Project Info</div>
        <div className='project-widget__header-action'>
          {editable ? (
            <>
              {!editing ? (
                <button
                  className='project-info__edit-button'
                  onClick={handleEditClick}>
                  <EditIcon /> Edit
                </button>
              ) : (
                <button
                  className='project-info__save-button'
                  onClick={handleSaveClick}>
                  <CheckIcon /> Save
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className='project-widget__content'>
        {editable && (
          <UaEditableField
            className='project-info__info-text'
            tag='p'
            editInput='textarea'
            text={projInfo}
            editOnClick={false}
            customEditing={editing}
            maxLength={300}
            closeOnEsc={false}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ProjectInfo);
