import React, { memo, useMemo } from 'react';
import { any, bool, func, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import noop from 'lodash/noop';

import {
  getPriorities as prioritiesSelector,
  getStatuses as statusesSelector,
} from 'store/projects/selectors';
import { editProject } from 'store/projects/actions';

import { ReactComponent as AlignLeft } from 'assets/images/icons/align-left.svg';
import { ReactComponent as Refresh } from 'assets/images/icons/refresh.svg';
import { ReactComponent as Paperclip } from 'assets/images/icons/paperclip.svg';
import { ReactComponent as Check } from 'assets/images/icons/check-circle-outline.svg';
import { ReactComponent as Loop } from 'assets/images/icons/loop.svg';

import ProjectTeam from '../project-team/project-team.component';
import ProjectClient from '../project-client/project-client.component';
import Priority from '../priority/priority.component';
import TagsList from '../tags-list/tags-list.component';
import Statuses from '../statuses/statuses.component';
import DueDate from '../due-date/due-date.component';
import { useParams } from 'react-router-dom';
import dateFormat from 'utils/date';
import clsx from 'clsx';
import { getCurrentRole } from 'store/accounts/selectors';
import { ROLES } from 'settings';

const CellContainer = memo(({ children, label }) => (
  <>
    <span className='col-label'>{label}</span>
    <span className='col-data'>{children}</span>
  </>
));

const TableCell = ({
  value,
  row,
  column,
  editing,
  onProjectClick,
  widgetFilters,
  setWidgetFilters,
  queryValue,
}) => {
  const dispatch = useDispatch();
  const { id: boardId } = useParams();
  const { id: projectId, files, tasks, updates } = row.original;

  const prioritiesList = useSelector(prioritiesSelector);
  const statusesList = useSelector(statusesSelector);
  const currentRole = useSelector(getCurrentRole);

  const isUser = currentRole.slug === ROLES.USER;

  const handleProjectClick = () => {
    onProjectClick({ id: projectId });
  };

  const isWidgetActive = (key) => widgetFilters[key].includes(projectId);

  const isShowAll = useMemo(
    () =>
      Object.keys(widgetFilters)
        .map((key) => widgetFilters[key].includes(projectId))
        .every((v) => v),
    [widgetFilters, projectId]
  );

  const onWidgetClick = (key) => () => {
    if (widgetFilters[key].includes(projectId)) {
      setWidgetFilters({
        ...widgetFilters,
        [key]: widgetFilters[key].filter((item) => item !== projectId),
      });
    } else {
      setWidgetFilters({
        ...widgetFilters,
        [key]: [...widgetFilters[key], projectId],
      });
    }
  };

  const onAllWidgetClick = () => {
    const newState = Object.keys(widgetFilters).reduce((acc, key) => {
      acc[key] = isShowAll
        ? widgetFilters[key].filter((item) => item !== projectId)
        : [...widgetFilters[key], projectId];
      return acc;
    }, Object.create(null));

    setWidgetFilters(newState);
  };

  switch (column.id) {
    case 'name': {
      const createHighlight = (text) => {
        if (!queryValue) return text;

        const filterRegExp = queryValue
          .replaceAll('\\', '\\\\')
          .replaceAll('[', '\\[')
          .replaceAll('*', '\\*')
          .replaceAll('?', '\\?')
          .replaceAll('+', '\\+');

        const regex = new RegExp(filterRegExp, 'gi');
        const indexArr = [...text.matchAll(regex)]
          .map(({ index }) => index)
          .map((item) => {
            const arr = [];
            for (let i = 0; i < queryValue.length; i++) {
              arr.push(item + i);
            }
            return arr;
          })
          .flat();

        let ttt = '';

        for (const i in text) {
          if (indexArr.includes(parseInt(i))) {
            ttt += `<span class='highlight'>${text[i]}</span>`;
          } else {
            ttt += text[i];
          }
        }
        return ttt;
      };

      return (
        <CellContainer label='Project Title'>
          <span
            className='project-title'
            onClick={handleProjectClick}
            dangerouslySetInnerHTML={{
              __html: createHighlight(value),
            }}
          />

          <div className='project-widgets'>
            <button
              className={clsx({ active: isWidgetActive('showInfo') })}
              onClick={onWidgetClick('showInfo')}>
              <AlignLeft />
            </button>

            <span />

            <button
              className={clsx({ active: isWidgetActive('showUpdates') })}
              onClick={onWidgetClick('showUpdates')}>
              <Refresh />
            </button>

            <span>
              {!!updates?.length &&
                dateFormat(updates[0].updated_at, 'month-day')}
            </span>

            <button
              className={clsx({ active: isWidgetActive('showFiles') })}
              onClick={onWidgetClick('showFiles')}>
              <Paperclip />
            </button>
            <span>{files?.length}</span>

            <button
              className={clsx({ active: isWidgetActive('showTasks') })}
              onClick={onWidgetClick('showTasks')}>
              <Check />
            </button>
            <span>{tasks?.length}</span>

            <button
              className={clsx({ active: isShowAll })}
              onClick={onAllWidgetClick}>
              <Loop />
            </button>
          </div>
        </CellContainer>
      );
    }
    case 'members': {
      return (
        <CellContainer label='Project Team'>
          <ProjectTeam
            project={row.original}
            addButton={false}
            editing={editing && !isUser}
          />
        </CellContainer>
      );
    }
    case 'client': {
      return (
        <CellContainer label='Client'>
          <ProjectClient project={row.original} editing={editing} />
        </CellContainer>
      );
    }
    case 'priority': {
      const onChangePriority = (priority_id) => {
        dispatch(
          editProject({
            id: projectId,
            data: { priority_id },
          })
        );
      };

      return (
        <CellContainer label='Priority'>
          <Priority
            value={value}
            priorities={prioritiesList}
            editing={editing}
            onChangePriority={onChangePriority}
          />
        </CellContainer>
      );
    }
    case 'tags': {
      const onChangeTags = (tags) => {
        dispatch(
          editProject({
            id: projectId,
            data: { tags },
          })
        );
      };

      return (
        <CellContainer label='Tags'>
          <TagsList
            projectTags={value}
            editing={editing}
            onChangeTags={onChangeTags}
          />
        </CellContainer>
      );
    }
    case 'status': {
      const onChangeStatus = (status_id) => {
        dispatch(
          editProject({
            id: projectId,
            boardId,
            data: { status_id },
          })
        );
      };

      return (
        <CellContainer label='Status'>
          <Statuses
            status={value}
            editing={editing}
            statusesList={statusesList}
            onChangeStatus={onChangeStatus}
            projectId={projectId}
          />
        </CellContainer>
      );
    }
    case 'due_date': {
      const handleUpdateDueDate = (due_date) => {
        dispatch(
          editProject({
            id: projectId,
            boardId,
            data: {
              due_date,
            },
          })
        );
      };

      return (
        <CellContainer label='Due Date'>
          <DueDate
            date={value}
            editing={editing}
            onDateChange={handleUpdateDueDate}
          />
        </CellContainer>
      );
    }
    default: {
      return null;
    }
  }
};

TableCell.propTypes = {
  value: any,
  row: object,
  column: object,
  editing: bool,
  onProjectClick: func,
};

TableCell.defaultProps = {
  onProjectClick: noop,
};

export default memo(TableCell);
