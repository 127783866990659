export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.left = '-99999px';
    input.style.top = '-99999px';
    document.body.appendChild(input);
    input.value = text;
    input.focus();
    input.select();
    return new Promise((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      input.remove();
    });
  }
};
