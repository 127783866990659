import React, { useContext, useEffect } from 'react';
import { bool, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import './project-card.styles.scss';

import Priority from 'components/dashboard/priority/priority.component';
import ProjectTeam from 'components/dashboard/project-team/project-team.component';
import ProjectDueDate from 'components/project/widgets/project-due-date/project-due-date.component';
import ProjectInfo from 'components/project/widgets/project-info/project-info.component';
import ProjectUpdate from 'components/project/widgets/project-update/project-update.component';
import ProjectFiles from 'components/project/widgets/project-files/project-files.component';
import ProjectTasks from 'components/project/widgets/project-tasks/project-tasks.component';

import { getProjectUpdates, editProject } from 'store/projects/actions';

import Statuses from '../dashboard/statuses/statuses.component';
import { useParams } from 'react-router-dom';
import UaEditableField from 'components/common/ua-editable-field/ua-editable-field.component';
import { BoardContext } from 'context/board-context/board-context';
import {
  getPriorities as prioritiesSelector,
  getStatuses as statusesSelector,
} from 'store/projects/selectors';
import ProjectClient from 'components/dashboard/project-client/project-client.component';
import TagsList from 'components/dashboard/tags-list/tags-list.component';

const ProjectCardItem = ({ title, children }) => {
  return (
    <div className='project-card-item'>
      <h6 className='project-card-item__title'>{title}</h6>
      <div className='project-card-item__content'>{children}</div>
    </div>
  );
};

const ProjectCard = ({ project, editing }) => {
  const dispatch = useDispatch();
  const { id: boardId } = useParams();

  const { isGuest } = useContext(BoardContext);
  const prioritiesList = useSelector(prioritiesSelector);
  const statusesList = useSelector(statusesSelector);

  useEffect(() => {
    if (project?.id && !isGuest)
      dispatch(getProjectUpdates({ id: project?.id }));
  }, [dispatch, isGuest, project?.id]);

  const handleUpdateDueDate = (due_date) => {
    dispatch(
      editProject({
        id: project.id,
        boardId,
        data: {
          due_date,
        },
      })
    );
  };

  const onChangeProjectName = (e) => {
    dispatch(
      editProject({
        id: project.id,
        boardId,
        data: {
          name: e.target.value,
        },
      })
    );
  };

  const onChangePriority = (priority_id) => {
    dispatch(
      editProject({
        id: project.id,
        data: { priority_id },
      })
    );
  };

  const onChangeStatus = (status_id) => {
    dispatch(
      editProject({
        id: project.id,
        boardId,
        data: { status_id },
      })
    );
  };

  const onChangeTags = (tags) => {
    dispatch(
      editProject({
        id: project.id,
        data: { tags },
      })
    );
  };

  return (
    <div className='project-card'>
      <div className='project-card__header'>
        <div className='project-card__title-container'>
          <UaEditableField
            tag='h4'
            contentClass='project-card__title'
            text={project.name}
            onBlur={onChangeProjectName}
            editOnClick={editing}
          />
        </div>
        <div className='project-card-row'>
          <ProjectCardItem title='Priority'>
            <Priority
              className='project-card__priority'
              value={project.priority}
              editing={editing}
              priorities={prioritiesList}
              onChangePriority={onChangePriority}
            />
          </ProjectCardItem>

          <ProjectCardItem title='Due date'>
            <ProjectDueDate
              project={project}
              editing={editing}
              onDateChange={handleUpdateDueDate}
            />
          </ProjectCardItem>
        </div>

        <div className='project-card-row' style={{ margin: 0 }}>
          <ProjectCardItem title='Project Team'>
            <ProjectTeam
              className='project-card__team'
              project={project}
              editing={editing}
              addButton={editing}
            />
          </ProjectCardItem>
        </div>
      </div>

      <div className='project-card__divider' />

      <div className='project-card__body'>
        <div className='project-card-row'>
          <ProjectCardItem title='Client'>
            <ProjectClient project={project} editing={editing} />
          </ProjectCardItem>
        </div>

        <div className='project-card-row'>
          <ProjectCardItem title='Status'>
            <Statuses
              status={project.status}
              editing={editing}
              statusesList={statusesList}
              onChangeStatus={onChangeStatus}
              projectId={project.id}
            />
          </ProjectCardItem>
        </div>

        <div className='project-card-row'>
          <ProjectCardItem title='Tags'>
            <TagsList
              projectTags={project.tags}
              editing={editing}
              onChangeTags={onChangeTags}
            />
          </ProjectCardItem>
        </div>

        <ProjectInfo
          className='project-card__info'
          info={project.info}
          projectId={project.id}
          editable={editing}
        />
        <ProjectUpdate
          className='project-card__update'
          updates={project.updates}
          projectId={project.id}
          editing={editing}
          isCard
        />
        <ProjectFiles
          className='project-card__files'
          projectId={project.id}
          files={project.files}
          editing={editing}
          isCard
        />
        <ProjectTasks
          className='project-card__tasks'
          projectId={project.id}
          tasks={project.tasks}
          editing={editing}
          isCard
        />
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  project: object,
  editing: bool,
};

ProjectCard.defaultProps = {
  project: {},
  editing: true,
};

export default ProjectCard;
