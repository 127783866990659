import React from 'react';
import { Spinner } from 'react-bootstrap';

import './payments-cards.styles.scss';

import plus from 'assets/images/icons/plus-circle-solid.png';

import CardsTable from '../tables/cards-table/cards-table.components';
import UATooltip from '../../../common/ua-tooltip/ua-tooltip.components';
import UaButton from '../../../common/ua-button/ua-button.component';

const PaymentCards = ({ cards, isLoading, gotoAddCard }) => {
  const isDisabled = cards.length >= 10;

  return (
    <div className='payment-cards billing__card'>
      <div className='payment-cards__row'>
        <h3 className='billing__title'>Payment Cards</h3>

        <UATooltip label='You can add only 10 cards' show={isDisabled}>
          <div>
            <UaButton
              className='payment-cards__add-card'
              disabled={isDisabled}
              onClick={() => gotoAddCard('add_card')}>
              <img src={plus} alt='plus-icon' />
              Add Card
            </UaButton>
          </div>
        </UATooltip>
      </div>

      {!isLoading ? (
        <CardsTable cards={cards} />
      ) : (
        <Spinner animation='border' role='status' />
      )}
    </div>
  );
};

export default PaymentCards;
