import routine from '../services/routine';

export const getRolesList = routine('GET_ROLES_LIST');
export const getMembersList = routine('GET_MEMBERS_LIST');
export const createAccount = routine('CREATE_ACCOUNT');
export const closeAccount = routine('CLOSE_ACCOUNT');
export const downloadDataRequest = routine('DOWNLOAD_DATA_REQUEST');
export const downloadData = routine('DOWNLOAD_DATA');
export const transferOwnership = routine('TRANSFER_OWNERSHIP');
export const ownerAccept = routine('OWNER_ACCEPT');
export const updateAccount = routine('UPDATE_ACCOUNT');
export const deleteLogo = routine('DELETE_ACCOUNT_LOGO');
export const deleteAccountUser = routine('DELETE_ACCOUNT_USER');
export const inviteAccountUser = routine('INVITE_ACCOUNT_USER');
export const updateAccountUser = routine('UPDATE_ACCOUNT_USER');
export const resetUserPassword = routine('RESET_USER_PASSWORD');
export const switchAccount = routine('SWITCH_ACCOUNT');
export const getCurrentAccount = routine('GET_CURRENT_ACCOUNT');
export const refreshCurrentAccount = routine('REFRESH_CURRENT_ACCOUNT');
