import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClientsModal from 'components/clients-modal/clients-modal.component';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-circle.svg';
import './project-client.styles.scss';
import { editProject } from 'store/projects/actions';
import { useDispatch } from 'react-redux';

const ProjectClient = ({ project = {}, editing = true, className = '' }) => {
  const dispatch = useDispatch();

  const [showSearchModal, setShowSearchModal] = useState(false);
  const { client } = project;

  const onOpen = () => {
    if (editing) setShowSearchModal(true);
  };

  const onClientClear = (e) => {
    e.stopPropagation();

    dispatch(
      editProject({
        id: project.id,
        data: {
          client_id: null,
        },
      })
    );
  };

  return (
    <div className={`project-client ${className}`}>
      <div className='project-client__members' onClick={onOpen}>
        {client ? client.name : '\u2014'}
        {client && (
          <button className='project-client__clear-btn' onClick={onClientClear}>
            <CloseIcon />
          </button>
        )}
      </div>
      {editing && (
        <ClientsModal
          show={showSearchModal}
          onHide={() => setShowSearchModal(false)}
          project={project}
        />
      )}
    </div>
  );
};

ProjectClient.propTypes = {
  project: PropTypes.object,
  editing: PropTypes.bool,
  className: PropTypes.string,
};

export default ProjectClient;
