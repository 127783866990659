import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import UaConfirm from 'components/common/ua-modal/ua-confirm.component';
import Tag from '../tag/tag.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import UaColorpicker from 'components/common/ua-colorpicker/ua-colorpicker.component';
import TagSchema from '../tags-list/TagSchema';
import '../tags-list/tags-list.styles.scss';
import './statuses.styles.scss';

import { ReactComponent as AddIcon } from 'assets/images/icons/plus-bold.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit-solid.svg';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-alt.svg';
import { addStatus, deleteStatus, editStatus } from 'store/boards/actions';
import { useParams } from 'react-router-dom';
import { archiveProjects } from 'store/projects/actions';
import { STATUSES } from 'settings';

const Statuses = ({
  status,
  statusesList = [],
  onChangeStatus,
  customBody,
  projectId,
  editing,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isTagListOpen, setIsTagListOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [statusToEdit, setStatusToEdit] = useState(null);
  const [deleteItem, setDeleteItem] = useState({
    showConfirm: false,
    item: null,
  });

  const [archiveModal, setArchiveModal] = useState(false);

  const handleOk = () => {
    dispatch(archiveProjects({ projects: [projectId] }));
    setArchiveModal(false);
  };

  const handleShowOptionClick =
    ({ id, slug }) =>
    (e) => {
      e.stopPropagation();
      setIsTagListOpen(false);
      if (onChangeStatus) onChangeStatus(id);
      if (slug === STATUSES.COMPLETED || slug === STATUSES.CANCELLED) {
        setArchiveModal(true);
      }
    };

  const handleAdd = () => () => {
    if (editing) setIsAddFormOpen(true);
  };

  const onAddClick = () => {
    if (editing) setIsTagListOpen(true);
  };

  const resetDropdown = () => {
    setIsAddFormOpen(false);
    setStatusToEdit(null);
    setEditMode(false);
    formik.values.name = '';
    formik.values.color = '';
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#000000',
    },
    validationSchema: TagSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        slug: values.name.replace(/\s/g, '-').toLocaleLowerCase(),
      };
      if (editMode) {
        dispatch(
          editStatus({
            boardId: id,
            statusId: statusToEdit.id,
            status: data,
            callback: resetDropdown,
          })
        );
      } else {
        dispatch(
          addStatus({ boardId: id, status: data, callback: resetDropdown })
        );
      }
    },
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  const handleColorChange = (event) => {
    formik.setFieldValue(event.name, event.value);
  };

  const handleDelete = (item) => () => {
    dispatch(deleteStatus(item));
  };

  return (
    <>
      <UaDropdown
        show={isTagListOpen}
        className='tag-list__dropdown status__dropdown'
        dropdownVariant='light'
        onClick={onAddClick}
        disabled={!editing}
        handleRootClose={() => {
          setIsTagListOpen(false);
          resetDropdown();
        }}
        buttonContent={
          customBody ? (
            customBody
          ) : !status ? (
            <span>&mdash;</span>
          ) : (
            <Tag text={status.name} color={status.color} />
          )
        }
        withIcon={false}>
        {!isAddFormOpen && statusesList.length ? (
          <>
            {statusesList.map((status) => (
              <React.Fragment key={`statuses-item-${status.id}`}>
                <Dropdown.Item
                  as='div'
                  className='tag-list-item'
                  onClick={handleShowOptionClick(status)}>
                  <Tag
                    key={`statuses-list-item-${status.id}`}
                    text={status.name}
                    color={status.color}
                  />
                  {!status.is_default && (
                    <>
                      <button
                        className='tag-list-item__button'
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.values.name = status.name;
                          formik.values.color = status.color;
                          setStatusToEdit(status);
                          setEditMode(true);
                          setIsAddFormOpen(true);
                        }}>
                        <EditIcon />
                      </button>
                      <button
                        className='tag-list-item__button'
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteItem({
                            showConfirm: true,
                            item: {
                              boardId: id,
                              statusId: status.id,
                            },
                          });
                        }}>
                        <TrashIcon fill='#FB404B' />
                      </button>
                    </>
                  )}
                </Dropdown.Item>
              </React.Fragment>
            ))}
            <Dropdown.Item
              onClick={handleAdd()}
              className='tag-list-add-button'>
              <AddIcon /> Create a new status
            </Dropdown.Item>
          </>
        ) : (
          <div className='tag-list-form'>
            <form onSubmit={formik.handleSubmit}>
              <UaInput
                id='name'
                name='name'
                type='text'
                placeholder='Status Name'
                value={formik.values.name}
                handleChange={handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && formik.errors.name}
                valid={formik.touched.name && !formik.errors.name}
                showError
              />
              <UaColorpicker
                color={formik.values.color}
                handleChange={handleColorChange}
                error={formik.touched.color && formik.errors.color}
                valid={formik.touched.color && !formik.errors.color}
              />
              <button type='submit' className='tag-list-add-button'>
                {editMode ? (
                  'Edit status'
                ) : (
                  <>
                    <AddIcon /> Create a new status
                  </>
                )}
              </button>
            </form>
          </div>
        )}
      </UaDropdown>
      <UaConfirm
        show={deleteItem.showConfirm}
        onHide={() => setDeleteItem({ showConfirm: false, item: null })}
        onOk={handleDelete(deleteItem.item)}
        okLabel='Yes'
        cancelLabel='No'
        message='Are you sure you want to delete this status?'
        notification={{
          text: 'We successfully deleted 1 item',
        }}
      />

      <UaConfirm
        show={archiveModal}
        onHide={() => setArchiveModal(false)}
        onOk={handleOk}
        okLabel='Yes'
        cancelLabel='No'
        message='Move the project to archive?'
      />
    </>
  );
};

export default Statuses;
