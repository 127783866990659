import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Button } from 'react-bootstrap';
import clsx from 'clsx';
import orderBy from 'lodash/orderBy';

import { getProfile } from 'store/auth/selectors';
import {
  deleteAccountUser,
  inviteAccountUser,
  updateAccountUser,
  resetUserPassword,
} from 'store/accounts/actions';
import {
  getAccountMembers,
  getInviteStatus,
  getAccountRoles,
  getCurrentRole,
  getCurrentAccount,
  getCurrentSubscription,
} from 'store/accounts/selectors';

import './manage-users.styles.scss';

import InviteUserButton from './invite-user-button/invite-user-button.component';
import UserAvatar from 'components/user-avatar/user-avatar.component';
import UaConfirm from 'components/common/ua-modal/ua-confirm.component';
import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import UserEditableName from 'components/user-editable-name/user-editable-name.component';

import { ReactComponent as ArrowDown } from 'assets/images/icons/arrow-down.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';

import { PLAN_SEAT_PREFIX, PLANS_TYPES, ROLES } from 'settings';
import dateFormat from '../../../utils/date';

// Time for blocking 'Reset password' button
const BLOCKING_TIME = 30000;

const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
};

const COLUMN_TYPES = {
  NAME: 'user.name',
  ACCESS: 'role.id',
  DATE: 'user.updated_at',
};

const ManageUsers = () => {
  const dispatch = useDispatch();

  const { id: userId, account_id } = useSelector(getProfile);
  const account = useSelector(getCurrentAccount);
  const { members } = useSelector(getAccountMembers);
  const { roles } = useSelector(getAccountRoles);
  const inviteStatus = useSelector(getInviteStatus);
  const currentRole = useSelector(getCurrentRole);
  const currentSubscription = useSelector(getCurrentSubscription);

  const isUser = currentRole.slug === ROLES.USER;

  const [showConfirm, setShowConfirm] = useState(null);
  const [openedRows, setOpenedRows] = useState([]);
  const [isResetDisabled, setIsResetDisabled] = useState({});
  const [sortBy, setSortBy] = useState({
    column: null,
    sortType: SORT_TYPES.ASC,
  });

  const onUserDelete = () => {
    dispatch(
      deleteAccountUser({
        accountId: account_id,
        userId: showConfirm,
      })
    );
  };

  const onUserInvite = (data) => {
    dispatch(
      inviteAccountUser({
        accountId: account_id,
        data,
      })
    );
  };

  const onChangeRole = (userId) => (role_id) => {
    dispatch(
      updateAccountUser({
        accountId: account_id,
        userId,
        data: { role_id },
      })
    );
  };

  const disableResetBtn = (id) => () => {
    setIsResetDisabled((prev) => ({ ...prev, [id]: true }));
    setTimeout(() => {
      setIsResetDisabled((prev) => ({ ...prev, [id]: false }));
    }, BLOCKING_TIME);
  };

  const onResetPassword = (userId) => () => {
    dispatch(
      resetUserPassword({
        accountId: account_id,
        userId,
        success: disableResetBtn(userId),
      })
    );
  };

  const onOpenRow = (userId) => () => {
    if (openedRows.includes(userId)) {
      setOpenedRows(openedRows.filter((item) => item !== userId));
    } else {
      setOpenedRows([...openedRows, userId]);
    }
  };

  const onChangeSort = (key) => () => {
    if (sortBy.column === key && sortBy.sortType === SORT_TYPES.ASC) {
      setSortBy((prev) => ({ ...prev, sortType: SORT_TYPES.DESC }));
    }
    if (sortBy.column === key && sortBy.sortType === SORT_TYPES.DESC) {
      setSortBy((prev) => ({ ...prev, sortType: SORT_TYPES.ASC }));
    }
    if (sortBy.column !== key) {
      setSortBy({ column: key, sortType: SORT_TYPES.ASC });
    }
  };

  const sortMembers = useMemo(
    () => orderBy(members, [sortBy.column], [sortBy.sortType]),
    [members, sortBy]
  );

  const renderArrow = (column) =>
    sortBy.column === column || !sortBy.column ? (
      <ArrowDown
        className={clsx('col-sort-arrow', {
          'col-sort-arrow__active': sortBy.column === column,
          'col-sort-arrow__rotate': sortBy.sortType === SORT_TYPES.DESC,
        })}
      />
    ) : null;

  const availableSeats = currentSubscription.plan.seats
    ? currentSubscription.plan.seats
    : account.seats;

  return (
    <div>
      <div className='manage-users__header'>
        <h3 className='manage-users__title'>Manage Users</h3>
        {!isUser && (
          <div className='manage-users__header-right'>
            {(currentSubscription.plan.slug === PLANS_TYPES.FREE ||
              currentSubscription.plan.slug.includes(PLAN_SEAT_PREFIX)) && (
              <div className='manage-users__account-seats'>
                {members.length}/{availableSeats} seats used
              </div>
            )}
            <InviteUserButton
              totalUsers={members.length}
              accountSeats={availableSeats}
              onUserInvite={onUserInvite}
              inviteStatus={inviteStatus}
              roles={roles.filter(({ slug }) => slug !== ROLES.OWNER)}
            />
          </div>
        )}
      </div>
      <div className='manage-users__body'>
        <div className='manage-users__table-container'>
          <table className='manage-users__table'>
            <thead>
              <tr>
                <th
                  className='col-user'
                  onClick={onChangeSort(COLUMN_TYPES.NAME)}>
                  <span className='col-label'>Name/Email</span>
                  {renderArrow(COLUMN_TYPES.NAME)}
                </th>
                <th
                  className='col-access'
                  onClick={onChangeSort(COLUMN_TYPES.ACCESS)}>
                  <span className='col-label'>Access Level</span>
                  {renderArrow(COLUMN_TYPES.ACCESS)}
                </th>
                <th onClick={onChangeSort(COLUMN_TYPES.DATE)}>
                  <span className='col-label'>Last Login Date</span>
                  {renderArrow(COLUMN_TYPES.DATE)}
                </th>
                {!isUser && (
                  <th className='col-actions'>
                    <span className='col-label'>Actions</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {sortMembers.map(({ id, user, role }) => (
                <React.Fragment key={`user-table-item-${id}`}>
                  <tr>
                    <td className='col-user'>
                      <div className='user'>
                        <UserAvatar width={50} height={50} user={user} />
                        <div className='user-info'>
                          {user.id === userId ? (
                            'You'
                          ) : (
                            <UserEditableName user={user} isEditing={false} />
                          )}

                          <p className='user-email'>{user.email}</p>
                        </div>
                        <button
                          className={clsx('collapse-button', {
                            'collapse-button--open': openedRows.includes(
                              user.id
                            ),
                          })}
                          onClick={onOpenRow(user.id)}>
                          <AngleDown />
                        </button>
                      </div>
                    </td>
                    <td className='col-role'>
                      <UaDropdown
                        dropdownVariant='light'
                        buttonContent={role.name || '\u2014'}
                        withIcon={false}
                        disabled={
                          !user.is_active || user.id === userId || isUser
                        }>
                        {roles
                          .filter(({ slug }) => slug !== ROLES.OWNER)
                          .map((item) => (
                            <Dropdown.Item
                              key={item.slug}
                              eventKey={item.id}
                              onSelect={onChangeRole(user.id)}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                      </UaDropdown>
                    </td>
                    <td className='col-date'>
                      {dateFormat(user.updated_at, 'full-year')}
                    </td>
                    {!isUser && (
                      <td className='col-actions'>
                        {user.id !== userId && role.slug !== ROLES.OWNER && (
                          <>
                            {user.is_active && (
                              <Button
                                onClick={onResetPassword(user.id)}
                                disabled={isResetDisabled[user.id]}
                                className='manage-users__resend-password'>
                                Reset Password
                              </Button>
                            )}
                            <button
                              className='manage-users__action-button manage-users__action-button--delete'
                              onClick={() => setShowConfirm(user.id)}>
                              <Trash fill='#FB404B' />
                            </button>
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                  <tr
                    className={clsx('manage-users__mobile-row', {
                      'manage-users__mobile-row--open': openedRows.includes(
                        user.id
                      ),
                    })}>
                    <td colSpan='1'>
                      <div className='manage-users__mobile-info'>
                        <div className='user-info-col'>
                          <p className='user-info-col__title'>Access Level</p>
                          <div className='user-info-col__data'>
                            <UaDropdown
                              dropdownVariant='light'
                              buttonContent={role.name || '\u2014'}
                              withIcon={false}
                              disabled={!user.is_active}>
                              {roles
                                .filter(({ id }) => id !== 1)
                                .map((item) => (
                                  <Dropdown.Item
                                    key={item.slug}
                                    eventKey={item.id}
                                    onSelect={onChangeRole(user.id)}>
                                    {item.name}
                                  </Dropdown.Item>
                                ))}
                            </UaDropdown>
                          </div>
                        </div>
                        <div className='user-info-col'>
                          <div className='user-info-col__data'>
                            <p className='user-info-col__title'>
                              Last Login Date
                            </p>
                            <p className='user-info-col__data'>
                              {dateFormat(user.updated_at, 'full-year')}
                            </p>
                          </div>
                        </div>
                        <div className='user-info-col user-info-col--actions'>
                          <Button
                            onClick={onResetPassword(user.id)}
                            disabled={isResetDisabled}
                            className='manage-users__resend-password'
                            variant='outline-secondary'>
                            Reset Password
                          </Button>
                          <Button
                            variant='outline-danger'
                            className='manage-users__delete-user'
                            onClick={() => setShowConfirm(user.id)}>
                            Delete User
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <UaConfirm
        show={!!showConfirm}
        onHide={() => setShowConfirm(null)}
        onOk={onUserDelete}
        okLabel='Yes'
        cancelLabel='No'
        message='Are you sure you want to delete this user?'
        notification={{
          text: 'We successfully deleted 1 item',
        }}
      />
    </div>
  );
};

export default ManageUsers;
