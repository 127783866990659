import { object, string, ref } from 'yup';

const EditProfileSchema = object().shape({
  email: string()
    .email('Invalid email address')
    .required('This field is required'),
  firstname: string().required('This field is required'),
  lastname: string().required('This field is required'),
  old_password: string().when('password', {
    is: (password) => password,
    then: string().required('Field is required'),
  }),
  password: string()
    .matches(/\d/g, 'Password should contains at least one number')
    .matches(/[A-Z]/g, 'Password should contains at least one capital letter')
    .matches(/\S/g, 'Password should not contains spaces')
    .min(8, 'Password should contain from 8 to 20 characters'),
  password_confirm: string().when('password', {
    is: (password) => password,
    then: string().oneOf(
      [ref('password'), null],
      'Passwords don’t match'
    ).required('Field is required'),
  }),
});

export default EditProfileSchema;
