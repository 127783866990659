import { all, fork } from 'redux-saga/effects';
// sagas
import authorization from './auth';
import signUp from './signUp';
import signIn from './signIn';
import requestResetPassword from './requestResetPassword';
import resetPassword from './resetPassword';
import logout from './logout';
import getProfile from './getProfile';
import editProfile from './editProfile';
import uploadProfilePhoto from './uploadProfilePhoto';
import verifyEmail from './verifyEmail';
import deleteProfilePhoto from './deleteProfilePhoto';
import confirmEmail from './confirmEmail';

export default function* auth() {
  yield all([
    fork(authorization),
    fork(signUp),
    fork(signIn),
    fork(requestResetPassword),
    fork(resetPassword),
    fork(logout),
    fork(getProfile),
    fork(editProfile),
    fork(uploadProfilePhoto),
    fork(verifyEmail),
    fork(confirmEmail),
    fork(deleteProfilePhoto),
  ]);
}
