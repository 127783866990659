import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import './dashboard.styles.scss';

import BoardsList from 'components/dashboard/boards-list/boards-list.component';
import CreateBoardButton from 'components/dashboard/create-board/create-board.component';
import PersonalDashboard from 'components/dashboard/personal-dashboard/personal-dashboard.component';
import CreateAccountModal from 'components/create-account-modal/create-account-modal.component';

import { draggable } from 'utils/draggable-sort';
import { ORDER_TYPES } from 'settings';
import useFuse from 'hooks/useFuse';

import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';

import {
  getActiveBoards,
  getFavoriteBoards,
  getBoardsSearch,
  getBoards,
} from 'store/boards/selectors';
import {
  getAccounts,
  getIsSubscribe,
} from 'store/accounts/selectors';
import {
  getDashboardLoading,
  isPersonalDashboardSelector,
} from 'store/dashboards/selectors';
import { dndBoardDashboard } from 'store/dashboards/actions';
import PageLoader from 'components/page-loader/page-loader.component';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const boards = useSelector(getActiveBoards);
  const allBoards = useSelector(getBoards);
  const starredBoards = useSelector(getFavoriteBoards);
  const boardsSearch = useSelector(getBoardsSearch);
  const isSubscribe = useSelector(getIsSubscribe);
  const isPersonalDashboard = useSelector(isPersonalDashboardSelector);
  const accounts = useSelector(getAccounts);
  const isDashboardLoading = useSelector(getDashboardLoading);
  const [isAccountsEmpty, setIsAccountsEmpty] = useState(false);

  const boardsSearchResults = useFuse(boards, boardsSearch, {
    threshold: 0.05,
    ignoreLocation: true,
    keys: ['name'],
  });
  const starredSearchResults = useFuse(starredBoards, boardsSearch, {
    threshold: 0.05,
    ignoreLocation: true,
    keys: ['name'],
  });

  useEffect(() => {
    if (accounts && !accounts.length) setIsAccountsEmpty(true);
  }, [accounts]);

  const closeAccountsEmptyModal = () => setIsAccountsEmpty(false);

  const handleDragAllBoards = (type) => (dragResult) => {
    const result = draggable(dragResult);
    if (!result) return;

    const { from, to } = result;
    dispatch(
      dndBoardDashboard({
        from,
        to,
        type,
        boardId: dragResult.draggableId,
      })
    );
  };

  const renderBoards = () => (
    <>
      <div className='section-content'>
        <h3 className='content-title'>Starred Boards</h3>
        <DragDropContext onDragEnd={handleDragAllBoards(ORDER_TYPES.FAVORITES)}>
          <BoardsList
            items={starredSearchResults}
            disabled={!!boardsSearch || !isSubscribe}
            droppableId='starredBoards'
          />
        </DragDropContext>
      </div>
      <div className='section-content'>
        <h3 className='content-title'>All Boards</h3>
        <DragDropContext onDragEnd={handleDragAllBoards(ORDER_TYPES.ALL)}>
          <BoardsList
            items={boardsSearchResults}
            disabled={!!boardsSearch || !isSubscribe}
            droppableId='allBoards'
          />
        </DragDropContext>
      </div>

      <>
        <CreateBoardButton
          title='Create a New Board'
          totalBoards={allBoards.length}
          label={
            <div className='button-label'>
              <PlusIcon /> Add a New Board
            </div>
          }
        />
      </>
    </>
  );

  if (isDashboardLoading)
    return <PageLoader classname='dashboard-page-loader' />;

  return (
    <div className='page dashboard-page'>
      <section className='section'>

        <div className='dashboard-page-container'>
          <div className='dashboard-page-container__item'>
            <h2 className='section__title'>Boards</h2>

            {isPersonalDashboard ? (
              <PersonalDashboard
                boards={boardsSearchResults}
                accounts={accounts}
              />
            ) : (
              renderBoards()
            )}
          </div>
        </div>
      </section>
      <CreateAccountModal
        show={isAccountsEmpty}
        onClose={closeAccountsEmptyModal}
      />
    </div>
  );
};

export default DashboardPage;
