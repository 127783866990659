import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';

import './user-avatar.styles.scss';

import useUserPhoto from 'hooks/useUserPhoto';

const colorClass = (id) => {
  const classes = [
    'user-avatar--blue',
    'user-avatar--dark-blue',
    'user-avatar--orange',
    'user-avatar--green',
  ];

  return classes[
    id < classes.length
      ? (id + classes.length) % classes.length
      : (id + classes.length) % classes.length
  ];
};

const UserAvatar = ({ user, className = '', width = 40, height = 40 }) => {
  const { id, firstname, lastname, profile_photo_path } = user;
  const [userPhoto, isLoading] = useUserPhoto(id, profile_photo_path);
  const nameInitials = useMemo(
    () => {
      if (firstname && lastname) {
        return `${firstname?.charAt(0).toUpperCase()}${lastname
          ?.charAt(0)
          .toUpperCase()}`;
      }
      return 'UN';
    },
    [firstname, lastname]
  );

  return (
    <div
      className={`user-avatar ${colorClass(id)} ${className}`}
      style={{ width: `${width}px`, height: `${height}px` }}>
      {profile_photo_path ? (
        isLoading ? (
          <div className='user-avatar__loading'>
            <Spinner size='sm' animation='border' />
          </div>
        ) : (
          <img
            className='user-avatar__image'
            src={userPhoto}
            alt='user avatar'
          />
        )
      ) : (
        <span className='user-avatar__name'>{nameInitials}</span>
      )}
    </div>
  );
};

export default UserAvatar;
