import { forwardRef, useImperativeHandle, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../utils/routes';
import UaModal from '../components/common/ua-modal/ua-modal.component';

export const LimitAddBoardsModal = forwardRef((props, ref) => {
  const history = useHistory();
  const [showLimitModal, setShowLimitModal] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      onOpenModal,
    };
  });

  function onOpenModal() {
    setShowLimitModal(true);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    history.push(`${routes.settings}?tab=billing`);
    setShowLimitModal(false);
  };

  const onHide = () => {
    setShowLimitModal(false);
  };

  return (
    <UaModal
      show={showLimitModal}
      onHide={onHide}
      title='Add a New Board'
      onSubmit={onSubmit}
      okLabel='Go to settings'>
      Please update your subscription to add more boards
    </UaModal>
  );
});
