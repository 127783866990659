import { AbilityBuilder, Ability } from '@casl/ability';
import { ROLES } from 'settings/index';

export default function defineAbilityFor(role) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (role.slug === ROLES.OWNER || role.slug === ROLES.ADMIN) {
    can('manage', 'all');
  } else {
    can('read', 'all');
  }

  cannot('delete', 'Post', { published: true });

  return build();
}
