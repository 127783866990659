import React from 'react';

import './create-account-modal.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createAccount } from 'store/accounts/actions';

const CreateAccountModal = ({ show, onClose }) => {
  const dispatch = useDispatch();

  const onAccountCreate = () => {
    dispatch(createAccount());
    onClose();
  };

  return (
    <UaModal
      dialogClassName='subscription-over-modal'
      show={show}
      onHide={onClose}
      withoutClose
      noFooter={true}>
      <h3>Your account list are empty</h3>
      Please create new account to continue using Unmanage app.
      <Button onClick={onAccountCreate}>
        Create account
      </Button>
    </UaModal>
  );
};

export default CreateAccountModal;
