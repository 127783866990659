import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

// Reducers
import reducers from './reducers';
// Sagas
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = composeWithDevTools(
	applyMiddleware(
		sagaMiddleware,
		LogRocket.reduxMiddleware(),
	)
);

const store = createStore(reducers, middleware);

sagaMiddleware.run(sagas);

export default store;
