import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getBoard } from '../../../store/boards/selectors';
import UATooltip from '../../common/ua-tooltip/ua-tooltip.components';
import { ReactComponent as Share } from '../../../assets/images/icons/share.svg';
import ShareBoardModal from '../share-board-modal/share-board-modal.component';

const ShareBoard = () => {
  const board = useSelector(getBoard);
  const [isShow, setIsShow] = useState(false);

  const onClose = () => {
    setIsShow(false);
  };

  const onOpen = () => {
    setIsShow(true);
  };

  return (
    <>
      <UATooltip label='Share'>
        <button type='button' className='toolbar-btn' onClick={onOpen}>
          <div>
            <Share />
          </div>
        </button>
      </UATooltip>

      <ShareBoardModal show={isShow} board={board} onClose={onClose} />
    </>
  );
};

export default ShareBoard;
