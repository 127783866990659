import React from 'react';
import { Form } from 'react-bootstrap';

import './payment-details.styles.scss';

import UaInput from 'components/common/ua-input/ua-input.component';

const OPTIONS = [
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
  },
  {
    value: 'US',
    label: 'USA',
  },
];

const PaymentDetails = ({ onChange, values, handleBlur, touched, errors }) => {
  return (
    <div className='payment-details billing__card'>
      <h3 className='payment-details__title billing__title'>
        Enter your payments details
      </h3>
      <h4 className='payment-details__subtitle billing__subtitle'>
        Billing Information
      </h4>

      <div className='payment-details__container'>
        <UaInput
          onChange={onChange}
          id='name'
          name='name'
          label='customer name'
          showError={true}
          value={values.name}
          onBlur={handleBlur}
          error={touched.name && errors.name}
          valid={touched.name && !errors.name}
        />
        <UaInput
          onChange={onChange}
          name='email'
          type='email'
          label='billing email'
          showError={true}
          value={values.email}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          valid={touched.email && !errors.email}
        />
        <UaInput
          onChange={onChange}
          id='address'
          name='address'
          label='address'
          showError={true}
          value={values.address}
          onBlur={handleBlur}
          error={touched.address && errors.address}
          valid={touched.address && !errors.address}
        />

        <Form.Group
          controlId='country'
          name='country'
          className='ua-input'
          onChange={onChange}>
          <Form.Label className='ua-input__label'>Country</Form.Label>
          <Form.Control
            as='select'
            defaultValue='US'
            className='ua-input__control'>
            {OPTIONS.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <UaInput
          onChange={onChange}
          id='city'
          name='city'
          label='city'
          showError={true}
          value={values.city}
          onBlur={handleBlur}
          error={touched.city && errors.city}
          valid={touched.city && !errors.city}
        />
        <div className='payment-details__row'>
          <UaInput
            onChange={onChange}
            id='region'
            name='region'
            label='state / province / region'
            showError={true}
            value={values.region}
            onBlur={handleBlur}
            error={touched.region && errors.region}
            valid={touched.region && !errors.region}
          />
          <UaInput
            onChange={onChange}
            id='zip'
            name='zip'
            type='number'
            label='zip / postal code'
            showError={true}
            value={values.zip}
            onBlur={handleBlur}
            error={touched.zip && errors.zip}
            valid={touched.zip && !errors.zip}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
