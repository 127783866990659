import {
  updateSocketState,
  setAccountSocket,
  setBoardSocket,
  setProjectSocket, setUserSocket,
} from './actions';

const INITIAL_STATE = {
  isSocketConnected: false,
  userSocket: null,
  accountSocket: null,
  boardSocket: null,
  projectSocket: null,
};

const socketReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case updateSocketState.SUCCESS:
      return {
        ...state,
        isSocketConnected: payload,
      };

    case setAccountSocket.TRIGGER:
      return {
        ...state,
        accountSocket: payload,
      };

    case setUserSocket.TRIGGER:
      return {
        ...state,
        userSocket: payload,
      };

    case setBoardSocket.TRIGGER:
      return {
        ...state,
        boardSocket: payload,
      };

    case setProjectSocket.TRIGGER:
      return {
        ...state,
        projectSocket: payload,
      };

    default:
      return state;
  }
};

export default socketReducer;
