import { put, takeLatest, call } from 'redux-saga/effects';

import { getStatuses as getStatusesAction } from '../actions';

import { getStatuses as getStatusesApi } from '../api';

export function* getSaga({ payload }) {
  try {
    yield put(getStatusesAction.request());

    const statuses = yield call(getStatusesApi, payload);

    yield put(getStatusesAction.success(statuses));
  } catch (error) {
    console.error(error);
    yield put(getStatusesAction.failure(error.response));
  } finally {
    yield put(getStatusesAction.fulfill());
  }
}

export default function* getStatuses() {
  yield takeLatest(getStatusesAction.TRIGGER, getSaga);
}
