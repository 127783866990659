import { apiGET, apiPOST, apiPATCH, apiDELETE } from '../services/api';
import { getProjectQueryParams } from 'utils/getProjectQueryParams';

export const getProjects = (props) => {
  const params = getProjectQueryParams(props);

  return apiGET({
    url: `/boards/${props.id}/projects`,
    params,
  });
};

export const getGuestProjects = (props) => {
  const params = getProjectQueryParams(props);

  return apiGET({
    url: `/boards/uuid/${props.id}/projects`,
    params,
  });
};

export const addProject = ({ id, name, status_id, socketId }) =>
  apiPOST({
    url: `/boards/${id}/projects`,
    data: {
      name,
      status_id,
    },
    headers: {
      'socket-client-id': socketId
    }

  });

export const editProject = ({ id, data, socketId }) =>
  apiPATCH({
    url: `/projects/${id}`,
    data,
    headers: {
      'socket-client-id': socketId
    }
  });

export const getProjectUpdates = (id) =>
  apiGET({
    url: `/projects/${id}/updates`,
  });

export const addProjectUpdate = ({ id, status, socketId }) =>
  apiPOST({
    url: `/projects/${id}/updates`,
    data: {
      status,
    },
    headers: {
      'socket-client-id': socketId
    }

  });

export const editProjectUpdate = ({ id, status, socketId }) =>
  apiPATCH({
    url: `/updates/${id}`,
    data: {
      status,
    },
    headers: {
      'socket-client-id': socketId
    }
  });

export const addMember = ({ projectId, memberId, socketId }) =>
  apiPOST({
    url: `/projects/${projectId}/members/${memberId}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const deleteMember = ({ projectId, memberId, socketId }) =>
  apiDELETE({
    url: `/projects/${projectId}/members/${memberId}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const getPriorities = () =>
  apiGET({
    url: '/priorities',
  });

export const getTags = (id) =>
  apiGET({
    url: `/accounts/${id}/tags`,
  });

export const archiveProjects = ({ids, socketId}) =>
  apiPOST({
    url: `/projects/${ids.join(',')}/archive`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const unarchiveProjects = ({ids, socketId}) =>
  apiDELETE({
    url: `/projects/${ids.join(',')}/archive`,
    headers: {
      'socket-client-id': socketId
    }

  });

export const getStatuses = () =>
  apiGET({
    url: '/project-statuses',
  });

export const addTask = ({ projectId, title, socketId }) =>
  apiPOST({
    url: `/projects/${projectId}/tasks`,
    data: { title },
    headers: {
      'socket-client-id': socketId
    }
  });

export const editTask = ({ projectId, taskId, data, socketId }) =>
  apiPATCH({
    url: `/projects/${projectId}/tasks/${taskId}`,
    data,
    headers: {
      'socket-client-id': socketId
    }
  });

export const deleteTask = ({ projectId, taskId, socketId }) =>
  apiDELETE({
    url: `/projects/${projectId}/tasks/${taskId}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const deleteFile = ({ projectId, fileId }) =>
  apiDELETE({
    url: `/projects/${projectId}/files/${fileId}`,
  });

export const uploadFile = ({ projectId, file }) => {
  const fileFormData = new FormData();
  fileFormData.append('file', file);

  return apiPOST({
    url: `/projects/${projectId}/files`,
    data: fileFormData,
  });
};

export const deleteProjects = ({ids, socketId}) =>
  apiDELETE({
    url: `/projects/${ids.join(',')}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const getProjectsCrossedTags = ({ id, boardId }) =>
  apiGET({
    url: `/projects/${id}/crossed-tags?to_board_id=${boardId}`,
  });

export const moveProjects = ({ ids, boardId, tags, socketId }) =>
  apiPATCH({
    url: `/projects/${ids}/move-projects`,
    data: {
      board_id: boardId,
      tags: tags,
    },
    headers: {
      'socket-client-id': socketId
    }
  });

export const copyProjects = ({ ids, boardId, socketId }) =>
  apiPOST({
    url: `/boards/${boardId}/projects?source=/projects/${ids.join(',')}`,
    headers: {
      'socket-client-id': socketId
    }
  });
