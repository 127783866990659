import { select, takeEvery } from 'redux-saga/effects';

import { getCurrentAccount } from '../../accounts/actions';
import { getBoard } from '../../boards/actions';
import { getProjectSocket } from '../selectors';
import { getBoard as boardSelector } from '../../boards/selectors';
import { getCurrentAccount as currentAccountSelector } from '../../accounts/selectors';

function* flow() {
  const socket = yield select(getProjectSocket);
  const board = yield select(boardSelector);
  const account = yield select(currentAccountSelector);

  if (account?.id && board?.id && socket) {
    socket.emit('leaveRoom', `${account.id}-${board.id}`);
    socket.emit('joinRoom', `${account.id}-${board.id}`);
  }
}

export default function* rootSaga() {
  yield takeEvery([getCurrentAccount.SUCCESS, getBoard.SUCCESS], flow);
}
