import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { fork, take, call, put, cancel, select } from 'redux-saga/effects';

import { API_BASE_URL } from 'settings';
import { logout } from 'store/auth/actions';
import {
  addTransaction,
  updateTransaction,
  unsubscribePlan,
  changePlan, deleteTransaction,
} from 'store/payment/actions';

import { setAccountSocket } from '../actions';
import { getCurrentAccount as currentAccountSelector } from 'store/accounts/selectors';
import { getCurrentAccount } from 'store/accounts/actions';
import { addClient, deleteClient, editClient } from '../../clients/actions';

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('joinedRoom', () => {
      socket.on('transaction', (res) => {
        switch (res.action) {
          case 'created':
            emit(addTransaction.success(res.data));
            break;

          case 'updated':
            emit(updateTransaction.success(res.data));
            break;

          case 'deleted':
            emit(deleteTransaction.success(res.data));
            break;

          default:
            break;
        }
      });

      socket.on('subscription', (res) => {
        switch (res.action) {
          case 'updated':
            emit(changePlan.success(res.data));
            break;

          case 'deleted':
            emit(unsubscribePlan.success(res.data));
            break;

          default:
            break;
        }
      });

      socket.on('clients', (res) => {
        const currentSocketId = socket.id;
        if (res.data?.sender_socket_id?.includes(currentSocketId)) return;

        switch (res.action) {
          case 'created':
            emit(addClient.success(res.data));
            break;

          case 'updated':
            emit(editClient.success(res.data));
            break;

          case 'deleted':
            emit(deleteClient.success(res.data.id));
            break;

          default:
            break;
        }
      });
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* handleIO(socket) {
  yield fork(read, socket);
}

function* flow() {
  while (true) {
    yield take(getCurrentAccount.SUCCESS);
    const socket = yield call(io, `${API_BASE_URL}/accounts`);
    yield put(setAccountSocket(socket));
    const { id } = yield select(currentAccountSelector);
    socket.emit('joinRoom', id);

    const task = yield fork(handleIO, socket);
    yield take(logout.SUCCESS);
    socket.emit('leaveRoom', id);
    yield cancel(task);
    socket.disconnect();
  }
}

export default function* rootSaga() {
  yield fork(flow);
}
