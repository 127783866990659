import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { createAccount as action, switchAccount } from '../actions';
// api
import { createAccount as api } from '../api';
import { getProfile } from 'store/auth/actions';
import { toast } from 'react-toastify';

function* getSaga() {
  try {
    yield put(action.request());

    const response = yield call(api);
    yield put(getProfile());

    toast('Successful create personal account', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      type: 'success',
    });

    yield put(switchAccount(response.id));

    yield put(action.success(response));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* createAccount() {
  yield takeLatest(action.TRIGGER, getSaga);
}
