import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { changePosition as action } from '../actions';
// api
import { changePosition as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success({ response, id: payload.dashboard_id }));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* changePosition() {
  yield takeLatest(action.TRIGGER, getSaga);
}
