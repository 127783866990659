import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import './add-client.styles.scss';

import { getClientsLoading } from 'store/clients/selectors';
import UaButton from 'components/common/ua-button/ua-button.component';
import { useFormik } from 'formik';
import AddClientSchema from 'components/clients-modal/add-client/add-client.validate';
import UaInput from 'components/common/ua-input/ua-input.component';

const AddClient = ({ onSubmit, onCancel, defaultName = '', submitLabel = 'Add client' }) => {
  const ref = useRef(null);
  const { addLoading } = useSelector(getClientsLoading);

  const formik = useFormik({
    initialValues: {
      name: defaultName,
    },
    validationSchema: AddClientSchema,
    validateOnMount: false,
    onSubmit,
  });

  useEffect(() => {
    ref.current.focus();
    return () => {
      formik.resetForm();
    };
  }, []);

  return (
    <form className='add-client-form' onSubmit={formik.handleSubmit}>
      <UaInput
        ref={ref}
        type='text'
        name='name'
        label='Client name'
        placeholder='Enter client name'
        value={formik.values.name}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        showError
        error={formik.touched.name && formik.errors.name}
        valid={formik.touched.name && !formik.errors.name}
      />

      <div className='add-client-form-controls'>
        <UaButton type='submit' isLoading={addLoading}>
          {submitLabel}
        </UaButton>

        <UaButton variant='outline-secondary' type='button' onClick={onCancel}>
          Cancel
        </UaButton>
      </div>
    </form>
  );
};

export default AddClient;
