import {put, takeLatest, call, select} from 'redux-saga/effects';

import {addBoardMember as action} from '../actions';

import {addBoardMember as api} from '../api';
import {getBoardSocket} from 'store/socket/selectors';

export function* getSaga({payload}) {
  try {
    yield put(action.request());
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    const response = yield call(api, payload);

    yield put(action.success({ board_id: payload.board_id, user: response }));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* addBoardMember() {
  yield takeLatest(action.TRIGGER, getSaga);
}
