import {put, takeLatest, call, select} from 'redux-saga/effects';
// actions
import { downloadDataRequest as action } from '../actions';
// api
import { downloadDataRequest as api } from '../api';
import { toast } from 'react-toastify';
import {getUserSocket} from 'store/socket/selectors';

function* getSaga({ payload }) {
  try {
    const socket = yield select(getUserSocket);

    yield put(action.request());
    yield call(api, { accountId: payload, socketId: socket.id });
    yield put(action.success(payload));
  } catch (error) {
    console.error(error);
    if (error.response.data.statusCode === 404) {
      toast('Nothing to download', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error',
      });
    }
    yield put(action.failure({ error: error.response, account_id: payload }));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* downloadDataRequest() {
  yield takeLatest(action.TRIGGER, getSaga);
}
