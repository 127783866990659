import {put, takeLatest, call, select} from 'redux-saga/effects';
// actions
import { deleteBoard as action } from '../actions';
// api
import { deleteBoard as api } from '../api';
import {getBoardSocket} from 'store/socket/selectors';

export function* deleteSaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    const socketId = socket.id;

    yield put(action.request());
    yield call(api, {id: payload, socketId});
    yield put(action.success({ payload }));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteBoard() {
  yield takeLatest(action.TRIGGER, deleteSaga);
}
