import React, { useMemo, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';

import './board.styles.scss';

import routes from 'utils/routes';
import { BOARD_VIEW, EnumBoardPopups, STATUSES, TABLET_QUERY } from 'settings';
import useQuery from 'hooks/useQuery';

import PageTitle from 'components/page-title/page-title.component';
import AsideContainer from 'components/board/aside-container/aside-container.component';
import ProjectCard from 'components/project-card/project-card.component';
import ModalContainer from 'components/modal-container/modal-container.component';
import FiltersCard from 'components/filters/filters-card/filters-card.component';
import BoardSettingCard from 'components/board-setting-card/board-setting-card.component';
import BoardHeader from 'components/board/board-header/board-header.component';
import BoardKanbanView from 'components/board/board-kanban-view/board-kanban-view.component';
import BoardListView from 'components/board/board-list-view/board-list-view.component';
import { BoardContext } from 'context/board-context/board-context';

import { getBoard, getBoardLoading } from 'store/boards/selectors';
import {
  getProjects as projectsSelector,
  isProjectsListLoading,
} from 'store/projects/selectors';
import { isAuth } from 'store/auth/selectors';
import { getIsSubscribe } from 'store/accounts/selectors';
import ProjectMoveModal from 'components/project/project-move-modal/project-move-modal.component';
import ProjectCopyModal from 'components/project/project-copy-modal/project-copy-modal.component';
import ProjectDeleteModal from 'components/project/project-delete-modal/project-delete-modal.component';
import ProjectArchiveModal from 'components/project/project-archive-modal/project-archive-modal.component';
import { getGuestProjects, getProjects } from 'store/projects/actions';
import PageLoader from 'components/page-loader/page-loader.component';
import useDevice from 'hooks/useDevice';
import ListControls from 'components/board/list-controls/list-controls.component';
import UaBottomMenu from 'components/common/ua-bottom-menu/ua-bottom-menu.component';
import KanbanControls from 'components/board/kanban-controls/kanban-controls.component';
import { usePrevious } from 'hooks/usePrevious';
import { arrayChecker } from 'utils/arrayChecker';

const BoardComponent = () => {
  const dispatch = useDispatch();
  const board = useSelector(getBoard);
  const isBoardLoading = useSelector(getBoardLoading);
  const isProjectLoading = useSelector(isProjectsListLoading);
  const projects = useSelector(projectsSelector);
  const authState = useSelector(isAuth);
  const isSubscribe = useSelector(getIsSubscribe);

  const {
    isGuest,
    queryValue,
    isCardShow,
    onCloseBoardCard,
    listShowOption,
    projectCardId,
    projectFilter,
    tableSortingShift,
    tableSortingList,
    setIsCardShow,
    setProjectCardId,
  } = useContext(BoardContext);

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const view = query.get('view');
  const { id } = useParams();

  const isKanbanView = view === BOARD_VIEW.KANBAN;
  const isListView = view === BOARD_VIEW.LIST;

  const isTablet = useDevice(TABLET_QUERY);

  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (!isBoardLoading) isFirstLoad.current = true;
  }, [id, isBoardLoading]);

  useEffect(() => {
    if (!view) {
      history.replace({
        pathname: location.pathname,
        search: `?view=${BOARD_VIEW.LIST}`,
      });
    }
  }, [view]);

  const activeProjectIds = useMemo(
    () =>
      projects.filter(({ is_archived }) => !is_archived).map(({ id }) => id),
    [projects]
  );

  const previousActiveProjectsIds = usePrevious(activeProjectIds, []);

  useEffect(() => {
    if (
      activeProjectIds.length &&
      !arrayChecker(previousActiveProjectsIds, activeProjectIds) &&
      !isTablet &&
      isListView &&
      !activeProjectIds.includes(projectCardId) &&
      (isCardShow === null || isCardShow === EnumBoardPopups.project) &&
      isFirstLoad.current
    ) {
      setIsCardShow(EnumBoardPopups.project);
      setProjectCardId(activeProjectIds[0]);
      isFirstLoad.current = false;
    }
    if (!activeProjectIds.length && isCardShow === EnumBoardPopups.project) {
      setIsCardShow(null);
      setProjectCardId(null);
    }
  }, [activeProjectIds, tableSortingList]);

  const getProjectsAction = isGuest ? getGuestProjects : getProjects;
  const boardId = isGuest ? board?.uuid : board?.id;

  const uncompletedStatuses = useMemo(
    () =>
      board?.statuses
        ?.filter(({ slug }) => slug !== STATUSES.COMPLETED)
        .map(({ id }) => id),
    [board?.statuses]
  );

  useEffect(() => {
    if (board?.id) {
      dispatch(
        getProjectsAction({
          id: boardId,
          sort: tableSortingList,
          isArchived: listShowOption.archivedProjects,
          statuses: !listShowOption.completedProjects && uncompletedStatuses,
          search: queryValue,
          filters: projectFilter,
          shift: tableSortingShift,
        })
      );
    }
  }, [
    boardId,
    authState,
    listShowOption.archivedProjects,
    listShowOption.completedProjects,
    projectFilter,
    queryValue,
    tableSortingShift,
    tableSortingList,
  ]);

  const project = useMemo(
    () =>
      Array.isArray(projects)
        ? projects.find((item) => item.id === projectCardId)
        : null,
    [projects, projectCardId]
  );

  const showModal = useMemo(
    () => isCardShow && (!listShowOption.projectCard || isTablet),
    [isCardShow, listShowOption, isTablet]
  );

  const showAside = useMemo(
    () =>
      listShowOption.projectCard &&
      ((isCardShow === EnumBoardPopups.project && !isProjectLoading) ||
        isCardShow === EnumBoardPopups.filter ||
        isCardShow === EnumBoardPopups.setting) &&
      (isCardShow === EnumBoardPopups.project || !isTablet),
    [isCardShow, listShowOption, isTablet, isProjectLoading]
  );

  if (!isSubscribe) return <Redirect to={routes.dashboard} />;

  if (isBoardLoading) return <PageLoader />;

  return (
    <div className='page board-page'>

      <PageTitle
        title={board?.name}
      />

      <BoardHeader />

      <div
        className={clsx('page-content', {
          'page-content--aside-open': showAside,
        })}>
        {isKanbanView ? <BoardKanbanView /> : <BoardListView />}

        <AsideContainer open={showAside} onClose={onCloseBoardCard}>
          {isCardShow === EnumBoardPopups.project && (
            <ProjectCard project={project} editing={!isGuest} />
          )}
          {isCardShow === EnumBoardPopups.filter && (
            <FiltersCard editing={!isGuest} />
          )}
          {isCardShow === EnumBoardPopups.setting && board && (
            <BoardSettingCard board={board} editing={!isGuest} />
          )}
        </AsideContainer>
      </div>

      <ModalContainer show={showModal} onHide={onCloseBoardCard}>
        {isCardShow === EnumBoardPopups.project && (
          <ProjectCard project={project} editing={!isGuest} />
        )}
        {isCardShow === EnumBoardPopups.filter && (
          <FiltersCard editing={!isGuest} />
        )}
        {isCardShow === EnumBoardPopups.setting && board && (
          <BoardSettingCard board={board} editing={!isGuest} />
        )}
      </ModalContainer>

      <UaBottomMenu className={showAside ? 'hide' : ''}>
        <div className='toolbar-wrapper toolbar-mobile'>
          {isKanbanView ? <KanbanControls /> : <ListControls />}
        </div>
      </UaBottomMenu>

      <ProjectMoveModal />
      <ProjectCopyModal />
      <ProjectDeleteModal projects={projects} />
      <ProjectArchiveModal projects={projects} />
    </div>
  );
};

export default BoardComponent;
