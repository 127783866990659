import { put, takeEvery, call, select } from 'redux-saga/effects';

import { deleteCard as action, updateCard } from '../actions';

import { deleteCard as api } from '../api';

import { getCardsState } from '../selectors';

function* addSaga({ payload }) {
  try {
    yield put(action.request());

    const { cards } = yield select(getCardsState);
    const index = yield cards.findIndex(({ id }) => id === payload);

    if (cards[index].is_default) {
      if (cards.length - 1 > index) {
        yield put(
          updateCard({ id: cards[index + 1].id, data: { is_default: true } })
        );
      } else if (cards.length > 1) {
        yield put(
          updateCard({ id: cards[index - 1].id, data: { is_default: true } })
        );
      }
    }

    yield call(api, payload);

    yield put(action.success(payload));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteCard() {
  yield takeEvery(action.TRIGGER, addSaga);
}
