import routine from '../services/routine';

export const createCard = routine('CREATE_CARD');
export const getCards = routine('GET_CARDS');
export const updateCard = routine('UPDATE_CARD');
export const deleteCard = routine('DELETE_CARD');

export const getTransactions = routine('GET_TRANSACTIONS');
export const updateTransaction = routine('UPDATE_TRANSACTION');
export const addTransaction = routine('ADD_TRANSACTIONS');
export const deleteTransaction = routine('DELETE_TRANSACTIONS');

export const getPlans = routine('GET_PLANS');
export const subscribePlan = routine('SUBSCRIBE_PLAN');
export const changePlan = routine('CHANGE_PLAN');
export const unsubscribePlan = routine('UNSUBSCRIBE_PLAN');
