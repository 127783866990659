import { put, takeLatest, call, select } from 'redux-saga/effects';
import {
  getBoard,
  getProjectsPositions,
  getProjectsPositionsByStatus,
} from 'store/boards/selectors';

import { addProject as addProjectAction } from '../actions';

import { addProject as addProjectApi } from '../api';

import { editSaga as editBoard } from 'store/boards/sagas/edit';
import { getBoard as getBoardAction } from 'store/boards/actions';
import {getProjectSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(addProjectAction.request());

    const project = yield call(addProjectApi, payload);

    yield put(addProjectAction.success(project));

    const board = yield select(getBoard);
    const projectsPositions = yield select(getProjectsPositions);
    const projectsByStatus = yield select(
      getProjectsPositionsByStatus(project.status_id)
    );

    yield call(editBoard, {
      payload: {
        id: board.id,
        projects_positions: [
          ...projectsPositions,
          {
            order: projectsByStatus.length,
            status_id: project.status_id,
            project_id: project.id,
          },
        ],
      },
    });
    yield put(getBoardAction({ id: project.board_id }));
  } catch (error) {
    console.error(error);
    yield put(addProjectAction.failure(error.response));
  } finally {
    yield put(addProjectAction.fulfill());
  }
}

export default function* add() {
  yield takeLatest(addProjectAction.TRIGGER, addSaga);
}
