import React, { useRef } from 'react';

import './ua-dropdown.styles.scss';

import { Dropdown } from 'react-bootstrap';

import { ReactComponent as CaretIcon } from '../../../assets/images/icons/caret.svg';
import useRootClose from 'react-overlays/esm/useRootClose';

const UaDropdown = ({
  children,
  buttonContent,
  className,
  disabled = false,
  withIcon = true,
  buttonVariant = 'light',
  dropdownVariant = 'default',
  handleRootClose,
  ...rest
}) => {
  const ref = useRef();
  useRootClose(ref, handleRootClose, {
    disabled: !rest.show,
  });

  return (
    <Dropdown
      className={`ua-dropdown ${className || ''}${
        dropdownVariant === 'dark' ? ' ua-dropdown--dark' : ''
      }`}
      {...rest}>
      <Dropdown.Toggle variant={buttonVariant} disabled={disabled}>
        <div className='ua-dropdown__button-content'>{buttonContent}</div>
        {withIcon ? <CaretIcon className='ua-dropdown__caret' /> : null}
      </Dropdown.Toggle>

      <Dropdown.Menu
        ref={ref}
        renderOnMount={true}
        popperConfig={{ strategy: 'fixed' }}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UaDropdown;
