import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { resetPassword as resetPasswordAction } from '../actions';
// api
import { resetPassword as resetPasswordApi } from '../api';
// services
import getErrorMessage from '../../services/getErrorMessages';
import { removeAccessToken } from '../services/tokenService';
import history from '../../../history';
import routes from 'utils/routes';


function* resetPasswordSaga({ payload: userData }) {
  try {
    yield put(resetPasswordAction.request());

    yield call(resetPasswordApi, userData);
    yield call(removeAccessToken);

    yield put(resetPasswordAction.success());
    history.push(`${routes.login}?password-changed=1`);
  } catch (error) {
    console.error(error);
    yield put(resetPasswordAction.failure(getErrorMessage(error.response)));
  } finally {
    yield put(resetPasswordAction.fulfill());
  }
}

export default function* resetPassword() {
  yield takeLatest(resetPasswordAction.TRIGGER, resetPasswordSaga);
}
