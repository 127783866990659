import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useFormik } from 'formik';
import { arrayOf, bool, func, objectOf, shape, string } from 'prop-types';
import noop from 'lodash/noop';

import routes from 'utils/routes';
// images
import eyeIcon from 'assets/images/icons/eye.svg';
// components
import UaInput from 'components/common/ua-input/ua-input.component';
import UaCheckbox from 'components/common/ua-checkbox/ua-checkbox.component';
import UaButton from 'components/common/ua-button/ua-button.component';
// schemas
import SignInSchema from './SignInSchema';
// hooks
import useQuery from 'hooks/useQuery';
import useAlert from 'hooks/useAlert';
import { SUPPORT_EMAIL } from '../../../storage/consts';

const LoginForm = ({ onSubmit, error, isLoading }) => {
  const query = useQuery();
  const successAlertParams = useAlert({
    isShow: query.has('password-changed'),
  });
  const errorAlertParams = useAlert({
    isShow: error.message,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: SignInSchema,
    onSubmit,
  });

  const handleChange = (event) => {
    formik.handleChange(event);
  };

  const handleIconClick = (event) => {
    const nearestInput = event.target.parentNode.querySelector('input');
    if (nearestInput.type === 'password') {
      nearestInput.type = 'text';
    } else {
      nearestInput.type = 'password';
    }
  };

  return (
    <form
      className='form form-auth form-auth--login'
      onSubmit={formik.handleSubmit}>
      <Alert {...successAlertParams} variant='success'>
        Your password has been reset successfully!
      </Alert>
      <Alert {...errorAlertParams} variant='danger'>
        {error.message}
      </Alert>
      <h1 className='form__caption'>Log In</h1>
      <div className='form__controls'>
        <UaInput
          name='email'
          type='email'
          value={formik.values.email}
          placeholder='Enter email'
          label='Email address'
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          showError={true}
          error={
            (formik.touched.email && formik.errors.email) || error.fields.email
          }
          valid={formik.touched.email && !formik.errors.email}
        />
        <UaInput
          id='password'
          name='password'
          type='password'
          value={formik.values.password}
          placeholder='Enter password'
          label='Password'
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          handleIconClick={handleIconClick}
          icon={eyeIcon}
          showError
          error={
            (formik.touched.password && formik.errors.password) ||
            error.fields.password
          }
          valid={formik.touched.password && !formik.errors.password}
          additionalLabel={
            <Link
              to={routes.resetPassword}
              className='form__link form__link--forgot'>
              Forgot password?
            </Link>
          }
        />
        <div className='form__controls-row form__action-row'>
          <UaCheckbox
            id='rememberMe'
            label='Remember me'
            checked={formik.values.rememberMe}
            name='rememberMe'
            handleChange={handleChange}
          />
          <UaButton
            type='submit'
            isLoading={isLoading}
            variant='primary'
            className='form__submit'>
            Log In
          </UaButton>
        </div>
        <Link
          variant='link'
          to={routes.registration}
          className='form__link btn btn-link'>
          Don’t have an account?
        </Link>
        <a href={`mailto:${SUPPORT_EMAIL}`} className='form__link btn btn-link'>
          Questions or issues? <br /> {SUPPORT_EMAIL}
        </a>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: func,
  isLoading: bool,
  error: shape({
    message: string,
    fields: objectOf(arrayOf(string)),
  }),
};

LoginForm.defaultProps = {
  onSubmit: noop,
  isLoading: false,
};

export default LoginForm;
