import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from 'hooks/useQuery';
import {isMobile} from 'react-device-detect';

import { AbilityProvider } from 'context/can';
import { IS_PRODUCTION, MAIN_TITLE, TITLES } from 'settings';

import DashboardLayout from 'layouts/dashboard/dashboard.component';
import AuthLayout from 'layouts/auth/auth.component';

import DashboardPage from 'pages/dashboard/dashboard.component';
import RegistrationPage from 'pages/registration/registration.component';
import LoginPage from 'pages/login/login.component';
import ResetPasswordPage from 'pages/reset-password/reset-password.component';
import BoardPage from 'pages/board/board-page.component';
import BoardGuestPage from 'pages/board-guest/board-guest-page.component';
import SettingsPage from 'pages/settings/settings.component';
import NotFound from 'pages/not-found/not-found.component';
import OwnerAccept from 'pages/owner-accept/owner-accept.component';
import EmailVerificationPage from 'pages/email-verification/email-verification.component';
import EmailConfirmationPage from 'pages/email-confirmation/email-confirmation.component';
import routes from 'utils/routes';

import { getCurrentRole } from 'store/accounts/selectors';
import { auth } from 'store/auth/actions';

import dashboardIcon from 'assets/images/icons/dashboard.svg';
import { AuthGuard } from './AuthGuard';
import { getProfile, isAuth } from './store/auth/selectors';

const App = () => {
  const dispatch = useDispatch();
  const role = useSelector(getCurrentRole);
  const userData = useSelector(getProfile);
  const isUserAuth = useSelector(isAuth);
  const { pathname } = useLocation();
  const query = useQuery();

  useEffect(() => {
    dispatch(auth());
  }, []);

  useEffect(() => {
    if (!IS_PRODUCTION) return;
    if (!window.userGuiding) return;

    if (isUserAuth) {
      window.userGuiding?.identify(userData.id, {
        email: userData.email,
        name: `${userData.firstname} ${userData.lastname}`,
        isMobile,
      });
    } else {
      window.userGuiding?.identify({
        isMobile,
      });
    }
  }, [isUserAuth, userData]);

  const getAuthTitle = useMemo(() => {
    const titleList = {
      [routes.registration]: { title: TITLES.registration },
      [routes.login]: { title: TITLES.login },
      [routes.resetPassword]: { title: TITLES.resetPassword },
      [routes.emailVerification]: { title: TITLES.emailVerification },
      [routes.emailConfirmation]: { title: TITLES.emailConfirmation },
    };

    return titleList[pathname] || '';
  }, [pathname]);

  const getLayoutProps = useMemo(() => {
    if (pathname === routes.dashboard) {
      return {
        icon: dashboardIcon,
        title: TITLES.dashboard,
        withSearch: true,
        withDashboardSwitcher: true,
      };
    }

    if (pathname.includes(routes.board.replace('/:id', ''))) {
      const view = query.get('view');
      return {
        icon: dashboardIcon,
        title: view === 'list' ? TITLES.board : TITLES.kanban,
      };
    }

    if (pathname.includes(routes.settings)) {
      return {
        icon: dashboardIcon,
        title: TITLES.settings,
      };
    }

    if (pathname.includes(routes.boardGuest.replace('/:id', ''))) {
      return {
        icon: dashboardIcon,
        title: TITLES.boardGuest,
      };
    }

    if (pathname.includes('/owner-accept')) {
      return {
        title: TITLES.ownerAccess,
      };
    }

    return {
      title: MAIN_TITLE,
    };
  }, [pathname]);

  return (
    <AbilityProvider role={role}>
      <div className='app'>
        <Switch>
          <Route exact path={routes.boardGuest}>
            <AuthGuard>
              <DashboardLayout {...getLayoutProps}>
                <BoardGuestPage />
              </DashboardLayout>
            </AuthGuard>
          </Route>

          <Route exact path={routes.ownerAccept}>
            <AuthGuard>
              <AuthLayout>
                <OwnerAccept />
              </AuthLayout>
            </AuthGuard>
          </Route>

          <Route exact path={routes.registration}>
            <AuthGuard>
              <AuthLayout {...getAuthTitle}>
                <RegistrationPage />
              </AuthLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.login}>
            <AuthGuard>
              <AuthLayout {...getAuthTitle}>
                <LoginPage />
              </AuthLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.resetPassword}>
            <AuthGuard>
              <AuthLayout {...getAuthTitle}>
                <ResetPasswordPage />
              </AuthLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.emailVerification}>
            <AuthGuard>
              <AuthLayout {...getAuthTitle}>
                <EmailVerificationPage />
              </AuthLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.emailConfirmation}>
            <AuthGuard>
              <AuthLayout {...getAuthTitle}>
                <EmailConfirmationPage />
              </AuthLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.dashboard}>
            <AuthGuard>
              <DashboardLayout {...getLayoutProps}>
                <DashboardPage />
              </DashboardLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.board}>
            <AuthGuard>
              <DashboardLayout {...getLayoutProps}>
                <BoardPage />
              </DashboardLayout>
            </AuthGuard>
          </Route>
          <Route exact path={routes.settings}>
            <AuthGuard>
              <DashboardLayout {...getLayoutProps}>
                <SettingsPage />
              </DashboardLayout>
            </AuthGuard>
          </Route>

          <Route>
            <DashboardLayout>
              <NotFound />
            </DashboardLayout>
          </Route>
        </Switch>
      </div>
    </AbilityProvider>
  );
};

export default App;
