import React from 'react';

import './ua-modal.styles.scss';

import { Modal, Button } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { toast } from 'react-toastify';
import Notification from 'components/common/ua-modal/notification.conponent';

const UaModal = ({
  show,
  onHide,
  title,
  okOnly,
  onOk,
  beforeAction,
  cancelAction,
  onSubmit,
  children,
  withoutClose,
  notification = {},
  dialogClassName = '',
  cancelLabel = 'Cancel',
  okLabel = 'Ok',
  noFooter = false,
  ...rest
}) => {
  const notify = () => {
    let deleteAfterClose = true;
    toast.success(
      <Notification
        text={notification.text}
        onUndo={() => {
          if (cancelAction) cancelAction();
          onHide();
          deleteAfterClose = false;
        }}
        timer={notification.autoClose || 3000}
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: notification.autoClose,
        pauseOnHover: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false,
        progress: undefined,
        onOpen: () => {
          onHide();
        },
        onClose: () => {
          if (deleteAfterClose) {
            document.activeElement.blur();
            onOk();
          }
          deleteAfterClose = true;
        },
      }
    );
  };

  const handleOk = () => {
    if (Object.keys(notification).length) {
      if (beforeAction) beforeAction();
      notify();
    } else {
      onOk();
    }
  };

  const renderBody = () => (
    <>
      <Modal.Header>
        {!withoutClose && (
          <button
            type='button'
            className='ua-modal__close-btn'
            onClick={onHide}>
            <CloseIcon />
          </button>
        )}
      </Modal.Header>
      <Modal.Body>
        {title && <h3 className='ua-modal__title'>{title}</h3>}
        {children}
      </Modal.Body>
      {!noFooter && (
        <Modal.Footer
          className={`${okOnly ? 'ua-modal__footer--ok-only' : ''}`}>
          {!okOnly && (
            <Button
              variant='outline-secondary'
              className='ua-modal__footer-button close-button'
              type='button'
              onClick={onHide}>
              {cancelLabel}
            </Button>
          )}
          <Button
            variant='primary'
            className='ua-modal__footer-button ok-button'
            type={onSubmit ? 'submit' : 'button'}
            onClick={onSubmit ? undefined : handleOk}>
            {okLabel}
          </Button>
        </Modal.Footer>
      )}
    </>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName={`ua-modal${
        noFooter ? ' ua-modal--no-footer' : ''
      } ${dialogClassName}`}
      {...rest}>
      {onSubmit ? (
        <form onSubmit={onSubmit}>{renderBody()}</form>
      ) : (
        renderBody()
      )}
    </Modal>
  );
};

export default UaModal;
