import Select from 'react-select';

import './ua-select.styles.scss';

import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';

const DropdownIndicator = () => <AngleDown width='28px' height='28px' />;

const UaSelect = ({
  value,
  name,
  suggestions,
  onChange,
  customOption = {},
  placeholder = 'Select an item',
  isSearchable = false,
  isClearable = false,
}) => {
  const components = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
    ...customOption,
  };

  return (
    <div className='ua-select-container'>
      <Select
        className='ua-select'
        classNamePrefix='ua-select'
        value={value}
        options={suggestions}
        name={name}
        isSearchable={isSearchable}
        isClearable={isClearable}
        menuPlacement='top'
        placeholder={placeholder}
        components={components}
        onChange={onChange}
      />
    </div>
  );
};

export default UaSelect;
