import { put, takeEvery, call } from 'redux-saga/effects';

import { saveCustomView as saveCustomViewAction } from '../actions';

import { saveCustomView as saveCustomViewApi } from '../api';

function* saveSaga({ payload }) {
  try {
    yield put(saveCustomViewAction.request());

    const customView = yield call(saveCustomViewApi, payload);

    yield put(saveCustomViewAction.success(customView));
  } catch (error) {
    console.error(error);
    yield put(saveCustomViewAction.failure(error.response));
  } finally {
    yield put(saveCustomViewAction.fulfill());
  }
}

export default function* save() {
  yield takeEvery(saveCustomViewAction.TRIGGER, saveSaga);
}
