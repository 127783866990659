import React from 'react';
import { number, string } from 'prop-types';
import clsx from 'clsx';

import './logo.styles.scss';

import logo from '../../assets/images/logo.png';

const Logo = ({ width = 290, height = 80, className, src }) => (
  <div className={clsx('logo', className)}>
    <img
      className='logo__image'
      src={src || logo}
      width={width}
      height={height}
      alt='site logo'
    />
  </div>
);

Logo.propTypes = {
  width: number,
  height: number,
  className: string,
  src: string,
};

export default Logo;
