import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getGuestProjects as getProjectsAction } from '../actions';
// api
import { getGuestProjects as getProjectsApi } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(getProjectsAction.request());

    const projects = yield call(getProjectsApi, payload);

    yield put(getProjectsAction.success({ projects }));
  } catch (error) {
    console.error(error);
    yield put(getProjectsAction.failure(error.response));
  } finally {
    yield put(getProjectsAction.fulfill());
  }
}

export default function* get() {
  yield takeLatest(getProjectsAction.TRIGGER, getSaga);
}
