import React from 'react';
import { useDispatch } from 'react-redux';

import UaConfirm from 'components/common/ua-modal/ua-confirm.component';

import {
  cancelArchiveBoard,
  editBoard,
  preArchiveBoard,
} from 'store/boards/actions';

const BoardArchiveModal = ({ board: { is_archived, id }, isShow, onClose }) => {
  const dispatch = useDispatch();

  const handleArchive = () => {
    dispatch(editBoard({ id, is_archived: !is_archived }));
    onClose();
  };

  const handleBefore = () => {
    dispatch(preArchiveBoard({ id, is_archived: !is_archived }));
  };

  const handleCancel = () => {
    dispatch(cancelArchiveBoard());
  };

  return (
    <UaConfirm
      show={isShow}
      onHide={onClose}
      onOk={handleArchive}
      beforeAction={handleBefore}
      cancelAction={handleCancel}
      okLabel='Yes'
      cancelLabel='No'
      message={`Are you sure you want to ${
        is_archived ? 'restore' : 'archive'
      } this board?`}
      notification={{
        text: `We ${is_archived ? 'restore' : 'archive'} this board in`,
      }}
    />
  );
};

export default BoardArchiveModal;
