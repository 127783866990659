import { put, takeEvery, call } from 'redux-saga/effects';

import { uploadProfilePhoto as uploadFileAction } from '../actions';

import { uploadProfilePhoto as uploadFileApi } from '../api';

function* addSaga({ payload }) {
  try {
    yield put(uploadFileAction.request());

    const response = yield call(uploadFileApi, payload);
    
    yield put(uploadFileAction.success(response));
  } catch (error) {
    console.error(error);
    yield put(uploadFileAction.failure(error.response));
  } finally {
    yield put(uploadFileAction.fulfill());
  }
}

export default function* uploadProfilePhoto() {
  yield takeEvery(uploadFileAction.TRIGGER, addSaga);
}
