import React from 'react';

import './project-kanban-card.styles.scss';

import Priority from '../../dashboard/priority/priority.component';
import Tag from '../../dashboard/tag/tag.component';
import DueDate from '../../dashboard/due-date/due-date.component';
import UserAvatar from '../../user-avatar/user-avatar.component';

import { ReactComponent as Paperclip } from '../../../assets/images/icons/paperclip.svg';
import { ReactComponent as Check } from '../../../assets/images/icons/check-circle-outline.svg';
import { ReactComponent as Refresh } from 'assets/images/icons/refresh.svg';

const ProjectKanbanCard = ({ project, onTitleClick }) => {
  return (
    <div className='project-kanban-card'>
      <div className='project-kanban-card__header'>
        <div className='project-kanban-card__header-col'>
          <h5
            className='project-kanban-card__title'
            onClick={() => onTitleClick(project)}>
            <Priority value={project.priority} noLabel />
            {project.name}
          </h5>
          <div className='project-kanban-card__tagline'>
            {project.tags.map((tag) => (
              <Tag
                key={`project-${project.id}-tag-${tag.id}`}
                text={tag.name}
                color={tag.color}
              />
            ))}
          </div>
        </div>
        <div
          className={`project-kanban-card__header-col team ${
            project.members.length
              ? ''
              : 'project-kanban-card__header-col--empty'
          }`}>
          {project.members.length ? (
            <UserAvatar user={project.members[0].user} width={30} height={30} />
          ) : null}
        </div>
      </div>
      <div className='project-kanban-card__widgets'>
        {!!project.due_date && (
          <div className='project-kanban-card__widget-col'>
            <div className='project-kanban-card__widget'>
              <DueDate date={project.due_date} />
            </div>
          </div>
        )}

        {!!project.updates.length && (
          <div className='project-kanban-card__widget-col'>
            <div className='project-kanban-card__widget'>
              <Refresh /> {project.updates.length}
            </div>
          </div>
        )}

        {!!project.files.length && (
          <div className='project-kanban-card__widget-col'>
            <div className='project-kanban-card__widget'>
              <Paperclip /> {project.files.length}
            </div>
          </div>
        )}

        {!!project.tasks.length && (
          <div className='project-kanban-card__widget-col'>
            <div className='project-kanban-card__widget'>
              <Check /> {project.tasks.length}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectKanbanCard;
