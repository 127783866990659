import { put, takeLatest, call } from 'redux-saga/effects';

import { getBoard as getBoardAction, getStatuses, getTags } from '../actions';

import { getBoard as getBoardApi } from '../api';
import { toast } from 'react-toastify';
import history from '../../../history';
import routes from 'utils/routes';

export function* getSaga({ payload }) {
  try {
    yield put(getBoardAction.request());

    const board = yield call(getBoardApi, payload);

    if (payload.initial) {
      yield put(getStatuses.trigger({ boardId: board.id }));
      yield put(getTags.trigger({ boardId: board.id }));
    }

    yield put(getBoardAction.success(board));
  } catch (error) {
    console.error(error);
    yield put(getBoardAction.failure(error.response));
    if (error.response.data.statusCode === 404) {
      toast('Board not found', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error',
      });
      history.push(routes.dashboard);
    }
  } finally {
    yield put(getBoardAction.fulfill());
  }
}

export default function* getBoard() {
  yield takeLatest(getBoardAction.TRIGGER, getSaga);
}
