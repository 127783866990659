import React, {
  useMemo,
  memo,
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import clsx from 'clsx';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getPriorities, unarchiveProjects } from 'store/projects/actions';
import { getMembersList } from 'store/accounts/actions';
import { getClients } from 'store/clients/actions';
import { getProfile } from 'store/auth/selectors';

import useProjectTable from './useProjectTable';

import './projects-table.styles.scss';

import TableCell from './projects-table-cell.components';
import ProjectWidget from './projects-table-widget.component';
import TableHeadCell from './projects-table-head-cell.component';
import UaConfirm from '../../common/ua-modal/ua-confirm.component';
import {
  getProjects as projectsSelector,
  isProjectsListLoading,
} from 'store/projects/selectors';
import { BoardContext } from 'context/board-context/board-context';
import { arrayChecker } from 'utils/arrayChecker';

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
  },
];

function ProjectsTable() {
  const {
    isGuest,
    onSelectProjectCard,
    checkedProjects,
    setCheckedProjects,
    listShowOption,
    tableSortingList,
    setTableSortingList,
    setTableSortingShift,
    widgetFilters,
    setWidgetFilters,
    queryValue,
  } = useContext(BoardContext);

  const dispatch = useDispatch();
  const showColumn = useMemo(
    () => ({
      selection: true,
      client: listShowOption.client,
      due_date: listShowOption.dueDate,
      priority: listShowOption.priority,
      members: listShowOption.projectTeam,
      name: listShowOption.projectTitle,
      status: listShowOption.status,
      tags: listShowOption.tags,
    }),
    [listShowOption]
  );
  const { account_id } = useSelector(getProfile);
  const projects = useSelector(projectsSelector);
  const loading = useSelector(isProjectsListLoading);

  const [projectForRestore, setProjectForRestore] = useState(null);

  const activeProjects = useMemo(
    () => projects.filter(({ is_archived }) => !is_archived),
    [projects]
  );
  const archiveProjects = useMemo(
    () => projects.filter(({ is_archived }) => is_archived),
    [projects]
  );

  const { getTableProps, headerGroups, prepareRow, rows, totalColumnsWidth } =
    useProjectTable({
      data: activeProjects,
    });

  const { rows: archiveRows } = useProjectTable({
    data: archiveProjects,
  });

  const onCheckChange = (id) => () => {
    if (checkedProjects.includes(id)) {
      setCheckedProjects((prev) => prev.filter((item) => item !== id));
    } else {
      setCheckedProjects((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    dispatch(getPriorities());
    if (account_id) dispatch(getMembersList(account_id));
    if (account_id) dispatch(getClients(account_id));
  }, [dispatch, account_id]);

  const handleColumnClick = useCallback(
    (filter, type) => {
      setTableSortingList((prevSort) => {
        const toggleTypeSort = (oldSort, sort) =>
          oldSort === `${sort}:asc` ? `${sort}:desc` : `${sort}:asc`;

        const filerIndex = prevSort.findIndex((item) =>
          item.startsWith(filter)
        );
        const oldFilter = prevSort[filerIndex];

        if (type) {
          // with type
          if (oldFilter) {
            // has current sort
            if (oldFilter === `${filter}:${type}`) {
              // old sort === new sort
              setTableSortingShift((shift) =>
                type === 'asc' ? ++shift : --shift
              ); // change shift
              return prevSort; // return old sort
            } else {
              setTableSortingShift(0); // return shift
              return [toggleTypeSort(oldFilter, filter)]; // change type for sort
            }
          } else {
            return [`${filter}:${type}`]; // create new sort
          }
        } else {
          if (oldFilter) {
            // has current sort
            return [toggleTypeSort(oldFilter, filter)]; // change type for sort
          } else {
            return [`${filter}:asc`]; // crate new sort
          }
        }
      });
    },
    [setTableSortingShift, setTableSortingList]
  );

  const handleRemoveSort = useCallback(() => {
    setTableSortingList([]);
    setTableSortingShift(0);
  }, [setTableSortingShift, setTableSortingList]);

  const onArchiveClick = (project) => () => setProjectForRestore(project);
  const onClose = () => setProjectForRestore(null);
  const onRestoreProject = () => {
    dispatch(
      unarchiveProjects({
        projects: [projectForRestore.id],
        boardId: projectForRestore.board_id,
      })
    );
    setProjectForRestore(null);
  };

  const renderRows = (rows, isArchive = false) =>
    rows.map((row) => {
      prepareRow(row);
      const rowProps = row.getRowProps({
        style: { minWidth: 'none' },
      });

      return (
        <Fragment key={rowProps.key}>
          <div {...rowProps} className='tr'>
            {isArchive && (
              <div
                className='archived-project-bg'
                onClick={onArchiveClick(row.original)}
              />
            )}

            {row.cells.map((cell) => {
              const className = cell.column.id.replace(/_/g, '-');

              if (cell.column.id === 'selection') {
                return (
                  <div className='td checkbox' key={cell.column.id}>
                    <input
                      type='checkbox'
                      checked={checkedProjects.includes(cell.row.original.id)}
                      onChange={onCheckChange(cell.row.original.id)}
                    />
                  </div>
                );
              }

              return (
                showColumn[cell.column.id] && (
                  <div
                    {...cell.getCellProps(cellProps)}
                    className={clsx('td', className)}
                    key={cell.column.id}>
                    <TableCell
                      editing={!isGuest}
                      onProjectClick={onSelectProjectCard}
                      value={cell.value}
                      widgetFilters={widgetFilters}
                      setWidgetFilters={setWidgetFilters}
                      queryValue={queryValue}
                      row={row}
                      column={cell.column}
                    />
                  </div>
                )
              );
            })}
          </div>

          <ProjectWidget
            className='tr'
            widgetFilters={widgetFilters}
            onProjectClick={onSelectProjectCard}
            editing={!isGuest}
            projectId={row.original.id}
            minWidth={totalColumnsWidth}
          />
        </Fragment>
      );
    });

  const activeIds = useMemo(
    () => activeProjects.map(({ id }) => id),
    [activeProjects]
  );

  const isAllChecked = useMemo(() => {
    if (!checkedProjects.length) return false;
    return arrayChecker(checkedProjects, activeIds);
  }, [activeIds, checkedProjects]);

  const onAllCheck = () => {
    if (isAllChecked) {
      setCheckedProjects([]);
    } else {
      setCheckedProjects(activeIds);
    }
  };

  return (
    <div className='projects-table'>
      <div
        {...getTableProps({ style: { minWidth: 'none' } })}
        className='table'>
        <div className='thead'>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps({
                style: { minWidth: 'none' },
              })}
              className='tr'>
              {headerGroup.headers.map((column) => {
                if (showColumn[column.id]) {
                  const isSelection = column.id === 'selection';
                  const className = column.id.replace(/_/g, '-');

                  if (isSelection) {
                    return (
                      <div className='th checkbox' key={column.id}>
                        <input
                          type='checkbox'
                          checked={isAllChecked}
                          onChange={onAllCheck}
                        />
                      </div>
                    );
                  }

                  return (
                    <div
                      {...column.getHeaderProps(headerProps)}
                      className={`th ${className}`}>
                      <TableHeadCell
                        id={column.id}
                        sorts={tableSortingList}
                        onClick={handleColumnClick}
                        onRemoveClick={handleRemoveSort}>
                        {column.render('Header')}
                      </TableHeadCell>

                      {column.canResize && (
                        <div {...column.getResizerProps()} className='resizer'>
                          <div
                            className={clsx('resizer__item', {
                              'resizer__item--resizing': column.isResizing,
                            })}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ))}
        </div>
        <div className='tbody'>
          {loading ? (
            <div className='tr'>
              <div className='td loading-cell'>
                <Spinner animation='border' role='status' />
              </div>
            </div>
          ) : (
            <>
              {renderRows(rows)}
              {archiveRows.length > 0 && (
                <div className='archived-project-title'>Archived projects</div>
              )}
              {renderRows(archiveRows, true)}
            </>
          )}
        </div>
      </div>

      <UaConfirm
        show={!!projectForRestore}
        onHide={onClose}
        onOk={onRestoreProject}
        okLabel='Yes'
        cancelLabel='No'
        message={`Are you sure you want restore project ${projectForRestore?.name}?`}
      />
    </div>
  );
}

export default memo(ProjectsTable);
