import { apiGET, apiPATCH, apiPOST } from '../services/api';

export const getCustomViews = (accountId) =>
  apiGET({
    url: `/accounts/${accountId}/custom-views`,
  });

export const saveCustomView = ({ accountId, userId, name, filters }) =>
  apiPOST({
    url: `/accounts/${accountId}/custom-views`,
    data: {
      user_id: userId,
      name,
      filters,
    },
  });

export const updateCustomView = ({ id, name, filters }) =>
  apiPATCH({
    url: `custom-views/${id}`,
    data: {
      name,
      filters,
    },
  });
