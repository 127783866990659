import { apiGET, apiPOST, apiPATCH, apiDELETE } from '../services/api';

export const getBoard = ({ id }) => apiGET({ url: `/boards/${id}` });

export const getGuestBoard = ({ id }) => apiGET({ url: `/boards/uuid/${id}` });

export const addBoard = ({ accountId, name, socketId }) =>
  apiPOST({
    url: `/accounts/${accountId}/boards`,
    data: {
      name,
    },
    headers: {
      'socket-client-id': socketId
    }
  });

export const copyBoard = ({ board_id, socketId }) =>
  apiPOST({
    url: 'boards/copy',
    data: { board_id },
    headers: {
      'socket-client-id': socketId
    }
  });

export const shareBoard = ({ board_id, email }) =>
  apiPOST({
    url: `/boards/${board_id}/share`,
    data: { email },
  });

export const addBoardMember = ({ board_id, email, socketId }) =>
  apiPOST({
    url: `/boards/${board_id}/users`,
    data: {
      email,
    },
    headers: {
      'socket-client-id': socketId
    }
  });

export const removeBoardMember = ({ board_id, user_id, socketId }) =>
  apiDELETE({
    url: `/boards/${board_id}/users/${user_id}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const editBoard = ({ id, socketId, ...body }) =>
  apiPATCH({
    url: `/boards/${id}`,
    data: body,
    headers: {
      'socket-client-id': socketId
    }
  });

export const deleteBoard = ({ id, socketId }) =>
  apiDELETE({
    url: `/boards/${id}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const setIsFavorite = ({ boardId, isFavorite }) => {
  const url = `/boards/${boardId}/favorites`;
  return isFavorite ? apiPOST({ url }) : apiDELETE({ url });
};

export const togglePersonal = ({ boardId, isPersonal }) => {
  const url = `/boards/${boardId}/personal-dashboard`;
  return isPersonal ? apiPOST({ url }) : apiDELETE({ url });
};

export const getStatuses = ({ boardId }) =>
  apiGET({
    url: `/boards/${boardId}/statuses`,
  });

export const addStatus = ({ boardId, status, socketId }) =>
  apiPOST({
    url: `/boards/${boardId}/statuses`,
    data: status,
    headers: {
      'socket-client-id': socketId
    }
  });

export const editStatus = ({ boardId, statusId, status, socketId }) =>
  apiPATCH({
    url: `/boards/${boardId}/statuses/${statusId}`,
    data: status,
    headers: {
      'socket-client-id': socketId
    }
  });

export const deleteStatus = ({ boardId, statusId, socketId }) =>
  apiDELETE({
    url: `/boards/${boardId}/statuses/${statusId}`,
    headers: {
      'socket-client-id': socketId
    }
  });

export const getTags = ({ boardId }) =>
  apiGET({
    url: `/boards/${boardId}/tags`,
  });

export const addTag = ({ boardId, tag, socketId }) =>
  apiPOST({
    url: `/boards/${boardId}/tags`,
    data: tag,
    headers: {
      'socket-client-id': socketId
    }
  });

export const editTag = ({ tagId, tag, socketId }) =>
  apiPATCH({
    url: `/tags/${tagId}`,
    data: tag,
    headers: {
      'socket-client-id': socketId
    }
  });

export const deleteTag = ({ id, socketId }) =>
  apiDELETE({
    url: `/tags/${id}`,
    headers: {
      'socket-client-id': socketId
    }
  });
