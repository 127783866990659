import { put, call, takeEvery, select } from 'redux-saga/effects';
// actions
import { getCurrentAccount as action, switchAccount } from '../actions';
// api
import { getCurrentAccount as api } from '../api';

import { getProfile } from 'store/auth/actions';
import { getProfile as profileSelector } from 'store/auth/selectors';

function* getSaga() {
  try {
    const { account_id } = yield select(profileSelector);
    if (account_id) {
      const response = yield call(api, account_id);
      yield put(action.success(response));
    } else {
      yield put(action.failure({ data: { message: 'No account_id' } }));
    }
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getCurrentAccount() {
  yield takeEvery(
    [switchAccount.SUCCESS, getProfile.SUCCESS],
    getSaga
  );
}
