import Select from 'react-select';
import { useSelector } from 'react-redux';
import useFilter from 'hooks/useFilter';

import ProjectFilter from '../filter.component';
import Priority from 'components/dashboard/priority/priority.component';

import { ReactComponent as FlagIcon } from 'assets/images/icons/flag.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-circle.svg';

import { getPriorities } from 'store/projects/selectors';

const DropdownIndicator = () => <AngleDown width='28px' height='28px' />;

const FilterPriority = ({ value, onChange }) => {
  const priorities = useSelector(getPriorities);
  const filter = useFilter({ options: priorities, selected: value, onChange });

  const CustomOption = (props) => {
    return (
      <div
        className={`project-filter-select__option ${
          props.isFocused ? 'project-filter-select__option--focused' : ''
        }`}
        {...props.innerProps}>
        <Priority value={props.data} withBackground={true} />
      </div>
    );
  };

  return (
    <ProjectFilter
      icon={<FlagIcon />}
      name='Priority'
      body={
        <>
          {value.length > 0 ? (
            <div className='project-filter-selected-items project-filter-selected-items--flex'>
              {value.map((item) => (
                <div
                  className='project-filter-selected-item'
                  key={`priority-${item.id}`}>
                  <Priority value={item} withBackground={true} />
                  <button
                    onClick={filter.onDelete(item)}
                    className='project-filter-selected-item__delete'>
                    <CloseIcon height={20} width={20} />
                  </button>
                </div>
              ))}
            </div>
          ) : null}
          <Select
            className='project-filter-select'
            classNamePrefix='project-filter-select'
            value=''
            options={filter.suggestions}
            name='priority'
            isSearchable={false}
            isClearable={false}
            menuPlacement='top'
            placeholder='Select priority'
            components={{
              DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: null,
              Option: CustomOption,
            }}
            onChange={filter.onChange}
          />
        </>
      }
    />
  );
};

export default FilterPriority;
