import { object, string } from 'yup';

const AddCardFormSchema = object().shape({
  email: string()
    .trim()
    .email('Invalid email address')
    .required('This field is required'),
  name: string().trim().required('This field is required'),
  address: string().trim().required('This field is required'),
  country: string().trim().required('This field is required'),
  region: string().trim(),
  city: string().trim(),
  zip: string().trim(),
});

export default AddCardFormSchema;
