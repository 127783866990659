import { all, fork } from 'redux-saga/effects';
// sagas
import getClients from './getClients';
import addClient from './addClient';
import editClient from './editClient';
import deleteClient from './deleteClient';

export default function* accounts() {
  yield all([
    fork(getClients),
    fork(addClient),
    fork(editClient),
    fork(deleteClient),
  ]);
}
