import { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import './kanban.styles.scss';

import KanbanCard from './kanban-card/kanban-card.component';
import KanbanColumn from './kanban-column/kanban-column.component';
import KanbanContainer from './kanban-container/kanban-container.component';
import ProjectKanbanCard from '../project/project-kanban-card/project-kanban-card.components';
import StatusesButton from '../dashboard/statuses/statuses.component';

import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg';

import {
  getSortedProjects,
  getSortedStatuses,
  getStatuses,
} from 'store/boards/selectors';
import { dndBoardStatus, dndBoardProject } from 'store/boards/actions';
import { getProfileSettings } from 'store/auth/selectors';
import { useParams } from 'react-router-dom';
import { BoardContext } from 'context/board-context/board-context';

const COLUMN_WIDTH = 320;

const Kanban = () => {
  const dispatch = useDispatch();

  const projects = useSelector(getSortedProjects);
  const { id } = useParams();
  const statuses = useSelector(getStatuses);
  const orderedStatuses = useSelector(getSortedStatuses);
  const settings = useSelector(getProfileSettings);

  const { isGuest, kanbanHiddenColumns, onSelectProjectCard } =
    useContext(BoardContext);

  const visibleColumns = useMemo(() => {
    const hiddenColumns = settings?.boards[id]?.hiddenColumns || [];

    return orderedStatuses.filter((item) =>
      kanbanHiddenColumns
        ? hiddenColumns.includes(item.id)
        : !hiddenColumns.includes(item.id)
    );
  }, [settings, id, orderedStatuses, kanbanHiddenColumns]);

  const columnContainerWidth = useMemo(
    () => visibleColumns.length * COLUMN_WIDTH,
    [visibleColumns]
  );

  const handleDragEnd = (dragResult) => {
    const { draggableId, destination, source, type } = dragResult;

    if (!destination) return;

    if (type === 'COLUMN_CONTAINER') {
      dispatch(
        dndBoardStatus({
          from: source.index,
          to: destination.index,
          statusId: orderedStatuses[source.index].id,
        })
      );
    } else {
      const fromStatus = +source.droppableId.split('-').pop();
      const fromIndex = +source.index;
      const toStatus = +destination.droppableId.split('-').pop();
      const toIndex = +destination.index;
      const projectId = +draggableId;
      dispatch(
        dndBoardProject({
          fromStatus,
          fromIndex,
          toStatus,
          toIndex,
          projectId,
        })
      );
    }
  };

  return (
    <div className='kanban'>
      <KanbanContainer>
        {kanbanHiddenColumns && !visibleColumns.length ? (
          <div className='kanban-empty'>No columns are hidden</div>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              droppableId='columnContainer'
              type='COLUMN_CONTAINER'
              direction='horizontal'>
              {(columnProvided) => (
                <div
                  className='kanban-column-container'
                  style={{
                    width: columnContainerWidth + 'px',
                    flexBasis: columnContainerWidth + 'px',
                  }}
                  ref={columnProvided.innerRef}
                  {...columnProvided.droppableProps}>
                  {visibleColumns.map((status, index) => (
                    <Draggable
                      key={`kanban-column-${status.id}`}
                      isDragDisabled={isGuest}
                      draggableId={`kanban-column-${status.id}`}
                      index={index}>
                      {(columnDragProvided) => (
                        <KanbanColumn
                          statusId={status.id}
                          title={
                            <span
                              className={clsx(
                                'kanban-column-title',
                                status.slug
                              )}
                              style={{ color: status.color }}>
                              {status.name}
                            </span>
                          }
                          ref={columnDragProvided.innerRef}
                          {...columnDragProvided.draggableProps}
                          {...columnDragProvided.dragHandleProps}>
                          <Droppable
                            droppableId={`status-${status.id}`}
                            type='CARD_CONTAINER'>
                            {(provided) => (
                              <div
                                className='kanban-droppable-container'
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {projects
                                  .filter(
                                    (project) =>
                                      project.status.id === status.id &&
                                      !project.is_archived
                                  )
                                  .map((project, index) => (
                                    <Draggable
                                      draggableId={`${project.id}`}
                                      isDragDisabled={isGuest}
                                      index={index}
                                      key={`project-card-${project.id}`}>
                                      {(dragProvided) => (
                                        <KanbanCard
                                          {...dragProvided.draggableProps}
                                          {...dragProvided.dragHandleProps}
                                          ref={dragProvided.innerRef}>
                                          <ProjectKanbanCard
                                            project={project}
                                            onTitleClick={onSelectProjectCard}
                                          />
                                        </KanbanCard>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </KanbanColumn>
                      )}
                    </Draggable>
                  ))}
                  {columnProvided.placeholder}
                </div>
              )}
            </Droppable>
            <KanbanColumn
              isCreateColumn={true}
              isHidden={kanbanHiddenColumns || isGuest}>
              <StatusesButton
                className='kanban-statuses'
                statusesList={statuses}
                editing={true}
                customBody={
                  <div className='kanban-create-button'>
                    <div className='button-label'>
                      <PlusIcon /> Add a New Column
                    </div>
                  </div>
                }
              />
            </KanbanColumn>
          </DragDropContext>
        )}
      </KanbanContainer>
    </div>
  );
};

export default Kanban;
