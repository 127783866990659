import { put, takeEvery, call, select } from 'redux-saga/effects';
// actions
import { copyBoard as action } from '../actions';
import { changePosition } from 'store/dashboards/actions';
// services
import { copyBoard as api } from '../api';
// selectors
import {
  getBoardPositions,
  getCurrentDashboard,
} from 'store/dashboards/selectors';
import {getBoardSocket} from 'store/socket/selectors';

function* copySaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(action.request());

    const board = yield call(api, payload);

    const { id } = yield select(getCurrentDashboard);
    const { all } = yield select(getBoardPositions);

    const newPosition = [...all];
    const index = newPosition.findIndex(({ id }) => id === payload.board_id);
    const nextPos = index + 1;
    if (index) {
      newPosition.splice(nextPos, 0, { id: board.id, order: nextPos });
    } else {
      newPosition.push({ id: board.id, order: newPosition.length });
    }

    const newAll = newPosition.map(({ id }, i) => ({ id, order: i }));

    yield put(
      changePosition({
        dashboard_id: id,
        boards_positions: { all: newAll },
      })
    );

    yield put(action.success(board));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* copy() {
  yield takeEvery(action.TRIGGER, copySaga);
}
