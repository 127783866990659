import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import getAuthStatus from './utils/getAuthStatus';
import routes from './utils/routes';

export const AuthGuard = ({ children }) => {
  const { pathname } = useLocation();
  const isAuth = getAuthStatus();

  if (
    !isAuth &&
    ![
      routes.login,
      routes.registration,
      routes.resetPassword,
      routes.emailVerification,
    ].includes(pathname)
  ) {
    return <Redirect to={routes.login} />;
  } else if (
    isAuth &&
    [
      routes.login,
      routes.registration,
      routes.resetPassword,
      routes.emailVerification,
    ].includes(pathname)
  ) {
    return <Redirect to={routes.dashboard} />;
  }

  return children;
};
