import { apiGET, apiPOST, apiDELETE, apiPATCH } from '../services/api';

export const getRolesList = () =>
  apiGET({
    url: '/roles',
  });

export const getMembersList = (id) =>
  apiGET({
    url: `/accounts/${id}/users`,
  });

export const createAccount = () => apiPOST({ url: '/accounts' });

export const closeAccount = ({ accountId, password }) =>
  apiPOST({
    url: `/accounts/${accountId}`,
    data: { password },
  });

export const deleteAccountUser = ({ accountId, userId }) =>
  apiDELETE({
    url: `/accounts/${accountId}/users/${userId}`,
  });

export const inviteAccountUser = ({ accountId, data, socketId }) =>
  apiPOST({
    url: `/accounts/${accountId}/users/invite`,
    data,
    headers: {
      'socket-client-id': socketId,
    },
  });

export const updateAccountUser = ({ accountId, userId, data }) =>
  apiPATCH({
    url: `/accounts/${accountId}/users/${userId}`,
    data,
  });

export const resetUserPassword = ({ accountId, userId }) =>
  apiGET({
    url: `/accounts/${accountId}/users/${userId}/change-password`,
  });

export const downloadDataRequest = ({ accountId, socketId }) =>
  apiGET({
    url: `/accounts/${accountId}/download-request`,
    headers: {
      'socket-client-id': socketId,
    },
  });

export const downloadData = async ({
  account_id,
  filename,
  name,
  isRemove = true,
}) => {
  apiGET({
    url: `/accounts/${account_id}/download?name=${filename}&remove-after-download=${isRemove}`,
    responseType: 'blob',
  }).then((blob) => {
    let url = window.URL.createObjectURL(new Blob([blob]));
    let a = document.createElement('a');
    a.href = url;
    a.download = name ? `${name}.zip` : filename;
    a.click();
  });
};

export const transferOwnership = ({ accountId, userId, password }) =>
  apiPOST({
    url: `/accounts/${accountId}/users/${userId}/owner`,
    data: { password },
  });

export const ownerAccept = ({ accountId, userId, token }) =>
  apiGET({
    url: `/accounts/${accountId}/users/${userId}/owner-accept?token=${token}`,
  });

export const updateAccount = ({ accountId, data }) => {
  const fileFormData = new FormData();
  if (data.name) fileFormData.append('name', data.name);
  if ('seats' in data) fileFormData.append('seats', data.seats);
  if (data.logo) fileFormData.append('logo', data.logo);

  return apiPATCH({
    url: `/accounts/${accountId}`,
    data: fileFormData,
  });
};

export const deleteLogo = (accountId) => {
  return apiDELETE({
    url: `/accounts/${accountId}/photo`,
  });
};

export const getCurrentAccount = (accountId) => {
  return apiGET({
    url: `/accounts/${accountId}`,
  });
};
