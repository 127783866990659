import { all, fork } from 'redux-saga/effects';
// sagas
import socket from './socket';
import socketAccounts from './socketAccounts';
import socketBoards from './socketBoards';
import socketProjects from './socketProjects';
import updateSocketState from './updateSocketState';
import changeAccountRoom from './changeAccountRoom';
import changeProjectRoom from './changeProjectRoom';

export default function* accounts() {
  yield all([
    fork(socket),
    fork(socketAccounts),
    fork(updateSocketState),
    fork(changeAccountRoom),
    fork(socketBoards),
    fork(socketProjects),
    fork(changeProjectRoom),
  ]);
}
