import { put, takeEvery, call } from 'redux-saga/effects';

import { deleteProfilePhoto as uploadFileAction } from '../actions';

import { deleteProfilePhoto as uploadFileApi } from '../api';

function* addSaga() {
  try {
    yield put(uploadFileAction.request());

    yield call(uploadFileApi);

    yield put(uploadFileAction.success());
  } catch (error) {
    console.error(error);
    yield put(uploadFileAction.failure(error.response));
  } finally {
    yield put(uploadFileAction.fulfill());
  }
}

export default function* deleteProfilePhoto() {
  yield takeEvery(uploadFileAction.TRIGGER, addSaga);
}
