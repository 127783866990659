import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getBoard } from 'store/boards/selectors';

import { copyProjects as copyProjectsAction } from '../actions';

import { copyProjects as copyProjectsApi } from '../api';

import { getSaga as getBoardAction } from 'store/boards/sagas/getBoard';
import {getProjectSocket} from 'store/socket/selectors';

function* copySaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    const board = yield select(getBoard);
    yield put(copyProjectsAction.request());

    const projects = yield call(copyProjectsApi, payload);

    yield put(copyProjectsAction.success(projects));

    if (board.id === payload.boardId) {
      yield call(getBoardAction, { payload: { id: board.id } });
    }
  } catch (error) {
    console.error(error);
    yield put(copyProjectsAction.failure(error.response));
  } finally {
    yield put(copyProjectsAction.fulfill());
  }
}

export default function* copyProjects() {
  yield takeLatest(copyProjectsAction.TRIGGER, copySaga);
}
