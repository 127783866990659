import { put, takeLatest, call, select } from 'redux-saga/effects';

import { deleteProjects as deleteProjectsAction } from '../actions';

import { deleteProjects as deleteProjectsApi } from '../api';
import { getBoard } from 'store/boards/selectors';
import { getBoard as getBoardAction } from 'store/boards/actions';
import {getProjectSocket} from 'store/socket/selectors';

function* deleteSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    const socketId = socket.id;

    yield put(deleteProjectsAction.request());

    yield call(deleteProjectsApi, {ids: payload, socketId});

    yield put(deleteProjectsAction.success(payload));

    const { id } = yield select(getBoard);

    yield put(getBoardAction({ id }));
  } catch (error) {
    console.error(error);
    yield put(deleteProjectsAction.failure(error.response));
  } finally {
    yield put(deleteProjectsAction.fulfill());
  }
}

export default function* deleteProjects() {
  yield takeLatest(deleteProjectsAction.TRIGGER, deleteSaga);
}
