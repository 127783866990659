import { apiGET } from 'store/services/api';

const getImage = async (url) => {
  const blob = await apiGET({
    url,
    responseType: 'blob',
  });
  return window.URL.createObjectURL(new Blob([blob]));
};

export default getImage;
