import { useEffect, useState } from 'react';

import getImage from 'utils/getImage';

export default function usePhoto(url, condition) {
  const [userPhoto, setUserPhoto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (condition) {
      setIsLoading(true);
      getImage(url).then((data) => {
        setUserPhoto(data);
        setIsLoading(false);
      });
    } else {
      setUserPhoto(null);
    }
  }, [condition]);

  return [userPhoto, isLoading];
}
