import {put, takeLatest, call, select} from 'redux-saga/effects';

import { editTask as editTaskAction } from '../actions';

import { editTask as editTaskApi } from '../api';
import {getProjectSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(editTaskAction.request());

    const task = yield call(editTaskApi, payload);

    yield put(editTaskAction.success({ ...payload, task }));
  } catch (error) {
    console.error(error);
    yield put(editTaskAction.failure(error.response));
  } finally {
    yield put(editTaskAction.fulfill());
  }
}

export default function* editTask() {
  yield takeLatest(editTaskAction.TRIGGER, addSaga);
}
