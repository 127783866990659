import { useState, useEffect } from 'react';

export default function useDevice(query) {
  const [isDevice, setIsDevice] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mql = window.matchMedia(query);
    mql.onchange = (e) => setIsDevice(e.matches);
  }, []);

  return isDevice;
}
