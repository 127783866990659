export const draggable = ({ destination, source }) => {
  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  return {
    from: source.index,
    to: destination.index,
  };
};

export const changePositions = (positions, { from, to }) => {
  if (!positions.length) return positions;
  const [itemToMove] = positions.splice(from, 1);
  positions.splice(to, 0, itemToMove);

  return positions;
};

const draggableSort = (state, handleChange, dragResult) => {
  const result = draggable(dragResult);

  if (!result) return;

  const { from, to } = result;

  const dropArea = [...state];
  const [itemToMove] = dropArea.splice(from, 1);
  dropArea.splice(to, 0, itemToMove);

  handleChange(dropArea);
};

export default draggableSort;
