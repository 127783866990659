import React, { useCallback, useContext, useMemo } from 'react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

import { BOARD_VIEW, EnumBoardPopups, EnumProjectModals } from 'settings';

import ShowOptionsDropdown from 'components/board/show-options-dropdown/show-options-dropdown.component';
import UATooltip from 'components/common/ua-tooltip/ua-tooltip.components';

import { ReactComponent as Settings } from 'assets/images/icons/settings.svg';
import { ReactComponent as AlignLeft } from 'assets/images/icons/align-left.svg';
import { ReactComponent as Refresh } from 'assets/images/icons/refresh.svg';
import { ReactComponent as Paperclip } from 'assets/images/icons/paperclip.svg';
import { ReactComponent as Check } from 'assets/images/icons/check-circle-outline.svg';
import { ReactComponent as Loop } from 'assets/images/icons/loop.svg';
import { ReactComponent as Copy } from 'assets/images/icons/copy-file.svg';
import { ReactComponent as MoveTo } from 'assets/images/icons/move-to.svg';
import { ReactComponent as Archive } from 'assets/images/icons/archive.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';
import { ReactComponent as Filter } from 'assets/images/icons/filter.svg';
import { ReactComponent as File } from 'assets/images/icons/file.svg';
import { ReactComponent as Graph } from 'assets/images/icons/graph.svg';
import ShareBoard from '../share-board/share-board.component';
import useQuery from 'hooks/useQuery';
import CreateProjectButton from 'components/dashboard/create-project/create-project.component';
import { ReactComponent as Plus } from 'assets/images/icons/plus-circle-solid.svg';
import { useSelector } from 'react-redux';
import { getBoard } from 'store/boards/selectors';
import { useHistory } from 'react-router-dom';
import { BoardContext } from 'context/board-context/board-context';
import { getProjects } from 'store/projects/selectors';
import { arrayChecker } from 'utils/arrayChecker';

function ListControls() {
  const query = useQuery();
  const history = useHistory();
  const board = useSelector(getBoard);
  const projects = useSelector(getProjects);

  const projectsIds = useMemo(() => projects.map(({ id }) => id), [projects]);

  const {
    isGuest,
    onOpenBoardCard,
    checkedProjects,
    onOpenActionModal,
    widgetFilters,
    setWidgetFilters,
    isCardShow,
  } = useContext(BoardContext);

  const view = query.get('view');
  const isListView = view === BOARD_VIEW.LIST;

  const onWidgetClick = useCallback(
    (key) => () => {
      const newValue = isEqual(projectsIds, widgetFilters[key])
        ? []
        : projectsIds;

      setWidgetFilters((prev) => ({ ...prev, [key]: newValue }));
    },
    [projectsIds, widgetFilters, setWidgetFilters]
  );

  const onAllWidgetClick = useCallback(() => {
    const keys = Object.keys(widgetFilters);
    const isShowAll = keys
      .map((key) => isEqual(projectsIds, widgetFilters[key]))
      .every((v) => v);
    const newValue = isShowAll ? [] : projectsIds;
    const newState = keys.reduce((acc, key) => {
      acc[key] = newValue;
      return acc;
    }, Object.create(null));

    setWidgetFilters(newState);
  }, [projectsIds, widgetFilters, setWidgetFilters]);

  const onViewChange = () => {
    if (isListView) {
      query.set('view', BOARD_VIEW.KANBAN);
      history.replace({ search: query.toString() });
    }
  };

  const isShowAll = useMemo(() => {
    return Object.keys(widgetFilters)
      .map(
        (key) =>
          widgetFilters[key].length && arrayChecker(widgetFilters[key], projectsIds)
      )
      .every((v) => v);
  }, [widgetFilters, projectsIds]);

  const isWidgetActive = (key) =>
    widgetFilters[key].length && arrayChecker(widgetFilters[key] ,projectsIds);

  return (
    <>
      {!isGuest && (
        <div className='toolbar'>
          <CreateProjectButton boardId={board?.id} className='new-project-btn'>
            <div>
              <Plus />
            </div>
          </CreateProjectButton>
        </div>
      )}

      <div className={clsx('toolbar', { 'ml-10': !isGuest })}>
        <UATooltip label='Show info'>
          <button
            className={clsx('toolbar-btn', {
              active: isWidgetActive('showInfo'),
            })}
            onClick={onWidgetClick('showInfo')}>
            <div>
              <AlignLeft className='stroke' />
            </div>
          </button>
        </UATooltip>
        <UATooltip label='Show updates'>
          <button
            className={clsx('toolbar-btn', {
              active: isWidgetActive('showUpdates'),
            })}
            onClick={onWidgetClick('showUpdates')}>
            <div>
              <Refresh />
            </div>
          </button>
        </UATooltip>
        <UATooltip label='Show files'>
          <button
            className={clsx('toolbar-btn', {
              active: isWidgetActive('showFiles'),
            })}
            onClick={onWidgetClick('showFiles')}>
            <div>
              <Paperclip />
            </div>
          </button>
        </UATooltip>
        <UATooltip label='Show tasks'>
          <button
            className={clsx('toolbar-btn', {
              active: isWidgetActive('showTasks'),
            })}
            onClick={onWidgetClick('showTasks')}>
            <div>
              <Check />
            </div>
          </button>
        </UATooltip>
        <UATooltip label='Show all'>
          <button
            className={clsx('toolbar-btn', {
              active: isShowAll,
            })}
            onClick={onAllWidgetClick}>
            <div>
              <Loop />
            </div>
          </button>
        </UATooltip>
      </div>

      {!isGuest && (
        <div className='toolbar ml-10'>
          <UATooltip label='Copy' show={checkedProjects.length}>
            <button
              className='toolbar-btn'
              disabled={!checkedProjects.length}
              onClick={onOpenActionModal(EnumProjectModals.COPY)}>
              <div>
                <Copy />
              </div>
            </button>
          </UATooltip>
          <UATooltip label='Move to' show={checkedProjects.length}>
            <button
              className='toolbar-btn'
              disabled={!checkedProjects.length}
              onClick={onOpenActionModal(EnumProjectModals.MOVE)}>
              <div>
                <MoveTo />
              </div>
            </button>
          </UATooltip>
          <UATooltip label='Archive' show={checkedProjects.length}>
            <button
              className='toolbar-btn'
              disabled={!checkedProjects.length}
              onClick={onOpenActionModal(EnumProjectModals.ARCHIVE)}>
              <div>
                <Archive />
              </div>
            </button>
          </UATooltip>
          <UATooltip label='Delete' show={checkedProjects.length}>
            <button
              className='toolbar-btn'
              disabled={!checkedProjects.length}
              onClick={onOpenActionModal(EnumProjectModals.DELETE)}>
              <div>
                <Trash />
              </div>
            </button>
          </UATooltip>
        </div>
      )}

      <div className='toolbar ml-30'>
        <UATooltip label='Show filter' show={!(isCardShow === EnumBoardPopups.filter)}>
          <button
            className={clsx('toolbar-btn', {
              active: isCardShow === EnumBoardPopups.filter,
            })}
            onClick={onOpenBoardCard(EnumBoardPopups.filter)}>
            <div>
              <Filter />
            </div>
          </button>
        </UATooltip>
        <ShowOptionsDropdown />
      </div>
      <div className='toolbar ml-10'>
        {!isGuest && <ShareBoard />}

        <UATooltip label='Board details' show={!(isCardShow === EnumBoardPopups.setting)}>
          <button
            className={clsx('toolbar-btn', {
              active: isCardShow === EnumBoardPopups.setting,
            })}
            onClick={onOpenBoardCard(EnumBoardPopups.setting)}>
            <div>
              <Settings />
            </div>
          </button>
        </UATooltip>
      </div>
      <div className='toolbar ml-14'>
        <UATooltip label='List view'>
          <button
            className={clsx('toolbar-btn', {
              active: view === 'list',
            })}>
            <div>
              <File />
            </div>
          </button>
        </UATooltip>
        <UATooltip label='Kanban view'>
          <button className='toolbar-btn' onClick={onViewChange}>
            <div>
              <Graph />
            </div>
          </button>
        </UATooltip>
      </div>
    </>
  );
}

export default ListControls;
