import { all, fork } from 'redux-saga/effects';
// sagas
import getRoles from './getRoles';
import getMembers from './getMembers';
import closeAccount from './closeAccount';
import downloadData from './downloadData';
import downloadDataRequest from './downloadDataRequest';
import transferOwnership from './transferOwnership';
import ownerAccept from './ownerAccept';
import updateAccount from './updateAccount';
import deleteAccountUser from './deleteAccountUser';
import inviteAccountUser from './inviteAccountUser';
import updateAccountUser from './updateAccountUser';
import resetUserPassword from './resetUserPassword';
import deleteLogo from './deleteLogo';
import switchAccount from './switchAccount';
import getCurrentAccount from './getCurrentAccount';
import refreshCurrentAccount from './refreshCurrentAccount';
import createAccount from 'store/accounts/sagas/createAccount';

export default function* accounts() {
  yield all([
    fork(getRoles),
    fork(getMembers),
    fork(closeAccount),
    fork(createAccount),
    fork(downloadData),
    fork(downloadDataRequest),
    fork(transferOwnership),
    fork(ownerAccept),
    fork(updateAccount),
    fork(deleteAccountUser),
    fork(inviteAccountUser),
    fork(updateAccountUser),
    fork(resetUserPassword),
    fork(deleteLogo),
    fork(switchAccount),
    fork(getCurrentAccount),
    fork(refreshCurrentAccount),
  ]);
}
