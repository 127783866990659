import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-circle.svg';
import spinner from 'assets/images/icons/ellipse.png';
import { ReactComponent as CloseCircleIcon } from 'assets/images/icons/close-circle.svg';

const Notification = ({ closeToast, text, onUndo, timer }) => {
  const [autoClose, setAutoClose] = useState(timer);
  useEffect(() => {
    let timerInterval = setInterval(() => {
      setAutoClose((timeToClose) => timeToClose - 1000);
    }, 1000);

    if (autoClose <= 0) {
      closeToast();
      clearInterval(timerInterval);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [autoClose, timer]);

  return (
    <div className='notification'>
      <CheckIcon className='notification__icon' />
      <p className='notification__text'>{text}</p>
      <div className='notification__spinner'>
        <img src={spinner} alt='spinner' />
      </div>
      <p className='notification__timer'>{autoClose / 1000} secs...</p>
      <div className='notification__actions'>
        <button
          className='notification__undo'
          onClick={() => {
            onUndo();
            closeToast();
          }}>
          Undo
        </button>
        <button className='notification__close' onClick={closeToast}>
          <CloseCircleIcon />
        </button>
      </div>
    </div>
  );
};

export default Notification;
