import React, { useState } from 'react';
import { func, string } from 'prop-types';
import clsx from 'clsx';
import noop from 'lodash/noop';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import './card-inputs.styles.scss';

import UaStripeInputWrap from 'components/common/ua-stripe-input-wrap/ua-stripe-input-wrap.component';

const CardInputs = ({ handleChange, className }) => {
  const [errors, setError] = useState({
    numbers: false,
    period: false,
    cvc: false,
  });

  const handleChangeInput = (name) => (event) => {
    handleChange(event);

    const hasError = Boolean(event.error);
    if (hasError !== errors[name]) {
      setError((errors) => ({
        ...errors,
        [name]: hasError,
      }));
    }
  };

  return (
    <div className={clsx('card-inputs', className)}>
      <UaStripeInputWrap
        label='CARD NUMBER'
        className='card-inputs__card-number'
        hasError={errors['numbers']}
        inputComponent={({ options }) => (
          <CardNumberElement
            options={{
              ...options,
              placeholder: 'XXXX – XXXX – XXXX – XXXX',
            }}
            onChange={handleChangeInput('numbers')}
          />
        )}
      />

      <div className='card-inputs__wrap'>
        <UaStripeInputWrap
          label='EXP. DATE'
          className='card-inputs__card-expiry'
          hasError={errors['period']}
          inputComponent={({ options }) => (
            <CardExpiryElement
              options={{ ...options, placeholder: 'XX/XX' }}
              onChange={handleChangeInput('period')}
            />
          )}
        />

        <UaStripeInputWrap
          label='CVV'
          className='card-inputs__card-cvc'
          hasError={errors['cvc']}
          inputComponent={({ options }) => (
            <CardCvcElement
              options={{ ...options, placeholder: 'XXX' }}
              onChange={handleChangeInput('cvc')}
            />
          )}
        />
      </div>
    </div>
  );
};

CardInputs.propTypes = {
  handleChange: func,
  className: string,
};

CardInputs.defaultProps = {
  handleChange: noop,
};

export default CardInputs;
