import React from 'react';
import { toast } from 'react-toastify';

import './ua-modal.styles.scss';

import { Modal, Button } from 'react-bootstrap';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import Notification from 'components/common/ua-modal/notification.conponent';

const UaConfirm = ({
  show,
  onHide,
  okOnly,
  onOk,
  beforeAction,
  cancelAction,
  message,
  notification = {},
  cancelLabel = 'Cancel',
  okLabel = 'Ok',
}) => {
  const notify = () => {
    let deleteAfterClose = true;
    toast.success(
      <Notification
        text={notification.text}
        onUndo={() => {
          if (cancelAction) cancelAction();
          onHide();
          deleteAfterClose = false;
        }}
        timer={notification.autoClose || 3000}
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: notification.autoClose,
        pauseOnHover: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false,
        progress: undefined,
        onOpen: () => {
          onHide();
        },
        onClose: () => {
          if (deleteAfterClose) {
            document.activeElement.blur();
            onOk();
          }
          deleteAfterClose = true;
        },
      }
    );
  };

  const handleOk = () => {
    if (Object.keys(notification).length > 0) {
      if (beforeAction) beforeAction();
      notify();
    } else {
      onOk();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      dialogClassName='ua-modal ua-modal--confirm-dialog'>
      <Modal.Header>
        <button className='ua-modal__close-btn' onClick={onHide}>
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className='ua-modal__message'>{message}</p>
      </Modal.Body>
      <Modal.Footer className={`${okOnly ? 'ua-modal__footer--ok-only' : ''}`}>
        {okOnly ? null : (
          <Button variant='outline-secondary' onClick={onHide}>
            {cancelLabel}
          </Button>
        )}
        <Button variant='success' type='submit' onClick={handleOk}>
          {okLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UaConfirm;
