import {put, takeLatest, call, select} from 'redux-saga/effects';

import { editProject as editProjectAction } from '../actions';

import { editProject as editProjectApi } from '../api';
import { getBoard } from '../../boards/actions';
import {getProjectSocket} from 'store/socket/selectors';

export function* editSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(editProjectAction.request());

    const project = yield call(editProjectApi, payload);

    if (payload.boardId) yield put(getBoard({ id: payload.boardId }));

    yield put(editProjectAction.success(project));
  } catch (error) {
    console.error(error);
    yield put(editProjectAction.failure(error.response));
  } finally {
    yield put(editProjectAction.fulfill());
  }
}

export default function* edit() {
  yield takeLatest(editProjectAction.TRIGGER, editSaga);
}
