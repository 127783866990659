import { apiGET, apiPATCH } from '../services/api';

export const getDashboard = (id) =>
  apiGET({
    url: `/dashboards/${id}`,
  });

export const update = (id, data) =>
  apiPATCH({
    url: `/dashboards/${id}`,
    data,
  });

export const changePosition = ({ dashboard_id, boards_positions }) =>
  apiPATCH({
    url: '/profile/boards-positions',
    data: {
      dashboard_id,
      boards_positions,
    },
  });
