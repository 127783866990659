import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { updateDashboard as updateDashboardAction } from '../actions';
// api
import { update as updateApi } from '../api';

export function* updateSaga({ payload: { id, ...dashboardData } }) {
  try {
    yield put(updateDashboardAction.request());

    const { dashboard } = yield call(updateApi, id, dashboardData);

    yield put(updateDashboardAction.success({ dashboard }));
  } catch (error) {
    console.error(error);
    yield put(updateDashboardAction.failure(error.response));
  } finally {
    yield put(updateDashboardAction.fulfill());
  }
}

export default function* update() {
  yield takeLatest(updateDashboardAction.TRIGGER, updateSaga);
}
