import UaModal from 'components/common/ua-modal/ua-modal.component';
import UaSelect from 'components/common/ua-select/ua-select.component';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getBoards } from 'store/boards/selectors';

import './boards-modal.styles.scss';

const BoardsModal = (props) => {
  const { id } = useParams();
  const [selectedBoard, setSelectedBoard] = useState(null);
  const boards = useSelector(getBoards);

  const suggestions = useMemo(
    () =>
      boards.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id,
      })),
    [boards]
  );

  const onChange = (value) => {
    setSelectedBoard(value);
  };

  const onHide = () => {
    setSelectedBoard(id);
    props.onHide();
  };

  const onOk = () => {
    props.onOk(selectedBoard.id);
  };

  return (
    <UaModal
      {...props}
      dialogClassName='boards-modal'
      onShow={() => {
        setSelectedBoard(suggestions.find((item) => item.id === +id));
      }}
      onHide={onHide}
      onOk={onOk}>
      <UaSelect
        value={selectedBoard}
        suggestions={suggestions}
        onChange={onChange}
      />
    </UaModal>
  );
};

export default BoardsModal;
