import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { bool, func, object } from 'prop-types';
import noop from 'lodash/noop';

// styles
import './request-reset-form.styles.scss';
// components
import UaInput from 'components/common/ua-input/ua-input.component';
import UaButton from 'components/common/ua-button/ua-button.component';
// schemas
import RequestResetSchema from './RequestResetSchema';
// utils
import routes from 'utils/routes';
import { SUPPORT_EMAIL } from '../../../storage/consts';

const RequestResetForm = ({ onSubmit, isSubmitted, error, isLoading }) => {
  const formik = useFormik({
    validationSchema: RequestResetSchema,
    initialValues: {
      email: '',
    },
    onSubmit,
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  return isSubmitted ? (
    <div className='form form-card'>
      Reset password link was sent to your inbox
      <Link to={routes.login}>Go back</Link>
      <a href={`mailto:${SUPPORT_EMAIL}`} className='form__link btn btn-link'>
        Questions or issues? <br /> {SUPPORT_EMAIL}
      </a>
    </div>
  ) : (
    <form
      className='form form-auth form--with-hint request-reset-form'
      onSubmit={formik.handleSubmit}>
      <h1 className='form__caption'>Reset your password</h1>
      <p className='form__hint'>
        Fill in your email and we’ll send you a link to reset your password
      </p>
      <div className='form__controls'>
        <UaInput
          name='email'
          type='text'
          label='Email address'
          placeholder='Enter email'
          value={formik.values.email}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          showError={!!error.message}
          error={(formik.touched.email && formik.errors.email) || error.message}
          valid={formik.touched.email && !formik.errors.email}
        />
      </div>
      <div className='form__controls-row'>
        <div className='form__controls-group reset-go-back'>
          <Link to={routes.login} className='btn btn-light'>
            Go Back
          </Link>
        </div>
        <div className='form__controls-group'>
          <UaButton
            isLoading={isLoading}
            type='submit'
            variant='primary'
            className='btn-submit reset-submit'>
            Send a Reset Link
          </UaButton>
        </div>
      </div>
      <a href={`mailto:${SUPPORT_EMAIL}`} className='form__link btn btn-link'>
        Questions or issues? <br /> {SUPPORT_EMAIL}
      </a>
    </form>
  );
};

RequestResetForm.propTypes = {
  isLoading: bool,
  onSubmit: func,
  isSubmitted: bool,
  error: object,
};

RequestResetSchema.defaultProps = {
  isLoading: false,
  onSubmit: noop,
  isSubmitted: false,
};

export default RequestResetForm;
