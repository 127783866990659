import cloneDeep from 'lodash/cloneDeep';
// actions
import { downloadData, downloadDataRequest } from 'store/accounts/actions';

const INITIAL_STATE = {};

const downloadFilesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case downloadDataRequest.TRIGGER: {
      return {
        ...state,
        [payload]: {
          isLoading: true,
        },
      };
    }

    case downloadDataRequest.FAILURE: {
      return {
        ...state,
        [payload.account_id]: {
          isLoading: false,
          error: payload.error,
        },
      };
    }

    case downloadData.SUCCESS: {
      const copy = cloneDeep(state);
      delete copy[payload.account_id];

      return {
        ...copy,
      };
    }

    default:
      return state;
  }
};

export default downloadFilesReducer;
