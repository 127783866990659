import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import isEmpty from 'lodash/isEmpty';
import { array } from 'prop-types';
import { NavLink } from 'react-router-dom';
import routes from 'utils/routes';

import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import BoardEditModal from 'components/dashboard/board-edit-modal/board-edit-modal.component';
import BoardArchiveModal from 'components/dashboard/board-archive-modal/board-archive-modal.component';

import { ReactComponent as DragIcon } from 'assets/images/icons/menu-small.svg';
import { ReactComponent as MenuDots } from 'assets/images/icons/menu-dots.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as ArchiveIcon } from 'assets/images/icons/archive-solid.svg';

import getInitials from 'utils/initials';
import { draggable } from 'utils/draggable-sort';

import { dndBoardDashboard } from 'store/dashboards/actions';
import { getIsSubscribe } from 'store/accounts/selectors';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-alt.svg';
import BoardDeleteModal from 'components/dashboard/board-delete-modal/board-delete-modal.component';

const IModal = {
  edit: 1,
  archive: 2,
  delete: 3,
};

const DashboardDnDBoardsContainer = ({ boards, type }) => {
  const dispatch = useDispatch();
  const isSubscribe = useSelector(getIsSubscribe);
  const [isModal, setIsModal] = useState(null);

  const openModal = (type, id) => () => {
    setIsModal({ type, id });
  };

  const closeModal = () => {
    setIsModal(null);
  };

  const handleDrop = (dragResult) => {
    const result = draggable(dragResult);
    if (!result) return;

    const { from, to } = result;

    dispatch(
      dndBoardDashboard({
        from,
        to,
        boardId: dragResult.draggableId.split('-')[1],
        type,
      })
    );
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId='boardsSidebar'>
        {(provided) => (
          <ul
            ref={provided.innerRef}
            {...provided.droppableProps}
            className='ua-collapse__list'>
            {isEmpty(boards) ? (
              <li className='ua-collapse__list-item collapse-item'>
                List is empty
              </li>
            ) : (
              boards.map((board, index) => (
                <Draggable
                  draggableId={`boardsSidebar-${board.id}`}
                  isDragDisabled={!isSubscribe}
                  index={index}
                  key={`sidebar-board-item-${board.id}`}>
                  {(dragProvided) => (
                    <li
                      className='ua-collapse__list-item collapse-item'
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      ref={dragProvided.innerRef}>
                      {isSubscribe && (
                        <DragIcon className='collapse-item__drag-btn' />
                      )}
                      <NavLink
                        to={
                          routes.board.replace(':id', board.id) + '?view=list'
                        }
                        className='collapse-item__link'>
                        <div className='collapse-item__avatar'>
                          {getInitials(board.name)}
                        </div>
                        <p className='collapse-item__name'>{board.name}</p>
                      </NavLink>

                      {isSubscribe && (
                        <>
                          <UaDropdown
                            className='board-item__menu'
                            withIcon={false}
                            buttonContent={<MenuDots height={15} />}
                            drop='left'
                            dropdownVariant='dark'>
                            <Dropdown.Item
                              onClick={openModal(IModal.edit, board.id)}>
                              <EditIcon /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={openModal(IModal.archive, board.id)}>
                              <ArchiveIcon />{' '}
                              {board.is_archived ? 'Restore' : 'Archive'}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={openModal(IModal.delete, board.id)}>
                              <TrashIcon /> Delete
                            </Dropdown.Item>
                          </UaDropdown>

                          <BoardEditModal
                            board={board}
                            isShow={
                              isModal &&
                              isModal.type === IModal.edit &&
                              isModal.id === board.id
                            }
                            onClose={closeModal}
                          />

                          <BoardArchiveModal
                            board={board}
                            isShow={
                              isModal &&
                              isModal.type === IModal.archive &&
                              isModal.id === board.id
                            }
                            onClose={closeModal}
                          />

                          <BoardDeleteModal
                            board={board}
                            isShow={
                              isModal &&
                              isModal.type === IModal.delete &&
                              isModal.id === board.id
                            }
                            onClose={closeModal}
                          />
                        </>
                      )}
                    </li>
                  )}
                </Draggable>
              ))
            )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DashboardDnDBoardsContainer.propTypes = {
  boards: array,
};

export default DashboardDnDBoardsContainer;
