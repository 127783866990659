import { forwardRef } from 'react';

import './kanban-card.styles.scss';

const KanbanCard = forwardRef(({ children, ...otherProps }, ref) => {
  return (
    <div className='kanban-card-container' ref={ref} {...otherProps}>
      <div className='kanban-card'>{children}</div>
    </div>
  );
});

export default KanbanCard;
