import { object, string } from 'yup';

const SupportSchema = object().shape({
  email: string()
    .email('Invalid email address')
    .required('This field is required'),
  firstName: string().required('This field is required'),
  lastName: string().required('This field is required'),
  text: string().required('This field is required'),
});

export default SupportSchema;
