import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PLAN_SEAT_PREFIX,
  PLAN_UNLIMITED_PREFIX,
  PLAN_YEAR_PREFIX,
  PLANS_TYPES,
  STRIPE_INVOICE_TYPES,
} from 'settings';
import clsx from 'clsx';
import './billing-account-info.styles.scss';

import UaButton from 'components/common/ua-button/ua-button.component';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-circle-solid.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus-circle-solid.svg';
import { ReactComponent as CheckCircleOutline } from 'assets/images/icons/check-circle-outline.svg';
import { changePlan } from 'store/payment/actions';
import { keyBy } from 'lodash';
import {
  getAccountMembers,
  getCurrentAccount,
} from '../../../../store/accounts/selectors';
import { toast } from 'react-toastify';
import { getBoards } from '../../../../store/boards/selectors';
import UATooltip from '../../../common/ua-tooltip/ua-tooltip.components';
import { updateAccount } from '../../../../store/accounts/actions';
import { UaToggle } from '../../../common/ua-toggle/ua-toggle';
import { useMountedEffect } from '../../../../hooks/useMountedEffect';

const BillingAccountInfo = ({
  plans,
  subscription,
  account_id,
  cards,
  gotoAddCard,
  goBack,
}) => {
  const dispatch = useDispatch();
  const { members } = useSelector(getAccountMembers);
  const accountBoards = useSelector(getBoards);
  const currentAccount = useSelector(getCurrentAccount);
  const [seats, setSeats] = useState(getDefaultSeatsValue());
  const [isLoading, setIsLoading] = useState([]);
  const [yearlyType, setYearlyType] = useState(
    subscription.plan.slug.includes(PLAN_YEAR_PREFIX)
  );

  useMountedEffect(() => {
    setSeats(getDefaultSeatsValue());
  }, [subscription, currentAccount]);

  function getDefaultSeatsValue() {
    const perSeatPlan = plans.find((plan) =>
      plan.slug.includes(PLAN_SEAT_PREFIX)
    );

    if (subscription.plan.slug.includes(PLAN_UNLIMITED_PREFIX)) {
      return members.length < perSeatPlan?.seats
        ? perSeatPlan?.seats
        : members.length;
    }

    if (subscription.plan.slug === PLANS_TYPES.FREE) {
      return perSeatPlan?.seats ?? 3;
    }

    if (subscription.plan.slug.includes(PLAN_SEAT_PREFIX)) {
      return perSeatPlan?.seats;
    }

    return members.length > 3 ? members.length : 3;
  }

  const planList = useMemo(() => {
    return keyBy(plans, 'slug');
  }, [plans]);

  const selectedPerSeatPlan = useMemo(() => {
    const planName = !yearlyType
      ? PLANS_TYPES.MONTH_SEAT
      : PLANS_TYPES.YEAR_SEAT;
    return planList[planName];
  }, [planList, yearlyType]);

  const selectedUnlimitedPlan = useMemo(() => {
    const planName = !yearlyType
      ? PLANS_TYPES.MONTH_UNLIMITED
      : PLANS_TYPES.YEAR_UNLIMITED;
    return planList[planName];
  }, [planList, yearlyType]);

  const getPrice = (planType, seats) => {
    if (planType === PLANS_TYPES.YEAR_UNLIMITED) {
      const fixPlan = planList[PLANS_TYPES.YEAR_UNLIMITED];

      return fixPlan?.price / 100 / 12;
    }

    if (planType === PLANS_TYPES.MONTH_UNLIMITED) {
      const fixPlan = planList[PLANS_TYPES.MONTH_UNLIMITED];

      return fixPlan?.price / 100;
    }

    if (planType === PLANS_TYPES.YEAR_SEAT) {
      const fixPlan = planList[PLANS_TYPES.YEAR];
      const perSeatPlan = planList[PLANS_TYPES.YEAR_SEAT];

      return (fixPlan?.price + perSeatPlan?.price * seats) / 12 / 100;
    }

    if (planType === PLANS_TYPES.MONTH_SEAT) {
      const fixPlan = planList[PLANS_TYPES.MONTH];
      const perSeatPlan = planList[PLANS_TYPES.MONTH_SEAT];

      return (fixPlan?.price + perSeatPlan?.price * seats) / 100;
    }

    return 0;
  };

  const formatPrice = (price) => `$${Number(price).toFixed(2)}`;

  const getBtnLabel = (plan) => {
    if (subscription?.plan_id === plan.id) {
      if (
        subscription.plan.slug.includes(PLAN_SEAT_PREFIX) &&
        currentAccount.seats !== seats
      ) {
        return 'Update Seats';
      }

      return 'Current Plan';
    }

    if (subscription?.plan_id !== plan.id) {
      return 'Get Started Today';
    }
  };

  const turnOffLoading = (id) =>
    setIsLoading((prevState) => prevState.filter((_) => _ !== id));

  const changePlanSuccess = (invoice, planId) => {
    if (invoice?.status === STRIPE_INVOICE_TYPES.OPEN) {
      const link = document.createElement('a');
      link.href = invoice.hosted_invoice_url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    goBack();
    turnOffLoading(planId);
  };

  const onBtnClick = (plan) => () => {
    if (cards.length) {
      setIsLoading((prevState) => [...prevState, plan.id]);

      if (subscription?.plan_id === plan?.id) {
        if (subscription.plan.slug.includes(PLAN_SEAT_PREFIX)) {
          dispatch(
            updateAccount({
              accountId: account_id,
              data: {
                seats: seats,
              },
              success: () => changePlanSuccess(undefined, plan.id),
              error: () => turnOffLoading(plan.id),
            })
          );
        }
      } else {
        dispatch(
          changePlan({
            oldId: subscription.id,
            newId: plan.id,
            seats: plan.slug.includes(PLAN_SEAT_PREFIX) ? seats : undefined,
            success: ({ invoice }) => changePlanSuccess(invoice, plan.id),
            error: () => turnOffLoading(plan.id),
          })
        );
      }
    } else {
      gotoAddCard({
        oldId: subscription.id,
        newId: plan.id,
        seats: plan.slug.includes(PLAN_SEAT_PREFIX) ? seats : undefined,
        success: ({ invoice }) => changePlanSuccess(invoice, plan.id),
        error: () => turnOffLoading(plan.id),
      });
    }
  };

  const handleChangeSeats = (type) => () => {
    if (type === 'decrease') {
      if (seats <= 3) {
        return;
      }

      setSeats((prevValue) => prevValue - 1);
    }
    if (type === 'increase') {
      setSeats((prevValue) => {
        const newValue = prevValue + 1;

        if (
          getPrice(PLANS_TYPES.MONTH_SEAT, newValue) >
          getPrice(PLANS_TYPES.MONTH_UNLIMITED)
        ) {
          toast('At 16+ users, the Unlimited plan is cheaper', {
            autoClose: 2000,
            pauseOnHover: false,
            hideProgressBar: true,
            closeButton: true,
            type: 'warning',
          });

          return prevValue;
        }

        return newValue;
      });
    }
  };

  const handleChangePlanType = () => {
    setYearlyType((prevState) => !prevState);
  };

  const getIsNotAvailableToGetFreePlan = () => {
    if (
      subscription.plan.slug.includes(PLAN_UNLIMITED_PREFIX) ||
      subscription.plan.slug.includes(PLAN_SEAT_PREFIX)
    ) {
      return (
        members.length > planList[PLANS_TYPES.FREE]?.seats ||
        accountBoards.length > planList[PLANS_TYPES.FREE]?.boards
      );
    }

    return false;
  };

  const getIsNotAvailableToGetPerSeatPlan = () => {
    if (subscription.plan.slug.includes(PLAN_UNLIMITED_PREFIX)) {
      return members.length > seats;
    }

    return seats < members.length;
  };

  const isCurrentPlanFree = subscription.plan.slug === PLANS_TYPES.FREE;
  const isNotAvailableToGetFreePlan = getIsNotAvailableToGetFreePlan();
  const isNotAvailableToGetPerSeatPlan = getIsNotAvailableToGetPerSeatPlan();
  const isUpdatingSeatsAvailable = subscription.plan.slug.includes(
    PLAN_SEAT_PREFIX
  )
    ? seats >= members.length
    : true;
  const canUpdateSeats =
    selectedPerSeatPlan?.slug === subscription.plan?.slug
      ? currentAccount.seats !== seats
      : true;

  return (
    <div>
      <div className='billing-account-info__type-switcher'>
        Monthly{' '}
        <UaToggle
          id='plan-type'
          checked={yearlyType}
          onChange={handleChangePlanType}
        />
        Yearly - Save 16%!
      </div>
      <div className='billing-account-info__list'>
        {planList[PLANS_TYPES.FREE] && (
          <div className='billing-account-info billing__card'>
            <h3 className='billing-account-info__title billing__title'>Free</h3>

            <div className='billing-account-info__price billing-price'>
              <p className='billing-price__number'>$0</p>
              <span className='billing-price__period'>per year</span>
            </div>

            <div className='billing-account-info__feature-list feature-list'>
              <div className='feature-list__item'>
                <CheckCircleOutline className='feature-list__icon' />
                <span>2 users</span>
              </div>
              <div className='feature-list__item'>
                <CheckCircleOutline className='feature-list__icon' />
                <span>1 board</span>
              </div>
              <div className='feature-list__item'>
                <CheckCircleOutline className='feature-list__icon' />
                <span>unlimited projects</span>
              </div>
            </div>

            <UaButton
              type='button'
              isLoading={isLoading.includes(planList[PLANS_TYPES.FREE]?.id)}
              onClick={
                isNotAvailableToGetFreePlan || isCurrentPlanFree
                  ? undefined
                  : onBtnClick(planList[PLANS_TYPES.FREE])
              }
              className={clsx('billing-account-info__start-now', {
                'billing-account-info__start-now--grey':
                  isNotAvailableToGetFreePlan,
              })}>
              {getBtnLabel(planList[PLANS_TYPES.FREE])}
              {isNotAvailableToGetFreePlan && (
                <UATooltip
                  label='You cannot downgrade to the free tier until you delete all but
                  1 board and 2 users from your account'>
                  <InfoIcon className='billing__card__info-icon' />
                </UATooltip>
              )}
            </UaButton>
          </div>
        )}
        <div className='billing-account-info billing__card'>
          <h3 className='billing-account-info__title billing__title'>
            Per seat
          </h3>

          <div className='billing-account-info__price billing-price'>
            {!yearlyType && (
              <p className='billing-price__number'>
                {formatPrice(getPrice(PLANS_TYPES.MONTH_SEAT, seats))}
              </p>
            )}
            {yearlyType && (
              <p className='billing-price__number'>
                {formatPrice(getPrice(PLANS_TYPES.YEAR_SEAT, seats))}
              </p>
            )}
            <span className='billing-price__period'>per month</span>
          </div>

          <div className='billing-account-info__feature-list feature-list'>
            <div className='feature-list__item'>
              <CheckCircleOutline className='feature-list__icon' />
              <span>
                {seats} users
                <button
                  className='billing-account-info__seat-button'
                  onClick={handleChangeSeats('decrease')}>
                  <MinusIcon />
                </button>
                <button
                  className='billing-account-info__seat-button'
                  onClick={handleChangeSeats('increase')}>
                  <PlusIcon />
                </button>
              </span>
            </div>
            <div className='feature-list__item'>
              <CheckCircleOutline className='feature-list__icon' />
              <span>unlimited boards</span>
            </div>
            <div className='feature-list__item'>
              <CheckCircleOutline className='feature-list__icon' />
              <span>unlimited projects</span>
            </div>
          </div>

          <UaButton
            type='button'
            isLoading={
              isLoading.includes(planList[PLANS_TYPES.MONTH_SEAT]?.id) ||
              isLoading.includes(planList[PLANS_TYPES.YEAR_SEAT]?.id)
            }
            onClick={
              isNotAvailableToGetPerSeatPlan ||
              !isUpdatingSeatsAvailable ||
              !canUpdateSeats
                ? undefined
                : onBtnClick(selectedPerSeatPlan)
            }
            className={clsx('billing-account-info__start-now', {
              'billing-account-info__start-now--grey':
                isNotAvailableToGetPerSeatPlan || !isUpdatingSeatsAvailable,
            })}>
            {selectedPerSeatPlan && getBtnLabel(selectedPerSeatPlan)}
            {(isNotAvailableToGetPerSeatPlan || !isUpdatingSeatsAvailable) && (
              <UATooltip label='Please go to Manage Users and delete enough seats to allow downgrading.'>
                <InfoIcon className='billing__card__info-icon' />
              </UATooltip>
            )}
          </UaButton>
        </div>

        <div className='billing-account-info billing__card'>
          <h3 className='billing-account-info__title billing__title'>
            Unlimited
          </h3>

          <div className='billing-account-info__price billing-price'>
            {!yearlyType && (
              <p className='billing-price__number'>
                {formatPrice(getPrice(PLANS_TYPES.MONTH_UNLIMITED))}
              </p>
            )}
            {yearlyType && (
              <p className='billing-price__number'>
                {formatPrice(getPrice(PLANS_TYPES.YEAR_UNLIMITED))}
              </p>
            )}
            <span className='billing-price__period'>per month</span>
          </div>

          <div className='billing-account-info__feature-list feature-list'>
            <div className='feature-list__item'>
              <CheckCircleOutline className='feature-list__icon' />
              <span>unlimited users</span>
            </div>
            <div className='feature-list__item'>
              <CheckCircleOutline className='feature-list__icon' />
              <span>unlimited boards</span>
            </div>
            <div className='feature-list__item'>
              <CheckCircleOutline className='feature-list__icon' />
              <span>unlimited projects</span>
            </div>
          </div>

          <UaButton
            type='button'
            isLoading={
              isLoading.includes(planList[PLANS_TYPES.YEAR_UNLIMITED]?.id) ||
              isLoading.includes(planList[PLANS_TYPES.MONTH_UNLIMITED]?.id)
            }
            onClick={
              subscription.plan.slug === selectedUnlimitedPlan?.slug
                ? undefined
                : onBtnClick(selectedUnlimitedPlan)
            }
            className='billing-account-info__start-now'>
            {selectedUnlimitedPlan && getBtnLabel(selectedUnlimitedPlan)}
          </UaButton>
        </div>
      </div>
    </div>
  );
};

export default BillingAccountInfo;
