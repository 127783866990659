import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
// actions
import { changePlan as action } from '../actions';
// api
import { changePlan as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    payload.success(response);

    toast('Plan have been changed successfully', {
      autoClose: 10000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'success',
    });

    yield put(action.success(response));
  } catch (error) {
    console.error(error);

    payload.error();

    if (error.response.data.rawType === 'invalid_request_error') {
      toast(error.response.data.raw.message, {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error',
      });
    }

    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* changePlan() {
  yield takeLatest(action.TRIGGER, getSaga);
}
