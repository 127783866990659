import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { updateAccountUser as action } from '../actions';
// api
import { updateAccountUser as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());
    const response = yield call(api, payload);
    yield put(action.success(response));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* updateAccountUser() {
  yield takeLatest(action.TRIGGER, getSaga);
}
