import { put, takeEvery, call, select } from 'redux-saga/effects';
// actions
import { setIsFavoriteBoard as setIsFavoriteAction } from '../actions';
// api
import { setIsFavorite as setIsFavoriteAPI } from '../api';
// selectors
import { getFavoriteBoards } from '../selectors';

import { changePosition } from 'store/dashboards/actions';
import {
  getBoardPositions,
  getCurrentDashboard,
} from 'store/dashboards/selectors';

function* setIsFavoriteSaga({ payload: { id, is_favorite } }) {
  try {
    const favoriteBoards = yield select(getFavoriteBoards);
    const { id: dashboard_id } = yield select(getCurrentDashboard);
    const { favorites } = yield select(getBoardPositions);

    if (is_favorite) {
      const newFavorite = [...favorites];
      newFavorite.push({ id, order: favoriteBoards.length });

      yield put(
        changePosition({
          dashboard_id,
          boards_positions: { favorites: newFavorite },
        })
      );
    } else {
      const newFavorite = [...favorites];
      const index = newFavorite.findIndex((item) => item.id === id);
      newFavorite.splice(index, 1);

      yield put(
        changePosition({
          dashboard_id,
          boards_positions: { favorites: newFavorite },
        })
      );
    }

    yield put(setIsFavoriteAction.request());
    yield call(setIsFavoriteAPI, {
      boardId: id,
      isFavorite: is_favorite,
    });

    yield put(setIsFavoriteAction.success({ id, is_favorite }));
  } catch (error) {
    console.error(error);
    yield put(setIsFavoriteAction.failure(error.response));
  } finally {
    yield put(setIsFavoriteAction.fulfill());
  }
}

export default function* add() {
  yield takeEvery(setIsFavoriteAction.TRIGGER, setIsFavoriteSaga);
}
