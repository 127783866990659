import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getProfile, signIn as signInAction } from '../actions';
// api
import { signIn as signInApi } from '../api';
// services
import getErrorMessage from '../../services/getErrorMessages';
import history from '../../../history';
import { setAccessToken } from '../services/tokenService';
import routes from 'utils/routes';

function* signInSaga({ payload: userData }) {
  try {
    yield put(signInAction.request());

    const user = yield call(signInApi, userData);

    yield call(setAccessToken, user.accessToken);

    yield put(getProfile());

    yield put(signInAction.success({ user }));

    history.push(routes.dashboard);
  } catch (error) {
    console.error(error);
    yield put(signInAction.failure(getErrorMessage(error.response)));
  } finally {
    yield put(signInAction.fulfill());
  }
}

export default function* signIn() {
  yield takeLatest(signInAction.TRIGGER, signInSaga);
}
