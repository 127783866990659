import {put, takeLatest, call, select} from 'redux-saga/effects';

import { editTag as editTagAction } from '../actions';
import { editTag as editTagApi } from '../api';

import { getSaga as getTags } from './getTags';
import {getBoardSocket} from 'store/socket/selectors';

function* editSaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(editTagAction.request());

    const tag = yield call(editTagApi, payload);

    yield put(editTagAction.success(tag));

    yield call(getTags, { payload: { boardId: payload.boardId } });
    payload.callback();
  } catch (error) {
    console.error(error);
    yield put(editTagAction.failure(error.response));
  } finally {
    yield put(editTagAction.fulfill());
  }
}

export default function* editTag() {
  yield takeLatest(editTagAction.TRIGGER, editSaga);
}
