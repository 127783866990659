import { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';

import ProjectFilter from '../filter.component';
import Tag from 'components/dashboard/tag/tag.component';
import UaDatepicker from 'components/common/ua-datepicker/ua-datepicker.component';

import { ReactComponent as CalendarIcon } from 'assets/images/icons/date.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';

import { dueDatesList } from 'storage/consts';

const DropdownIndicator = () => <AngleDown width='28px' height='28px' />;

const CUSTOM = 'Custom';

const FilterDate = ({ value, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [showDatepicker, setShowDatepicker] = useState(false);

  useEffect(() => {
    const isEmpty = !Object.keys(value || {}).length;
    if (!isEmpty && !value.label) {
      setSelectedOptions({
        ...value,
        id: 6,
        label: CUSTOM,
      });
    } else {
      setSelectedOptions(value);
    }
  }, [value]);

  useEffect(() => {
    if (!value && !!selectedOptions) {
      setSelectedOptions(null);
    }
  }, [value, selectedOptions]);

  const handleChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
    if (selectedOption?.label !== CUSTOM) {
      onChange(selectedOption);
    }
  };

  const isCustomDateRange = useMemo(() => {
    return selectedOptions?.label === CUSTOM;
  }, [selectedOptions]);

  const CustomOption = (props) => {
    return (
      <div
        className={`project-filter-select__option ${
          props.isFocused ? 'project-filter-select__option--focused' : ''
        }`}
        {...props.innerProps}
        onClick={(e) => {
          if (props.data.datepicker) {
            setShowDatepicker(true);
          }
          props.innerProps.onClick(e);
        }}>
        <Tag text={props.data.label} variant='dark' />
      </div>
    );
  };

  const getCustomLabel = (label) => {
    if (selectedOptions.gte) {
      return `${label}(${selectedOptions.gte} - ${selectedOptions.lte})`;
    } else {
      return `${label}(Up to ${selectedOptions.lte})`;
    }
  };

  return (
    <ProjectFilter
      icon={<CalendarIcon />}
      name='Due Date'
      body={
        <>
          <Select
            className='project-filter-select'
            classNamePrefix='project-filter-select'
            options={dueDatesList}
            name='date'
            value={selectedOptions}
            isSearchable={false}
            isClearable={true}
            menuPlacement='top'
            placeholder='Select a date'
            components={{
              DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: null,
              Option: CustomOption,
            }}
            onChange={handleChange}
            getOptionLabel={(option) =>
              !isCustomDateRange ? option.label : getCustomLabel(option.label)
            }
          />
          <UaDatepicker
            show={showDatepicker}
            onHide={() => setShowDatepicker(false)}
            date={value?.gte}
            endDate={value?.lte}
            range={true}
            onDateChange={(dates) => {
              if (isCustomDateRange) {
                setSelectedOptions({
                  ...selectedOptions,
                  gte: dates.startDate,
                  lte: dates.endDate,
                });
              }
              onChange({ gte: dates.startDate, lte: dates.endDate });
            }}
          />
        </>
      }
    />
  );
};

export default FilterDate;
