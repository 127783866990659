import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelMoveProjects,
  moveProjects,
  preMoveProjects,
} from 'store/projects/actions';
import { BoardContext } from 'context/board-context/board-context';
import { EnumProjectModals } from 'settings';
import BoardsModal from 'components/board/boards-modal/boards-modal.component';
import BoardDuplicateTagsModal from '../../board/board-duplicate-tags-modal/board-duplicate-tags-modal.component';
import { getProjectsCrossedTags } from 'store/projects/api';
import { getProjectByIds } from 'store/projects/selectors';
import { toast } from 'react-toastify';
import Notification from 'components/common/ua-modal/notification.conponent';

const ProjectMoveModal = () => {
  const dispatch = useDispatch();
  const {
    checkedProjects,
    setCheckedProjects,
    isActionModalOpen,
    onCloseActionModal,
    onOpenActionModal,
  } = useContext(BoardContext);

  const [selectedBoard, setSelectedBoard] = useState(null);
  const [moveProjectList, setMoveProjectList] = useState([]);

  const projects = useSelector((state) =>
    getProjectByIds(state, checkedProjects)
  );

  const handleBefore = () => {
    dispatch(preMoveProjects(checkedProjects));
  };

  const handleCancel = () => {
    dispatch(cancelMoveProjects());
  };

  const handleOk = () => {
    moveProjectsToBoard(selectedBoard, moveProjectList);
  };

  const handleChangeTagName = (value, projectId, index) => {
    const projects = moveProjectList.map(project => {
      if (project.id === projectId) {
        project.duplicatedTags[index].name = value;
      }
      return project;
    });

    setMoveProjectList(projects);
  };

  const onOk = (boardId, moveProjectList) => {
    moveProjectsToBoard(boardId, moveProjectList);
  };

  const onHide = () => {
    onCloseActionModal();
  };

  const checkProjectsCrossedTags = async (boardId) => {
    setSelectedBoard(boardId);
    const moveProjectList = [];
    let hasDuplicates = false;
    await Promise.all(
      projects.map(async (project) => {
        if (project.tags.length) {
          const crossedTags = await getProjectsCrossedTags({
            id: project.id,
            boardId,
          });
          const tags = [];
          const duplicatedTags = [];
          project.tags.forEach((tag) => {
            const newTag = {
              name: tag.name,
              color: tag.color,
            };
            if (crossedTags.find((item) => item.id === tag.id)) {
              hasDuplicates = true;
              duplicatedTags.push(newTag);
            } else {
              tags.push(newTag);
            }
          });

          return moveProjectList.push({
            id: project.id,
            name: project.name,
            tags,
            duplicatedTags,
          });
        }
      })
    );

    setMoveProjectList(moveProjectList);
    if (hasDuplicates) {
      onOpenActionModal(EnumProjectModals.DUPLICATE)();
    } else {
      handleBefore();
      notify({ boardId, moveProjectList });
    }
  };

  const moveProjectsToBoard = (boardId, moveProjectList) => {
    let tags = {};
    moveProjectList.map((project) => {
      let data = [...project.tags, ...project.duplicatedTags];
      return (tags[project.id] = data);
    });

    dispatch(moveProjects({ ids: checkedProjects, boardId, tags }));
    onCloseActionModal();
    setCheckedProjects([]);
  };

  const notify = ({ boardId, moveProjectList }) => {
    let deleteAfterClose = true;
    toast.success(
      <Notification
        text={'Project(s) will be moved'}
        onUndo={() => {
          handleCancel();
          onHide();
          deleteAfterClose = false;
        }}
        timer={3000}
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeOnClick: false,
        draggable: false,
        progress: undefined,
        onOpen: () => {
          onHide();
        },
        onClose: () => {
          if (deleteAfterClose) {
            document.activeElement.blur();
            onOk(boardId, moveProjectList);
          }
          deleteAfterClose = true;
        },
      }
    );
  };

  return (
    <>
      <BoardsModal
        show={isActionModalOpen === EnumProjectModals.MOVE}
        title='Move project to:'
        onHide={onCloseActionModal}
        onOk={checkProjectsCrossedTags}
        cancelAction={handleCancel}
        beforeAction={handleBefore}
      />

      <BoardDuplicateTagsModal
        show={isActionModalOpen === EnumProjectModals.DUPLICATE}
        title='Duplicate Tags'
        onHide={onCloseActionModal}
        notification={{ text: 'Project(s) will be moved' }}
        onOk={handleOk}
        okLabel='Save all'
        onChangeTagName={handleChangeTagName}
        boardId={selectedBoard}
        projects={moveProjectList}
        beforeAction={handleBefore}
        cancelAction={handleCancel}
      />
    </>
  );
};

export default ProjectMoveModal;
