import React from 'react';
import DatePicker from 'react-datepicker';
import { addYears, getYear, getMonth, formatISO } from 'date-fns';

import './ua-datepicker.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';

import { ReactComponent as PointerIcon } from 'assets/images/icons/pointer-right.svg';

const UaDatepicker = ({
  date,
  endDate,
  onDateChange,
  onHide,
  range,
  ...modalProps
}) => {
  const currentDate = new Date();
  const renderDayContents = (day) => {
    return <>{day < 10 ? `0${day}` : day}</>;
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleClearDate = () => {
    onDateChange(range ? { startDate: '', endDate: '' } : null);
    onHide();
  };

  const handleSelectDate = (date) => {
    if (range) {
      let [start, end] = date;
      if (start && !end) {
        onDateChange({
          startDate: formatISO(start, { representation: 'date' }),
        });
      }
      if (start && end) {
        onDateChange({
          startDate: formatISO(new Date(start), { representation: 'date' }),
          endDate: formatISO(end, { representation: 'date' }),
        });
        onHide();
      }
    } else {
      onDateChange(formatISO(date, { representation: 'date' }));
      onHide();
    }
  };

  return (
    <UaModal
      onHide={onHide}
      {...modalProps}
      noFooter={true}
      dialogClassName='ua-datepicker-modal'>
      <DatePicker
        calendarClassName='ua-datepicker'
        inline={true}
        open={true}
        selected={new Date(date || currentDate)}
        startDate={date}
        endDate={endDate}
        selectsRange={range}
        onChange={handleSelectDate}
        maxDate={addYears(currentDate, 100)}
        renderDayContents={renderDayContents}
        renderCustomHeader={({
          date,
          decreaseMonth,
          decreaseYear,
          increaseMonth,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
        }) => (
          <div className='ua-datepicker__header'>
            <div className='ua-datepicker__header-col'>
              <button
                className='ua-datepicker__prev-button ua-datepicker__header-button'
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}>
                <PointerIcon />
              </button>
              <span className='ua-datepicker__header-label ua-datepicker__header-label--month'>
                {months[getMonth(date)].substring(0, 3)}
              </span>

              <button
                className='ua-datepicker__next-button ua-datepicker__header-button'
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}>
                <PointerIcon />
              </button>
            </div>
            <div className='ua-datepicker__header-col'>
              <button
                className='ua-datepicker__prev-button ua-datepicker__header-button'
                onClick={decreaseYear}
                disabled={prevYearButtonDisabled}>
                <PointerIcon />
              </button>
              <span className='ua-datepicker__header-label'>
                {getYear(date)}
              </span>
              <button
                className='ua-datepicker__next-button ua-datepicker__header-button'
                onClick={increaseYear}
                disabled={nextYearButtonDisabled}>
                <PointerIcon />
              </button>
            </div>
          </div>
        )}
      />
      <button className='ua-datepicker__clear-date' onClick={handleClearDate}>
        Clear Date
      </button>
    </UaModal>
  );
};

export default UaDatepicker;
