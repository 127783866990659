import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { addBoard } from 'store/boards/actions';
import { getProfile } from 'store/auth/selectors';
import { getIsAccountDashboard } from 'store/dashboards/selectors';

import UaInput from 'components/common/ua-input/ua-input.component';
import UaModal from 'components/common/ua-modal/ua-modal.component';
import CreateBoardSchema from 'components/dashboard/create-board/create-board.validate';
import { toast } from 'react-toastify';
import { LimitAddBoardsModal } from '../../../modals/limit-add-boards-modal.component';
import { getCurrentSubscription } from '../../../store/accounts/selectors';

const CreateBoardButton = ({
  className,
  label = 'Create a New Board',
  totalBoards,
}) => {
  const dispatch = useDispatch();

  const { account_id } = useSelector(getProfile);
  const isAccountDashboard = useSelector(getIsAccountDashboard);
  const currentSubscription = useSelector(getCurrentSubscription);

  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const toastId = useRef(null);
  const limitModalRef = useRef();

  const formik = useFormik({
    initialValues: {
      board: '',
    },
    validationSchema: CreateBoardSchema,
    onSubmit: (values) => {
      dispatch(addBoard({ accountId: account_id, name: values.board }));
      setShow(false);
    },
  });

  useEffect(() => {
    if (!show) formik.resetForm();
    if (show) ref.current.focus();
  }, [show]);

  const onAddBoard = () => {
    if (
      currentSubscription.plan.boards &&
      totalBoards >= currentSubscription.plan.boards
    ) {
      limitModalRef.current?.onOpenModal();
      return;
    }

    setShow(true);
  };

  const handleChange = (event) => {
    if (event.target.value.length > 50) {
      if (!toastId.current) {
        toastId.current = toast('Maximum board name length - 50 characters', {
          autoClose: 2000,
          pauseOnHover: false,
          hideProgressBar: true,
          closeButton: true,
          type: 'error',
          onClose: () => (toastId.current = null),
        });
      } else {
        toast.update(toastId.current);
      }
    } else {
      formik.handleChange(event);
    }
  };

  if (!isAccountDashboard) return null;

  return (
    <>
      <LimitAddBoardsModal ref={limitModalRef} />
      <button
        className={`create-board-button ${className || ''}`}
        onClick={onAddBoard}>
        {label}
      </button>

      <UaModal
        show={show}
        onHide={() => setShow(false)}
        title='Create a New Board'
        okLabel='Save'
        onSubmit={formik.handleSubmit}>
        <UaInput
          ref={ref}
          type='text'
          name='board'
          label='Board Name'
          value={formik.values.board}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
        />
      </UaModal>
    </>
  );
};

export default CreateBoardButton;
