import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

// styles
import './reset-password.styles.scss';
// components
import RequestResetForm from 'components/auth/request-reset-form/request-reset-form.component';
import ResetPasswordForm from 'components/auth/reset-password-form/reset-password-form.component';
// hooks
import useQuery from 'hooks/useQuery';
import useAlert from 'hooks/useAlert';
// actions
import {
  requestResetPass,
  resetPassword,
  removeErrors,
} from 'store/auth/actions';
// selectors
import { getAuthState } from 'store/auth/selectors';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const showResetForm = useMemo(() => query.has('token'), [query]);

  const { error, isSuccessResetPassword, isLoading } =
    useSelector(getAuthState);
  const alertProps = useAlert({ isShow: error.message });

  useEffect(() => {
    dispatch(removeErrors());
  }, [dispatch, showResetForm]);

  const handleSubmitRequestReset = (values) => {
    dispatch(requestResetPass(values));
  };

  const handleSubmitReset = (values) => {
    dispatch(
      resetPassword({
        ...values,
        token: query.get('token'),
        email: query.get('email'),
      })
    );
  };

  return (
    <>
      <Alert variant='danger' {...alertProps}>
        {error.message}
      </Alert>

      {showResetForm ? (
        <ResetPasswordForm
          onSubmit={handleSubmitReset}
          error={error}
          isLoading={isLoading}
        />
      ) : (
        <RequestResetForm
          onSubmit={handleSubmitRequestReset}
          isSubmitted={isSuccessResetPassword}
          isLoading={isLoading}
          error={error}
        />
      )}
    </>
  );
};

export default ResetPasswordPage;
