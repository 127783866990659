import { object, string } from 'yup';

const SignInSchema = object().shape({
  email: string()
    .email('Invalid email address')
    .required('This field is required'),
  password: string().required('This field is required'),
});

export default SignInSchema;
