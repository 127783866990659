import { logout } from 'store/auth/actions';
import {
  getClients,
  addClient,
  editClient,
  deleteClient,
} from './actions';

const INITIAL_STATE = {
  list: [],
  getLoading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
};

const clientsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    //region Get list
    case getClients.TRIGGER:
      return {
        ...state,
        getLoading: true,
      };

    case getClients.SUCCESS:
      return {
        ...state,
        list: payload,
      };

    case getClients.FULFILL:
      return {
        ...state,
        getLoading: false,
      };
    //endregion

    //region Add client
    case addClient.TRIGGER:
      return {
        ...state,
        addLoading: true,
      };

    case addClient.SUCCESS: {
      const clients = [...state.list];
      const index = clients.findIndex(({ id }) => id === payload.id);

      if (index === -1) {
        clients.push(payload);
      }

      return {
        ...state,
        list: clients
      };
    }

    case addClient.FULFILL:
      return {
        ...state,
        addLoading: false,
      };
    //endregion

    //region Edit client
    case editClient.TRIGGER:
      return {
        ...state,
        editLoading: true,
      };

    case editClient.SUCCESS: {
      const clients = [...state.list];
      const index = clients.findIndex(
        ({ id }) => id === payload.id
      );

      if (index !== -1) {
        clients.splice(index, 1, payload);
      }

      return {
        ...state,
        list: clients
      };
    }

    case editClient.FULFILL:
      return {
        ...state,
        editLoading: false,
      };
    //endregion

    //region Delete client
    case deleteClient.TRIGGER:
      return {
        ...state,
        deleteLoading: true,
      };

    case deleteClient.SUCCESS: {
      const clients = [...state.list];
      const index = clients.findIndex(
        ({ id }) => id === payload
      );

      if (index !== -1) {
        clients.splice(index, 1);
      }

      return {
        ...state,
        list: clients
      };
    }

    case deleteClient.FULFILL:
      return {
        ...state,
        deleteLoading: false,
      };
    //endregion

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default clientsReducer;
