import { select, takeEvery } from 'redux-saga/effects';

import { getCurrentAccount } from '../../accounts/actions';
import { getAccountSocket, getBoardSocket } from '../selectors';
import { getCurrentAccount as currentAccountSelector } from '../../accounts/selectors';

function* flow({ payload }) {
  const socket = yield select(getAccountSocket);
  const boardSocket = yield select(getBoardSocket);
  const { id } = yield select(currentAccountSelector);

  if (payload.id) {
    if (socket) {
      socket.emit('leaveRoom', id);
      socket.emit('joinRoom', payload.id);
    }
    if (boardSocket) {
      boardSocket.emit('leaveRoom', id);
      boardSocket.emit('joinRoom', payload.id);
    }
  }
}

export default function* rootSaga() {
  yield takeEvery(getCurrentAccount.SUCCESS, flow);
}
