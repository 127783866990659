import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getProfile as getProfileAction } from '../actions';
// api
import { getProfile as getProfileApi } from '../api';
// services
import getErrorMessage from '../../services/getErrorMessages';

function* getProfileSaga({ payload: userData }) {
  try {
    yield put(getProfileAction.request());

    const profile = yield call(getProfileApi, userData);

    yield put(getProfileAction.success({ profile }));
  } catch (error) {
    console.error(error);
    yield put(getProfileAction.failure(getErrorMessage(error.response)));
  } finally {
    yield put(getProfileAction.fulfill());
  }
}

export default function* getProfile() {
  yield takeLatest(getProfileAction.TRIGGER, getProfileSaga);
}
