import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import './client-list.styles.scss';

import Search from 'components/search/search.component';

import useFuse from 'hooks/useFuse';
import { getClientsLoading } from 'store/clients/selectors';
import UaButton from 'components/common/ua-button/ua-button.component';
import ClientItem from 'components/clients-modal/client-item/client-item.component';
import AddClient from 'components/clients-modal/add-client/add-client.component';
import useSearch from 'hooks/useSearch';

const ClientsList = ({
  clients,
  selectedClient,
  onAddClient,
  onClientSelect,
  onClientClear,
  onEditClient,
  onDeleteClient,
}) => {
  const { getLoading } = useSelector(getClientsLoading);

  const [search, debounceSearch, setSearch] = useSearch('');
  const [editingField, setEditingField] = useState(null);

  const clientsSearchResults = useFuse(clients, debounceSearch, {
    threshold: 0.05,
    ignoreLocation: true,
    keys: ['name'],
  });

  const onSubmitEditing = ({ name }) => {
    onEditClient({
      id: editingField.id,
      name,
    });
    setEditingField(null);
  };

  const renderList = () => {
    if (getLoading) return <Spinner animation='border' role='status' />;

    if (!clientsSearchResults.length)
      return (
        <li className='user-search-list__item-empty'>Clients list are empty</li>
      );

    return clientsSearchResults.map((client) => (
      <ClientItem
        key={client.id}
        client={client}
        selectedClient={selectedClient}
        onClientSelect={onClientSelect}
        onEditClient={onEditClient}
        onDeleteClient={onDeleteClient}
        editingField={editingField}
        setEditingField={setEditingField}
      />
    ));
  };

  return editingField ? (
    <AddClient
      onSubmit={onSubmitEditing}
      onCancel={() => setEditingField(null)}
      defaultName={editingField.name}
      submitLabel='Save'
    />
  ) : (
    <div className='client-list-container'>
      <Search
        id='userSearch'
        className='user-search'
        value={search}
        handleSearch={(e) => setSearch(e.target.value)}
        type='text'
        placeholder='Search'
        handleClearField={() => setSearch('')}
        showClearField
      />
      <ul className='client-list'>{renderList()}</ul>
      <UaButton className='mb-2' variant='secondary' onClick={onClientClear}>Clear client</UaButton>
      <UaButton onClick={onAddClient}>Add client</UaButton>
    </div>
  );
};

export default ClientsList;
