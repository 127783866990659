import {put, takeLatest, call, select} from 'redux-saga/effects';

import { archiveProjects as archiveProjectsAction } from '../actions';

import { archiveProjects as archiveProjectsApi } from '../api';
import { getBoard } from '../../boards/actions';
import {getProjectSocket} from 'store/socket/selectors';

function* archiveSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    const socketId = socket.id;

    yield put(archiveProjectsAction.request());

    const response = yield call(archiveProjectsApi, {ids: payload.projects, socketId});

    yield put(archiveProjectsAction.success({ ids: payload.projects }));

    if (response) {
      yield put(getBoard({ id: response.data[0].board_id }));
    }
  } catch (error) {
    console.error(error);
    yield put(archiveProjectsAction.failure(error.response));
  } finally {
    yield put(archiveProjectsAction.fulfill());
  }
}

export default function* archive() {
  yield takeLatest(archiveProjectsAction.TRIGGER, archiveSaga);
}
