import React, { useEffect } from 'react';

export function useMountedEffect(fn, deps) {
  const mountedRef = React.useRef(false);

  useEffect(() => {
    if (mountedRef.current) {
      fn();
    }
    mountedRef.current = true;
    // eslint-disable-next-line
  }, deps);
}
