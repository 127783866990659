import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import useQuery from 'hooks/useQuery';
import routes from 'utils/routes';

import UAButton from 'components/common/ua-button/ua-button.component';

import { ownerAccept } from 'store/accounts/actions';
import { getOwnerAcceptStatus } from 'store/accounts/selectors';
import { isAuth as getAuth } from 'store/auth/selectors';

const OwnerAcceptPage = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { accountId, userId } = useParams();
  const history = useHistory();
  const { isLoading, error } = useSelector(getOwnerAcceptStatus);
  const isAuth = useSelector(getAuth);

  const token = query.get('token');

  const callback = () => {
    toast('Successful accept transfer ownership', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      type: 'success',
    });

    history.push('/');
  };

  useEffect(() => {
    dispatch(
      ownerAccept({
        accountId,
        userId,
        token,
        callback,
      })
    );
  }, []);

  const onRetryPress = () =>
    dispatch(
      ownerAccept({
        accountId,
        userId,
        token,
        callback,
      })
    );

  if (isLoading) {
    return (
      <div className='form form-card form-card--verification form-card--verification-loading'>
        <div className='loader-container'>
          <Spinner animation='border' role='status' />
        </div>
      </div>
    );
  }

  if (error && error !== 404) {
    return (
      <div className='form form-card form-card--verification'>
        <p>Owner accepting unsuccessful, please try again.</p>
        <UAButton onClick={onRetryPress}>Retry</UAButton>
      </div>
    );
  }

  return (
    <div className='form form-card form-card--verification'>
      <p>Something wrong.</p>
      {!isAuth && <Link to={routes.login}>Go to login page</Link>}
      {isAuth && <Link to={routes.dashboard}>Go to home page</Link>}
    </div>
  );
};

export default OwnerAcceptPage;
