/* eslint-disable no-unused-vars */
import { put, takeLatest, call, select } from 'redux-saga/effects';
// actions
import { editBoard as editBoardAction } from '../actions';
// api
import { editBoard as editBoardApi } from '../api';
// selectors
import {
  getFavoriteBoards,
  getActiveBoards,
  getArchivedBoards
} from '../selectors';

import { changePosition } from 'store/dashboards/actions';
import {
  getBoardPositions,
  getCurrentDashboard,
} from 'store/dashboards/selectors';
import {getBoardSocket} from 'store/socket/selectors';

export function* editSaga({ payload }) {
  try {
    // set position in dashboard (update is_archived)
    if ('is_archived' in payload) {
      const favoriteBoards = yield select(getFavoriteBoards);
      const activeBoards = yield select(getActiveBoards);
      const archiveBoards = yield select(getArchivedBoards);

      const { id: dashboard_id } = yield select(getCurrentDashboard);
      const { favorites, all, archived } = yield select(getBoardPositions);

      if (payload.is_archived) {
        const favIndex = favorites.findIndex((item) => item.id === payload.id);
        favorites.splice(favIndex, 1);

        const allIndex = all.findIndex((item) => item.id === payload.id);
        all.splice(allIndex, 1);

        archived.push({ id: payload.id, order: archiveBoards.length });
      } else {
        if (payload.is_favorite) {
          favorites.push({ id: payload.id, order: favoriteBoards.length });
        }

        all.push({ id: payload.id, order: activeBoards.length });

        const archivedIndex = archived.findIndex(
          (item) => item.id === payload.id
        );
        archived.splice(archivedIndex, 1);
      }

      yield put(
        changePosition({
          dashboard_id,
          boards_positions: { favorites, all, archived },
        })
      );
    }

    yield put(editBoardAction.request());
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;
    const board = yield call(editBoardApi, payload);
    yield put(editBoardAction.success({ ...board, ...payload }));
  } catch (error) {
    console.error(error);
    yield put(editBoardAction.failure(error.response));
  } finally {
    yield put(editBoardAction.fulfill());
  }
}

export default function* edit() {
  yield takeLatest(editBoardAction.TRIGGER, editSaga);
}
