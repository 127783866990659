import { put, takeEvery, call, select } from 'redux-saga/effects';

import { deleteFile as deleteFileAction } from '../actions';

import { deleteFile as deleteFileApi } from '../api';
import { getProjectSocket } from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(deleteFileAction.request());

    yield call(deleteFileApi, payload);

    yield put(deleteFileAction.success(payload));
  } catch (error) {
    console.error(error);
    yield put(deleteFileAction.failure(error.response));
  } finally {
    yield put(deleteFileAction.fulfill());
  }
}

export default function* deleteFile() {
  yield takeEvery(deleteFileAction.TRIGGER, addSaga);
}
