import React, { useState } from 'react';
import useSearch from 'hooks/useSearch';
import { EnumBoardPopups } from 'settings';

const DEFAULT_LIST_OPTIONS = {
  projectCard: true,
  archivedProjects: true,
  completedProjects: true,
  projectTitle: true,
  projectTeam: true,
  client: true,
  priority: true,
  tags: true,
  status: true,
  dueDate: true,
};

const DEFAULT_PROJECT_FILTERS = {
  tags: {
    include: [],
    exclude: [],
    isStrict: false,
  },
  members: {
    include: [],
    exclude: [],
    isStrict: false,
  },
  clients: [],
  statuses: [],
  priorities: [],
  filter: [],
};

const DEFAULT_WIDGET_FILTERS = {
  showInfo: [],
  showUpdates: [],
  showFiles: [],
  showTasks: [],
};

const DEFAULT_PROJECT_OPTION = {
  status: false,
  files: false,
  tasks: false,
};

const BoardContext = React.createContext();

function BoardContextProvider({ children, isGuest = false }) {
  const [isCardShow, setIsCardShow] = useState(null);
  const [projectCardId, setProjectCardId] = useState(null);
  const [projectCardOptions, setProjectCardOptions] = useState(
    DEFAULT_PROJECT_OPTION
  );
  const [listShowOption, setListShowOption] = useState(DEFAULT_LIST_OPTIONS);
  const [kanbanHiddenColumns, setKanbanHiddenColumns] = useState(false);
  const [projectFilter, setProjectFilter] = useState(DEFAULT_PROJECT_FILTERS);
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [widgetFilters, setWidgetFilters] = useState(DEFAULT_WIDGET_FILTERS);
  const [tableSortingList, setTableSortingList] = useState([]);
  const [tableSortingShift, setTableSortingShift] = useState(0);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);

  const [searchValue, queryValue, setSearch, resetSearch] = useSearch('');

  const onResetOptions = () => {
    setProjectCardOptions(DEFAULT_PROJECT_OPTION);
  };

  const onOpenBoardCard = (param) => () => {
    if (isCardShow === param) {
      setIsCardShow(null);
    } else {
      setIsCardShow(param);
    }
  };

  const onCloseBoardCard = () => {
    setIsCardShow(null);
    setProjectCardId(null);
  };

  const onSelectProjectCard = (project, option) => {
    if (
      projectCardId === project.id &&
      isCardShow === EnumBoardPopups.project &&
      !option
    ) {
      setIsCardShow(null);
      setProjectCardId(null);
    } else {
      setIsCardShow(EnumBoardPopups.project);
      setProjectCardId(project.id);
      if (option) {
        setProjectCardOptions((prev) => ({ ...prev, ...option }));
      } else {
        onResetOptions();
      }
    }
  };

  const onListShowChange = (key) => {
    setListShowOption((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onClearProjectFilter = () => {
    setProjectFilter(DEFAULT_PROJECT_FILTERS);
    resetSearch();
  };

  const onChangeProjectFilter = (newValue) => {
    setProjectFilter((prev) => ({ ...prev, ...newValue }));
  };

  const onOpenActionModal = (modal) => () => {
    setIsActionModalOpen(modal);
  };

  const onCloseActionModal = () => {
    setIsActionModalOpen(null);
  };

  const providedValue = {
    isGuest,
    isCardShow,
    onOpenBoardCard,
    onCloseBoardCard,
    projectCardId,
    onResetOptions,
    projectCardOptions,
    setIsCardShow,
    setProjectCardId,
    onSelectProjectCard,
    listShowOption,
    setListShowOption,
    onListShowChange,
    kanbanHiddenColumns,
    setKanbanHiddenColumns,
    projectFilter,
    setProjectFilter,
    onClearProjectFilter,
    onChangeProjectFilter,
    checkedProjects,
    setCheckedProjects,
    widgetFilters,
    setWidgetFilters,
    searchValue,
    queryValue,
    setSearch,
    tableSortingList,
    setTableSortingList,
    tableSortingShift,
    setTableSortingShift,
    isActionModalOpen,
    onOpenActionModal,
    onCloseActionModal,
  };

  return (
    <BoardContext.Provider value={providedValue}>
      {children}
    </BoardContext.Provider>
  );
}

export {
  BoardContext,
  BoardContextProvider,
  DEFAULT_LIST_OPTIONS,
  DEFAULT_PROJECT_FILTERS,
};
