import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
// actions
import { updateAccount as action } from '../actions';
// api
import { updateAccount as api } from '../api';

function* getSaga({ payload }) {
  try {
    const { success, error, ...rest } = payload;
    yield put(action.request());

    const response = yield call(api, rest);

    if (payload.data.name) {
      toast('Name changed', {
        autoClose: 1000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        type: 'success',
      });
    }

    yield put(action.success({ response, data: payload.data }));
    success && success();
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
    error && error();
  } finally {
    yield put(action.fulfill());
  }
}

export default function* updateAccount() {
  yield takeLatest(action.TRIGGER, getSaga);
}
