import {put, takeLatest, call, select} from 'redux-saga/effects';

import { unarchiveProjects as unarchiveProjectsAction } from '../actions';

import { unarchiveProjects as unarchiveProjectsApi } from '../api';
import { getBoard } from '../../boards/actions';
import {getProjectSocket} from 'store/socket/selectors';

function* unarchiveSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    const socketId = socket.id;

    yield put(unarchiveProjectsAction.request());

    yield call(unarchiveProjectsApi, {ids: payload.projects, socketId});
    if (payload.boardId) yield put(getBoard({ id: payload.boardId }));

    yield put(unarchiveProjectsAction.success({ ids: payload.projects }));
  } catch (error) {
    console.error(error);
    yield put(unarchiveProjectsAction.failure(error.response));
  } finally {
    yield put(unarchiveProjectsAction.fulfill());
  }
}

export default function* unarchive() {
  yield takeLatest(unarchiveProjectsAction.TRIGGER, unarchiveSaga);
}
