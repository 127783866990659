import { call, put, select, takeEvery } from '@redux-saga/core/effects';
import { sortBy } from 'lodash';
import { editProject as editProjectAction } from 'store/projects/actions';
import { dndBoardProject as dndBoardProjectAction } from '../actions';
import {
  getBoard,
  getProjectsPositions,
  getProjectsPositionsByStatus,
} from '../selectors';
import { editSaga as editBoard } from './edit';
import { editSaga as editProject } from 'store/projects/sagas/edit';
import { changePositions } from 'utils/draggable-sort';
import { getProjects } from 'store/projects/selectors';

const filterProjectPositions = (projectsPositions, projects) => {
  // Filter from duplicates
  const filteredPositions = projectsPositions.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t['status_id'] === thing['status_id'] && t['project_id'] === thing['project_id']
      ))
  );

  return projects.reduce((output, { id, status }) => {
    if (status?.id) {
      const order = filteredPositions.find((item) => item.project_id === id && item.status_id === status?.id);
      const emptyOrder = filteredPositions.filter((item) => item.status_id === status?.id);

      output.push({
        project_id: id,
        status_id: status?.id,
        order: order ? order.order : emptyOrder.length,
      });
    }

    return output;
  }, []);
};

function* dndBoardProjectSaga({ payload }) {
  const { fromStatus, toStatus, fromIndex, toIndex, projectId } = payload;

  try {
    const board = yield select(getBoard);
    const projectsPositions = yield select(getProjectsPositions);
    const projects = yield select(getProjects);

    if (fromStatus === toStatus) {
      const filter = filterProjectPositions(projectsPositions, projects);

      const positionsByStatus = filter.filter((item) => item.status_id === fromStatus);

      const dropArea = changePositions(sortBy(positionsByStatus, ['order']), {
        from: fromIndex,
        to: toIndex,
      });

      const newPositions = dropArea.map((props, index) => {
        return { ...props, order: index };
      });

      const oldPosition = filter.filter((item) => item.status_id !== fromStatus);

      const newProjectsPositions = [
        ...oldPosition,
        ...newPositions,
      ];

      yield put(dndBoardProjectAction.success(newProjectsPositions));

      yield call(editBoard, {
        payload: { id: board.id, projects_positions: newProjectsPositions },
      });
    } else {
      const newProjectStatus = board.statuses.find(({ id }) => id === toStatus);
      const positionsByFromStatus = yield select(
        getProjectsPositionsByStatus(fromStatus)
      );
      const positionsByToStatus = yield select(
        getProjectsPositionsByStatus(toStatus)
      );

      const fromArea = sortBy(positionsByFromStatus, ['order']);
      fromArea.splice(fromIndex, 1);
      const newFromArea = fromArea.map((item, index) => ({
        ...item,
        order: index,
      }));

      const toArea = sortBy(positionsByToStatus, ['order']);
      toArea.splice(toIndex, 0, {
        project_id: projectId,
        status_id: toStatus,
        order: toIndex,
      });
      const newToArea = toArea.map((item, index) => {
        return index > toIndex ? { ...item, order: item.order + 1 } : item;
      });

      const newProjectsPositions = [
        ...projectsPositions.filter(
          ({ status_id }) => ![fromStatus, toStatus].includes(status_id)
        ),
        ...newFromArea,
        ...newToArea,
      ];

      yield put(dndBoardProjectAction.success(newProjectsPositions));

      yield put(
        editProjectAction.success({ id: projectId, status: newProjectStatus })
      );

      yield call(editProject, {
        payload: { id: projectId, data: { status_id: toStatus } },
      });

      yield call(editBoard, {
        payload: { id: board.id, projects_positions: newProjectsPositions },
      });
    }
  } catch (error) {
    console.error(error);
    yield put(dndBoardProjectAction.failure(error.response));
  } finally {
    yield put(dndBoardProjectAction.fulfill());
  }
}

export default function* dndBoardProject() {
  yield takeEvery(dndBoardProjectAction.TRIGGER, dndBoardProjectSaga);
}
