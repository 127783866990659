import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getClients as action } from '../actions';
// api
import { getClients as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());

    const users = yield call(api, payload);

    yield put(action.success(users));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getClients() {
  yield takeLatest(action.TRIGGER, getSaga);
}
