import Select from 'react-select';

import ProjectFilter from '../filter.component';
import Tag from 'components/dashboard/tag/tag.component';

import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-circle.svg';

import { useSelector } from 'react-redux';
import { getStatuses } from 'store/projects/selectors';
import useFilter from 'hooks/useFilter';

const DropdownIndicator = () => <AngleDown width='28px' height='28px' />;

const FilterStatus = ({ value, onChange }) => {
  const statuses = useSelector(getStatuses);
  const filter = useFilter({ options: statuses, selected: value, onChange });

  const CustomOption = (props) => {
    return (
      <div
        className={`project-filter-select__option ${
          props.isFocused ? 'project-filter-select__option--focused' : ''
        }`}
        {...props.innerProps}>
        <Tag
          text={props.data.name}
          color={props.data.color}
        />
      </div>
    );
  };

  return (
    <ProjectFilter
      icon={<InfoIcon />}
      name='Status'
      body={
        <>
          {value.length > 0 ? (
            <div className='project-filter-selected-items project-filter-selected-items--flex'>
              {value.map((item) => (
                <div className='project-filter-selected-item'>
                  <Tag
                    text={item.name}
                    color={item.color}
                  />
                  <button
                    onClick={filter.onDelete(item)}
                    className='project-filter-selected-item__delete'>
                    <CloseIcon height={20} width={20} />
                  </button>
                </div>
              ))}
            </div>
          ) : null}
          <Select
            className='project-filter-select'
            classNamePrefix='project-filter-select'
            value=''
            options={filter.suggestions}
            name='tag'
            isSearchable={false}
            isClearable={false}
            menuPlacement='top'
            placeholder='Select a status'
            components={{
              DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: null,
              Option: CustomOption,
            }}
            onChange={filter.onChange}
          />
        </>
      }
    />
  );
};

export default FilterStatus;
