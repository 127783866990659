import { createSelector } from 'reselect';

import { getProfile } from 'store/auth/selectors';
import { getAccounts } from 'store/accounts/selectors';

// constants
import { DASHBOARD_TYPES } from './consts';
import { ROLES } from 'settings';

export const getDashboardsState = (state) => state.dashboards;
export const getDashboards = (state) => getDashboardsState(state).list;
export const getDashboardLoading = (state) => getDashboardsState(state).isLoading;
export const getIsAccountDashboard = (state) =>
  getDashboardsState(state).isAccount;

export const getIsDashboardOwner = createSelector(
  getAccounts,
  getProfile,
  (accounts, { account_id }) => {
    const currentAccount = accounts.find(({ id }) => id === account_id);
    return currentAccount?.account_users[0].role.slug === ROLES.OWNER;
  }
);

export const getCurrentDashboard = createSelector(
  getDashboards,
  getIsAccountDashboard,
  getProfile,
  (list, isAccount, { account_id }) => {
    if (!isAccount) {
      return (
        list?.find(({ type }) => type === DASHBOARD_TYPES[isAccount]) || {}
      );
    } else {
      return (
        list?.find(
          ({ account_id: dashboardAccountId }) =>
            account_id === dashboardAccountId
        ) || {}
      );
    }
  }
);

export const getBoardPositions = createSelector(
  getDashboardsState,
  getCurrentDashboard,
  ({ boards_positions }, { id }) =>
    boards_positions[id] || { archived: [], all: [], favorites: [] }
);

export const isPersonalDashboardSelector = createSelector(
  getCurrentDashboard,
  ({ type }) => type === 'personal'
);
