import { put, takeEvery, call } from 'redux-saga/effects';
// actions
import { shareBoard as action } from '../actions';
// services
import { shareBoard as api } from '../api';
import { toast } from 'react-toastify';

function* shareBoardSaga({ payload }) {
  try {
    yield put(action.request());
    yield call(api, payload);
    toast('Link Successfully Sent', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'success',
    });
    payload.success();
    yield put(action.success());
  } catch (error) {
    console.error(error);
    toast('Something went wrong', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'error',
    });
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* add() {
  yield takeEvery(action.TRIGGER, shareBoardSaga);
}
