import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { NavLink, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';
import routes from 'utils/routes';

import './dashboard.styles.scss';

import PageTitle from 'components/page-title/page-title.component';
import Logo from 'components/logo/logo.component';
import SupportForm from 'components/settings/support-form/support-form.component';
import UaModal from 'components/common/ua-modal/ua-modal.component';
import Search from 'components/search/search.component';
import ProfileDropdown from 'components/dashboard/profile-dropdown/profile-dropdown.component';
import CreateBoardButton from 'components/dashboard/create-board/create-board.component';
import DashboardDnDBoardsContainer from './dashboards-dnd-boards-container.component';

import smallLogo from 'assets/images/logo-small.png';
import { ReactComponent as Menu } from 'assets/images/icons/menu.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import { ReactComponent as GraphIcon } from 'assets/images/icons/dashboard.svg';
import { ReactComponent as FolderIcon } from 'assets/images/icons/folder.svg';
import { ReactComponent as CaretIcon } from 'assets/images/icons/caret.svg';
import { ReactComponent as BoxIcon } from 'assets/images/icons/archive.svg';
import { ReactComponent as SupportIcon } from 'assets/images/icons/support.svg';
import { ReactComponent as GuideIcon } from 'assets/images/icons/book.svg';
import dashboardIcon from 'assets/images/icons/dashboard.svg';

import {
  getActiveBoards,
  getArchivedBoards,
  getBoardsSearch,
} from 'store/boards/selectors';
import { setBoardsSearch } from 'store/boards/actions';
import { switchAccount } from 'store/accounts/actions';
import {
  getCurrentDashboard,
  getDashboardLoading,
  getIsAccountDashboard,
} from 'store/dashboards/selectors';
import { logout } from 'store/auth/actions';
import { getDashboard, toggleTypeDashboard } from 'store/dashboards/actions';
import getAuthStatus from 'utils/getAuthStatus';
import useFuse from '../../hooks/useFuse';
import { ReactComponent as HeadphonesIcon } from 'assets/images/icons/headphones.svg';
import { getSidebarState } from 'store/app/selectors';
import { openAppSidebar, toggleAppSidebar } from 'store/app/actions';
import PageLoader from 'components/page-loader/page-loader.component';
import { useWindowSize } from '../../hooks/useWindowSize';

const DashboardLayout = ({
  children,
  title,
  icon,
  withSearch,
  withDashboardSwitcher,
}) => {
  const dispatch = useDispatch();
  const allBoards = useSelector(getActiveBoards);
  const archivedBoards = useSelector(getArchivedBoards);
  const { id } = useSelector(getCurrentDashboard);
  const boardsSearch = useSelector(getBoardsSearch);
  const isAuth = getAuthStatus();
  const isAccountDashboard = useSelector(getIsAccountDashboard);
  const isSidebarOpen = useSelector(getSidebarState);
  const isDashboardLoading = useSelector(getDashboardLoading);
  const history = useHistory();
  const windowSize = useWindowSize();

  const [sidebarSearch, setSidebarSearch] = useState('');
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [tabBoards, setTabBoards] = useState({
    list: false,
    archived: false,
  });

  const activeProjects = useFuse(allBoards, sidebarSearch, {
    threshold: 0.05,
    ignoreLocation: true,
    keys: ['name'],
  });
  const archiveProjects = useFuse(archivedBoards, sidebarSearch, {
    threshold: 0.05,
    ignoreLocation: true,
    keys: ['name'],
  });

  useEffect(
    () =>
      history.listen(() => {
        setMobileSidebarOpen(false);
      }),
    [history]
  );

  useEffect(() => {
    if (id) dispatch(getDashboard({ id }));
  }, [id, dispatch]);

  const toggleTab = (name) => {
    setTabBoards((tabs) => ({
      ...tabs,
      [name]: !tabs[name],
    }));
  };

  const handleSearchClick = () => {
    if (!isSidebarOpen) {
      dispatch(openAppSidebar());
    }
    setTimeout(() => {
      document.getElementById('sidebarSearch').focus();
    }, 300);
  };

  const toggleSidebar = () => {
    if (window.innerWidth >= 1025) {
      dispatch(toggleAppSidebar());
    } else {
      setMobileSidebarOpen(!isMobileSidebarOpen);
    }
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const onAccountSwitch = (accountId) => {
    dispatch(switchAccount(parseInt(accountId)));
    history.push(routes.dashboard);
  };

  const handleChangeDashboardType = () => {
    dispatch(toggleTypeDashboard());
  };

  const onDashboardSearch = (e) => {
    dispatch(setBoardsSearch(e.target.value));
  };

  const onDashboardClearSearch = () => {
    dispatch(setBoardsSearch(''));
  };

  const onOpenSupport = () => setIsSupportOpen(true);
  const onCloseSupport = () => setIsSupportOpen(false);

  return (
    <div
      style={{ maxHeight: windowSize.height }}
      className={clsx('layout layout-dashboard', {
        'layout-dashboard--sidebar-open': isMobileSidebarOpen,
      })}>
      <PageTitle title='Dashboard' />

      <aside
        className={clsx('layout__sidebar sidebar', {
          'sidebar--closed': !isSidebarOpen && isAuth,
          'sidebar--show': isAuth,
        })}>
        {isAuth && (
          <>
            <div className='sidebar__header'>
              <div className='sidebar__logo'>
                <NavLink to={routes.dashboard}>
                  <Logo className='logo-big' width={145} height={40} />
                  <Logo
                    className='logo-small'
                    src={smallLogo}
                    width={33}
                    height={40}
                  />
                </NavLink>
              </div>
            </div>
            <div className='sidebar__header-menu-wrap'>
              <div className='sidebar__profile'>
                <ProfileDropdown
                  collapse
                  logout={onLogout}
                  onAccountSwitch={onAccountSwitch}
                />
              </div>
              <nav className='sidebar__menu menu'>
                <div className='menu-item menu-item--search'>
                  <div className='menu-item__truncate'>
                    <button className='sidebar-btn' onClick={handleSearchClick}>
                      <SearchIcon />
                    </button>
                  </div>
                  <Search
                    id='sidebarSearch'
                    tabIndex={!isSidebarOpen ? -1 : undefined}
                    className='menu-item__expanded'
                    value={sidebarSearch}
                    onChange={(e) => setSidebarSearch(e.target.value)}
                    handleClearField={() => setSidebarSearch('')}
                    showClearField
                    type='text'
                    placeholder='Search'
                  />
                </div>
                <div className='menu-item'>
                  <NavLink exact to={routes.dashboard} className='sidebar-link'>
                    <GraphIcon /> <span>Dashboard</span>
                  </NavLink>
                </div>
                {isDashboardLoading ? (
                  <PageLoader classname='sidebar-loader' />
                ) : (
                  <>
                    <div className='menu-item'>
                      <div className='ua-collapse'>
                        <div
                          className='ua-collapse__label'
                          onClick={() => toggleTab('list')}>
                          <div className='sidebar-link'>
                            <FolderIcon /> <span>Boards</span>
                          </div>
                          <span
                            className={clsx('ua-collapse__toggler', {
                              'ua-collapse__toggler--close': tabBoards['list'],
                            })}>
                            <CaretIcon />
                          </span>
                        </div>
                        <div
                          className={clsx('ua-collapse__content', {
                            'ua-collapse__content--close': tabBoards['list'],
                          })}>
                          <div className='ua-collapse__content-inner'>
                            <CreateBoardButton
                              className='ua-collapse__button'
                              totalBoards={allBoards.length}
                              title='Create a New Board'
                              label='Create a New Board'
                            />
                            <DashboardDnDBoardsContainer
                              boards={activeProjects}
                              type='all'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='menu-item'>
                      <div className='ua-collapse'>
                        <div
                          className='ua-collapse__label'
                          onClick={() => toggleTab('archived')}>
                          <div className='sidebar-link'>
                            <BoxIcon /> <span>Archive</span>
                          </div>
                          <span
                            className={clsx('ua-collapse__toggler', {
                              'ua-collapse__toggler--close':
                                tabBoards['archived'],
                            })}>
                            <CaretIcon />
                          </span>
                        </div>
                        <div
                          className={clsx('ua-collapse__content', {
                            'ua-collapse__content--close':
                              tabBoards['archived'],
                          })}>
                          <div className='ua-collapse__content-inner'>
                            <DashboardDnDBoardsContainer
                              boards={archiveProjects}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </nav>
            </div>

            <div className='support-link-container menu-item'>
              <a
                className='help-link'
                href='https://www.unmanage.io/userguide'
                target='_blank'
                rel='noreferrer'>
                <GuideIcon />
                <span>User Guide</span>
              </a>
              <NavLink
                exact
                to={`${routes.settings}?tab=support`}
                className='help-link'>
                <HeadphonesIcon />
                <span>Support</span>
              </NavLink>
            </div>
          </>
        )}
      </aside>
      {isAuth ? (
        <header className='layout__header'>
          <button
            className='navigation-toggler'
            onClick={() => toggleSidebar()}>
            <Menu />
          </button>
          <div className='page-title'>
            {title === 'Dashboard' ? (
              <NavLink exact to={routes.dashboard} className='page-title-link'>
                {icon && (
                  <img className='page-icon' src={icon} alt='page icon' />
                )}
                {title}
              </NavLink>
            ) : (
              <>
                {icon && (
                  <img className='page-icon' src={icon} alt='page icon' />
                )}
                {title}
              </>
            )}
          </div>
          {withSearch ? (
            <Search
              handleSearch={onDashboardSearch}
              value={boardsSearch}
              placeholder='Search in Boards'
              name='search'
              handleClearField={onDashboardClearSearch}
              showClearField
            />
          ) : null}
          {withDashboardSwitcher ? (
            <div className='layout__header-controls'>
              <div className='dashboard-account-switch'>
                <Form.Check
                  type='switch'
                  id='account-switch-header'
                  label={
                    isAccountDashboard
                      ? 'Account Dashboard'
                      : 'Personal Dashboard'
                  }
                  checked={isAccountDashboard}
                  onChange={handleChangeDashboardType}
                />
              </div>
            </div>
          ) : null}
          <ProfileDropdown
            logout={onLogout}
            onAccountSwitch={onAccountSwitch}
          />
        </header>
      ) : (
        <header className='layout__header layout__header--guest'>
          <div className='page-title'>
            <img
              className='page-icon'
              src={icon || dashboardIcon}
              alt='page icon'
            />
          </div>
          <div className='header-controls__container'>
            <div className='header-controls__container-support'>
              <Button
                className='header-controls__support'
                variant='outline-secondary'
                onClick={onOpenSupport}>
                <SupportIcon />
                Contact Support
              </Button>
            </div>
            <Button
              className='header-controls__login'
              variant='outline-primary'
              as={NavLink}
              to={routes.login}>
              Log In
            </Button>
            <Button
              className='header-controls__register'
              variant='primary'
              as={NavLink}
              to={routes.registration}>
              Sign Up
            </Button>
          </div>
        </header>
      )}
      <div className='layout__content'>
        <main className='layout__main'>
          {withDashboardSwitcher ? (
            <div className='layout__main-controls'>
              <div className='dashboard-account-switch'>
                <Form.Check
                  type='switch'
                  id='account-switch-main'
                  label={
                    isAccountDashboard
                      ? 'Account Dashboard'
                      : 'Personal Dashboard'
                  }
                  checked={isAccountDashboard}
                  onChange={handleChangeDashboardType}
                />
              </div>
            </div>
          ) : null}
          {withSearch ? (
            <Search
              handleSearch={onDashboardSearch}
              value={boardsSearch}
              placeholder='Search in Boards'
              name='search'
              handleClearField={onDashboardClearSearch}
              showClearField
            />
          ) : null}
          {children}
        </main>
      </div>
      <ToastContainer closeButton={false} />

      <UaModal
        show={isSupportOpen}
        onHide={onCloseSupport}
        noFooter
        dialogClassName='support-form-modal'
        okLabel='Update'>
        <SupportForm onClose={onCloseSupport} />
      </UaModal>
    </div>
  );
};

export default React.memo(DashboardLayout);
