import { useEffect, useMemo, useRef } from 'react';
import Fuse from 'fuse.js';

export default function useFuse(initialValue, search, options) {
  const fuse = useRef(new Fuse(initialValue, options));

  useEffect(() => {
    fuse.current.setCollection(initialValue);
  }, [initialValue]);

  const searchResult = useMemo(
    () =>
      search
        ? fuse.current.search(search).map(({ item }) => item)
        : initialValue,
    [search, initialValue]
  );

  return searchResult;
}
