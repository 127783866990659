import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getTags as getTagsAction } from '../actions';
// api
import { getTags as getTagsApi } from '../api';

export function* getSaga({ payload }) {
  try {
    yield put(getTagsAction.request());

    const tags = yield call(getTagsApi, payload);

    yield put(getTagsAction.success(tags));
  } catch (error) {
    console.error(error);
    yield put(getTagsAction.failure(error.response));
  } finally {
    yield put(getTagsAction.fulfill());
  }
}

export default function* getTags() {
  yield takeLatest(getTagsAction.TRIGGER, getSaga);
}
