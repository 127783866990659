import { put, takeEvery, call, select } from 'redux-saga/effects';

import { uploadFile as uploadFileAction } from '../actions';

import { uploadFile as uploadFileApi } from '../api';
import { getProjectSocket } from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    yield put(uploadFileAction.request());

    const response = yield call(uploadFileApi, payload);

    yield put(
      uploadFileAction.success({
        projectId: payload.projectId,
        file: response,
        socketId: socket.id,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(uploadFileAction.failure(error.response));
  } finally {
    yield put(uploadFileAction.fulfill());
  }
}

export default function* uploadFile() {
  yield takeEvery(uploadFileAction.TRIGGER, addSaga);
}
