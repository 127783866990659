import { apiGET, apiPOST, apiDELETE, apiPATCH } from '../services/api';

export const getClients = (id) =>
  apiGET({
    url: `/accounts/${id}/clients`,
  });

export const addClient = ({ account_id, name, user_id, socketId }) =>
  apiPOST({
    url: `/accounts/${account_id}/clients`,
    data: {
      user_id,
      name,
    },
    headers: {
      'socket-client-id': socketId,
    },
  });

export const editClient = ({ id, name, user_id, socketId }) =>
  apiPATCH({
    url: `/clients/${id}`,
    data: {
      name,
      user_id,
    },
    headers: {
      'socket-client-id': socketId,
    },
  });

export const deleteClient = ({ id, socketId }) =>
  apiDELETE({
    url: `/clients/${id}`,
    headers: {
      'socket-client-id': socketId,
    },
  });
