import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import { editBoard } from 'store/boards/actions';

import BoardEditModalSchema from './board-edit-modal.validate';

const BoardEditModal = ({ board: { name, id }, isShow, onClose }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const formik = useFormik({
    validationSchema: BoardEditModalSchema,
    initialValues: { name },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(editBoard({ id, name: values.name.trim() }));
      onClose();
    },
  });

  useEffect(() => {
    if (!isShow) formik.resetForm();
    if (isShow) ref.current.focus();
  }, [isShow]);

  return (
    <UaModal
      show={isShow}
      onHide={onClose}
      title='Editing'
      okLabel='Save'
      onSubmit={formik.handleSubmit}>
      <UaInput
        ref={ref}
        type='text'
        name='name'
        label='Change board name'
        value={formik.values.name}
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        valid={formik.touched.name && !formik.errors.name}
        showError
      />
    </UaModal>
  );
};

export default BoardEditModal;
