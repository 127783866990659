const getInitials = (name) => {
  if (name?.trim().length) {
    const [firstWord, secondWord] = name.trim().split(' ');

    return `${firstWord[0].toUpperCase()}${
      secondWord ? secondWord[0].toUpperCase() : ''
    }`;
  } else {
    return '';
  }
};

export default getInitials;
