import React from 'react';

import './kpi-card.styles.scss';

import { ReactComponent as Cog } from '../../../assets/images/icons/cog.svg';

const KpiCard = ({ overview, icon, onClick, disable = false }) => {
  return (
    <div className={`kpi-card ${overview.type}`} onClick={onClick}>
      <img className='kpi-card__icon' src={icon} alt='' />
      <div className='kpi-card__info'>
        <div className='kpi-card__info-count'>{overview.count}</div>
        <div className='kpi-card__info-label'>{overview.label}</div>
      </div>
      {!disable && (
        <button className='kpi-card__settings'>
          <Cog />
        </button>
      )}
    </div>
  );
};

export default KpiCard;
