import './modal-container.styles.scss';

import UaModal from '../common/ua-modal/ua-modal.component';

const ModalContainer = ({ children, show, onHide }) => {
  return (
    <UaModal
      dialogClassName='modal-container'
      onHide={onHide}
      show={show}
      noFooter={true}
    >
      {children}
    </UaModal>
  );
};

export default ModalContainer;
