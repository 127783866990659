export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const LOGROCKET_KEY = process.env.REACT_APP_LOGROCKET_KEY;
export const NAME_APP = 'UN-MANAGE_APP';
export const MAIN_TITLE = 'Unmanage';
export const TITLES = {
  board: 'Board',
  kanban: 'Kanban Board',
  dashboard: 'Dashboard',
  settings: 'Settings',
  login: 'Login',
  registration: 'Registration',
  resetPassword: 'Reset Password',
  emailVerification: 'Email Verification',
  emailConfirmation: 'Email Confirmation',
  ownerAccess: 'Owner access',
  boardGuest: 'Guest board',
};

export const EnumBoardPopups = {
  project: 'project',
  filter: 'filter',
  setting: 'setting',
};

export const EnumProjectModals = {
  COPY: 'copy',
  MOVE: 'move',
  DELETE: 'delete',
  ARCHIVE: 'archive',
  DUPLICATE: 'duplicate-tags',
};

export const STRIPE_INVOICE_TYPES = {
  UPCOMING: 'upcoming',
  OPEN: 'open',
  PAID: 'paid',
  DRAFT: 'draft',
};

export const PLAN_SEAT_PREFIX = 'seat';
export const PLAN_YEAR_PREFIX = 'year';
export const PLAN_UNLIMITED_PREFIX = 'unlimited';

export const PLANS_TYPES = {
  FREE: 'free',
  YEAR: 'year',
  MONTH: 'month',
  YEAR_SEAT: 'year_seat',
  MONTH_SEAT: 'month_seat',
  YEAR_UNLIMITED: 'unlimited_year',
  MONTH_UNLIMITED: 'unlimited_month',
};

export const ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  USER: 'user',
};

export const STATUSES = {
  NEW: 'new',
  ON_TRACK: 'on-track',
  ON_HOLD: 'on-hold',
  INFO_NEEDED: 'info-needed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const BOARD_VIEW = {
  LIST: 'list',
  KANBAN: 'kanban',
};

export const ORDER_TYPES = {
  FAVORITES: 'favorites',
  ALL: 'all',
  ARCHIVED: 'archived',
};

export const MOBILE_QUERY = '(max-width: 480px)';
export const TABLET_QUERY = '(max-width: 1024px)';
