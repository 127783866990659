import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import './share-board-modal.styles.scss';

import UaInput from 'components/common/ua-input/ua-input.component';
import UaModal from 'components/common/ua-modal/ua-modal.component';
import routes from '../../../utils/routes';
import UaButton from '../../common/ua-button/ua-button.component';
import { useFormik } from 'formik';
import { shareBoard } from 'store/boards/actions';
import { toast } from 'react-toastify';
import { copyToClipboard } from 'utils/copyToClipboard';

const getSharedBoardLink = (boardId) =>
  window.location.origin + routes.boardGuest.replace(':id', boardId);

const ShareBoardModal = ({ show, onClose, board }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: object().shape({
      email: string()
        .email('Invalid email address')
        .required('This field is required'),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      setIsLoading(true);
      dispatch(
        shareBoard({
          board_id: board.id,
          email: values.email,
          success: () => {
            setIsLoading(false);
            onClose();
          },
        })
      );
    },
  });

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  const handleCopy = () => {
    copyToClipboard(getSharedBoardLink(board?.uuid)).then(() => {
      toast('Copy to clipboard', {
        autoClose: 1000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'success',
      });
    });
  };

  return (
    <UaModal
      show={show}
      onHide={onClose}
      dialogClassName='share-board-modal'
      noFooter>
      <p className='share-board-title'>Copy a link to share</p>
      <div className='share-board-row'>
        <UaInput
          type='text'
          value={getSharedBoardLink(board?.uuid)}
          disabled={true}
          containerClassName='share-board-row-input-container'
          className='share-board-row-input'
        />
        <UaButton
          type='button'
          className='share-board-row-btn'
          onClick={handleCopy}>
          Copy
        </UaButton>
      </div>

      <form className='share-board-form' onSubmit={formik.handleSubmit}>
        <p className='share-board-title'>Share to email</p>
        <div className='share-board-row'>
          <UaInput
            type='text'
            name='email'
            placeholder='Enter the email to share...'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            showError={true}
            error={formik.touched.email && formik.errors.email}
            valid={formik.touched.email && !formik.errors.email}
            containerClassName='share-board-row-input-container'
            className='share-board-row-input'
          />
          <UaButton
            type='submit'
            className='share-board-row-btn'
            isLoading={isLoading}
            disabled={!formik.isValid}>
            Share
          </UaButton>
        </div>
      </form>
    </UaModal>
  );
};

export default ShareBoardModal;
