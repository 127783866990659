// actions
import { logout } from 'store/auth/actions';
import { sendSupport } from './actions';

const INITIAL_STATE = {
  isLoading: false,
  successSend: false,
  errorSend: false,
};

const supportReducer = (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case sendSupport.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case sendSupport.SUCCESS:
      return {
        ...state,
        successSend: true,
      };

    case sendSupport.FAILURE:
      return {
        ...state,
        errorSend: true,
      };

    case sendSupport.FULFILL: {
      return {
        ...INITIAL_STATE,
      };
    }

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default supportReducer;
