import localForage from '../../services/localForage';
import { removeHeader, setHeader } from '../../services/api';

export const tokenService = {
  headers: {
    set: (value) =>
      Promise.resolve(setHeader('Authorization', `Bearer ${value}`)),
    remove: () => Promise.resolve(removeHeader('Authorization')),
  },
  storage: {
    get: async () => (await localForage.getItem('token')) || '',
    set: (token) => localForage.setItem('token', token),
    remove: () => localForage.removeItem('token'),
  },
};

export const getAccessToken = async () => {
  const token = await tokenService.storage.get();
  await tokenService.headers.set(token);
  return token;
};

export const removeAccessToken = () =>
  Promise.all([tokenService.headers.remove(), tokenService.storage.remove()]);

export const setAccessToken = (token) =>
  Promise.all([
    tokenService.headers.set(token),
    tokenService.storage.set(token),
  ]);

export default tokenService;
