import {put, takeLatest, call, select} from 'redux-saga/effects';

import { addTag as addTagAction } from '../actions';
import { addTag as addTagApi } from '../api';

import { getSaga as getTags } from './getTags';
import {getBoardSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(addTagAction.request());

    const tag = yield call(addTagApi, payload);

    yield put(addTagAction.success(tag));
    yield call(getTags, { payload: { boardId: payload.boardId } });
    payload.callback();
  } catch (error) {
    console.error(error);
    yield put(addTagAction.failure(error.response));
  } finally {
    yield put(addTagAction.fulfill());
  }
}

export default function* addTag() {
  yield takeLatest(addTagAction.TRIGGER, addSaga);
}
