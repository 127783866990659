import { useEffect, useMemo } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { verifyEmail } from 'store/auth/actions';
import {
  getVerificationError,
  isVerificationLoading,
} from 'store/auth/selectors';
import routes from 'utils/routes';
import { SUPPORT_EMAIL } from '../../storage/consts';

const EmailVerificationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isLoading = useSelector(isVerificationLoading);
  const verificationError = useSelector(getVerificationError);

  const token = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token');
  }, [location]);

  const sendConfirmation = () => {
    dispatch(verifyEmail(token));
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (token && !location.state) {
      sendConfirmation();
      return;
    }
    if (!token && !location.state?.isEmailConfirmed) {
      history.replace(routes.login);
    }
  }, []);

  return (
    <div
      className={`form form-card form-card--verification ${
        isLoading ? 'form-card--verification-loading' : ''
      }`}>
      {isLoading && (
        <div className='loader-container'>
          <Spinner animation='border' role='status' />
        </div>
      )}

      {!isLoading && verificationError?.statusCode && (
        <>
          Registration is already confirmed or verification link is broken,
          please log in to continue using Unmanage.
          <div className='column'>
            <Button onClick={sendConfirmation}>Retry</Button>
            <Button as={NavLink} to={routes.login}>
              Login
            </Button>
          </div>
        </>
      )}

      {!isLoading && !verificationError && (
        <>
          Your registration has been completed successfully.
          <div className='column'>
            <Button as={NavLink} to={routes.login}>
              Login
            </Button>
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              className='form__link btn btn-link'>
              Questions or issues? <br /> {SUPPORT_EMAIL}
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default EmailVerificationPage;
