import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import './support-form.styles.scss';

import UaInput from 'components/common/ua-input/ua-input.component';
import UaTextarea from 'components/common/ua-textarea/ua-textarea.component';
import UaButton from 'components/common/ua-button/ua-button.component';
import { getProfile } from 'store/auth/selectors';
import { sendSupport } from 'store/support/actions';
import { getSupportState } from 'store/support/selectors';

import { ReactComponent as Paperclip } from 'assets/images/icons/paperclip.svg';
import { ReactComponent as Close } from 'assets/images/icons/close-circle.svg';

import SupportSchema from './SupportSchema';

const MAX_FILES = 5;

const SupportForm = ({ onClose }) => {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const { isLoading, successSend, errorSend } = useSelector(getSupportState);

  const onSubmit = (values) => {
    dispatch(sendSupport(values));
    if (onClose) onClose();
  };

  const formik = useFormik({
    validationSchema: SupportSchema,
    onSubmit,
    initialValues: {
      email: profile.email || '',
      firstName: profile.firstname || '',
      lastName: profile.lastname || '',
      text: '',
    },
  });

  useEffect(() => {
    if (successSend) {
      formik.resetForm();

      toast('Message Sent', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'success',
      });
    }
  }, [successSend]);

  useEffect(() => {
    if (errorSend)
      toast('Failure send', {
        autoClose: 2000,
        pauseOnHover: false,
        hideProgressBar: true,
        closeButton: true,
        type: 'error',
      });
  }, [errorSend]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      files,
    });
  }, [files]);

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  const onChangeFile = (e) => {
    if (files.length + e.target.files.length <= MAX_FILES) {
      setFiles((prev) => [...prev, ...e.target.files]);
    }
  };

  const onDeleteFile = (index) => () => {
    const newValue = [...files];
    newValue.splice(index, 1);
    setFiles(newValue);
  };

  return (
    <form className='support-form form' onSubmit={formik.handleSubmit}>
      <h3 className='settings-tab__title'>Contact Support</h3>

      <div className='form__controls-row support-form__space'>
        <div className='form__controls-group'>
          <UaInput
            id='firstName'
            name='firstName'
            placeholder='Enter first name'
            label='First name'
            handleChange={handleChange}
            showError={true}
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && formik.errors.firstName}
            valid={formik.touched.firstName && !formik.errors.firstName}
          />
        </div>
        <div className='form__controls-group'>
          <UaInput
            id='lastName'
            name='lastName'
            placeholder='Enter last name'
            label='Last name'
            value={formik.values.lastName}
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            showError={true}
            error={formik.touched.lastName && formik.errors.lastName}
            valid={formik.touched.lastName && !formik.errors.lastName}
          />
        </div>
      </div>
      <div className='form__controls-row support-form__space'>
        <div className='form__controls-group'>
          <UaInput
            name='email'
            type='text'
            placeholder='Enter email'
            label='Email address'
            value={formik.values.email}
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            showError={true}
            error={formik.touched.email && formik.errors.email}
            valid={formik.touched.email && !formik.errors.email}
          />
        </div>
      </div>
      <div className='form__controls-row'>
        <div className='form__controls-group'>
          <UaTextarea
            id='text'
            name='text'
            placeholder='How we can help?'
            label='Question'
            value={formik.values.text}
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            showError={true}
            error={formik.touched.text && formik.errors.text}
            valid={formik.touched.text && !formik.errors.text}
            rows='5'
          />
        </div>
      </div>
      <div className='form__controls-row'>
        <div className='form__controls-group'>
          {!!files.length && (
            <div className='support-form__attachment-list'>
              {files.map((file, index) => (
                <span
                  className='support-form__attachment-file'
                  key={`attachment-file-${file.lastModified}`}>
                  {file.name}
                  <button
                    type='button'
                    onClick={onDeleteFile(index)}
                    className='support-form__attachment-btn'>
                    <Close width={12} height={12} />
                  </button>
                </span>
              ))}
            </div>
          )}

          {files.length < MAX_FILES && (
            <label className='support-form__attachment'>
              <Paperclip />
              <span className='support-form__attachment-label'>
                Add Attachments
              </span>

              <input
                type='file'
                multiple
                onChange={onChangeFile}
                className='support-form__attachment-input'
              />
            </label>
          )}
        </div>
      </div>
      <UaButton
        variant='primary'
        type='submit'
        isLoading={isLoading}
        className='support-form__submit-button'>
        Submit
      </UaButton>
    </form>
  );
};

export default SupportForm;
