import { useMemo } from 'react';

export default function useStrictFilter({ value, list = [], onChange }) {
  const filtered = useMemo(() => {
    const selected = [...value.include, ...value.exclude];
    const selectedIds = selected.map(({ id }) => id);

    return list.filter(({ id }) => !selectedIds.includes(id));
  }, [value, list]);

  const onToggleStrict = () => {
    onChange({
      ...value,
      exclude: [],
      isStrict: !value.isStrict,
    });
  };

  const onSelect = (key, item) => () => {
    onChange({
      ...value,
      [key]: [...value[key], item],
    });
  };

  const onDelete = (key, item) => () => {
    const newValue = value[key].filter(({ id }) => id !== item.id);
    onChange({
      ...value,
      [key]: newValue,
    });
  };

  return {
    filtered,
    onToggleStrict,
    onSelect,
    onDelete,
  };
}
