// actions
import {
  signUp,
  signIn,
  removeErrors,
  resetPassword,
  requestResetPass,
  resetAuthState,
  logout,
  getProfile,
  editProfile,
  uploadProfilePhoto,
  verifyEmail,
  deleteProfilePhoto,
} from './actions';

import { switchAccount } from 'store/accounts/actions';
import { NAME_APP } from 'settings';

const INITIAL_STATE = {
  isSuccessSignUp: false,
  isSuccessResetPassword: false,
  isLoading: false,
  error: {
    message: '',
    fields: {},
  },
  profile: {},
  verificationError: null,
  isVerificationLoading: false,
  profilePhotoLoading: false,
  profileUpdateLoading: false,
  profileUpdateError: {},
  profileUpdateSuccess: false,
};

const DEFAULT_SETTINGS = {
  boards: {},
};

const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    // triggers
    case resetAuthState.TRIGGER:
      return {
        ...INITIAL_STATE,
      };

    case removeErrors.TRIGGER:
      return {
        ...state,
        error: INITIAL_STATE.error,
      };

    case getProfile.TRIGGER:
    case logout.TRIGGER:
    case requestResetPass.TRIGGER:
    case resetPassword.TRIGGER:
    case signIn.TRIGGER:
    case signUp.TRIGGER:
      return {
        ...state,
        isLoading: true,
        error: INITIAL_STATE.error,
      };

    case verifyEmail.TRIGGER: {
      return {
        ...state,
        isVerificationLoading: true,
        verificationError: INITIAL_STATE.verificationError
      };
    }

    // successes
    case getProfile.SUCCESS: {
      const { id, profile_photo_path, firstname, lastname, email } =
        payload.profile;

      const accountId = payload.profile?.preferred_dashboard.account_id;
      const storageId = localStorage.getItem(`${NAME_APP}/accountId`);
      const parsedId = storageId ? parseInt(storageId) : null;
      const checkId = payload.profile?.accounts?.find(
        (account) => account.id === parsedId
      );

      const firstAccountId = payload.profile?.accounts[0] && payload.profile?.accounts[0].id;

      const controlledId = checkId ? parsedId : firstAccountId;

      if (accountId) {
        localStorage.setItem(`${NAME_APP}/accountId`, accountId);
      }

      if (controlledId !== parsedId) {
        localStorage.setItem(`${NAME_APP}/accountId`, controlledId);
      }

      return {
        ...state,
        profile: {
          id,
          profile_photo_path,
          firstname,
          lastname,
          email,
          account_id: accountId || controlledId,
          settings: payload.profile.settings || DEFAULT_SETTINGS,
        },
      };
    }

    case switchAccount.SUCCESS: {
      localStorage.setItem(`${NAME_APP}/accountId`, payload);
      return {
        ...state,
        profile: {
          ...state.profile,
          account_id: payload,
        },
      };
    }

    case editProfile.TRIGGER: {
      return {
        ...state,
        profileUpdateLoading: true,
        profileUpdateError: {},
      };
    }

    case editProfile.SUCCESS: {
      const { id, profile_photo_path, firstname, lastname } = payload.profile;

      return {
        ...state,
        profile: {
          ...state.profile,
          id,
          profile_photo_path,
          firstname,
          lastname,
          settings: payload.profile.settings || DEFAULT_SETTINGS,
        },
        profileUpdateSuccess: true,
      };
    }

    case editProfile.FAILURE: {
      return {
        ...state,
        profileUpdateError: payload,
      };
    }

    case editProfile.FULFILL: {
      return {
        ...state,
        profileUpdateLoading: false,
        profileUpdateSuccess: false,
      };
    }

    case uploadProfilePhoto.TRIGGER: {
      return {
        ...state,
        profilePhotoLoading: true,
      };
    }

    case uploadProfilePhoto.SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload,
        },
      };
    }

    case uploadProfilePhoto.FULFILL: {
      return {
        ...state,
        profilePhotoLoading: false,
      };
    }

    case deleteProfilePhoto.TRIGGER: {
      return {
        ...state,
        profilePhotoLoading: true,
      };
    }

    case deleteProfilePhoto.SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          profile_photo_path: '',
        },
      };
    }

    case deleteProfilePhoto.FULFILL: {
      return {
        ...state,
        profilePhotoLoading: false,
      };
    }

    case signUp.SUCCESS:
      return {
        ...state,
        isSuccessSignUp: true,
      };

    case requestResetPass.SUCCESS:
      return {
        ...state,
        isSuccessResetPassword: true,
      };

    case resetPassword.SUCCESS:
      return {
        ...state,
        profile: INITIAL_STATE.profile,
      };

    case verifyEmail.SUCCESS: {
      return {
        ...state,
        profile: {
          id: payload.user.id,
          email: payload.user.email,
        },
      };
    }
    // failures
    case resetPassword.FAILURE:
    case requestResetPass.FAILURE:
    case signIn.FAILURE:
    case signUp.FAILURE:
      return {
        ...state,
        error: {
          message: payload.main,
          fields: payload.fields,
        },
      };

    case verifyEmail.FAILURE: {
      return {
        ...state,
        verificationError: payload.data,
      };
    }
    // fulfill
    case verifyEmail.FULFILL:
    case getProfile.FULFILL:
    case resetPassword.FULFILL:
    case requestResetPass.FULFILL:
    case signIn.FULFILL:
    case signUp.FULFILL:
      return {
        ...state,
        isLoading: false,
        isVerificationLoading: false,
      };

    case logout.SUCCESS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default authReducer;
