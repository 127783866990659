import React, { memo } from 'react';

import './payment-infromation.styles.scss';

import CardInputs from '../card-inputs/card-inputs.component';

const PaymentInformation = () => {
  return (
    <div className='payment-information billing__card'>
      <h3 className='payment-information__title billing__title '>
        Payment Information
      </h3>

      <CardInputs className='payment-information__card-inputs' />
    </div>
  );
};

export default memo(PaymentInformation);
