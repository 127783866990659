import { useDispatch, useSelector } from 'react-redux';

import './board-setting-card.styles.scss';

import dateFormat from 'utils/date';
import SettingItem from './setting-item/setting-item.component';

import { copyBoard } from 'store/boards/actions';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as TextIcon } from 'assets/images/icons/align-left.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/user.svg';
import { ReactComponent as ProjectIcon } from 'assets/images/icons/projects.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/date.svg';
import { ReactComponent as UpdateIcon } from 'assets/images/icons/update.svg';
import { getCopyLoading } from 'store/boards/selectors';
import UaButton from 'components/common/ua-button/ua-button.component';
import BoardArchiveModal from 'components/dashboard/board-archive-modal/board-archive-modal.component';
import React, { useState } from 'react';

const BoardSettingCard = ({ board, onClose, editing = true }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getCopyLoading);
  const [isArchiveModal, setIsArchiveModal] = useState(false);

  const {
    id,
    is_archived,
    created_at,
    updated_at,
    name,
    projects_count,
    owner,
  } = board;

  const onCopy = () => {
    dispatch(copyBoard({ board_id: id }));
  };

  const onOpenArchiveModal = () => {
    setIsArchiveModal(true);
  };

  const onCloseArchiveModal = () => {
    setIsArchiveModal(false);
  };

  const rowsArray = [
    {
      icon: <TextIcon />,
      name: 'Name',
      body: name,
    },
    {
      icon: <ProjectIcon />,
      name: 'Projects',
      body: `${projects_count} in this board`,
    },
    {
      icon: <UserIcon />,
      name: 'Owner',
      body: owner.firstname + ' ' + owner.lastname,
    },
    {
      icon: <CalendarIcon />,
      name: 'Created',
      body: dateFormat(created_at, 'full'),
    },
    {
      icon: <UpdateIcon />,
      name: 'Updated',
      body: dateFormat(updated_at, 'full'),
    },
  ];

  return (
    <>
      <div className='board-setting-card'>
        <div className='board-setting-card__header'>
          <div className='board-setting-card__title-line'>
            <h5 className='board-setting-card__title'>Board details</h5>
            {!!onClose && (
              <button
                className='board-setting-card__close-button'
                onClick={onClose}>
                <CloseIcon />
              </button>
            )}
          </div>
          {editing && (
            <div className='board-setting-card__actions'>
              <div className='board-setting-card__actions-wrapper'>
                <UaButton
                  className='board-setting-card__clear-button'
                  isLoading={isLoading}
                  onClick={onCopy}>
                  Copy this board
                </UaButton>
                <button
                  className='board-setting-card__save-button'
                  onClick={onOpenArchiveModal}>
                  {is_archived ? 'Unarchive this board' : 'Archive this board'}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className='board-setting-card__body'>
          {rowsArray.map((item) => (
            <SettingItem key={`setting-item-${item.name}`} {...item} />
          ))}
        </div>
      </div>
      <BoardArchiveModal
        board={board}
        isShow={
          isArchiveModal
        }
        onClose={onCloseArchiveModal}
      />
    </>
  );
};

export default BoardSettingCard;
