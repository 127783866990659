import {put, takeLatest, call, select} from 'redux-saga/effects';

import { editProjectUpdate as editUpdateAction } from '../actions';

import { editProjectUpdate as editUpdateApi } from '../api';
import {getProjectSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(editUpdateAction.request());

    const project = yield call(editUpdateApi, payload);

    yield put(editUpdateAction.success(project));
  } catch (error) {
    console.error(error);
    yield put(editUpdateAction.failure(error.response));
  } finally {
    yield put(editUpdateAction.fulfill());
  }
}

export default function* editUpdate() {
  yield takeLatest(editUpdateAction.TRIGGER, addSaga);
}
