import { object, string } from 'yup';

const TagSchema = object().shape({
  name: string().max(20, 'Maximum name length - 20 characters').required('This field is required'),
  color: string()
    .matches(/^#[0-9A-F]{6}$/i)
    .required('This field is required'),
});

export default TagSchema;
