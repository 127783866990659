export const getProjectQueryParams = ({
  sort,
  isArchived,
  statuses,
  search,
  filters,
  shift,
}) => {
  const params = {
    shift,
    'filters[archive_status]': isArchived ? 'all' : 'active',
  };

  if (filters) {
    for (let key in filters) {
      if (Array.isArray(filters[key]) && filters[key].length) {
        params[`filters[${key}]`] = filters[key]
          .map((item) => item.id)
          .join(',');
      } else if (
        key === 'due_date' &&
        ((filters[key]?.gte && filters[key]?.lte) || filters[key]?.eq)
      ) {
        for (let dateKey in filters[key]) {
          params[`filters[${key}][${dateKey}]`] = filters[key][dateKey];
        }
      } else if (key === 'tags' || key === 'members') {
        const id = key === 'tags' ? 'id' : 'user_id';

        if (filters[key].include.length) {
          params[`filters[${key}_include]`] = filters[key].include
            .map((item) => item[id])
            .join(',');
        }

        if (filters[key].exclude.length && !filters[key].isStrict) {
          params[`filters[${key}_exclude]`] = filters[key].exclude
            .map((item) => item[id])
            .join(',');
        }

        if (filters[key].include.length || filters[key].exclude.length) {
          params[`filters[${key}_is_strict]`] = filters[key].isStrict;
        }
      } else {
        if (filters[key]) {
          params[`filters[${key}]`] = filters[key];
        }
      }
      if (
        key === 'members' &&
        Array.isArray(filters[key]) &&
        filters[key].length
      ) {
        params[`filters[${key}]`] = filters[key]
          .map((item) => item.user_id)
          .join(',');
      }
    }
  }

  if (sort?.length) params.sort = sort.join(',');
  if (statuses?.length && !filters['statuses'].length)
    params['filters[statuses]'] = statuses;
  if (search) params['filters[name]'] = search;

  return params;
};
