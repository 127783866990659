import React, { useState } from 'react';

import './project-due-date.styles.scss';

import UaDatepicker from '../../../common/ua-datepicker/ua-datepicker.component';

import dateFormat from '../../../../utils/date';

import { ReactComponent as DateIcon } from '../../../../assets/images/icons/date.svg';

const ProjectDueDate = ({
  project: { due_date },
  className = '',
  onDateChange,
  editing,
}) => {
  const [show, setShow] = useState(false);

  const onShow = () => {
    if (editing) setShow(true);
  };

  return (
    <>
      <div className={`project-due-date ${className}`}>
        <button className='project-due-date__button' onClick={onShow}>
          <DateIcon />
          <span className='project-due-date__button-label'>
            {due_date ? dateFormat(due_date, 'month-day') : '\u2014'}
          </span>
        </button>
      </div>

      {editing && (
        <UaDatepicker
          title='Due Date'
          noFooter={true}
          show={show}
          onHide={() => setShow(false)}
          date={due_date}
          onDateChange={onDateChange}
        />
      )}
    </>
  );
};

export default ProjectDueDate;
