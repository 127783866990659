import { put, takeEvery, select, call } from 'redux-saga/effects';
import sortBy from 'lodash/sortBy';

import { changePositions } from 'utils/draggable-sort';
import { dndBoardStatus as dndBoardStatusAction } from '../actions';
import {
  getBoard,
  getSortedStatuses,
  getStatusesPositions,
} from '../selectors';

import { editSaga as editBoard } from './edit';
import dndDataFilter from 'utils/dndDataFilter';

function* dndBoardStatusSaga({ payload: { from, to } }) {
  try {
    const board = yield select(getBoard);
    const statuses_positions = yield select(getStatusesPositions);
    const statuses = yield select(getSortedStatuses);
    const filter = dndDataFilter(statuses_positions, statuses, 'status_id', 'id');

    const dropArea = changePositions(sortBy(filter, ['order']), {
      from,
      to,
    });

    const newPositions = dropArea.map(({ status_id }, index) => ({
      status_id,
      order: index,
    }));

    yield put(dndBoardStatusAction.success(newPositions));

    yield call(editBoard, {
      payload: { id: board.id, statuses_positions: newPositions },
    });
  } catch (error) {
    console.error(error);
    yield put(dndBoardStatusAction.failure(error.response));
  } finally {
    yield put(dndBoardStatusAction.fulfill());
  }
}

export default function* dndBoardStatus() {
  yield takeEvery(dndBoardStatusAction.TRIGGER, dndBoardStatusSaga);
}
