import { getOverdue } from 'utils/getFilterDate';

export const SUPPORT_EMAIL = 'support@unmanage.io';

const now = new Date();
const tommorow = new Date();
tommorow.setDate(new Date().getDate() + 1);

let currentDate = now.toISOString().split('T')[0];
let tommorowDate = tommorow.toISOString().split('T')[0];

let startOfWeek = (date) => {
  const start = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  return new Date(date.setDate(start));
};

let endOfWeek = (date) => {
  const end = date.setDate(date.getDate() + 6);
  return new Date(end);
};

let currentWeekStartDate = startOfWeek(now).toISOString().split('T')[0];
let currentWeekEndDate = endOfWeek(now).toISOString().split('T')[0];
now.setDate(now.getDate() + 7);
let nextWeekStartDate = startOfWeek(now).toISOString().split('T')[0];
let nextWeekEndDate = endOfWeek(now).toISOString().split('T')[0];

export const dueDatesList = [
  {
    id: 1,
    eq: currentDate,
    label: 'Due Today',
  },
  {
    id: 2,
    eq: tommorowDate,
    label: 'Due Tomorrow',
  },
  {
    id: 3,
    gte: currentWeekStartDate,
    lte: currentWeekEndDate,
    label: 'Due this week',
  },
  {
    id: 4,
    gte: nextWeekStartDate,
    lte: nextWeekEndDate,
    label: 'Due Next week',
  },
  {
    id: 5,
    ...getOverdue(),
    label: 'Overdue',
  },
  {
    id: 6,
    datepicker: true,
    label: 'Custom',
  },
];
