import { useCallback, useState } from 'react';

export default function useSearch(initialValue, timeout = 300) {
  const [value, setValue] = useState(initialValue);
  const [queryVal, setQueryVal] = useState(initialValue);
  const [cbTimeout, setCbTimeout] = useState(null);

  const setSearch = useCallback(
    (newValue) => {
      setValue(newValue);

      if (cbTimeout) clearTimeout(cbTimeout);

      setCbTimeout(
        window.setTimeout(async () => {
          await setQueryVal(newValue);

          if (cbTimeout) {
            clearTimeout(cbTimeout);
            setCbTimeout(null);
          }
        }, timeout)
      );
    },
    [setCbTimeout, setValue, cbTimeout, timeout]
  );

  const resetSearch = useCallback(() => {
    setValue(initialValue);
    setQueryVal(initialValue);
  }, [initialValue]);

  return [value, queryVal, setSearch, resetSearch];
}
