import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { getProjectUpdates as getUpdatesAction } from '../actions';
// api
import { getProjectUpdates as getUpdatesApi } from '../api';

function* getUpdatesSaga({ payload: { id } }) {
  try {
    yield put(getUpdatesAction.request());

    const updateList = yield call(getUpdatesApi, id);

    yield put(getUpdatesAction.success({ updateList, id }));
  } catch (error) {
    console.error(error);
    yield put(getUpdatesAction.failure(error.response));
  } finally {
    yield put(getUpdatesAction.fulfill());
  }
}

export default function* getUpdates() {
  yield takeLatest(getUpdatesAction.TRIGGER, getUpdatesSaga);
}
