import React from 'react';

import './account-logo.styles.scss';

import useAccountLogo from 'hooks/useAccountLogo';
import getInitials from 'utils/initials';

const colorClass = (id) => {
  const classes = [
    'account-logo--blue',
    'account-logo--dark-blue',
    'account-logo--orange',
    'account-logo--green',
  ];

  return classes[(id + classes.length) % classes.length];
};

const UserAvatar = ({ account, className = '', width = 24, height = 24 }) => {
  const { id, name, logo_path } = account;
  const [userPhoto] = useAccountLogo(account.id, logo_path);

  return (
    <div
      className={`account-logo ${colorClass(id)} ${className}`}
      style={{ width: `${width}px`, height: `${height}px` }}>
      {logo_path ? (
        <img
          className='account-logo__image'
          src={userPhoto}
          alt='user avatar'
        />
      ) : (
        <span className='account-logo__name'>{getInitials(name)}</span>
      )}
    </div>
  );
};

export default UserAvatar;
