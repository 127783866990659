import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import UaButton from 'components/common/ua-button/ua-button.component';
import './add-card-form.styles.scss';

import PaymentDetails from '../payment-details/payment-details.components';
import PaymentInformation from '../paymet-information/payment-information.component';
import AddCardFormSchema from './add-card-form.validation';

import { changePlan, createCard } from 'store/payment/actions';

const AddCardForm = ({ goBack, cards, profile, addCardType, planData }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          city: values.city,
          country: values.country,
          line1: values.address,
          postal_code: values.zip,
          state: values.region,
        },
        email: values.email,
        name: values.name,
      },
    });

    const successCreateCard = () => {
      if (addCardType === 'add_card') {
        goBack();
      } else {
        dispatch(
          changePlan({
            ...planData,
            error: () => {
              setIsLoading(false);
              goBack();
            },
          })
        );
      }
    };

    if (error) {
      setStripeError(error);
    } else {
      setIsLoading(true);
      await dispatch(
        createCard({
          id: paymentMethod.id,
          is_default: !cards.length,
          success: successCreateCard,
          error: () => {
            setIsLoading(false);
          },
        })
      );
      setStripeError(null);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: profile.firstname + ' ' + profile.lastname,
      email: profile.email,
      address: '',
      country: 'US',
      city: '',
      region: '',
      zip: '',
    },
    validationSchema: AddCardFormSchema,
    validateOnMount: false,
    onSubmit: handleSubmit,
  });

  return (
    <form className='add-card-form' onSubmit={formik.handleSubmit}>
      <PaymentDetails
        onChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        touched={formik.touched}
        errors={formik.errors}
      />
      <PaymentInformation />

      <div className='ua-input__error'>
        {stripeError && stripeError.message}
      </div>

      <div className='add-card-form__actions'>
        <UaButton
          className='add-card-form__cancel'
          type='button'
          onClick={() => goBack()}
          disabled={isLoading}>
          Cancel
        </UaButton>
        <UaButton
          className='add-card-form__submit'
          type='submit'
          isLoading={isLoading}
          disabled={!stripe}>
          {addCardType === 'add_card' ? 'Add card' : 'Start Subscription'}
        </UaButton>
      </div>
    </form>
  );
};

export default memo(AddCardForm);
