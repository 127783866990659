import {put, takeLatest, call, select} from 'redux-saga/effects';

import { deleteStatus as deleteStatusAction } from '../actions';
import { deleteStatus as deleteStatusApi } from '../api';
import { getSaga as getStatuses } from './getStatuses';
import {getBoardSocket} from 'store/socket/selectors';

function* deleteSaga({ payload }) {
  try {
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(deleteStatusAction.request());

    const status = yield call(deleteStatusApi, payload);

    yield call(getStatuses, { payload: { boardId: payload.boardId } });

    yield put(deleteStatusAction.success(status));
  } catch (error) {
    console.error(error);
    yield put(deleteStatusAction.failure(error.response));
  } finally {
    yield put(deleteStatusAction.fulfill());
  }
}

export default function* deleteStatus() {
  yield takeLatest(deleteStatusAction.TRIGGER, deleteSaga);
}
