import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import routes from 'utils/routes';

import './board-item.styles.scss';

import { Dropdown } from 'react-bootstrap';
import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import BoardEditModal from 'components/dashboard/board-edit-modal/board-edit-modal.component';
import BoardArchiveModal from 'components/dashboard/board-archive-modal/board-archive-modal.component';
import BoardPersonalModal from 'components/dashboard/board-personal-modal/board-personal-modal.component';
import BoardItemKPI from './board-item-kpi.component';

import { ReactComponent as DragIcon } from 'assets/images/icons/menu-small.svg';
import { ReactComponent as StarIcon } from 'assets/images/icons/star.svg';
import { ReactComponent as MenuDots } from 'assets/images/icons/menu-dots.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as ArchiveIcon } from 'assets/images/icons/archive-solid.svg';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash-alt.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/clipboard-plus.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/icons/clipboard-x.svg';

import dateFormat from 'utils/date';

import { setIsFavoriteBoard } from 'store/boards/actions';
import BoardDeleteModal from 'components/dashboard/board-delete-modal/board-delete-modal.component';
import UATooltip from 'components/common/ua-tooltip/ua-tooltip.components';

const IModal = {
  edit: 1,
  archive: 2,
  delete: 3,
  personal: 4,
};

const BoardItem = ({ board, disabled }) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(null);

  const openModal = (val) => () => {
    setIsModal(val);
  };

  const closeModal = () => {
    setIsModal(null);
  };

  const handleSetStarred = () => {
    dispatch(
      setIsFavoriteBoard({ id: board.id, is_favorite: !board.is_favorite })
    );
  };

  return (
    <div className='board-item'>
      {!disabled && <DragIcon className='board-item__drag' />}

      <div className='board-item__description'>
        <h5 className='board-item__description-name'>
          <UATooltip hideArrow placement='top-start' label={board.name}>
            <Link to={routes.board.replace(':id', board.id) + '?view=list'}>
              {board.name}
            </Link>
          </UATooltip>

        </h5>

        {!disabled && (
          <button
            className={clsx('board-item__starred-button', {
              'board-item__starred-button--active': board.is_favorite,
            })}
            onClick={handleSetStarred}>
            <StarIcon className='board-item__star' />
          </button>
        )}

        <small className='board-item__description-projects'>
          {board.projects_count || 0} projects
        </small>
      </div>
      <div className='board-item__updated'>
        <p className='board-item__updated-label'>Last Updated</p>
        <small className='board-item__updated-at'>
          {dateFormat(board.updated_at)}
        </small>
      </div>
      <div className='board-item__projects-counters'>
        <BoardItemKPI className='board-item__projects' kpi={board.kpi} />
      </div>
      {!disabled && (
        <>
          <UaDropdown
            className='board-item__menu'
            withIcon={false}
            buttonContent={<MenuDots />}
            dropdownVariant='dark'>
            <Dropdown.Item onClick={openModal(IModal.edit)}>
              <EditIcon /> Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={openModal(IModal.archive)}>
              <ArchiveIcon /> Archive
            </Dropdown.Item>
            <Dropdown.Item onClick={openModal(IModal.delete)}>
              <TrashIcon /> Delete
            </Dropdown.Item>

            <Dropdown.Item onClick={openModal(IModal.personal)}>
              {board.is_in_personal_dashboard ? (
                <>
                  <RemoveIcon width={11} /> Remove from personal dashboard
                </>
              ) : (
                <>
                  <PlusIcon width={11} /> Add to personal dashboard
                </>
              )}
            </Dropdown.Item>
          </UaDropdown>

          <BoardEditModal
            board={board}
            isShow={isModal === IModal.edit}
            onClose={closeModal}
          />

          <BoardArchiveModal
            board={board}
            isShow={isModal === IModal.archive}
            onClose={closeModal}
          />

          <BoardPersonalModal
            board={board}
            isShow={isModal === IModal.personal}
            onClose={closeModal}
          />

          <BoardDeleteModal
            board={board}
            isShow={isModal === IModal.delete}
            onClose={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default BoardItem;
