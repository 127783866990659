import React from 'react';
import { useDispatch } from 'react-redux';
import { array, string } from 'prop-types';
import clsx from 'clsx';

import './cards-table.styles.scss';

import { ReactComponent as CreditCard } from 'assets/images/icons/credit-card.svg';
import { ReactComponent as Refresh } from 'assets/images/icons/refresh.svg';
import { ReactComponent as CheckOutline } from 'assets/images/icons/check-circle-outline.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';

import { updateCard, deleteCard } from 'store/payment/actions';

const CardsTable = ({ cards, className }) => {
  const dispatch = useDispatch();

  const onSwitchCard = (id) => () => {
    dispatch(updateCard({ id, data: { is_default: true } }));
  };

  const onDeleteCard = (id) => () => {
    dispatch(deleteCard(id));
  };

  return (
    <div className={clsx('cards-table', className)}>
      <table>
        <tbody>
          {cards.map(({ id, card_last_four, is_default }, index) => (
            <tr key={id}>
              <td className='col-card-icon'>
                <CreditCard />
              </td>
              <td className='col-card-number'>
                <span className='cards-table__card-number'>
                  **** **** **** {card_last_four}
                </span>
              </td>
              <td className='col-card-status'>
                {is_default ? (
                  <span className='card-table__active-card'>
                    <CheckOutline className='card-table__check-icon' />
                    Active card
                  </span>
                ) : (
                  <div>
                    <button
                      type='button'
                      className='cards-table__switch-card'
                      onClick={onSwitchCard(id)}>
                      <Refresh /> Switch Card
                    </button>
                  </div>
                )}
              </td>
              <td className='col-action-remove'>
                <button
                  type='button'
                  onClick={onDeleteCard(id)}
                  className='card-table__action-button card-table__action-button'>
                  <Trash fill='#FB404B' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

CardsTable.propTypes = {
  cards: array,
  className: string,
};

CardsTable.defaultProps = {
  cards: [],
};

export default CardsTable;
