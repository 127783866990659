import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';

import './page-loader.styles.scss';

const PageLoader = ({ classname }) => {
  return (
    <div className={clsx(['page-loader-container', classname])}>
      <Spinner animation='border' />
    </div>
  );
};

export default PageLoader;
