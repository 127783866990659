import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getBoard } from 'store/boards/selectors';

import { moveProjects as moveProjectsAction } from '../actions';

import { moveProjects as moveProjectsApi } from '../api';

import { getBoard as getBoardAction } from 'store/boards/actions';
import {getProjectSocket} from 'store/socket/selectors';

function* moveSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    const board = yield select(getBoard);
    yield put(moveProjectsAction.request());

    const response = yield call(moveProjectsApi, payload);

    if (response) {
      yield put(getBoardAction({ id: board.id }));
    }

    yield put(moveProjectsAction.success(payload.ids));
  } catch (error) {
    console.error(error);
    yield put(moveProjectsAction.failure(error.response));
  } finally {
    yield put(moveProjectsAction.fulfill());
  }
}

export default function* moveProjects() {
  yield takeLatest(moveProjectsAction.TRIGGER, moveSaga);
}
