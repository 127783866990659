import {put, takeEvery, call, select} from 'redux-saga/effects';

import { addTask as addTaskAction } from '../actions';

import { addTask as addTaskApi } from '../api';
import {getProjectSocket} from 'store/socket/selectors';

function* addSaga({ payload }) {
  try {
    const socket = yield select(getProjectSocket);
    payload.socketId = socket.id;

    yield put(addTaskAction.request());

    const response = yield call(addTaskApi, payload);

    yield put(addTaskAction.success({ projectId: payload.projectId, task: response }));
  } catch (error) {
    console.error(error);
    yield put(addTaskAction.failure(error.response));
  } finally {
    yield put(addTaskAction.fulfill());
  }
}

export default function* addTask() {
  yield takeEvery(addTaskAction.TRIGGER, addSaga);
}
