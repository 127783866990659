import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import {
  archiveProjects,
  cancelArchiveProjects,
  preArchiveProjects,
} from 'store/projects/actions';
import { BoardContext } from 'context/board-context/board-context';
import { EnumProjectModals } from 'settings';
import UaConfirm from 'components/common/ua-modal/ua-confirm.component';

const ProjectArchiveModal = ({ projects }) => {
  const dispatch = useDispatch();
  const {
    checkedProjects,
    setCheckedProjects,
    projectCardId,
    setIsCardShow,
    setProjectCardId,
    isActionModalOpen,
    onCloseActionModal,
  } = useContext(BoardContext);

  const handleBefore = () => {
    dispatch(preArchiveProjects(checkedProjects));
  };

  const handleCancel = () => {
    dispatch(cancelArchiveProjects());
  };

  const changeActiveProjects = () => {
    if (!checkedProjects.includes(projectCardId)) return;

    const newProjects = projects.filter(
      ({ id }) => !checkedProjects.includes(id)
    );
    if (newProjects.length) {
      setProjectCardId(newProjects[0].id);
    } else {
      setProjectCardId(null);
      setIsCardShow(null);
    }
  };

  const handleOk = () => {
    changeActiveProjects();
    dispatch(archiveProjects({ projects: checkedProjects }));
    onCloseActionModal();
    setCheckedProjects([]);
  };

  return (
    <UaConfirm
      show={isActionModalOpen === EnumProjectModals.ARCHIVE}
      onHide={onCloseActionModal}
      onOk={handleOk}
      beforeAction={handleBefore}
      cancelAction={handleCancel}
      okLabel='Yes'
      cancelLabel='No'
      message='Are you sure you want to archive this projects?'
      notification={{
        text: `We successfully archived project${
          checkedProjects.length > 1 && 's'
        }`,
      }}
    />
  );
};

export default ProjectArchiveModal;
