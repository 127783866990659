import { all, fork } from 'redux-saga/effects';
// sagas
import getCards from './getCards';
import createCard from './createCard';
import updateCard from './updateCard';
import deleteCard from './deleteCard';
import getTransactions from './getTransactions';
import getPlans from './getPlans';
import subscribePlan from './subscribePlan';
import changePlan from './changePlan';
import unsubscribePlan from './unsubscribePlan';

export default function* projects() {
  yield all([
    fork(getCards),
    fork(createCard),
    fork(updateCard),
    fork(deleteCard),
    fork(getTransactions),
    fork(getPlans),
    fork(subscribePlan),
    fork(changePlan),
    fork(unsubscribePlan),
  ]);
}
