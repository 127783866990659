import React from 'react';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';

import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';

import './priority.styles.scss';

const Priority = ({
  value,
  priorities,
  onChangePriority,
  editing,
  withBackground = false,
  className = '',
  noLabel = false,
}) => {
  const handleShowOptionClick = (option) => () => {
    onChangePriority(option);
  };

  return Array.isArray(priorities) ? (
    <UaDropdown
      className='priority-dropdown'
      dropdownVariant='light'
      disabled={!editing}
      buttonContent={
        !value ? (
          <span>&mdash;</span>
        ) : (
          <span
            className={clsx('priority', {
              [value.slug]: value.slug,
              [className]: className,
              'priority--with-background': withBackground,
              'priority--no-label': noLabel,
            })}>
            <span className='priority-dot' />
            {!noLabel ? value.name : null}
          </span>
        )
      }
      withIcon={false}>
      {priorities.map(({ id, name, slug }) => (
        <React.Fragment key={`priority-item-${id}`}>
          <Dropdown.Item onSelect={handleShowOptionClick(id)}>
            <span
              className={clsx('priority', {
                [slug]: slug,
                [className]: className,
                'priority--with-background': withBackground,
                'priority--no-label': noLabel,
              })}>
              <span className='priority-dot' />
              {!noLabel ? name : null}
            </span>
          </Dropdown.Item>

          <Dropdown.Divider />
        </React.Fragment>
      ))}

      <Dropdown.Item onSelect={handleShowOptionClick(null)}>
        <span
          className={clsx('priority', {
            [className]: className,
            'priority--with-background': withBackground,
            'priority--no-label': noLabel,
          })}>
          <span className='priority-dot' />
          none
        </span>
      </Dropdown.Item>
    </UaDropdown>
  ) : !value && noLabel ? (
    <span
      className={clsx('priority', {
        [className]: className,
        'priority--with-background': withBackground,
        'priority--no-label': noLabel,
      })}>
      <span className='priority-dot' />
    </span>
  ) : value ? (
    <span
      className={clsx('priority', {
        [value.slug]: value.slug,
        [className]: className,
        'priority--with-background': withBackground,
        'priority--no-label': noLabel,
      })}>
      <span className='priority-dot' />
      {!noLabel ? value.name : null}
    </span>
  ) : (
    <span>&mdash;</span>
  );
};

export default Priority;
