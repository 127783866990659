import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
// actions
import { transferOwnership as action } from '../actions';
// api
import { transferOwnership as api } from '../api';

function* getSaga({ payload }) {
  try {
    yield put(action.request());

    yield call(api, payload);

    toast('Request successfully has been sent', {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      type: 'success',
    });

    yield put(action.success());
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* transferOwnership() {
  yield takeLatest(action.TRIGGER, getSaga);
}
