import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { fork, take, call, put, cancel, select } from 'redux-saga/effects';
import { API_BASE_URL } from 'settings';
import { logout } from 'store/auth/actions';
import { setProjectSocket } from '../actions';
import { getBoard, getGuestBoard } from '../../boards/actions';
import {
  addProject,
  preDeleteProjects,
  deleteProjects,
  archiveProjects,
  unarchiveProjects,
  uploadFile,
  deleteFile,
  addTask,
  editTask,
  deleteTask,
  addProjectStatusFromSocket,
  editProjectStatusFromSocket,
  removeProjectStatusFromSocket,
  addProjectUpdate,
  editProjectUpdate,
  addMember,
  deleteMember,
  addProjectTagFromSocket,
  editProjectTagFromSocket,
  removeProjectTagFromSocket,
  updateProject,
} from '../../projects/actions';
import { getBoard as boardSelector } from '../../boards/selectors';

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('joinedRoom', () => {
      socket.on('project', (res) => {
        const currentSocketId = socket.id;
        if (res.data?.sender_socket_id?.includes(currentSocketId)) return;

        switch (res.action) {
          case 'created':
            emit(addProject.success(res.data.project));
            emit(getBoard({ id: res.data.project.board_id }));
            break;

          case 'updated':
            emit(updateProject.success(res.data.project));
            break;

          case 'deleted':
            emit(preDeleteProjects.trigger(res.data.ids));
            emit(deleteProjects.success());
            break;

          case 'archived':
            emit(archiveProjects.success({ ids: res.data.ids }));
            break;

          case 'unarchived':
            emit(unarchiveProjects.success({ ids: res.data.ids }));
            break;

          default:
            break;
        }
      });
      socket.on('project-files', (res) => {
        const { project_id, ...file } = res.data;
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;

        switch (res.action) {
          case 'uploaded':
            emit(
              uploadFile.success({
                projectId: parseInt(project_id),
                file: { ...file, id: parseInt(file.id) },
              })
            );
            break;

          case 'deleted':
            emit(
              deleteFile.success({
                projectId: parseInt(project_id),
                fileId: parseInt(file.id),
              })
            );
            break;

          default:
            break;
        }
      });
      socket.on('project-tasks', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;

        const { project_id: projectId, ...taskData } = res.data;

        switch (res.action) {
          case 'created':
            emit(
              addTask.success({
                projectId,
                task: taskData,
              })
            );
            break;

          case 'updated':
            emit(
              editTask.success({
                projectId,
                taskId: taskData.id,
                task: taskData,
              })
            );
            break;

          case 'deleted':
            emit(
              deleteTask.success({
                projectId,
                taskId: taskData.id,
              })
            );
            break;

          default:
            break;
        }
      });
      socket.on('project-status', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;

        const { board_id, sender_socket_id, ...newStatus } = res.data;

        switch (res.action) {
          case 'created':
            emit(addProjectStatusFromSocket.success(newStatus));
            break;

          case 'updated':
            emit(editProjectStatusFromSocket.success(newStatus));
            break;

          case 'deleted':
            emit(removeProjectStatusFromSocket.success(newStatus));
            break;

          default:
            break;
        }
      });
      socket.on('project-update', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;

        switch (res.action) {
          case 'created':
            emit(addProjectUpdate.success(res.data));
            break;

          case 'updated':
            emit(editProjectUpdate.success(res.data));
            break;

          default:
            break;
        }
      });
      socket.on('project-members', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;

        switch (res.action) {
          case 'created':
            emit(
              addMember.success({
                projectId: res.data.project_id,
                boardId: res.data.board_id,
                user: res.data.user,
              })
            );
            break;

          case 'deleted':
            emit(
              deleteMember.success({
                projectId: res.data.project_id,
                memberId: res.data.user_id,
              })
            );
            break;

          default:
            break;
        }
      });
      socket.on('project-tags', (res) => {
        const currentSocketId = socket.id;
        if (currentSocketId === res.data?.sender_socket_id) return;

        const { board_id, ...tagData } = res.data;

        switch (res.action) {
          case 'created':
            emit(addProjectTagFromSocket.success(tagData));
            break;

          case 'updated':
            emit(editProjectTagFromSocket.success(tagData));
            break;

          case 'deleted':
            emit(removeProjectTagFromSocket.success(tagData));
            break;

          default:
            break;
        }
      });
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* handleIO(socket) {
  yield fork(read, socket);
}

function* flow() {
  while (true) {
    if (window.location.href.includes('board/shared')) {
      yield take(getGuestBoard.SUCCESS);
    } else {
      yield take(getBoard.SUCCESS);
    }

    const socket = yield call(io, `${API_BASE_URL}/projects`);
    yield put(setProjectSocket(socket));

    const board = yield select(boardSelector);

    let connectId = null;

    if (board?.account_id && board?.id && socket) {
      connectId = `${board.account_id}-${board.id}`;
    }

    socket.emit('joinRoom', connectId);

    const task = yield fork(handleIO, socket);
    yield take(logout.SUCCESS);
    socket.emit('leaveRoom', connectId);
    yield cancel(task);
    socket.disconnect();
  }
}

export default function* rootSaga() {
  yield fork(flow);
}
