import {put, takeLatest, call, select} from 'redux-saga/effects';

import {removeBoardMember as action} from '../actions';

import {removeBoardMember as api} from '../api';
import {getBoardSocket} from 'store/socket/selectors';

export function* getSaga({payload}) {
  try {
    const socket = yield select(getBoardSocket);
    payload.socketId = socket.id;

    yield put(action.request());

    yield call(api, payload);

    yield put(action.success(payload));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* removeBoardMember() {
  yield takeLatest(action.TRIGGER, getSaga);
}
