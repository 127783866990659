import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import 'components/common/ua-confirm-input/ua-confirm-input.styles.scss';

import { ReactComponent as CancelIcon } from 'assets/images/icons/times-circle-bold.svg';
import { ReactComponent as SubmitIcon } from 'assets/images/icons/check-circle-solid.svg';

const UaConfirmInput = forwardRef(
  (
    {
      type = 'text',
      onSubmit,
      onCancel,
      className,
      maxLength = 50,
      value = '',
      onChange,
      toastLabel = 'Maximum tasks name length - 50 characters',
      ...otherProps
    },
    ref
  ) => {
    const toastId = useRef(null);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      onChange && onChange(inputValue);
    }, [inputValue]);

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(inputValue);
    };

    const toastOptions = {
      autoClose: 2000,
      pauseOnHover: false,
      hideProgressBar: true,
      closeButton: true,
      type: 'error',
      onClose: () => (toastId.current = null),
    };

    const handleChange = (event) => {
      setInputValue(event.target.value.slice(0, maxLength));

      if (event.target.value.length >= maxLength) {
        if (!toastId.current) {
          toastId.current = toast(toastLabel, toastOptions);
        } else {
          toast.update(toastId.current);
        }
      }
    };

    return (
      <form className='ua-confirm' onSubmit={handleSubmit}>
        <input
          className={clsx('ua-confirm__input', className)}
          type={type}
          ref={ref}
          value={inputValue}
          onChange={handleChange}
          {...otherProps}
        />

        <button className='ua-confirm__btn --submit' type='submit'>
          <SubmitIcon fill='#87CB16' />
        </button>

        <button
          className='ua-confirm__btn --cancel'
          type='button'
          onClick={onCancel}>
          <CancelIcon />
        </button>
      </form>
    );
  }
);

export default UaConfirmInput;
