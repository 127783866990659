import { memo } from 'react';
import { bool, string } from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';

import './ua-button.styles.scss';

const UaButton = ({
  isLoading = false,
  children,
  className,
  onClick,
  ...rest
}) => (
  <Button
    disabled={isLoading}
    className={`ua-button ${isLoading ? 'loading' : ''} ${className}`}
    {...rest}
    onClick={isLoading ? undefined : onClick}>
    <span className='label'>{children}</span>

    {isLoading && (
      <span className='loader'>
        <Spinner animation='border' size='sm' color='#fff' />
      </span>
    )}
  </Button>
);

UaButton.propTypes = {
  isLoading: bool,
  className: string,
};

export default memo(UaButton);
