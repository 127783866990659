import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Tag from 'components/dashboard/tag/tag.component';

import { ReactComponent as TagIcon } from 'assets/images/icons/tag.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus-circle-solid.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-circle-solid.svg';

import { getTags } from 'store/projects/selectors';
import UaModal from 'components/common/ua-modal/ua-modal.component';
import { Tab, Tabs } from 'react-bootstrap';
import useStrictFilter from 'hooks/useStrictFilter';

const FilterTag = ({ value, onChange }) => {
  const tags = useSelector(getTags);
  const [isOpen, setIsOpen] = useState(false);

  const { onSelect, onDelete, onToggleStrict, filtered } = useStrictFilter({
    value,
    list: tags,
    onChange,
  });

  return (
    <div className='project-filter'>
      <div className='project-filter__header'>
        <span className='project-filter__icon'>
          <TagIcon />
        </span>
        <span className='project-filter__name'>Tags</span>
        <label className='project-filter__toggle'>
          <input
            type='checkbox'
            value={value.isStrict}
            onChange={onToggleStrict}
          />
          <span className='project-tasks-completed--span'>Strict filter</span>
        </label>
      </div>
      <div className='project-filter__body'>
        <div className='project-filter-selected-items project-filter-selected-items--flex'>
          {value.include.map((item) => (
            <Tag
              key={`include-${item.id}`}
              beforeIcon={<PlusIcon />}
              text={item.name}
              color={item.color}
              editing
              onDelete={onDelete('include', item)}
            />
          ))}
          {!value.isStrict &&
            value.exclude.map((item) => (
              <Tag
                key={`exclude-${item.id}`}
                beforeIcon={<MinusIcon />}
                text={item.name}
                color={item.color}
                editing
                onDelete={onDelete('exclude', item)}
              />
            ))}
        </div>

        <button
          className='project-filter-select-btn'
          onClick={() => setIsOpen(true)}>
          Select a tag <AngleDown />
        </button>
      </div>

      <UaModal show={isOpen} onHide={() => setIsOpen(false)} noFooter>
        <Tabs className='project-filter-tabs'>
          <Tab eventKey='include' title='Include'>
            {!!filtered.length &&
              filtered.map((tag) => (
                <Tag
                  key={tag.id}
                  text={tag.name}
                  color={tag.color}
                  onClick={onSelect('include', tag)}
                />
              ))}

            {!filtered.length && 'Tag list is empty'}
          </Tab>
          {!value.isStrict && (
            <Tab eventKey='exclude' title='Exclude'>
              {!!filtered.length &&
                filtered.map((tag) => (
                  <Tag
                    key={tag.id}
                    text={tag.name}
                    color={tag.color}
                    onClick={onSelect('exclude', tag)}
                  />
                ))}

              {!filtered.length && 'Tag list is empty'}
            </Tab>
          )}
        </Tabs>
      </UaModal>
    </div>
  );
};

export default FilterTag;
