import React from 'react';
import { useDispatch } from 'react-redux';

import UaConfirm from 'components/common/ua-modal/ua-confirm.component';

import { cancelDeleteBoard, deleteBoard, preDeleteBoard } from 'store/boards/actions';

const BoardDeleteModal = ({ board: { id }, isShow, onClose }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteBoard(id));
    onClose();
  };

  const handleBefore = () => {
    dispatch(preDeleteBoard(id));
  };

  const handleCancel = () => {
    dispatch(cancelDeleteBoard());
  };

  return (
    <UaConfirm
      show={isShow}
      onHide={onClose}
      onOk={handleDelete}
      beforeAction={handleBefore}
      cancelAction={handleCancel}
      okLabel='Yes'
      cancelLabel='No'
      message='Are you sure you want to delete this board?'
      notification={{
        text: 'We delete this board in',
        autoClose: 3000,
      }}
    />
  );
};

export default BoardDeleteModal;
