import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Spinner } from 'react-bootstrap';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_KEY } from 'settings';

import './billing.styles.scss';

import PaymentCards from './payment-cards/payments-cards.component';
import RecentPayments from './recent-payments/recent-payments.component';
import BillingAccountInfo from './billing-account-info/billing-account-info.component';
import AddCardForm from './add-card-form/add-card-form.component';

import { getCards, getPlans, getTransactions } from 'store/payment/actions';
import {
  getCardsState,
  getPlansState,
  getTransactionsState,
} from 'store/payment/selectors';
import { isAuth as authSelector, getProfile } from 'store/auth/selectors';
import { getCurrentSubscription } from 'store/accounts/selectors';

const stripePromise = loadStripe(STRIPE_KEY, {
  locale: 'en',
});

const EnumBillingPages = {
  main: 'Main',
  subscription: 'Subscription',
  paymentDetails: 'Payment Details',
};

const Billing = () => {
  const dispatch = useDispatch();
  const { cards, cardsLoading } = useSelector(getCardsState);
  const { plans, plansLoading } = useSelector(getPlansState);
  const { transactions, transactionsLoading } =
    useSelector(getTransactionsState);
  const isAuth = useSelector(authSelector);
  const profile = useSelector(getProfile);
  const subscription = useSelector(getCurrentSubscription);

  const defaultPage = EnumBillingPages.main;

  const [page, setPage] = useState(defaultPage);
  const [addCardType, setAddCardType] = useState(null);
  const [planData, setPlanData] = useState(null);

  useEffect(() => {
    if (dispatch && isAuth) {
      dispatch(getCards());
      dispatch(getPlans());
      dispatch(getTransactions(profile.account_id));
    }
  }, [isAuth, profile.account_id]);

  const gotoAddCard = () => {
    setPage(EnumBillingPages.paymentDetails);
    setAddCardType('add_card');
  };
  const gotoAddCardFromPlans = (planData) => {
    setPage(EnumBillingPages.paymentDetails);
    setPlanData(planData);
    setAddCardType('from_plans');
  };

  const goBack = () => setPage(EnumBillingPages.main);

  if (cardsLoading || plansLoading || transactionsLoading || isEmpty(profile)) {
    return (
      <div className='billing-spinner-container'>
        <Spinner animation='border' className='billing-spinner' />
      </div>
    );
  }

  return (
    <div className='billing'>
      {page === EnumBillingPages.main && (
        <>
          <BillingAccountInfo
            plans={plans}
            subscription={subscription}
            account_id={profile.account_id}
            gotoAddCard={gotoAddCardFromPlans}
            goBack={goBack}
            cards={cards}
          />
          <PaymentCards
            gotoAddCard={gotoAddCard}
            cards={cards}
            isLoading={cardsLoading}
          />
          <RecentPayments
            transactions={transactions}
            isLoading={transactionsLoading}
          />
        </>
      )}

      {page === EnumBillingPages.paymentDetails && (
        <Elements stripe={stripePromise}>
          <AddCardForm
            goBack={goBack}
            cards={cards}
            addCardType={addCardType}
            planData={planData}
            profile={profile}
          />
        </Elements>
      )}
    </div>
  );
};

export default Billing;
