import React, { memo, useEffect, useState, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import dateFormat from 'utils/date';
import { usePrevious } from 'hooks/usePrevious';
import { BoardContext } from 'context/board-context/board-context';

import UaConfirmInput from 'components/common/ua-confirm-input/ua-confirm-input.component';
import UserAvatar from 'components/user-avatar/user-avatar.component';

import './project-update.styles.scss';

import { ReactComponent as Refresh } from 'assets/images/icons/refresh.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';

import { addProjectUpdate, editProjectUpdate } from 'store/projects/actions';

const UNFULL_COUNT = 2;

const ProjectUpdate = ({
  projectId,
  updates = [],
  isSeeAll = false,
  className = '',
  editing = true,
  isCard = false,
}) => {
  const dispatch = useDispatch();
  const { onSelectProjectCard, onResetOptions, projectCardOptions } =
    useContext(BoardContext);

  const inputRef = useRef();
  const editRef = useRef(null);
  const [isFullSee, setIsFullSee] = useState(false);
  const [addingUpdate, setAddingUpdate] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({
    id: null,
    value: '',
  });
  const previousProjectId = usePrevious(projectId, null);

  const maxLength = !isFullSee ? UNFULL_COUNT : updates.length;

  useEffect(() => {
    if (projectCardOptions.status && !isFullSee) {
      setIsFullSee(true);
      onResetOptions();
    }
  }, [projectCardOptions.status]);

  useEffect(() => {
    if (previousProjectId !== projectId && !projectCardOptions.status)
      setIsFullSee(false);
  }, [projectId, projectCardOptions.status]);

  const onSeeAllClick = () => {
    onSelectProjectCard({ id: projectId }, { status: true });
  };

  const onToggleSeeAll = () => setIsFullSee((prev) => !prev);

  const onAdd = (value) => {
    if (value) {
      dispatch(
        addProjectUpdate({
          id: projectId,
          status: value,
        })
      );
    }
    setAddingUpdate(false);
  };

  const onAddCancel = () => {
    setAddingUpdate(false);
  };

  const onUpdate = () => {
    dispatch(
      editProjectUpdate({ id: updateStatus.id, status: updateStatus.value })
    );
    setUpdateStatus({ id: null, value: '' });
  };

  const onUpdateCancel = () => {
    setUpdateStatus({ id: null, value: '' });
  };

  const handleBeginUpdate =
    ({ id, status }) =>
    () => {
      if (editing) setUpdateStatus({ id, value: status });
    };

  const onChangeEdit = (value) => {
    setUpdateStatus({ id: updateStatus.id, value });
  };

  const handleClickAddUpdate = () => {
    setAddingUpdate(true);
  };
  useEffect(() => {
    if (addingUpdate) {
      inputRef.current.focus();
    }
  }, [addingUpdate, inputRef]);

  useEffect(() => {
    if (updateStatus.id) {
      editRef.current.focus();
    }
  }, [updateStatus.id, editRef]);

  return (
    <div className={`project-widget project-update ${className}`}>
      <div className='project-widget__header'>
        <div className='project-widget__header-icon'>
          <Refresh />
        </div>
        <div className='project-widget__header-title'>Latest Update</div>
        <div className='project-widget__header-action'>
          {updates?.length > UNFULL_COUNT && !isSeeAll && (
            <div className='project-update__list-control'>
              <button
                onClick={onToggleSeeAll}
                className='project-update__action'>
                {isFullSee ? 'Show Less' : 'Show More'}
              </button>
            </div>
          )}

          {isSeeAll && (
            <button onClick={onSeeAllClick} className='project-update__action'>
              See all
            </button>
          )}
        </div>
      </div>
      <div className='project-widget__content'>
        {(updates && updates.length > 0) || addingUpdate ? (
          <div className='project-widget__list'>
            {updates.slice(0, maxLength).map((update) =>
              updateStatus.id === update.id ? (
                <div
                  className='project-widget__item-wrapper'
                  key={`project-update-${update.id}`}>
                  <UaConfirmInput
                    ref={editRef}
                    value={updateStatus.value}
                    onChange={onChangeEdit}
                    onSubmit={onUpdate}
                    onCancel={onUpdateCancel}
                    toastLabel='Maximum status update name length - 125 characters'
                    maxLength={125}
                  />
                </div>
              ) : (
                <div
                  className='project-widget__item-wrapper'
                  key={`project-update-${update.id}`}
                  onClick={handleBeginUpdate(update)}>
                  <div className='project-update__record'>
                    <div className='project-update__record-user'>
                      <UserAvatar user={update.user} width={30} height={30} />
                    </div>
                    <div className='project-update__record-description'>
                      {dateFormat(update.updated_at)} – {update.status}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <span className='project-widget__content-empty'>
            Update list is empty
          </span>
        )}

        <div className='project-update__add-container'>
          {addingUpdate ? (
            <div
              className={`project-widget__item-wrapper ${
                !addingUpdate ? 'hidden' : ''
              }`}>
              <UaConfirmInput
                ref={inputRef}
                onSubmit={onAdd}
                onCancel={onAddCancel}
                toastLabel='Maximum status update name length - 125 characters'
                maxLength={125}
              />
            </div>
          ) : (
            editing &&
            isCard && (
              <button
                className='project-update__add-update'
                onClick={handleClickAddUpdate}>
                <PlusIcon /> Add an Update
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProjectUpdate);
