import { put, takeLatest } from 'redux-saga/effects';
// actions
import { updateSocketState as action } from '../actions';

function* getSaga({ payload }) {
  try {
    yield put(action.request());
    yield put(action.success(payload));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* updateSocketState() {
  yield takeLatest(action.TRIGGER, getSaga);
}
