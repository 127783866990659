import React, { useState, useMemo, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';

import './transfer-ownership-modal.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import UaInput from 'components/common/ua-input/ua-input.component';
import UaButton from 'components/common/ua-button/ua-button.component';
import UserAvatar from 'components/user-avatar/user-avatar.component';

import { ReactComponent as CheckIcon } from 'assets/images/icons/check-circle-solid.svg';
import TransferSchema from './TransferSchema';

const TransferOwnershipModal = ({
  show,
  onHide,
  title,
  list,
  onTransferOwnership,
  isLoading,
  status,
  ownershipLoading,
}) => {
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (status?.success) {
      setError(null);
      onHide();
    } else if (status?.error) {
      setError(status.message);
    }
  }, [status, onHide]);

  const onSubmit = (values) => {
    onTransferOwnership(values);
  };

  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
    },
    initialErrors: {
      userId: '',
      password: '',
    },
    validationSchema: TransferSchema,
    onSubmit,
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    formik.handleBlur(event);
  };

  useEffect(() => {
    if (!show) {
      setSelectedUser(null);
      formik.setValues({
        userId: '',
        password: '',
      });
      formik.setFieldTouched('userId', false);
      formik.setFieldTouched('password', false);
    }
  }, [show]);

  useEffect(() => {
    formik.setFieldValue('userId', selectedUser);
  }, [selectedUser]);

  const filteredMembers = useMemo(
    () =>
      list.filter(({ user }) => (user.email.includes(search) || user.name.includes(search))  && user.is_active),
    [search, list]
  );

  const onUserClick = (id) => () => {
    setSelectedUser((prev) => (prev === id ? null : id));
  };

  const passwordError = () => {
    if (formik.touched.password) {
      return formik.errors.password || error;
    } else {
      return undefined;
    }
  };

  return (
    <UaModal
      show={show}
      onHide={onHide}
      title={title}
      noFooter={true}
      dialogClassName='transfer-ownership-modal'>
      <UaInput
        id='userSearch'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type='text'
        placeholder='Write the email address...'
      />
      <form onSubmit={formik.handleSubmit}>
        <ul className='transfer-ownership-list'>
          {isLoading ? (
            <Spinner animation='border' role='status' />
          ) : (
            filteredMembers.map(({ user: member }) => (
              <li
                key={`transfer-ownership-list-item-${member.id}`}
                className='transfer-ownership-list__item'
                onClick={onUserClick(member.id)}>
                <UserAvatar
                  className='transfer-ownership-list__item-avatar'
                  user={member}
                  width={25}
                  height={25}
                />
                <p className='transfer-ownership-list__item-name'>{`${member.firstname} ${member.lastname}`}</p>
                <CheckIcon
                  className={`transfer-ownership-list__item-check${
                    member.id !== selectedUser ? ' hidden' : ''
                  }`}
                />
              </li>
            ))
          )}
          {!filteredMembers.length && (
            <li className='transfer-ownership-list__item-empty'>
              There are no results to display
            </li>
          )}
        </ul>
        <div className='transfer-ownership-list__error'>
          {!!formik.touched.userId && formik.errors.userId}
        </div>

        <UaInput
          id='password'
          name='password'
          type='password'
          placeholder='Account password'
          value={formik.values.password}
          handleChange={handleChange}
          onBlur={formik.handleBlur}
          showError={true}
          error={passwordError()}
        />
        <div className='transfer-ownership__submit-container'>
          <UaButton
            type='submit'
            isLoading={ownershipLoading}
            className='transfer-ownership__submit'>
            Transfer
          </UaButton>
        </div>
      </form>
    </UaModal>
  );
};

export default TransferOwnershipModal;
