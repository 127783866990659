import React from 'react';

import './not-found.styles.scss';

function NotFound() {
  return (
    <div className='not-found-container'>
      <h1>404: Page not found</h1>
    </div>
  );
}

export default NotFound;
