import { node, string } from 'prop-types';

import './auth.styles.scss';

import Logo from '../../components/logo/logo.component';
import PageTitle from 'components/page-title/page-title.component';
import routes from '../../utils/routes';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

const PAGE_NAMES = {
  [routes.registration]: 'registration-page',
};

const AuthLayout = ({ className, children, title }) => {
  const location = useLocation();

  return (
    <div
      className={clsx('auth-layout', className, PAGE_NAMES[location.pathname])}>
      <PageTitle title={title} />
      <a
        href='https://unmanage.io'
        referrerPolicy='no-referrer'
        className='auth-layout__logo-wrapper'>
        <Logo />
      </a>
      {children}
    </div>
  );
};

AuthLayout.propTypes = {
  className: string,
  children: node,
};

export default AuthLayout;
