import { useContext, useState, Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';
import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';
import UATooltip from 'components/common/ua-tooltip/ua-tooltip.components';

import { ReactComponent as Look } from 'assets/images/icons/look.svg';
import { ReactComponent as ShowIcon } from 'assets/images/icons/eye-regular.svg';
import { ReactComponent as HideIcon } from 'assets/images/icons/eye-regular-close.svg';

import './show-options-dropdown.styles.scss';
import { BoardContext, DEFAULT_LIST_OPTIONS } from 'context/board-context/board-context';
import useDevice from 'hooks/useDevice';
import { TABLET_QUERY } from 'settings';
import isEqual from 'lodash/isEqual';

const LIST_DATA = [
  {
    label: 'Project card',
    key: 'projectCard',
    divider: true,
    showMobile: true,
  },
  {
    label: 'Archived Projects',
    key: 'archivedProjects',
    divider: false,
    showMobile: true,
  },
  {
    label: 'Completed Projects',
    key: 'completedProjects',
    divider: true,
    showMobile: true,
  },
  {
    label: 'Project Title',
    key: 'projectTitle',
    divider: false,
    showMobile: true,
  },
  {
    label: 'Project Team',
    key: 'projectTeam',
    divider: false,
    showMobile: false,
  },
  {
    label: 'Client',
    key: 'client',
    divider: false,
    showMobile: false,
  },
  {
    label: 'Priority',
    key: 'priority',
    divider: false,
    showMobile: false,
  },
  {
    label: 'Tags',
    key: 'tags',
    divider: false,
    showMobile: false,
  },
  {
    label: 'Status',
    key: 'status',
    divider: false,
    showMobile: true,
  },
  {
    label: 'Due Date',
    key: 'dueDate',
    divider: false,
    showMobile: false,
  },
];

const ShowOptionsDropdown = () => {
  const { listShowOption, onListShowChange } = useContext(BoardContext);
  const [isShow, setIsShow] = useState(false);
  const isTablet = useDevice(TABLET_QUERY);

  const onToggleHandler = (isOpen, e, metadata) => {
    if (metadata.source !== 'select') {
      setIsShow(isOpen);
    }
  };

  const onSelect = (key) => () => {
    if (key === 'projectCard') {
      setIsShow(false);
    }
    onListShowChange(key);
  };

  return (
    <UaDropdown
      className={clsx('show-options-dropdown toolbar-btn toolbar-btn--dropdown', {
        active: !isEqual(listShowOption, DEFAULT_LIST_OPTIONS),
      })}
      dropdownVariant='dark'
      show={isShow}
      onToggle={onToggleHandler}
      buttonContent={
        <UATooltip label='Show/Hide' show={!isShow}>
          <div className='show-options-dropdown-overlay'>
            <Look />
          </div>
        </UATooltip>
      }
      withIcon={false}>
      {LIST_DATA.map(
        ({ label, key, divider, showMobile }) =>
          ((showMobile && isTablet) || !isTablet) && (
            <Fragment key={key}>
              <Dropdown.Item
                className={`${listShowOption[key] ? 'show' : 'hide'}`}
                onSelect={onSelect(key)}>
                <ShowIcon className='show-icon' />
                <HideIcon className='hide-icon' />
                {label}
              </Dropdown.Item>
              {divider && <Dropdown.Divider />}
            </Fragment>
          )
      )}
    </UaDropdown>
  );
};

export default ShowOptionsDropdown;
