// After creating or deleting items whose order can be controlled by DnD,
// errors may occur due to the list of ordering data being out of date.
// This function updates the given list by adding or removing items.
// This is because we store this user list as JSON in the settings.

const dndDataFilter = (positionArr, itemArr, positionKey, itemKey) => {
  let filter;
  const idsArr = itemArr.map((item) => item[itemKey]);

  // Filter actually data
  if (positionArr.length > itemArr.length) {
    // Delete from positionArr not actual data (deleted from itemArr)
    filter = positionArr.filter((item) => idsArr.includes(item[positionKey]));
  } else if (positionArr.length < itemArr.length) {
    // Add to positionArr new data from itemArr (if exist)
    filter = itemArr.map((item, index) => ({ [positionKey]: item[itemKey], order: index }));
  } else {
    // Do nothing, return actually positionArr
    filter = positionArr;
  }

  // Clear from duplicates
  return filter.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t[positionKey] === thing[positionKey]
      ))
  );
};

export default dndDataFilter;
