import { object, string, ref } from 'yup';

const ResetPasswordSchema = object().shape({
  password: string()
    .matches(/\d/g, 'Password should contains at least one number')
    .matches(/[A-Z]/g, 'Password should contains at least one capital letter')
    .matches(/\S/g, 'Password should not contains spaces')
    .min(8, 'Password should contain from 8 to 20 characters')
    .max(20, 'Password should contain from 8 to 20 characters')
    .required('This field is required'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], 'Passwords don’t match')
    .required('This field is required'),
});

export default ResetPasswordSchema;
