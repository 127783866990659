import Select from 'react-select';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import useFilter from 'hooks/useFilter';

import ProjectFilter from '../filter.component';

import { ReactComponent as FlagIcon } from 'assets/images/icons/user.svg';
import { ReactComponent as AngleDown } from 'assets/images/icons/angle-down.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-circle.svg';

import { getClients } from 'store/clients/selectors';
import { getProjects as projectsSelector } from 'store/projects/selectors';
import { useMemo } from 'react';

const DropdownIndicator = () => <AngleDown width='28px' height='28px' />;

const FilterClients = ({ value, onChange }) => {
  const clients = useSelector(getClients);
  const projects = useSelector(projectsSelector);

  const filteredClients = useMemo(() => {
    const projectClientIds = projects.map(({ client_id }) => client_id);

    return clients.filter(({ id }) => projectClientIds.includes(id));
  }, [projects, clients]);

  const filter = useFilter({
    options: filteredClients,
    selected: value,
    onChange,
  });

  const CustomOption = (props) => {
    return (
      <div
        className={clsx('project-filter-select__option', {
          'project-filter-select__option--focused': props.isFocused,
        })}
        {...props.innerProps}>
        {props.data.name}
      </div>
    );
  };

  return (
    <ProjectFilter
      icon={<FlagIcon />}
      name='Clients'
      body={
        <>
          {value.length > 0 && (
            <div className='project-filter-selected-items project-filter-selected-items--flex'>
              {value.map((item) => (
                <div
                  className='project-filter-selected-item'
                  key={`priority-${item.id}`}>
                  {item.name}
                  <button
                    onClick={filter.onDelete(item)}
                    className='project-filter-selected-item__delete'>
                    <CloseIcon height={20} width={20} />
                  </button>
                </div>
              ))}
            </div>
          )}
          <Select
            className='project-filter-select'
            classNamePrefix='project-filter-select'
            value=''
            options={filter.suggestions}
            name='priority'
            isSearchable={false}
            isClearable={false}
            menuPlacement='top'
            placeholder='Select client'
            components={{
              DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: null,
              Option: CustomOption,
            }}
            onChange={filter.onChange}
          />
        </>
      }
    />
  );
};

export default FilterClients;
