import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import './board-team.styles.scss';

import UserAvatar from '../../user-avatar/user-avatar.component';
import UserSearchModal from '../../user-search-modal/user-search-modal.component';

import { getAccountMembers } from 'store/accounts/selectors';

import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import {
  addBoardMember,
  removeBoardMember,
} from 'store/boards/actions';

const BoardTeam = ({
  board = {},
  emptyState = '\u2014',
  editing = true,
  addButton = true,
  className = '',
}) => {
  const dispatch = useDispatch();
  const { members: membersList, isLoading } = useSelector(getAccountMembers);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const { members = [] } = board;

  const onMemberClick = (member) => {
    const isBoardMember = members.find((item) => item.id === member.id);

    if (!isBoardMember) {
      dispatch(
        addBoardMember({
          board_id: board.id,
          email: member.email,
        })
      );
    } else {
      dispatch(
        removeBoardMember({
          board_id: board.id,
          user_id: member.id,
        })
      );
    }
  };

  return (
    <div className={`project-team ${className}`}>
      <div
        className={`project-team__members ${
          !addButton && 'project-team__editable'
        }`}
        {...(!addButton &&
          editing && { onClick: () => setShowSearchModal(true) })}>
        {!members.length && !addButton
          ? emptyState
          : members.map((member) => (
              <UserAvatar
                key={`project-team-${member.id}`}
                user={member}
                width={30}
                height={30}
                className='project-team__members-item'
              />
            ))}
      </div>
      {addButton && editing && (
        <button
          className='project-team__add-button'
          onClick={() => setShowSearchModal(true)}>
          <PlusIcon />
        </button>
      )}
      <UserSearchModal
        title='Members'
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        onSelect={onMemberClick}
        list={membersList}
        selectedList={members}
        isLoading={isLoading}
      />
    </div>
  );
};

BoardTeam.propTypes = {
  project: PropTypes.object,
  addButton: PropTypes.bool,
  className: PropTypes.string,
};

export default BoardTeam;
