import { object, string, ref } from 'yup';

const SignUpSchema = object().shape({
  email: string()
    .email('Invalid email address')
    .required('This field is required'),
  firstName: string().required('This field is required'),
  lastName: string().required('This field is required'),
  password: string()
    .matches(/\d/g, 'Password should contains at least one number')
    .matches(/[A-Z]/g, 'Password should contains at least one capital letter')
    .matches(/\S/g, 'Password should not contains spaces')
    .min(8, 'Password should contain from 8 to 20 characters')
    .max(20, 'Password should contain from 8 to 20 characters')
    .required('This field is required'),
  passwordConfirmation: string()
    .oneOf([ref('password'), null], 'Passwords don’t match')
    .required('This field is required'),
});

export default SignUpSchema;
