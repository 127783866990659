import { createSelector } from 'reselect';

export const getProjectsState = (state) => state.projects;
export const getProjects = (state) => getProjectsState(state).list;
export const getProjectsPagination = (state) =>
  getProjectsState(state).pagination;
export const isProjectsListLoading = (state) =>
  getProjectsState(state).listLoading;
export const getPriorities = (state) => getProjectsState(state).priorities;
export const getTags = (state) => getProjectsState(state).tags;
export const getStatuses = (state) => getProjectsState(state).statuses;


export const getProjectByIds = createSelector(
  [
    getProjects,
    // Take the second arg, `category`, and forward to the output selector
    (state, ids) => ids
  ],
  // Output selector gets (`items, category)` as args
  (items, ids) => items.filter(item => ids.includes(item.id))
);
