function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function getDueSoon() {
  const today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);
  const tomorrowString = formatDate(tomorrow);
  const nextWeek = today.setDate(today.getDate() + 7);
  const nextWeekString = formatDate(nextWeek);

  return { gte: tomorrowString, lte: nextWeekString };
}

export function getOverdue() {
  const today = new Date();
  const todayString = formatDate(today);
  const beginDate = new Date(0);
  const beginDateString = formatDate(beginDate);

  return { gte: beginDateString,  lte: todayString };
}
