import clsx from 'clsx';
import { arrayOf, number, shape, string } from 'prop-types';

import UATooltip from 'components/common/ua-tooltip/ua-tooltip.components';

import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/check.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/clock.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/times-circle.svg';

const BoardItemKPI = ({ className, kpi }) => {
  const typesKPI = {
    active: {
      className: `${className}--active`,
      icon: <CalendarIcon />,
      label: 'active project',
      labelPlural: 'active projects',
    },
    completed: {
      className: `${className}--completed`,
      icon: <CheckIcon />,
      label: 'project completed',
      labelPlural: 'projects completed',
    },
    due_soon: {
      className: `${className}--due`,
      icon: <ClockIcon />,
      label: ' project due soon',
      labelPlural: 'projects due soon',
    },
    overdue: {
      className: `${className}--overdue`,
      icon: <CloseIcon />,
      label: 'project overdue',
      labelPlural: 'projects overdue',
    },
  };

  const displayTooltipLabel = (value, typeObj) => {
    if ( value === 1 ) {
      return `${value} ${typeObj.label}`;
    }

    return `${value} ${typeObj.labelPlural}`;
  };

  return kpi.map(({ type, value }) => {
    if (!(type in typesKPI)) {
      return null;
    }

    return (
      <UATooltip label={displayTooltipLabel(value, typesKPI[type])}>
        <div key={type} className={clsx(className, typesKPI[type].className)}>
          {typesKPI[type].icon}
          {value}
        </div>
      </UATooltip>
    );
  });
};

BoardItemKPI.propTypes = {
  className: string,
  kpi: arrayOf(
    shape({
      type: string,
      value: number,
    })
  ),
};

BoardItemKPI.defaultProps = {
  className: 'kpi',
  kpi: [],
};

export default BoardItemKPI;
