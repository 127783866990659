import { put, takeLatest, call } from 'redux-saga/effects';
import history from '../../../history';

import { verifyEmail as confirmEmailAction } from '../actions';

import { verifyEmail as confirmEmailApi } from '../api';
import routes from 'utils/routes';

function* confirmSaga({ payload }) {
  try {
    yield put(confirmEmailAction.request());

    const response = yield call(confirmEmailApi, payload);

    yield put(confirmEmailAction.success(response));

    history.replace(routes.emailVerification, { isEmailConfirmed: true  });
  } catch (error) {
    console.error(error);
    yield put(confirmEmailAction.failure(error.response));
  } finally {
    yield put(confirmEmailAction.fulfill());
  }
}

export default function* verifyEmail() {
  yield takeLatest(confirmEmailAction.TRIGGER, confirmSaga);
}
