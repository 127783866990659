import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './profile-dropdown.styles.scss';

import UaDropdown from 'components/common/ua-dropdown/ua-dropdown.component';

import { getProfile } from 'store/auth/selectors';
import {
  getAccounts,
  getCurrentAccount,
  getIsSubscribe,
  isCurrentAccountOwner,
} from 'store/accounts/selectors';
import UserAvatar from 'components/user-avatar/user-avatar.component';
import routes from 'utils/routes';
import AccountLogo from 'components/account-logo/account-logo.component';

import { ReactComponent as UserIcon } from 'assets/images/icons/user.svg';
import { ReactComponent as SwitchIcon } from 'assets/images/icons/switch.svg';
import { ReactComponent as AngleDownIcon } from 'assets/images/icons/collapse.svg';
import { ReactComponent as ToolsIcon } from 'assets/images/icons/tools.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-circle.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus-circle-solid.svg';

import { ROLES, IS_PRODUCTION } from 'settings';
import { createAccount } from 'store/accounts/actions';

import LogRocket from 'logrocket';

const ProfileDropdown = ({ collapse, logout, onAccountSwitch }) => {
  const dispatch = useDispatch();

  const profile = useSelector(getProfile);
  const accounts = useSelector(getAccounts);
  const currentAccount = useSelector(getCurrentAccount);
  const isAccountOwner = useSelector(isCurrentAccountOwner);
  const isSubscribe = useSelector(getIsSubscribe);

  const [isExpanded, setIsExpanded] = useState(false);

  const isEmptyAccount = !Object.keys(currentAccount).length;

  const onToggleExpanded = () => setIsExpanded((prev) => !prev);

  const onCloseDropdown = (isOpen) => {
    if (!isOpen) setIsExpanded(false);
  };

  const hasPersonalAccount = useMemo(() => {
    const ownerAccountIndex = accounts?.findIndex(
      ({ account_users }) =>
        account_users && account_users[0].role.slug === ROLES.OWNER
    );
    return ownerAccountIndex >= 0;
  }, [accounts]);

  const onAccountCreate = () => {
    dispatch(createAccount());
  };

  useEffect(() => {
    if ( IS_PRODUCTION ) {
      LogRocket.identify(profile.id, {
        name: `${profile.firstname} ${profile.lastname}`,
        email: profile.email,
        account_id: profile.account_id,
      });
    }
  }, [profile]);

  return (
    <UaDropdown
      className={`profile-dropdown ${
        collapse ? 'profile-dropdown--collapse' : ''
      }`}
      onToggle={onCloseDropdown}
      buttonContent={
        <>
          <div className='profile-dropdown__avatar'>
            <UserAvatar user={profile} width={40} height={40} />
          </div>
          <p className='profile-dropdown__name'>{`${
            profile.firstname || 'User'
          } ${profile.lastname || 'Name'}`}</p>
        </>
      }>
      {isSubscribe && (
        <Dropdown.Item as={NavLink} to={`${routes.settings}?tab=profile`}>
          <UserIcon />
          Profile
        </Dropdown.Item>
      )}

      {accounts?.length > 1 && (
        <>
          <button
            className='profile-dropdown__account-accordion'
            onClick={onToggleExpanded}
            aria-controls='expanded-account-list'
            aria-expanded={isExpanded}>
            <SwitchIcon />
            Switch Account
            <AngleDownIcon
              className={`arrow-icon ${isExpanded ? 'expanded' : ''}`}
            />
          </button>
          <Collapse in={isExpanded}>
            <div id='expanded-account-list'>
              {accounts?.map((account) => (
                <Dropdown.Item
                  key={account.id}
                  className={
                    account.id === currentAccount.id && 'selected-account'
                  }
                  eventKey={account.id}
                  onSelect={onAccountSwitch}>
                  <>
                    <AccountLogo account={account} />
                    <p className='account-switcher__name'>
                      {account.name || 'Without name'}
                    </p>
                  </>
                </Dropdown.Item>
              ))}
            </div>
          </Collapse>
        </>
      )}

      {!hasPersonalAccount && (
        <Dropdown.Item onClick={onAccountCreate}>
          <PlusIcon height={25} width={25} fill='#888' /> Create account
        </Dropdown.Item>
      )}

      {!isEmptyAccount && isAccountOwner && isSubscribe && (
        <Dropdown.Item
          as={NavLink}
          to={`${routes.settings}?tab=account-settings`}>
          <ToolsIcon />
          Settings
        </Dropdown.Item>
      )}

      <Dropdown.Divider />
      <Dropdown.Item className='link-logout' onClick={logout}>
        <CloseIcon /> Log Out
      </Dropdown.Item>
    </UaDropdown>
  );
};

export default ProfileDropdown;
