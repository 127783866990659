import { all, fork } from 'redux-saga/effects';

import getBoard from 'store/boards/sagas/getBoard';
import getGuestBoard from './getGuestBoard';
import add from './add';
import edit from './edit';
import deleteBoard from './deleteBoard';
import setIsFavorite from './setIsFavorite';
import togglePersonal from './togglePersonal';
import getStatuses from './getStatuses';
import addStatus from './addStatus';
import editStatus from './editStatus';
import deleteStatus from './deleteStatus';
import dndBoardStatus from './dndStatus';
import dndBoardProject from './dndBoardProject';
import getTags from './getTags';
import addTag from './addTag';
import editTag from './editTag';
import deleteTag from './deleteTag';
import removeBoardMember from './removeBoardMember';
import addBoardMember from './addBoardMember';
import shareBoard from './shareBoard';
import copy from 'store/boards/sagas/copy';
import preDeleteBoard from 'store/boards/sagas/preDeleteBoard';

export default function* boards() {
  yield all([
    fork(getBoard),
    fork(add),
    fork(copy),
    fork(edit),
    fork(deleteBoard),
    fork(setIsFavorite),
    fork(togglePersonal),
    fork(getStatuses),
    fork(addStatus),
    fork(editStatus),
    fork(deleteStatus),
    fork(dndBoardStatus),
    fork(dndBoardProject),
    fork(getTags),
    fork(addTag),
    fork(editTag),
    fork(deleteTag),
    fork(getGuestBoard),
    fork(removeBoardMember),
    fork(addBoardMember),
    fork(shareBoard),
    fork(preDeleteBoard),
  ]);
}
