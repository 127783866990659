import { put, takeLatest, call, select } from 'redux-saga/effects';

// actions
import { downloadData as action } from '../actions';
// api
import { downloadData as api } from '../api';
import { getCurrentAccount } from 'store/accounts/selectors';

function* getSaga({ payload }) {
  try {
    yield put(action.request());
    const { name } = yield select(getCurrentAccount);
    yield call(api, { ...payload, name });
    yield put(action.success(payload));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* downloadData() {
  yield takeLatest(action.TRIGGER, getSaga);
}
