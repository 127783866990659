import isEmpty from 'lodash/isEmpty';
import { createSelector, defaultMemoize } from 'reselect';

export const getAuthState = (state) => state.auth;
export const isAuth = defaultMemoize(
  (state) => !isEmpty(getAuthState(state).profile)
);

export const getProfile = (state) => getAuthState(state).profile;
export const isProfileLoading = (state) => getAuthState(state).isLoading;

export const isProfilePhotoLoading = (state) =>
  getAuthState(state).profilePhotoLoading;

export const isProfileUpdateLoading = (state) =>
  getAuthState(state).profileUpdateLoading;

export const getProfileUpdateError = (state) =>
  getAuthState(state).profileUpdateError;

export const getProfileUpdateSuccess = (state) =>
  getAuthState(state).profileUpdateSuccess;

const getUsersSelector = (state) => getAuthState(state).users;
const getCurrentUserEmailSelector = (state) =>
  getAuthState(state).currentUserEmail;

export const getAuthIsLoading = (state) => getAuthState(state).isLoading;

export const getAuthErrors = (state) => {
  const { error } = getAuthState(state);

  return {
    ...error,
    hasError: error.message || isEmpty(error.fields),
  };
};

export const getCurrentUser = createSelector(
  getUsersSelector,
  getCurrentUserEmailSelector,
  ({ users, currentUserEmail }) =>
    users?.find(({ email }) => email === currentUserEmail)
);

export const getProfileSettings = (state) => getProfile(state).settings;
export const getVerificationError = (state) =>
  getAuthState(state).verificationError;

export const isVerificationLoading = (state) =>
  getAuthState(state).isVerificationLoading;
