import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';

import { getProjects } from 'store/projects/selectors';
import { getBoardPositions } from '../dashboards/selectors';

const setOrderForBoards = (boards, positions) => {
  const formattedObj = positions?.reduce((a, b) => {
    a[b.id] = b.order;
    return a;
  }, {});

  return boards.map((board) => ({
    ...board,
    position: formattedObj[board.id],
  }));
};

const setOrderForStatuses = ({ statuses, positions }) =>
  statuses.map((status) => {
    const order =
      positions.find(({ status_id }) => status_id === status.id)?.order ??
      Infinity;

    return { ...status, order };
  });

const setOrderForProjects = ({ statuses, projects, positions }) => {
  const orderedProjects = [];
  for (let status of statuses) {
    const projectsByStatus = projects.filter(
      (project) => project.status_id === status.id
    );
    orderedProjects.push(
      ...projectsByStatus.map((project) => {
        const order =
          positions.find(({ project_id }) => project_id === project.id)
            ?.order ?? Infinity;

        return { ...project, order };
      })
    );
  }

  return orderedProjects;
};

export const getBoard = (state) => getBoardsState(state).board;
export const getStatuses = (state) => getBoardsState(state).statuses;
export const getStatusesPositions = (state) =>
  getBoardsState(state).statusesPositions;
export const getSortedStatuses = createSelector(
  getStatuses,
  getStatusesPositions,
  (statuses, positions) => {
    if (isEmpty(positions)) {
      return statuses;
    }

    const newStatuses = setOrderForStatuses({
      statuses,
      positions,
    });

    return sortBy(newStatuses, ['order']);
  }
);

export const getBoardProjectsPositions = (state) =>
  getBoard(state).projects_positions || [];

export const getProjectsPositions = (state) =>
  getBoardsState(state).projectsPositions;

export const getSortedProjects = createSelector(
  getStatuses,
  getProjects,
  getProjectsPositions,
  (statuses, projects, positions) => {
    if (isEmpty(positions)) {
      return projects;
    }

    const newProjects = setOrderForProjects({
      statuses,
      projects,
      positions,
    });

    return sortBy(newProjects, ['order']);
  }
);
export const getProjectsPositionsByStatus = (statusId) => (state) => {
  const positions = getProjectsPositions(state);

  return positions.filter(({ status_id }) => status_id === statusId);
};

export const getBoardsState = (state) => state.boards;
export const getBoards = (state) => getBoardsState(state).list;
export const getBoardError = (state) => getBoardsState(state).boardError;

export const getBoardsSearch = (state) => getBoardsState(state).searchBoard;

export const getArchivedBoards = createSelector(
  getBoards,
  getBoardPositions,
  (boards, { archived }) => {
    const filtered = boards.filter(({ is_archived }) => is_archived);

    return sortBy(setOrderForBoards(filtered, archived), ['position']);
  }
);

export const getActiveBoards = createSelector(
  getBoards,
  getBoardPositions,
  (boards, { all }) => {
    const filtered = boards.filter(({ is_archived }) => !is_archived);

    return sortBy(setOrderForBoards(filtered, all), ['position']);
  }
);

export const getFavoriteBoards = createSelector(
  getBoards,
  getBoardPositions,
  (boards, { favorites }) => {
    const filtered = boards.filter(
      ({ is_archived, is_favorite }) => !is_archived && is_favorite
    );

    return sortBy(setOrderForBoards(filtered, favorites), ['position']);
  }
);

export const getBoardById = createSelector(
  [
    getBoards,
    (state, id) => id
  ],
  (items, id) => items.find(item => item.id === id)
);

export const getTags = (state) => getBoardsState(state).tags;

export const getCopyLoading = (state) => getBoardsState(state).copyLoading;
export const getBoardLoading = (state) => getBoardsState(state).boardLoading;
