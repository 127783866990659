import React from 'react';
import { bool, func, string } from 'prop-types';
import clsx from 'clsx';
import noop from 'lodash/noop';

import './ua-stripe-input-wrap.styles.scss';

const OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#333',
      fontFamily: "'Roboto', sans-serif",
      letterSpacing: '-0.3px',
    },
  },
};

const UaStripeInputWrap = ({ inputComponent, hasError, className, label }) => {
  return (
    <div
      className={clsx('ua-input', className, {
        'ua-input--invalid': hasError,
      })}>
      <label className='ua-input__label'>{label}</label>
      <div className='ua-stripe-field__input ua-input__control'>
        {inputComponent({ options: OPTIONS })}
      </div>
    </div>
  );
};

UaStripeInputWrap.propTypes = {
  inputComponent: func,
  hasErrors: bool,
  className: string,
  label: string,
};

UaStripeInputWrap.defalutProps = {
  inputComponent: noop,
};

export default UaStripeInputWrap;
