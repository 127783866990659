import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  copyProjects,
  getGuestProjects,
  getProjects,
} from 'store/projects/actions';
import { BoardContext } from 'context/board-context/board-context';
import { EnumProjectModals, STATUSES } from 'settings';
import BoardsModal from 'components/board/boards-modal/boards-modal.component';
import { getBoard } from 'store/boards/selectors';

const ProjectCopyModal = () => {
  const dispatch = useDispatch();
  const board = useSelector(getBoard);
  const {
    isGuest,
    checkedProjects,
    setCheckedProjects,
    isActionModalOpen,
    onCloseActionModal,
    projectFilter,
    listShowOption,
    queryValue,
    tableSortingShift,
    tableSortingList,
  } = useContext(BoardContext);

  const getProjectsAction = isGuest ? getGuestProjects : getProjects;
  const chooseBoardId = isGuest ? board?.uuid : board?.id;

  const uncompletedStatuses = useMemo(
    () =>
      board?.statuses
        ?.filter(({ slug }) => slug !== STATUSES.COMPLETED)
        .map(({ id }) => id),
    [board?.statuses]
  );

  const handleOk = async (boardId) => {
    await dispatch(copyProjects({ ids: checkedProjects, boardId }));
    onCloseActionModal();
    setCheckedProjects([]);

    if (boardId === board.id) {
      dispatch(
        getProjectsAction({
          id: chooseBoardId,
          sort: tableSortingList,
          isArchived: listShowOption.archivedProjects,
          statuses: !listShowOption.completedProjects && uncompletedStatuses,
          search: queryValue,
          filters: projectFilter,
          shift: tableSortingShift,
        })
      );
    }
  };

  return (
    <BoardsModal
      show={isActionModalOpen === EnumProjectModals.COPY}
      title='Copy project to:'
      onHide={onCloseActionModal}
      onOk={handleOk}
    />
  );
};

export default ProjectCopyModal;
