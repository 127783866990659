import React from 'react';

import UaInput from 'components/common/ua-input/ua-input.component';
import ProjectFilter from '../filter.component';

import { ReactComponent as TextIcon } from 'assets/images/icons/align-left.svg';
import closeIcon from 'assets/images/icons/close.svg';

const FilterTitle = ({ value, onChange }) => {
  return (
    <ProjectFilter
      icon={
        <>
          <TextIcon />
        </>
      }
      name='Project Title'
      body={
        <UaInput
          placeholder='Search a project name'
          type='text'
          value={value}
          handleChange={(e) => onChange(e.target.value)}
          handleIconClick={() => onChange('')}
          showClearField
          clearFieldIcon={closeIcon}
        />
      }
    />
  );
};

export default FilterTitle;
