import { put, takeLatest, call } from 'redux-saga/effects';
// actions
import { closeAccount as action } from '../actions';
// api
import { closeAccount as api } from '../api';

import { logout } from '../../auth/actions';

function* getSaga({ payload }) {
  try {
    yield put(action.request());

    yield call(api, payload);

    yield put(action.success());
    yield put(logout());
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* closeAccount() {
  yield takeLatest(action.TRIGGER, getSaga);
}
