const routes = {
  dashboard: '/',
  settings: '/settings',
  board: '/board/:id',
  emailVerification: '/email-verification',
  emailConfirmation: '/email-confirmation',
  boardGuest: '/board/shared/:id',
  registration: '/registration',
  login: '/login',
  resetPassword: '/reset-password',
  ownerAccept: '/accounts/:accountId/users/:userId/owner-accept',
};

export default routes;
