import React, { useEffect, useRef, useState } from 'react';

import './ua-editable-field.styles.scss';
import { toast } from 'react-toastify';
import safariCheck from 'utils/safariCheck';
import firefoxCheck from 'utils/firefoxCheck';

const UaEditableField = ({
  tag,
  onBlur,
  onChange,
  customEditing,
  closeOnEsc = true,
  editOnClick = true,
  text = '',
  className = '',
  contentClass = '',
  inputClass = '',
  maxLength = 50,
  editInput = 'input',
}) => {
  const Tag = tag;
  const EditInput = editInput;
  const inputRef = useRef();
  const toastId = useRef(null);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => {
    if (value !== text) {
      setValue(text);
    }
  }, [text]);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const handleClick = () => {
    if (editOnClick) {
      setEditing(true);
    }
  };

  const handleBlur = (e) => {
    if (!customEditing) {
      setEditing(false);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setEditing(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && (safariCheck() || firefoxCheck())) {
      inputRef.current.blur();
    }
    if (event.key === 'Escape' && closeOnEsc) {
      setEditing(false);
    }
  };

  const handleChange = (event) => {
    const text = event.target.value;
    if (text.length > maxLength) {
      if (!toastId.current) {
        toastId.current = toast(`Maximum length - ${maxLength} characters`, {
          autoClose: 2000,
          pauseOnHover: false,
          hideProgressBar: true,
          closeButton: true,
          type: 'error',
          onClose: () => toastId.current = null
        });
      } else {
        toast.update(toastId.current);
      }
    } else {
      setValue(event.target.value);
      if (onChange) onChange(event.target.value);
    }
  };

  return (
    <div
      className={`ua-editable-field ${className} ${
        customEditing || editing ? 'ua-editable-field--editing' : ''
      }`}>
      <Tag
        className={`ua-editable-field__content ${contentClass} ${
          editOnClick ? '' : 'ua-editable-field__block-editing'
        }`}
        onClick={handleClick}>
        {value}
      </Tag>
      <form onSubmit={onSubmit}>
        <EditInput
          className={`ua-editable-field__input ${inputClass} ${editInput}`}
          ref={inputRef}
          value={value || ''}
          onBlur={handleBlur}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
        />
      </form>
    </div>
  );
};

export default UaEditableField;
