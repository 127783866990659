import UaModal from 'components/common/ua-modal/ua-modal.component';

import 'components/board/board-duplicate-tags-modal/board-duplicate-tags-modal.styles.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getBoardById } from 'store/boards/selectors';
import clsx from 'clsx';
import TagEditItem from 'components/board/board-duplicate-tags-modal/tag-edit-item.component';
import { Button } from 'react-bootstrap';

const BoardDuplicateTagsModal = (props) => {
  const { projects, onChangeTagName, boardId } = props;
  const [activeTab, setActiveTab] = useState(0);

  const board = useSelector((state) => getBoardById(state, boardId));

  const onHide = () => {
    props.onHide();
  };

  const onOk = () => {
    props.onOk();
  };

  const onSelect = (key) => () => {
    if (activeTab !== key) setActiveTab(key);
  };

  return (
    <UaModal
      {...props}
      dialogClassName='boards-modal boards-modal-lg boards-modal-duplicate-tags'
      onHide={onHide}
      okOnly={true}
      onOk={onOk}>
      <p className='sub-title'>
        These tags already exist in "{board?.name}". You need to manage each
        project tags separately:
      </p>
      <p className='help-text'>
        Please rename them, or click Save all to merge duplicates
      </p>

      <div className='tab-container'>
        <div className='tab-column projects-title'>Projects</div>
        <div className='tab-column tags-title'>Tags</div>
      </div>

      <div className='tab-container'>
        <div className='tab-column tab-projects'>
          {projects.map((project, index) => (
            <>
              {index > 0 && <span className='stepper-line' />}

              <button
                className={clsx('tab-link', {
                  active: index === activeTab,
                })}
                key={project.id}
                onClick={onSelect(index)}>
                <span className='icon-position'>{index + 1}</span>
                {project.name}
              </button>
            </>
          ))}
        </div>

        <div className='tab-column tab-tags tag-border-left'>
          {projects[activeTab]?.duplicatedTags.map((tag, index) => (
            <TagEditItem
              key={`${projects[activeTab]?.id}-${index}`}
              name={tag.name}
              color={tag.color}
              index={index}
              onTagChange={onChangeTagName}
              projectId={projects[activeTab]?.id}
            />
          ))}
        </div>
      </div>

      <div className='button-container'>
        <div className='button-row'>
          <Button
            variant='outline-secondary'
            className='button-navigate'
            type='button'
            onClick={() => setActiveTab(activeTab - 1)}
            disabled={activeTab < 1}>
            Previous project
          </Button>

          <Button
            variant='outline-primary'
            className='button-navigate'
            type='button'
            onClick={() => setActiveTab(activeTab + 1)}
            disabled={activeTab >= projects.length - 1}>
            Next project
          </Button>
        </div>
      </div>
    </UaModal>
  );
};

export default BoardDuplicateTagsModal;
