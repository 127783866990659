import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import './user-search-modal.styles.scss';

import UaModal from 'components/common/ua-modal/ua-modal.component';
import Search from 'components/search/search.component';
import UserAvatar from 'components/user-avatar/user-avatar.component';

import { ReactComponent as CheckIcon } from 'assets/images/icons/check-circle-solid.svg';
import { inviteAccountUser, getRolesList } from 'store/accounts/actions';
import { getAccountRoles, getCurrentAccount } from 'store/accounts/selectors';
import { getProfile } from 'store/auth/selectors';
import emailCheck from 'utils/emailCheck';
import { ROLES } from 'settings';
import useFuse from '../../hooks/useFuse';
import { useParams } from 'react-router-dom';
import {LimitAddUsersModal} from 'modals/limit-add-users-modal.component';

const UserSearchModal = ({
  show,
  onHide,
  title,
  list,
  selectedList,
  onSelect,
  isLoading,
  type = 'member',
  projectId,
}) => {
  const dispatch = useDispatch();
  const { id: board_id } = useParams();
  const { account_id } = useSelector(getProfile);
  const currentAccount = useSelector(getCurrentAccount);
  const { roles } = useSelector(getAccountRoles);

  const [search, setSearch] = useState('');
  const [click, setClick] = useState(false);

  const limitModalRef = useRef();

  useEffect(() => {
    if (account_id && !roles.length) {
      dispatch(getRolesList());
    }
  }, [account_id, roles]);

  useEffect(() => {
    if (!show) setSearch('');
  }, [show]);

  const memberSearchResults = useFuse(list, search, {
    threshold: 0.03,
    ignoreLocation: true,
    keys: ['user.name', 'user.email'],
  });

  const selectedMembers = useMemo(
    () => selectedList.map((item) => (type === 'member' ? item.id : item.id)),
    [selectedList, type]
  );

  const onUserInvite = () => {
    if (!!currentAccount.seats && list.length >= currentAccount.seats) {
      limitModalRef.current?.onOpenModal();
    } else {
      dispatch(
        inviteAccountUser({
          accountId: account_id,
          data: {
            email: search,
            role_id: roles.find(({ slug }) => slug === ROLES.USER).id,
            board_id,
            project_id: projectId,
          },
        })
      );
      setSearch('');
    }
  };

  const onUserClick =
    ({ user }) =>
    () => {
      if (click) return;

      onSelect(user);
      setClick(true);
    };

  useEffect(() => {
    setClick(false);
  }, [selectedList]);

  const renderLabel = (user) => {
    if (user.firstname) return `${user.firstname} ${user.lastname}`;
    return user.email;
  };

  const renderList = () => {
    const isSearchEmail = /@./g.test(search);
    const isValidEmail = emailCheck(search);

    if (isLoading) return <Spinner animation='border' role='status' />;

    if (memberSearchResults.length) {
      return memberSearchResults.map((member) => (
        <li
          key={`user-search-list-item-${member.user.id}`}
          className='user-search-list__item'
          onClick={onUserClick(member)}>
          <UserAvatar
            className='user-search-list__item-avatar'
            user={member.user}
            width={25}
            height={25}
          />
          <p className='user-search-list__item-name'>
            {renderLabel(member.user)}
          </p>
          <CheckIcon
            className={`user-search-list__item-check${
              !selectedMembers.includes(member.user_id) ? ' hidden' : ''
            }`}
          />
        </li>
      ));
    }

    if (!memberSearchResults.length && isSearchEmail) {
      return (
        <li className='user-search-list__item--center'>
          <Button disabled={!isValidEmail} onClick={onUserInvite}>
            Invite by email
          </Button>
        </li>
      );
    }

    return (
      <li className='user-search-list__item-empty'>
        There are no results to display
      </li>
    );
  };

  return (
    <>
      <LimitAddUsersModal ref={limitModalRef} />

      <UaModal
        show={show}
        onHide={onHide}
        title={title}
        noFooter={true}
        dialogClassName='user-search-modal'>
        <Search
          id='userSearch'
          className='user-search'
          value={search}
          handleSearch={(e) => setSearch(e.target.value)}
          type='text'
          placeholder='Search'
          handleClearField={() => setSearch('')}
          showClearField
        />
        <ul className='user-search-list'>{renderList()}</ul>
      </UaModal>
    </>
  );
};

export default UserSearchModal;
