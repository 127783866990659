export const getPaymentState = (state) => state.payment;

export const getCardsState = (state) => ({
  cards: getPaymentState(state).cards,
  cardsLoading: getPaymentState(state).cardsLoading,
});

export const getTransactionsState = (state) => ({
  transactions: getPaymentState(state).transactions,
  transactionsLoading: getPaymentState(state).transactionsLoading,
});

export const getPlansState = (state) => ({
  plans: getPaymentState(state).plans,
  plansLoading: getPaymentState(state).plansLoading,
});
