import { put, takeLatest, call, select } from 'redux-saga/effects';

import { togglePersonal as action } from '../actions';
import { togglePersonal as api } from '../api';

import { isPersonalDashboardSelector } from 'store/dashboards/selectors';

function* addSaga({ payload }) {
  try {
    yield put(action.request());
    yield call(api, payload);
    const isPersonalDashboard = yield select(isPersonalDashboardSelector);
    yield put(action.success({ ...payload, isPersonalDashboard }));
  } catch (error) {
    console.error(error);
    yield put(action.failure(error.response));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* togglePersonal() {
  yield takeLatest(action.TRIGGER, addSaga);
}
