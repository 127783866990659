import React from 'react';
import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';
import { string } from 'prop-types';

import './recent-payments.styles.scss';

import RecentPaymentsTable from '../tables/recent-payments-table/recent-payments-table.component';

const RecentPayments = ({ className, transactions, isLoading }) => {
  return (
    <div className={clsx('recent-payments billing__card', className)}>
      <h3 className='recent-payments__title billing__title '>
        Recent Payments
      </h3>

      {!isLoading ? (
        <RecentPaymentsTable payments={transactions} />
      ) : (
        <Spinner animation='border' role='status' />
      )}
    </div>
  );
};

RecentPayments.propTypes = {
  className: string,
};

export default RecentPayments;
