import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';

import './project-tasks.styles.scss';

import { addTask, deleteTask, editTask } from 'store/projects/actions';

import { ReactComponent as Check } from 'assets/images/icons/check-circle.svg';
import { ReactComponent as Trash } from 'assets/images/icons/trash-alt.svg';
import { ReactComponent as Edit } from 'assets/images/icons/edit-solid.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { ReactComponent as CircleCheck } from 'assets/images/icons/check.svg';
import { ReactComponent as Circle } from 'assets/images/icons/circle.svg';
import { BoardContext } from 'context/board-context/board-context';
import { usePrevious } from 'hooks/usePrevious';
import UaConfirmInput from 'components/common/ua-confirm-input/ua-confirm-input.component';

const UNFULL_COUNT = 2;

const ProjectTasks = ({
  projectId,
  tasks = [],
  isSeeAll = false,
  className = '',
  editing = true,
  isCard = false,
}) => {
  const dispatch = useDispatch();
  const { onSelectProjectCard, onResetOptions, projectCardOptions } =
    useContext(BoardContext);

  const inputRef = useRef();
  const editRef = useRef();
  const [addingTask, setAddingTask] = useState(false);
  const [updateTask, setUpdateTask] = useState({
    id: null,
    value: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isFullSee, setIsFullSee] = useState(false);
  const previousProjectId = usePrevious(projectId, null);

  const maxLength = !isFullSee ? UNFULL_COUNT : tasks.length;

  const filterTask = tasks.filter(({ is_completed }) =>
    !isCompleted ? !is_completed : true
  );

  useEffect(() => {
    if (projectCardOptions.tasks && !isFullSee) {
      setIsFullSee(true);
      onResetOptions();
    }
  }, [projectCardOptions.tasks]);

  useEffect(() => {
    if (previousProjectId !== projectId && !projectCardOptions.tasks)
      setIsFullSee(false);
  }, [projectId, projectCardOptions.tasks]);

  useEffect(() => {
    if (addingTask) {
      inputRef.current.focus();
    }
  }, [addingTask, inputRef]);

  useEffect(() => {
    if (updateTask.id) {
      editRef.current.focus();
    }
  }, [updateTask.id, editRef]);

  const onToggleSeeAll = () => setIsFullSee((prev) => !prev);

  const onToggleCompleted = () => {
    setIsCompleted((prev) => !prev);
  };

  const onSeeAllClick = () => {
    onSelectProjectCard({ id: projectId }, { tasks: true });
  };

  const onAdd = (value) => {
    if (value) {
      dispatch(
        addTask({
          projectId,
          title: value,
        })
      );
    }
    setAddingTask(false);
  };

  const onAddCancel = () => {
    setAddingTask(false);
  };

  const handleDeleteTask = (taskId) => (e) => {
    e.stopPropagation();
    dispatch(
      deleteTask({
        projectId,
        taskId,
      })
    );
  };

  const handleEdit =
    ({ id, title }) =>
    (e) => {
      e.stopPropagation();
      setUpdateTask({ id, value: title });
    };

  const handleChange = (value) => {
    setUpdateTask({ id: updateTask.id, value });
  };

  const onUpdate = () => {
    dispatch(
      editTask({
        projectId,
        taskId: updateTask.id,
        data: { title: updateTask.value },
      })
    );
    setUpdateTask({ id: null, value: '' });
  };

  const onUpdateCancel = () => {
    setUpdateTask({ id: null, value: '' });
  };

  const handleToggleCompleted = (taskId, is_completed) => () => {
    if (editing) {
      dispatch(
        editTask({
          projectId,
          taskId,
          data: { is_completed },
        })
      );
    }
  };

  return (
    <div className={`project-widget project-tasks ${className}`}>
      <div className='project-widget__header'>
        <div className='project-widget__header-icon'>
          <Check />
        </div>
        <div className='project-widget__header-title'>Tasks</div>
        <div className='project-widget__header-action'>
          {isCard && (
            <label className='project-tasks-completed--label'>
              <input
                type='checkbox'
                value={isCompleted}
                onChange={onToggleCompleted}
              />
              <span className='project-tasks-completed--span'>
                Show Completed
              </span>
            </label>
          )}

          {tasks?.length > UNFULL_COUNT && !isSeeAll && (
            <button onClick={onToggleSeeAll} className='project-update__action'>
              {isFullSee ? 'Show Less' : 'Show More'}
            </button>
          )}

          {isSeeAll && (
            <button onClick={onSeeAllClick} className='project-update__action'>
              See all
            </button>
          )}
        </div>
      </div>
      <div className='project-widget__content'>
        {filterTask.length ? (
          <div className='project-tasks__list'>
            {filterTask.slice(0, maxLength).map((task) =>
              task.id === updateTask.id ? (
                <div className='project-tasks__task' key={`task-${task.id}`}>
                  <UaConfirmInput
                    ref={editRef}
                    value={updateTask.value}
                    onChange={handleChange}
                    onSubmit={onUpdate}
                    onCancel={onUpdateCancel}
                  />
                </div>
              ) : (
                <div
                  className='project-tasks__task'
                  key={`task-${task.id}`}
                  onClick={handleToggleCompleted(task.id, !task.is_completed)}>
                  <div className='project-tasks__task-check'>
                    {task.is_completed ? <CircleCheck /> : <Circle />}
                  </div>
                  <div className='project-tasks__task-description'>
                    {task.title}
                  </div>
                  {editing && (
                    <div className='project-tasks__task-action'>
                      <button
                        className='project-tasks__task-edit'
                        onClick={handleEdit(task)}>
                        <Edit />
                      </button>
                      <button
                        className='project-tasks__task-remove'
                        onClick={handleDeleteTask(task.id)}>
                        <Trash fill='#FB404B' opacity={0.5} />
                      </button>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        ) : (
          <span className='project-widget__content-empty'>
            Task list is empty
          </span>
        )}
        {editing && isCard && (
          <div
            className={`project-tasks__task ${
              !addingTask
                ? 'project-tasks__task--action'
                : 'project-tasks__task--input'
            }`}>
            {!addingTask ? (
              <button
                className='project-tasks__add-task'
                onClick={() => setAddingTask(true)}>
                <PlusIcon /> Add Task
              </button>
            ) : (
              <UaConfirmInput
                ref={inputRef}
                onSubmit={onAdd}
                onCancel={onAddCancel}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ProjectTasks.defaultProps = {
  tasks: [],
};

export default ProjectTasks;
