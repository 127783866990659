import React, { useContext } from 'react';
import clsx from 'clsx';

import { BOARD_VIEW, EnumBoardPopups } from 'settings';

import UATooltip from 'components/common/ua-tooltip/ua-tooltip.components';

import { ReactComponent as Filter } from 'assets/images/icons/filter.svg';
import { ReactComponent as File } from 'assets/images/icons/file.svg';
import { ReactComponent as Graph } from 'assets/images/icons/graph.svg';
import { ReactComponent as Look } from 'assets/images/icons/look.svg';
import { ReactComponent as LookClose } from 'assets/images/icons/look-close.svg';
import useQuery from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { BoardContext } from 'context/board-context/board-context';

function KanbanControls() {
  const query = useQuery();
  const history = useHistory();
  const {
    isGuest,
    kanbanHiddenColumns,
    setKanbanHiddenColumns,
    onOpenBoardCard,
  } = useContext(BoardContext);
  const view = query.get('view');
  const isKanbanView = view === BOARD_VIEW.KANBAN;

  const onViewChange = () => {
    if (isKanbanView) {
      query.set('view', BOARD_VIEW.LIST);
      history.replace({ search: query.toString() });
    }
  };

  const toggleHiddenColumns = () => setKanbanHiddenColumns((prev) => !prev);

  return (
    <>
      <div className='toolbar'>
        <UATooltip label='Show filter'>
          <button
            className='toolbar-btn'
            onClick={onOpenBoardCard(EnumBoardPopups.filter)}>
            <div>
              <Filter />
            </div>
          </button>
        </UATooltip>
      </div>

      {!isGuest && (
        <div className='toolbar ml-14'>
          <UATooltip label='Show hidden columns'>
            <button
              className={clsx('toolbar-btn', {
                active: kanbanHiddenColumns,
              })}
              onClick={toggleHiddenColumns}>
              <div>{kanbanHiddenColumns ? <LookClose /> : <Look />}</div>
            </button>
          </UATooltip>
        </div>
      )}

      <div className='toolbar ml-14'>
        <UATooltip label='List view'>
          <button className='toolbar-btn' onClick={onViewChange}>
            <div>
              <File />
            </div>
          </button>
        </UATooltip>
        <UATooltip label='Kanban view'>
          <button
            className={clsx('toolbar-btn', {
              active: view === 'kanban',
            })}>
            <div>
              <Graph />
            </div>
          </button>
        </UATooltip>
      </div>
    </>
  );
}

export default KanbanControls;
