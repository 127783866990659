import React from 'react';
import { array, string } from 'prop-types';
import clsx from 'clsx';
import { STRIPE_INVOICE_TYPES } from 'settings';

import './recent-payments-table.styles.scss';

import dateFormat from 'utils/date';

const RecentPaymentsTable = ({ payments, className }) => {
  return (
    <div className={clsx('recent-payments-table', className)}>
      <table>
        <thead>
          <tr>
            <th className='title'>
              <span className='col-label'>Date</span>
            </th>
            <th className='title'>
              <span className='col-label'>Reference</span>
            </th>

            <th className='title'>
              <span className='col-label'>Price</span>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {payments.map(
            ({
              id,
              created_at,
              stripe_invoice_number,
              stripe_status,
              amount,
              hasError,
              invoice_pdf,
              hosted_invoice_url,
            }) => (
              <tr key={id} className={clsx({ 'row--dangerous': hasError })}>
                <td className='col-date'>{dateFormat(created_at)}</td>
                <td className='col-reference'>
                  {stripe_invoice_number || '—'}
                </td>
                <td className='col-price'>{(amount / 100).toFixed(2)}</td>
                <td className={`col-status ${stripe_status}`}>
                  {stripe_status}
                </td>
                <td className='col-action'>
                  {(stripe_status !== STRIPE_INVOICE_TYPES.UPCOMING && stripe_status !== STRIPE_INVOICE_TYPES.DRAFT) && (
                    <>
                      {stripe_status === STRIPE_INVOICE_TYPES.OPEN ? (
                        <a
                          className='recent-payments-table__action-view'
                          href={hosted_invoice_url}
                          target='_blank'
                          rel='noreferrer'>
                          Pay
                        </a>
                      ) : (
                        <a
                          className='recent-payments-table__action-view'
                          href={invoice_pdf}
                          download={stripe_invoice_number}>
                          Download
                        </a>
                      )}
                    </>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

RecentPaymentsTable.propTypes = {
  payments: array,
  className: string,
};

RecentPaymentsTable.defalutProps = {
  payments: [],
};

export default RecentPaymentsTable;
